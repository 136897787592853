import I18n from 'I18n';
import { UNIFIED_EVENTS_QL } from 'reporting-data/constants/dataTypes';
import { isConfigReport } from 'reporting-data/tsTypes/reportTypes';
import { getRawDataType, getDataType } from 'reporting-data/report/configReportGetters';
export const getUnifiedEvent = report => {
  const dataType = getRawDataType(report);
  return dataType === UNIFIED_EVENTS_QL ? getDataType(report) : undefined;
};
export const SMS = 'sms';
export const CTA = 'cta';
const translate = optionKey => I18n.text(`report-customization-components.customization-components.unified-event-dropdown.options.${optionKey}`);
export const UnifiedEventGroups = {
  [SMS]: ['e_sent_shortmessage', 'e_delivered_shortmessage', 'e_clicked_link_in_shortmessage', 'e_failed_to_deliver_shortmessage', 'e_failed_to_send_shortmessage', 'e_dropped_shortmessage', 'e_handed_shortmessage_to_agent'],
  [CTA]: ['e_viewed_web_interactive', 'e_clicked_web_interactive']
};
export const getUEDropdownGroup = event => {
  const groups = Object.keys(UnifiedEventGroups);
  return groups.find(group => UnifiedEventGroups[group].includes(event));
};
export const getUEDropdownOptions = group => {
  return UnifiedEventGroups[group].map(event => ({
    text: translate(event),
    value: event
  }));
};
export const getIsReportValidForUnifiedEventDropdown = ({
  report
}) => isConfigReport(report) && [UNIFIED_EVENTS_QL].includes(getRawDataType(report));