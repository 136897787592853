import getIn from 'transmute/getIn';
import { fromJS } from 'immutable';
// @ts-expect-error migrate upstream types
import FilterOperatorBoolInput from 'customer-data-filters/components/operator/FilterOperatorBoolInput';
// @ts-expect-error migrate upstream types
import FilterOperatorDateInput from 'customer-data-filters/components/operator/FilterOperatorDateInput';
// @ts-expect-error migrate upstream types
import FilterOperatorDurationInput from 'customer-data-filters/components/operator/FilterOperatorDurationInput';
// @ts-expect-error migrate upstream types
import FilterOperatorElasticsearchTextQueryInput from 'customer-data-filters/components/operator/FilterOperatorElasticsearchTextQueryInput';
// @ts-expect-error migrate upstream types
import FilterOperatorEnumInput from 'customer-data-filters/components/operator/FilterOperatorEnumInput';
// @ts-expect-error migrate upstream types
import FilterOperatorMultiStringInput from 'customer-data-filters/components/operator/FilterOperatorMultiStringInput';
// @ts-expect-error migrate upstream types
import FilterOperatorNumberInput from 'customer-data-filters/components/operator/FilterOperatorNumberInput';
// @ts-expect-error migrate upstream types
import FilterOperatorOwnerInput from 'customer-data-filters/components/operator/FilterOperatorOwnerInput';
// @ts-expect-error migrate upstream types
import FilterOperatorPercentageInput from 'customer-data-filters/components/operator/FilterOperatorPercentageInput';
// @ts-expect-error migrate upstream types
import FilterOperatorTeamInput from 'customer-data-filters/components/operator/FilterOperatorTeamInput';
// @ts-expect-error migrate upstream types
import FilterOperatorTextInput from 'customer-data-filters/components/operator/FilterOperatorTextInput';
import * as DisplayTypes from 'customer-data-filters/filterQueryFormat/DisplayTypes';
import { NotWildCardEqual, WildCardEqual
// @ts-expect-error migrate upstream types
} from 'customer-data-filters/filterQueryFormat/operator/Operators';
// @ts-expect-error migrate upstream types
import Known from 'customer-data-filters/filterQueryFormat/operator/Known';
// @ts-expect-error migrate upstream types
import Equal from 'customer-data-filters/filterQueryFormat/operator/Equal';
// @ts-expect-error migrate upstream types
import Less from 'customer-data-filters/filterQueryFormat/operator/Less';
// @ts-expect-error migrate upstream types
import In from 'customer-data-filters/filterQueryFormat/operator/In';
// @ts-expect-error migrate upstream types
import InRollingDateRange from 'customer-data-filters/filterQueryFormat/operator/InRollingDateRange';
import * as ExternalOptionTypes from 'customer-data-objects/property/ExternalOptionTypes';
import { BOOLEAN, DATE, DATE_TIME } from 'customer-data-objects/property/PropertyTypes';
import { EMAIL_SUBSCRIPTION, SITE_CONTENT, SURVEY_QUESTION
// @ts-expect-error migrate upstream types
} from 'reference-resolvers/constants/ReferenceObjectTypes';
import { getPropertyResolver } from 'reference-resolvers-lite/utils/getPropertyResolver';
// @ts-expect-error migrate upstream types
import { get as isUnified } from 'reporting-data/retrieve/unified/supported';
import { buildSpecialOptionsByReferenceType, getLabelString, isSupportedExternalOption, canUseReferenceResolverLiteForFilterInput
// @ts-expect-error migrate upstream types
} from 'reporting-snowflake/relational/modify/filters/xo-filter-utils';
import { PROPERTY_TYPES, TYPES
// @ts-expect-error migrate upstream types
} from 'reporting-snowflake/relational/metadata/references/external/external-mapping';
// @ts-expect-error migrate upstream types
import { unifiedPropertyResolvers } from 'reporting-enablement/components/reportEditors/UnifiedFilterEditor/utils';
import { HUBSPOT_OBJECT_COORDINATES_TO_DATA_TYPE } from 'reporting-data/constants/objectCoordinates';
import { dataTypeSettings } from 'reporting-data/retrieve/inboundDb/common/dataTypes';
import { getExternalOptionsReferenceType, getName, getReferencedObjectType, getType
// @ts-expect-error migrate upstream types
} from './propertySchema';
import { isGenericDateRangeProperty } from './dateRangeHelpers';
import { isListMembershipProperty, getReferenceTypeForListMembershipProperty } from 'platform-dashboard-ui/filter/utils/listMembershipFilterUtils';
import { isValidOperator } from 'customer-data-filters/filterQueryFormat/operator/Operator';
// @ts-expect-error migrate deps
import * as Operators from 'customer-data-filters/filterQueryFormat/operator/Operators';
import I18n from 'I18n';
export const getReferenceTypeForReportingProperties = ({
  dataSource,
  property
}) => {
  const dataType = HUBSPOT_OBJECT_COORDINATES_TO_DATA_TYPE.get(dataSource) || dataSource;
  const propertyName = getName(property);
  const reportingPropertyReferenceType = getIn([dataType, 'propertyReferenceType', propertyName], dataTypeSettings);
  return reportingPropertyReferenceType;
};

//based on getReferenceType & getReferenceTypeForResolverLite from reporting-snowflake's external-mapping file
const getReferenceType = ({
  dataSource,
  property
}) => {
  const propertyName = getName(property);
  const referencedObjectType = getReferencedObjectType(property);
  const externalOptionsReferenceType = getExternalOptionsReferenceType(property);
  return referencedObjectType || TYPES.getIn([dataSource, propertyName]) || PROPERTY_TYPES.get(propertyName) || getIn([dataSource, propertyName], unifiedPropertyResolvers) || getReferenceTypeForReportingProperties({
    dataSource,
    property
  }) || getReferenceTypeForListMembershipProperty({
    dataSource,
    property
  }) || externalOptionsReferenceType;
};

// @param dataSource - the object type id of the selected data source
// @param property - property in DSFieldRecord format
// @param resolvers - reference resolvers object with reference type mapped to the associated resolver
const getFamilyValueResolver = ({
  dataSource,
  property,
  resolvers
}) => {
  const referenceResolverLite = isSupportedExternalOption(dataSource, property) && canUseReferenceResolverLiteForFilterInput(property) ? getPropertyResolver({
    property,
    objectTypeId: dataSource
  }) : null;
  return referenceResolverLite || resolvers[getReferenceType({
    dataSource,
    property
  })] || null;
};
export const getSharedFilterOperatorProps = ({
  currencyCode,
  dataSource,
  property,
  resolvers,
  userId
}) => {
  return {
    currencyCode,
    filterFamily: dataSource,
    getFamilyValueResolver: () => getFamilyValueResolver({
      dataSource,
      property,
      resolvers
    }),
    getLabelString: () => getLabelString(property),
    getReferencedObjectType: () => getReferenceType({
      dataSource,
      property
    }),
    getSpecialOptionsForReferenceType: buildSpecialOptionsByReferenceType(getType(property), userId),
    isXoEnabled: true
  };
};

// copied from reporting-snowflake's xo-filter-utils file
const INPUT_OVERRIDES = fromJS({
  '0-4': {
    hs_attachment_ids: FilterOperatorMultiStringInput
  }
});
export function getInputComponentFromReferencedObjectType(dataSource, property) {
  const referencedObjectType = getReferenceType({
    dataSource,
    property
  });
  if (referencedObjectType === ExternalOptionTypes.TEAM) {
    return FilterOperatorTeamInput;
  }
  if (referencedObjectType === ExternalOptionTypes.OWNER) {
    return FilterOperatorOwnerInput;
  }
  if (referencedObjectType === ExternalOptionTypes.USER) {
    return FilterOperatorEnumInput;
  }
  return null;
}
export function getInputComponentFromFieldType(operator) {
  const field = operator.field;
  const fieldType = field.displayType || field.type;
  if (fieldType === DisplayTypes.StringDisplayType && [WildCardEqual, NotWildCardEqual].includes(operator.constructor)) {
    return FilterOperatorElasticsearchTextQueryInput;
  }

  // HACK: This is for a special case in ListSegClassic and should not be
  // relied upon to remain the same.
  if (fieldType === DisplayTypes.StringDisplayType && operator.constructor.isIterableField('value')) {
    return FilterOperatorMultiStringInput;
  }
  switch (fieldType) {
    case DisplayTypes.BooleanDisplayType:
      return FilterOperatorBoolInput;
    case DisplayTypes.DateDisplayType:
    case DisplayTypes.DatetimeDisplayType:
      return FilterOperatorDateInput;
    case DisplayTypes.EnumerationDisplayType:
    case SITE_CONTENT:
    case SURVEY_QUESTION:
      return FilterOperatorEnumInput;
    case EMAIL_SUBSCRIPTION:
      return FilterOperatorEnumInput;
    case DisplayTypes.NumberDisplayType:
      return FilterOperatorNumberInput;
    case DisplayTypes.PercentageDisplayType:
      return FilterOperatorPercentageInput;
    default:
      return FilterOperatorTextInput;
  }
}

// based on getInputComponent in reporting-snowflake's xo-filter-utils file
export function getInputComponent(dataSource, property, operator) {
  const {
    name
  } = property;
  const maybeOverriddenInput = INPUT_OVERRIDES.getIn([dataSource, name]);
  if (maybeOverriddenInput) {
    return maybeOverriddenInput;
  }
  const referencedObjectTypeInputComponent = getInputComponentFromReferencedObjectType(dataSource, property);
  if (referencedObjectTypeInputComponent !== null) {
    return referencedObjectTypeInputComponent;
  }
  const field = operator.field;
  if (field.type === 'number' && field.numberDisplayHint === 'duration') {
    return FilterOperatorDurationInput;
  }
  return getInputComponentFromFieldType(operator);
}
const isDateProperty = propertyType => [DATE, DATE_TIME].includes(propertyType);
export const getDefaultOperatorForProperty = (dataSource, property) => {
  const propertyType = getType(property);
  const propertyName = getName(property);
  if (propertyType === BOOLEAN) {
    return Equal.of(property);
  }
  if (isUnified(dataSource)) {
    return isDateProperty(propertyType) ? Less.of(property) : Equal.of(property);
  }
  if (isListMembershipProperty(propertyName)) {
    return In.of(property);
  }
  if (isGenericDateRangeProperty(propertyName) || isDateProperty(propertyType) // Known.of operators will not be used for dynamic date range filter groups
  ) {
    return InRollingDateRange.of(property);
  }
  return Known.of(property);
};
export const validateFilterOperator = operator => {
  if (!isValidOperator(operator)) {
    return {
      isValid: false,
      message: I18n.text('dashboardHeader.dashboardFilters.filterOperator.validationMessages.invalidOperator')
    };
  }
  const Operator = Operators[operator.name];
  if (Operator === Operators.InRange) {
    const lowValue = operator.value;
    const highValue = operator.highValue;
    if (lowValue > highValue) {
      return {
        isValid: false,
        message: I18n.text('dashboardHeader.dashboardFilters.filterOperator.validationMessages.invalidDateRange')
      };
    }
  }
  return {
    isValid: true
  };
};