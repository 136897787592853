// @ts-expect-error Untyped dependency
import CustomWidgets from 'reporting-ui-components/components/customWidgets/constants/widgets';
// @ts-expect-error Untyped dependency
import getDefaultFrequency from 'reporting-ui-components/lib/getDefaultFrequency';
import { isFiscalYearEnabled } from 'reporting-enablement/components/reportEditors/common/isFiscalYearEnabled';
import * as Frequency from 'reporting-data/constants/frequency';
import { RANGE_TYPE, RANGE_TYPE_LIST } from './range';
import { DIMENSIONS, getCustomWidgetType } from './salesAnalyticsHelpers';
const TIME_UNITS = {
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  QUARTER: 'QUARTER',
  YEAR: 'YEAR'
};
const RangesByUnit = {
  [TIME_UNITS.DAY]: [RANGE_TYPE.THIS_DAY, RANGE_TYPE.LAST_DAY],
  [TIME_UNITS.WEEK]: [RANGE_TYPE.THIS_WEEK, RANGE_TYPE.THIS_ENTIRE_WEEK, RANGE_TYPE.LAST_WEEK, RANGE_TYPE.NEXT_WEEK],
  [TIME_UNITS.MONTH]: [RANGE_TYPE.THIS_MONTH, RANGE_TYPE.THIS_ENTIRE_MONTH, RANGE_TYPE.LAST_MONTH, RANGE_TYPE.NEXT_MONTH, RANGE_TYPE.CUSTOM_MONTH],
  [TIME_UNITS.QUARTER]: [RANGE_TYPE.THIS_QUARTER, RANGE_TYPE.THIS_ENTIRE_QUARTER, RANGE_TYPE.LAST_QUARTER, RANGE_TYPE.NEXT_QUARTER, RANGE_TYPE.CUSTOM_QUARTER, RANGE_TYPE.THIS_FISCAL_QUARTER, RANGE_TYPE.THIS_ENTIRE_FISCAL_QUARTER, RANGE_TYPE.LAST_FISCAL_QUARTER, RANGE_TYPE.NEXT_FISCAL_QUARTER],
  [TIME_UNITS.YEAR]: [RANGE_TYPE.THIS_YEAR, RANGE_TYPE.THIS_ENTIRE_YEAR, RANGE_TYPE.LAST_YEAR, RANGE_TYPE.NEXT_YEAR, RANGE_TYPE.CUSTOM_YEAR, RANGE_TYPE.THIS_FISCAL_YEAR, RANGE_TYPE.THIS_ENTIRE_FISCAL_YEAR, RANGE_TYPE.LAST_FISCAL_YEAR, RANGE_TYPE.NEXT_FISCAL_YEAR]
};
const FUTURE_RANGES = [RANGE_TYPE.THIS_ENTIRE_WEEK, RANGE_TYPE.NEXT_WEEK, RANGE_TYPE.THIS_ENTIRE_MONTH, RANGE_TYPE.NEXT_MONTH, RANGE_TYPE.THIS_ENTIRE_QUARTER, RANGE_TYPE.THIS_ENTIRE_FISCAL_QUARTER, RANGE_TYPE.NEXT_QUARTER, RANGE_TYPE.THIS_ENTIRE_YEAR, RANGE_TYPE.THIS_ENTIRE_FISCAL_YEAR, RANGE_TYPE.NEXT_YEAR];
const QUOTA_RANGES = [RANGE_TYPE.CUSTOM_MONTH, RANGE_TYPE.CUSTOM_QUARTER, RANGE_TYPE.CUSTOM_YEAR];
const FISCAL_RANGES = [RANGE_TYPE.THIS_FISCAL_QUARTER, RANGE_TYPE.THIS_ENTIRE_FISCAL_QUARTER, RANGE_TYPE.LAST_FISCAL_QUARTER, RANGE_TYPE.NEXT_FISCAL_QUARTER, RANGE_TYPE.THIS_FISCAL_YEAR, RANGE_TYPE.THIS_ENTIRE_FISCAL_YEAR, RANGE_TYPE.LAST_FISCAL_YEAR, RANGE_TYPE.NEXT_FISCAL_YEAR];
const getRangeOptionsForTimeUnits = timeUnits => timeUnits.reduce( /* eslint-disable-next-line hubspot-dev/no-reduce-accumulator-copy */
(ranges, timeUnit) => ranges.concat(RangesByUnit[timeUnit]), []);
const getRangeOptionsForQuotaReport = (grouping, intraperiod) => {
  const {
    MONTH,
    QUARTER,
    YEAR
  } = TIME_UNITS;
  if (grouping === DIMENSIONS.DATE) {
    if (intraperiod) {
      return getRangeOptionsForTimeUnits([MONTH]);
    }
    return getRangeOptionsForTimeUnits([QUARTER, YEAR]);
  }
  return getRangeOptionsForTimeUnits([MONTH, QUARTER, YEAR]);
};
export const isValidRangeForReport = (range, report, grouping, intraperiod, gates) => {
  if (FISCAL_RANGES.includes(range) && !isFiscalYearEnabled(report, gates)) {
    return false;
  }
  const customWidgetType = getCustomWidgetType(report);
  if (customWidgetType === CustomWidgets.SALES_QUOTA) {
    return getRangeOptionsForQuotaReport(grouping, intraperiod).includes(range);
  }
  if (QUOTA_RANGES.includes(range)) {
    return false;
  }
  if (FUTURE_RANGES.includes(range) && customWidgetType === CustomWidgets.PIPELINE_SNAPSHOTS) {
    return false;
  }
  return true;
};
export const getRangeOptionsForReport = (report, grouping, intraperiod, gates) => RANGE_TYPE_LIST.filter(range => isValidRangeForReport(range, report, grouping, intraperiod, gates));
const FrequencyOrder = [Frequency.DAY, Frequency.WEEK, Frequency.MONTH, Frequency.QUARTER, Frequency.YEAR];
export const getAllowedFrequenciesForRangeType = (range, report, intraperiod) => {
  const customWidgetType = getCustomWidgetType(report);
  if (customWidgetType === CustomWidgets.SALES_QUOTA) {
    if (intraperiod) {
      return [Frequency.DAY, Frequency.WEEK];
    }
    return [Frequency.MONTH];
  }
  return FrequencyOrder;
};
export const getDefaultFrequencyForRangeType = (dateRange, report, intraperiod) => {
  const defaultFrequency = getDefaultFrequency(dateRange.toJS());
  const allowedFrequencies = getAllowedFrequenciesForRangeType(dateRange.get('rangeType'), report, intraperiod);
  if (allowedFrequencies.includes(defaultFrequency)) {
    return defaultFrequency;
  }
  return allowedFrequencies.at(-1);
};