const updateContentTypeCode = breakdown => {
  const metadata = breakdown.metadata;
  if (metadata) {
    const scpContentType = metadata.SCP_CONTENT_TYPE;
    const contentTypeCode = metadata.CONTENT_TYPE_CODE;
    if (contentTypeCode === 6 && scpContentType) {
      metadata.CONTENT_TYPE_CODE = `${contentTypeCode}|${scpContentType}`;
      breakdown.metadata = metadata;
    }
  }
  return breakdown;
};
export const preprocess = () => response => {
  if (response.breakdowns) {
    response.breakdowns = response.breakdowns.map(updateContentTypeCode);
  }
  return response;
};