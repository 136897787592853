'use es6';

import { isCustom, isCustomDatasetReport, isSalesAnalyticsCustomWidget, isSnowflakeReportTemplateException, isSnowflake } from '../../../lib/report-logic';
import { useHasRequiredAttributionReportScope } from '../../../access/scopes/hooks/useHasRequiredAttributionReportScope';
import { useHasCustomReportingAccess, useHasDatasetsAccess, useHasDatasetsRead, useHasSalesAnalyticsAccess } from '../../../access/scopes/hooks/useHasScope';
import useUserInfo from '../../../access/useUserInfo';
import { LACKS_CUSTOM_REPORTING, LACKS_DATASETS_ACCESS, LACKS_DATASETS_READ, LACKS_ENTERPRISE_ATTRIBUTION_REPORTING, LACKS_PRO_ATTRIBUTION_REPORTING, LACKS_SALES_ANALYTICS_ACCESS, LOADING, LACKS_SNOWFLAKE_TEMPLATE_ACCESS } from '../../problems';
import { ATTRIBUTION_UPGRADE_PRODUCT_TYPES, getAttributionReportUpgradeProduct } from '../upgradePoints/upgradeProducts';
const useReportTypeProblem = ({
  report,
  shouldBecomeCustom
}) => {
  const hasCustomReportingAccess = useHasCustomReportingAccess();
  const hasDatasetsAccess = useHasDatasetsAccess();
  const hadDatasetsRead = useHasDatasetsRead();
  const hasRequiredAttributionReportAccess = useHasRequiredAttributionReportScope(report);
  const hasSalesAnalyticsAccess = useHasSalesAnalyticsAccess();
  const {
    loading: userInfoLoading
  } = useUserInfo();
  if (userInfoLoading) {
    return LOADING;
  }
  if (!hasRequiredAttributionReportAccess) {
    return getAttributionReportUpgradeProduct(report) === ATTRIBUTION_UPGRADE_PRODUCT_TYPES.ENTERPRISE ? LACKS_ENTERPRISE_ATTRIBUTION_REPORTING : LACKS_PRO_ATTRIBUTION_REPORTING;
  }
  if (isCustomDatasetReport(report)) {
    if (!hasDatasetsAccess) {
      return LACKS_DATASETS_ACCESS;
    }
    if (!hadDatasetsRead) {
      return LACKS_DATASETS_READ;
    }
  }
  if ((isCustom(report) || shouldBecomeCustom) && !hasCustomReportingAccess) {
    return LACKS_CUSTOM_REPORTING;
  }
  if (isSalesAnalyticsCustomWidget(report) && !hasSalesAnalyticsAccess) {
    return LACKS_SALES_ANALYTICS_ACCESS;
  }
  if (isSnowflake(report) && !isSnowflakeReportTemplateException(report) && !hasCustomReportingAccess) {
    return LACKS_SNOWFLAKE_TEMPLATE_ACCESS;
  }
  return null;
};
export default useReportTypeProblem;