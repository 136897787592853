'use es6';

import { fromJS } from 'immutable';
import toJS from '../../lib/toJS';
import { load } from '../../dataTypeDefinitions';
import * as http from '../../request/http';
import Request from '../../request/Request';
import * as endpoints from './endpoints';
import * as hybrid from './hybrid';
import * as labels from './labels';
import * as params from './params';
import * as preconditions from './preconditions';
import * as preprocessors from './preprocessors';
import { summarize } from '../../dataset/summarize';
export default ((immutableConfig, debug, runtimeOptions) => {
  const config = toJS(immutableConfig);
  const {
    dataType
  } = config;
  return load(dataType).then(module => module.getUnifiedSpecForConfig({
    config
  }).then(spec => ({
    module,
    spec
  }))).then(({
    module,
    spec
  }) => {
    const param = params.get(spec, config, runtimeOptions);
    const preprocessor = preprocessors.get(spec, config);
    const precondition = preconditions.get(spec, config);
    const {
      url,
      parse,
      downgrade
    } = endpoints.get(spec, config, runtimeOptions);
    const {
      method,
      data
    } = hybrid.get(spec, config);
    const hydrate = labels.get(spec, config, module);
    const request = Request.instance({
      url,
      method,
      data,
      query: param
    });
    debug('request', request);
    return http.retrieve(request).then(response => {
      debug('response', response);
      return response;
    }).then(toJS).then(preprocessor).then(precondition).then(parse).then(hydrate).then(downgrade).then(fromJS).then(summarize).then(nested => ({
      dataConfig: immutableConfig,
      dataset: nested,
      response: null
    }));
  });
});