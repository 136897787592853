import { fromJS } from 'immutable';
import { EDITOR_TYPES } from '../tsTypes/editorTypes';
import { getReportEditorsSchema } from './reportGetters';
import { fromMetricKey } from '../v2/dataset/datasetMetrics';
const getEditorType = (report, editorType) => getReportEditorsSchema(report).filter(editor => editor.get('type') === editorType);
const getFirstEditorType = (report, editorType) => getReportEditorsSchema(report).find(editor => editor.get('type') === editorType);
export const getOvertimeTotalsEditorSchema = report => getFirstEditorType(report, EDITOR_TYPES.OVERTIME_TOTALS);
export const getUserAddedQuickFilters = report => getEditorType(report, EDITOR_TYPES.USER_ADDED_QUICK_FILTER);
export const getQuickFilters = report => getEditorType(report, EDITOR_TYPES.QUICK_FILTER);
export const getSummaryKpiMetricsEditorSchema = report => {
  const maybeEditorConfig = getFirstEditorType(report, EDITOR_TYPES.SUMMARY_KPI_METRICS);
  return maybeEditorConfig;
};
export const getMetricKeysForSummaryKpiMetricsEditor = report => {
  const editorConfig = getSummaryKpiMetricsEditorSchema(report);
  return (editorConfig === null || editorConfig === void 0 ? void 0 : editorConfig.get('metrics')) || [];
};
export const getConfigMetricsForSummaryKpiMetricsEditor = report => {
  const metricKeys = getMetricKeysForSummaryKpiMetricsEditor(report);
  return fromJS(metricKeys.map(metricKey => {
    const {
      property,
      type
    } = fromMetricKey(metricKey);
    return {
      property,
      metricTypes: [type]
    };
  }));
};
export const getTableMetricEditorSchema = report => getFirstEditorType(report, EDITOR_TYPES.TABLE_METRIC);