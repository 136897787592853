'use es6';

import { EDITOR_TYPES } from 'reporting-data/tsTypes/editorTypes';
import { getSpecificEditorSchema, isBreakdownRequired } from '../salesAnalyticsHelpers';
export function validateChartMetrics(contextChange, context, report, __subAppKey, defaultContext) {
  const chartMetrics = contextChange.chartMetrics || context.chartMetrics;
  const metricSchema = getSpecificEditorSchema(report, EDITOR_TYPES.METRIC);
  if (!metricSchema) {
    return contextChange;
  }
  const {
    metrics: validChartMetrics
  } = metricSchema;
  const filteredChartMetrics = chartMetrics.filter(metric => validChartMetrics.includes(metric));
  if (filteredChartMetrics.isEmpty()) {
    return Object.assign({}, contextChange, {
      chartMetrics: defaultContext.chartMetrics
    });
  }
  if (isBreakdownRequired(report)) {
    return Object.assign({}, contextChange, {
      chartMetrics: filteredChartMetrics.take(1)
    });
  }
  return Object.assign({}, contextChange, {
    chartMetrics: filteredChartMetrics
  }, filteredChartMetrics.size > 1 && {
    breakdown: null
  });
}