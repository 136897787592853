'use es6';

import PortalIdParser from 'PortalIdParser';
import { APP_NAMES } from '../constants/apps';
const isUrlSalesAnalytics = () => window.location.pathname.includes('sales-reports');
const isUrlSubscriptionAnalytics = () => window.location.pathname.includes('subscription-reports');
const isUrlServiceAnalytics = () => window.location.pathname.includes('service-reports');
export const getRootUrl = () => {
  if (isUrlSalesAnalytics()) {
    return `/analytics/${PortalIdParser.get()}/sales-reports`;
  } else if (isUrlSubscriptionAnalytics()) {
    return `/analytics/${PortalIdParser.get()}/subscription-reports`;
  } else {
    return `/analytics/${PortalIdParser.get()}/service-reports`;
  }
};
export const getAppName = () => {
  if (isUrlSalesAnalytics()) {
    return APP_NAMES.SALES_ANALYTICS;
  } else if (isUrlSubscriptionAnalytics()) {
    return APP_NAMES.SUBSCRIPTION_ANALYTICS;
  } else {
    return APP_NAMES.SERVICE_ANALYTICS;
  }
};
export const isEmbeddedTool = () => {
  return !isUrlSalesAnalytics() && !isUrlSubscriptionAnalytics() && !isUrlServiceAnalytics();
};
const getRootReportsListUrl = () => `/reports-list/${PortalIdParser.get()}`;
export const getTemplatesReportsListUrl = (template, search) => `${getRootReportsListUrl()}/templates/${template}${search}`;