'use es6';

import { List } from 'immutable';
export const sortTeamsAlphabetically = teams => teams.sort((a, b) => a.name.localeCompare(b.name)).map(team => team.set('childTeams', sortTeamsAlphabetically(team.childTeams)));

// Expects teams to be the result of HierarchicalTeamReferenceResolver
export const getTeamFromHierarchy = (teams, teamId) => {
  const getFlattenedTeams = team => {
    const children = team.get('childTeams').map(getFlattenedTeams).reduce( /* eslint-disable-next-line hubspot-dev/no-reduce-accumulator-copy */
    (acc, list) => acc.concat(list), List());
    return List.of(team).concat(children);
  };
  teamId = Number(teamId);
  return teams.map(team => team.get('referencedObject')).map(getFlattenedTeams).reduce( /* eslint-disable-next-line hubspot-dev/no-reduce-accumulator-copy */
  (acc, list) => acc.concat(list), List()).find(team => team.get('id') === teamId);
};