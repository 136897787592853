'use es6';

import { updateSort } from '../utils/salesAnalyticsHelpers';
import { applyMutators } from './mutators';
import { SUB_APPS } from 'reporting-data/tsTypes/subAppTypes';
const generateChart = ({
  subAppKey,
  context,
  reportTemplate,
  filteredGates,
  options
}) => {
  const generatedConfig = applyMutators({
    reportTemplate,
    subAppKey,
    context,
    filteredGates,
    options
  });
  if (subAppKey === SUB_APPS.lineItemRevenue) {
    return generatedConfig;
  }
  return generatedConfig.update(updateSort);
};
export default generateChart;