'use es6';

import { List, fromJS } from 'immutable';
import { EDITOR_TYPES } from 'reporting-data/tsTypes/editorTypes';
import { getSpecificEditorSchema } from '../utils/salesAnalyticsHelpers';
import { PIPELINE_SNAPSHOT_JIT_GATE } from './gates';
export const CALL_METRICS = ['SUM|count', 'SUM|raas_calls_connected_rate', 'SUM|raas_voicemails_rate', 'SUM|raas_avg_calls_made_per_day', 'AVG|hs_call_duration', 'SUM|hs_call_duration'];
export const MEETING_METRICS = ['SUM|count', 'SUM|raas_meetings_scheduled_rate', 'SUM|raas_meetings_completed_rate', 'SUM|raas_meetings_rescheduled_rate', 'SUM|raas_meetings_cancelled_rate', 'SUM|raas_no_show_meetings_rate', 'SUM|raas_avg_meetings_completed_per_day', 'AVG|hs_time_to_book_meeting_from_first_contact'];
export const SEQUENCE_ENROLLMENT_METRICS = ['DISTINCT_APPROX|hs_contact_id', 'SUM|hs_num_associated_meetings', 'SUM|raas_meeting_conversion_rate', 'SUM|raas_touches_per_contact', 'SUM|raas_avg_time_to_book_a_meeting', 'SUM|hs_email_sent_count', 'SUM|hs_email_open_count', 'SUM|hs_email_click_count', 'SUM|raas_emails_open_rate', 'SUM|raas_emails_click_rate', 'SUM|hs_email_reply_count'];
export const TASK_METRICS = ['SUM|count', 'SUM|hs_task_is_completed', 'SUM|hs_task_is_completed_sequence', 'SUM|hs_task_is_completed_call', 'SUM|hs_task_is_completed_email', 'SUM|hs_task_is_completed_linked_in', 'SUM|hs_task_missed_due_date_count', 'SUM|raas_task_completion_rate', 'SUM|raas_average_tasks_completed_per_day'];
export const LEAD_METRICS = ['SUM|count', 'SUM|hs_lead_is_open', 'SUM|hs_lead_is_in_progress', 'SUM|raas_in_progress_leads_rate', 'SUM|raas_lead_conversion_rate', 'AVG|hs_lead_time_to_first_outreach', 'SUM|hs_lead_associated_deals_count', 'AVG|hs_lead_outreach_activity_count', 'SUM|hs_lead_closed_won_deals_amount', 'AVG|hs_lead_closed_won_deals_amount', 'SUM|hs_lead_pipeline_value', 'AVG|hs_lead_pipeline_value'];
export const SALES_VELOCITY_METRICS = ['SUM|raas_sales_velocity_in_home_currency', 'AVG|amount_in_home_currency', 'SUM|raas_deal_win_rate', 'AVG|days_to_close'];
export const ADS_METRICS = ['SUM|clicks', 'SUM|engagements', 'SUM|newContacts'];
export const LEAD_CAPTURE_METRICS = ['SUM|submissions', 'SUM|formViews', 'PERCENTILES|submissionsPerFormView'];
export const MARKETING_EMAIL_METRICS = ['SUM|sent', 'SUM|open', 'SUM|click', 'PERCENTILES|clickratio', 'PERCENTILES|deliveredratio', 'SUM|delivered', 'PERCENTILES|hardbounceratio', 'PERCENTILES|unsubscribedratio', 'PERCENTILES|spamreportratio'];
export const SOURCES_METRICS = ['SUM|visits', 'PERCENTILES|sessionToContactRate', 'SUM|contacts', 'PERCENTILES|contactToCustomerRate', 'SUM|customers', 'PERCENTILES|bounceRate', 'SUM|visitors', 'PERCENTILES|newVisitorSessionRate', 'PERCENTILES|pageviewsPerSession', 'PERCENTILES|timePerSession'];
export const PAGES_METRICS = ['SUM|rawViews', 'SUM|submissions', 'SUM|contacts', 'SUM|customers', 'PERCENTILES|submissionsPerPageview', 'PERCENTILES|contactsPerPageview', 'PERCENTILES|customersPerPageview', 'PERCENTILES|contactToCustomerRate', 'SUM|entrances', 'SUM|exits', 'PERCENTILES|timePerPageview', 'PERCENTILES|pageBounceRate', 'PERCENTILES|exitsPerPageview', 'SUM|ctaViews', 'SUM|ctaClicks', 'SUM|ampViews', 'PERCENTILES|ctaRate'];
export const UTM_PARAM_METRICS = ['SUM|visits', 'SUM|contacts', 'SUM|customers', 'PERCENTILES|bounceRate', 'SUM|visitors', 'PERCENTILES|newVisitorSessionRate', 'PERCENTILES|pageviewsPerSession', 'PERCENTILES|timePerSession'];
export const DEVICE_TYPES_METRICS = ['SUM|visits', 'PERCENTILES|sessionToContactRate', 'SUM|contacts', 'PERCENTILES|contactToCustomerRate', 'SUM|customers', 'PERCENTILES|bounceRate', 'SUM|visitors', 'PERCENTILES|newVisitorSessionRate', 'PERCENTILES|pageviewsPerSession', 'PERCENTILES|timePerSession'];
export const GEOLOCATION_METRICS = ['SUM|visits', 'SUM|returningVisits', 'PERCENTILES|sessionToContactRate', 'SUM|contacts', 'PERCENTILES|contactToCustomerRate', 'SUM|customers', 'PERCENTILES|bounceRate', 'SUM|visitors', 'PERCENTILES|newVisitorSessionRate', 'PERCENTILES|pageviewsPerSession', 'PERCENTILES|timePerSession'];
export const BROWSERS_METRICS = ['SUM|visits', 'PERCENTILES|sessionToContactRate', 'SUM|contacts', 'PERCENTILES|contactToCustomerRate', 'SUM|customers', 'PERCENTILES|bounceRate', 'SUM|visitors', 'PERCENTILES|newVisitorSessionRate', 'PERCENTILES|pageviewsPerSession', 'PERCENTILES|timePerSession'];
export const TOPIC_CLUSTERS_METRICS = ['SUM|visits', 'PERCENTILES|sessionToContactRate', 'SUM|contacts', 'PERCENTILES|contactToCustomerRate', 'SUM|customers', 'PERCENTILES|bounceRate', 'SUM|visitors', 'PERCENTILES|newVisitorSessionRate', 'PERCENTILES|pageviewsPerSession', 'PERCENTILES|timePerSession'];
export const PIPELINE_SNAPSHOTS_METRICS = ['SUM|count', 'SUM|amount_in_home_currency'];
const metricOptionsByTemplateKey = {
  uniqueContactsSequenced: SEQUENCE_ENROLLMENT_METRICS,
  meetingsByRepChart: MEETING_METRICS,
  callsByRepChart: CALL_METRICS,
  tasksByRepChart: TASK_METRICS,
  leadWorkRateChart: LEAD_METRICS,
  salesVelocityChart: SALES_VELOCITY_METRICS
};
const metricOptionsByGate = {
  [PIPELINE_SNAPSHOT_JIT_GATE]: {
    pipelineSnapshots: PIPELINE_SNAPSHOTS_METRICS
  }
};
export const getTemplateWithMetricEditor = (template, reportKey, gates) => {
  const inDevMetricOptions = metricOptionsByTemplateKey[reportKey] || [];
  const gatedMetricOptions = Object.keys(metricOptionsByGate).filter(key => gates.includes(key) && metricOptionsByGate[key][reportKey]).flatMap(key => metricOptionsByGate[key][reportKey]);
  if (gatedMetricOptions) {
    inDevMetricOptions.push(...gatedMetricOptions);
  }
  if (inDevMetricOptions.length > 0 && !getSpecificEditorSchema(template, EDITOR_TYPES.METRIC)) {
    return template.update('editors', (templateEditors = List()) => templateEditors.push(fromJS({
      type: EDITOR_TYPES.METRIC,
      metrics: inDevMetricOptions
    })));
  }
  return template;
};