import { useCallback } from 'react';
import { useAsyncFetcher } from 'reporting-data/asyncData/useAsyncFetcher';
// @ts-expect-error Untyped import
import getReportingPropertyGroups from 'reporting-data/properties/groups';
import { getDataType } from 'reporting-data/report/configReportGetters';
const useFetchPropertyGroupsForEditors = (report, customResultFormatter) => {
  const dataType = getDataType(report);
  const resultFormatter = useCallback(response => {
    const properties = response.filter(group => !!group.get('displayName')).map(group => group.update('properties', props => props.filter(prop => !prop.get('hidden')))).filter(group => {
      var _group$get;
      return (_group$get = group.get('properties')) === null || _group$get === void 0 ? void 0 : _group$get.size;
    });
    if (customResultFormatter) {
      return customResultFormatter(properties);
    }
    return properties;
  }, [customResultFormatter]);
  const fetcher = useCallback(() => {
    return getReportingPropertyGroups(dataType);
  }, [dataType]);
  return useAsyncFetcher({
    fetcher,
    resultFormatter
  });
};
export default useFetchPropertyGroupsForEditors;