import { List, OrderedSet, Repeat, Set as ImmutableSet } from 'immutable';
import { Contexts } from '../query/v1/context/context';
import { isContextViolation } from '../query/v1/context/context-violation';
import { withStaticEnvironment } from '../query/v1/environment/environment-utils';
import { isEnvironmentViolation } from '../query/v1/environment/environment-violation';
import { isFieldExpression } from '../query/v1/expression/expression';
import { isExpressionViolation } from '../query/v1/expression/expression-violation';
import { createPropertyType } from '../query/v1/type/type';
import { checkExpressionType as checkEnvironmentExpressionType } from '../query/v1/type/type-check';
import { isArgumentViolation, isGenericTypeViolation, isReferenceViolation, isTypeViolation } from '../query/v1/type/type-violation';
import { BOOLEAN_TYPE, createUnionType, isLiteralType, isNullType, isPrimitiveType, isPropertyType, isUndefinedType, isUnionType, NULLABLE_BOOLEAN_TYPE, NULLABLE_DATETIME_TYPE, NULLABLE_DATE_TYPE, NULLABLE_NUMBER_TYPE, NULLABLE_STRING_TYPE, NULL_TYPE, NUMBER_TYPE, PropertyTypeNames, STRING_TYPE, Type, TypeNames, UNDEFINED_TYPE } from './type-records';
import { Violation, ViolationLevels, ViolationTypes } from './violation-records';

// @ts-expect-error add annotation for param
export const toSetBasedUnions = type => isUnionType(type) ?
// @ts-expect-error add annotation for param
type.update('types', types => ImmutableSet(types.map(toSetBasedUnions))) : type;

// @ts-expect-error add annotation for params
export const equal = (typeA, typeB) => toSetBasedUnions(typeA).equals(toSetBasedUnions(typeB));

// @ts-expect-error add annotation for param
export const typeToSet = t => {
  if (isUnionType(t)) {
    return t.types.flatMap(typeToSet).toOrderedSet();
  }
  return OrderedSet([t]);
};

// @ts-expect-error add annotation for param
export const flattenType = type => {
  const list = typeToSet(type).toList();
  return list.count() === 1 ? list.first() : createUnionType(...list);
};

// @ts-expect-error add annotation for params
export const widenType = (type, __maybeProperty) => {
  if (isUnionType(type)) {
    const typeSet = typeToSet(type).map(widenType);
    return flattenType(createUnionType(...typeSet.toList()));
  }
  if (isLiteralType(type)) {
    switch (type.type) {
      case TypeNames.NUMERIC_LITERAL:
        return NUMBER_TYPE;
      case TypeNames.STRING_LITERAL:
        return STRING_TYPE;
      case TypeNames.BOOLEAN_LITERAL:
        return BOOLEAN_TYPE;
      default:
        throw new Error();
    }
  }
  if (isPropertyType(type)) {
    switch (type.propertyType) {
      case PropertyTypeNames.number:
      case PropertyTypeNames.currency_number:
        return NULLABLE_NUMBER_TYPE;
      case PropertyTypeNames.string:
      case PropertyTypeNames.enumeration:
      case PropertyTypeNames.phone_number:
      case PropertyTypeNames.json:
      case PropertyTypeNames.object_coordinates:
        return NULLABLE_STRING_TYPE;
      case PropertyTypeNames.bool:
        return NULLABLE_BOOLEAN_TYPE;
      case PropertyTypeNames.date:
        return NULLABLE_DATE_TYPE;
      case PropertyTypeNames.datetime:
        return NULLABLE_DATETIME_TYPE;
      default:
        throw new Error();
    }
  }
  return type;
};

// @ts-expect-error add annotation for params
export const applyRestArguments = (functionType, count) => functionType.rest ?
// @ts-expect-error add annotation for param
functionType.update('arguments', args => args.concat(Repeat(functionType.rest, Math.max(0, count - functionType.arguments.count())))) : functionType;

// @ts-expect-error add annotation for params
export const check = (providedType, requiredType) => {
  const providedTypeSet = typeToSet(providedType);
  const requiredTypeSet = typeToSet(requiredType);
  return providedTypeSet.every(
  // @ts-expect-error add annotation for param
  type => requiredTypeSet.includes(type) ||
  // @ts-expect-error add annotation for param
  requiredTypeSet.isSuperset(typeToSet(widenType(type))));
};
const mapDependenciesToEnvironment = dependencies => {
  const {
    fields,
    properties
  } = dependencies;
  const environmentProperties = Object.fromEntries(properties.map(tableProperties => tableProperties.toList()).toList().flatten(1).map(property => ({
    table: property.table,
    name: property.name,
    type: createPropertyType(property.type),
    context: Contexts.ROW_LEVEL
  })).map(property => [`${property.table}.${property.name}`, property]).toArray());
  const environmentFields = fields.map(field => ({
    name: field.name,
    input: field.input,
    // shouldn't be needed, not going to convert to JS
    expression: field.expression,
    type: field.type ? field.type.toJS() : UNDEFINED_TYPE,
    context: field.context,
    valid: field.valid,
    circular: false,
    violations: {
      expression: [],
      type: [],
      context: []
    }
  })).toObject();
  return withStaticEnvironment({
    properties: environmentProperties,
    fields: environmentFields,
    functions: dependencies.functions ? Object.fromEntries(Object.entries(dependencies.functions).map(([name, fn]) => [name,
    // @ts-expect-error add annotation
    fn.toJS()])) : undefined
  });
};
const mapEnvironmentViolationToViolation = (violation, dependencies) => {
  if (isEnvironmentViolation(violation)) {
    throw new Error('Unexpected environment violation from signature check');
  }
  if (isExpressionViolation(violation)) {
    throw new Error('Unexpected expression violation from signature check');
  }
  if (isTypeViolation(violation)) {
    if (isReferenceViolation(violation)) {
      const field = isFieldExpression(violation.expression) ? dependencies.fields.get(violation.expression.name) : undefined;
      return Violation({
        violation: ViolationTypes.REFERENCE,
        level: field ? ViolationLevels.WARNING : ViolationLevels.ERROR,
        expression: violation.expression,
        // @ts-expect-error ignored
        location: violation.expression && violation.expression.location,
        field
      });
    }
    if (isArgumentViolation(violation)) {
      return Violation({
        violation: ViolationTypes.TYPE,
        level: 'ERROR',
        expression: violation.expression,
        parentExpression: violation.parentExpression,
        argumentIndex: violation.argumentIndex,
        // @ts-expect-error ignored
        location: violation.expression && violation.expression.location,
        expected: violation.expected,
        provided: violation.provided
      });
    }
    if (isGenericTypeViolation(violation)) {
      return Violation({
        violation: ViolationTypes.GENERICS,
        level: 'ERROR',
        expression: violation.expression,
        // @ts-expect-error ignored
        location: violation.expression && violation.expression.location,
        argumentIndices: violation.argumentIndices,
        genericType: violation.genericType,
        providedTypes: violation.providedTypes
      });
    }
    throw new Error('Unexpected type violation from signature check');
  }
  if (isContextViolation(violation)) {
    throw new Error('Unexpected context violation from signature check');
  }
  throw new Error('Unexpected violation from signature check');
};
export const checkExpressionType = (expression, dependencies) => {
  if (!expression) {
    return {
      type: UNDEFINED_TYPE,
      violations: List()
    };
  }
  const environment = mapDependenciesToEnvironment(dependencies);
  const checked = checkEnvironmentExpressionType(
  // @ts-expect-error ignored
  expression.toJS(), environment);
  const resultType = Type(checked.type);
  const resultViolations = List(checked.violations.map(violation => mapEnvironmentViolationToViolation(violation, dependencies)).filter(violation => violation !== undefined));
  return {
    type: resultType,
    violations: resultViolations
  };
};

// @ts-expect-error add annotation for param
export const typeToString = type => {
  // @ts-expect-error add annotation for param
  const toString = t => {
    switch (t.type) {
      case TypeNames.UNDEFINED:
        return 'Undefined';
      case TypeNames.NULL:
        return 'Null';
      case TypeNames.NUMBER:
        return 'Number';
      case TypeNames.STRING:
        return 'String';
      case TypeNames.BOOLEAN:
        return 'Boolean';
      case TypeNames.NUMERIC_LITERAL:
        return `${t.value}`;
      case TypeNames.STRING_LITERAL:
        return `"${t.value}"`;
      case TypeNames.BOOLEAN_LITERAL:
        return `${t.value}`;
      case TypeNames.DATE:
        return 'Date';
      case TypeNames.DATETIME:
        return 'Datetime';
      case TypeNames.PROPERTY:
        {
          switch (t.propertyType) {
            case PropertyTypeNames.number:
              return 'Number property';
            case PropertyTypeNames.currency_number:
              return 'Currency property';
            case PropertyTypeNames.string:
              return 'String property';
            case PropertyTypeNames.enumeration:
              return 'Multi-checkbox property';
            case PropertyTypeNames.phone_number:
              return 'Phone number property';
            case PropertyTypeNames.json:
              return 'JSON property';
            case PropertyTypeNames.object_coordinates:
              return 'Object coordinates property';
            case PropertyTypeNames.bool:
              return 'Checkbox property';
            case PropertyTypeNames.date:
              return 'Date property';
            case PropertyTypeNames.datetime:
              return 'Datetime property';
            default:
              throw new Error();
          }
        }
      case TypeNames.GENERIC:
        return `<${t.name}>`;
      case TypeNames.FUNCTION:
        return `Function`;
      default:
        throw new Error();
    }
  };

  // @ts-expect-error add annotation for param
  const getOrder = t => {
    if (isLiteralType(t)) return 1;
    if (isPrimitiveType(t)) return 2;
    if (isPropertyType(t)) return 3;
    if (isNullType(t)) return 4;
    if (isUndefinedType(t)) return 5;
    return 6;
  };
  const typeStrings = typeToSet(type).toSeq()
  // @ts-expect-error add annotation for param
  .map(t => [t, getOrder(t)])
  // @ts-expect-error add annotation for param
  .sortBy(([, order]) => order)
  // @ts-expect-error add annotation for param
  .map(([t]) => t).map(toString);
  return typeStrings.join(' | ');
};
export const toPropertyBasicType = type => {
  if (isUndefinedType(type) || isNullType(type)) {
    return PropertyTypeNames.string;
  }
  if (isPrimitiveType(type)) {
    switch (type.type) {
      case TypeNames.NUMBER:
        return PropertyTypeNames.number;
      case TypeNames.STRING:
        return PropertyTypeNames.string;
      case TypeNames.BOOLEAN:
        return PropertyTypeNames.bool;
      case TypeNames.DATE:
        return PropertyTypeNames.date;
      case TypeNames.DATETIME:
        return PropertyTypeNames.datetime;
      default:
        throw new Error();
    }
  }
  if (isLiteralType(type)) {
    switch (type.type) {
      case TypeNames.NUMERIC_LITERAL:
        return PropertyTypeNames.number;
      case TypeNames.STRING_LITERAL:
        return PropertyTypeNames.string;
      case TypeNames.BOOLEAN_LITERAL:
        return PropertyTypeNames.bool;
      default:
        throw new Error();
    }
  }
  if (isPropertyType(type)) {
    switch (type.propertyType) {
      case PropertyTypeNames.number:
      case PropertyTypeNames.currency_number:
        return PropertyTypeNames.number;
      case PropertyTypeNames.string:
      case PropertyTypeNames.enumeration:
      case PropertyTypeNames.phone_number:
      case PropertyTypeNames.json:
      case PropertyTypeNames.object_coordinates:
        return PropertyTypeNames.string;
      case PropertyTypeNames.bool:
        return PropertyTypeNames.bool;
      case PropertyTypeNames.date:
        return PropertyTypeNames.date;
      case PropertyTypeNames.datetime:
        return PropertyTypeNames.datetime;
      default:
        throw new Error();
    }
  }

  // Workaround until the builder and dataset tool are aligned closer w/ regard
  // to types
  const typeSet = typeToSet(type).remove(NULL_TYPE);
  if (typeSet.count() === 1) {
    return toPropertyBasicType(typeSet.first());
  }
  throw new Error();
};
export const toPropertyBasicTypeWithEnumReturn = type => {
  if (isPropertyType(type) && type.propertyType === PropertyTypeNames.enumeration) {
    return PropertyTypeNames.enumeration;
  } else {
    return toPropertyBasicType(type);
  }
};

// @ts-expect-error add annotation for param
export const getIconNameForType = type => {
  try {
    const propertyBasicType = toPropertyBasicType(type);
    switch (propertyBasicType) {
      case PropertyTypeNames.number:
      case PropertyTypeNames.currency_number:
        return 'numericDataType';
      case PropertyTypeNames.string:
      case PropertyTypeNames.enumeration:
      case PropertyTypeNames.phone_number:
      case PropertyTypeNames.json:
      case PropertyTypeNames.object_coordinates:
      case PropertyTypeNames.bool:
        return 'textDataType';
      case PropertyTypeNames.date:
      case PropertyTypeNames.datetime:
        return 'date';
      default:
        return 'blank';
    }
  } catch (__e) {
    return 'blank';
  }
};