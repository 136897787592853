import { useEffect, useMemo, useState } from 'react';
import { emptyTracker, isEmptyTracker } from 'reporting-data/tsTypes/usageTrackerTypes';
import { getUsageTracker as getUsageTrackerFromReportingVisualization } from 'reporting-ui-components/monitoring/usageTracker';
import reportingVisualizationEvents from 'reporting-ui-components/events.yaml';
import events from '../../events.yaml';
const useCloneTracker = appUsageTracker => {
  const [usageTracker, setUsageTracker] = useState(emptyTracker);
  const primaryTracker = useMemo(() => getUsageTrackerFromReportingVisualization().clone({
    events
  }), []);
  useEffect(() => {
    if (!isEmptyTracker(primaryTracker)) {
      setUsageTracker(primaryTracker);
    } else if (appUsageTracker) {
      setUsageTracker(appUsageTracker.clone({
        events: reportingVisualizationEvents
      }) || emptyTracker.clone({
        events
      }));
    }
  }, [appUsageTracker, primaryTracker]);
  return usageTracker;
};
export default useCloneTracker;