// @ts-expect-error untyped import
import useProFunnelOptInAvailability from '../../../data/hooks/useProFunnelOptInAvailability';
import { FUNNEL_JOURNEY_ANALYTICS_READ } from '../scopeTypes';

// @ts-expect-error untyped import
import { useHasScopes } from './useHasScope';
export const useHasFunnelJourneyAccess = () => {
  const proFunnelOptInAvailability = useProFunnelOptInAvailability();
  const optedIn = proFunnelOptInAvailability.data.optedIn;
  return useHasScopes(FUNNEL_JOURNEY_ANALYTICS_READ) && optedIn;
};