'use es6';

import hubHttp from 'hub-http/clients/apiClient';
import { Promise } from '../../lib/promise';
const BASE_URL = 'contacts/search/v1/report';
const CRM_SEARCH_BASE_URL = 'crm-search/report';
const MAX_QUEUE = 5;
const MAX_WAIT = 250;
const getUrl = (objectType, isMulti) => `${BASE_URL}/${objectType}${isMulti ? '/multi' : ''}`;
const getCrmSearchUrl = isMulti => `${CRM_SEARCH_BASE_URL}${isMulti ? '/multi' : ''}/beta`;
const parse = url => {
  const tail = url.replace(BASE_URL, '');
  const parts = tail.split('/').filter(s => s !== '');
  const isMulti = parts[parts.length - 1] === 'multi';
  if (isMulti) {
    parts.pop();
  }
  const objectType = parts.join('/');
  return {
    objectType,
    isMulti
  };
};
const match = url => url.startsWith(BASE_URL) && parse(url).objectType !== 'quotas';
const matchCrmSearch = url => url.includes(CRM_SEARCH_BASE_URL);

/**
 * Register all setTimeout ids to be cleared during tests
 */
const timeoutIds = new Set();
export function clearTimeouts() {
  timeoutIds.keys().forEach(id => clearTimeout(id));
  timeoutIds.clear();
}
const makeBatchedClient = (objectType, {
  maxWait = MAX_WAIT,
  useCrmSearch = false
} = {}) => {
  const multiUrl = useCrmSearch ? getCrmSearchUrl(true) : getUrl(objectType, true);
  let timeout = null;
  let queue = [];
  const flush = () => {
    const requestPayload = queue.reduce((batchedPayload, currentRequest) => {
      const {
        isMulti,
        options: {
          data: payload
        }
      } = currentRequest;
      const index = batchedPayload.length;
      currentRequest.getResponse = batchedResponse => isMulti ? batchedResponse.slice(index, index + payload.length) : batchedResponse[index];
      return /* eslint-disable-next-line hubspot-dev/no-reduce-accumulator-copy */(
        batchedPayload.concat(isMulti ? payload : [payload])
      );
    }, []);
    const flushed = queue;
    hubHttp.post(multiUrl, {
      data: requestPayload
    }).then(batchedResponse => {
      flushed.forEach(request => {
        const response = request.getResponse(batchedResponse);
        request.defer.resolve(response);
      });
    }).catch(e => {
      flushed.forEach(request => {
        request.defer.reject(e);
      });
    });
    queue = [];
    clearTimeout(timeout);
    timeoutIds.delete(timeout);
    timeout = null;
  };
  return {
    post: (url, options) => {
      const {
        isMulti
      } = parse(url);
      return new Promise((resolve, reject) => {
        queue.push({
          url,
          options,
          isMulti,
          defer: {
            resolve,
            reject
          }
        });
        if (queue.length >= MAX_QUEUE) {
          flush();
          return;
        }
        if (!timeout) {
          timeout = setTimeout(flush, maxWait);
          timeoutIds.add(timeout);
        }
      });
    }
  };
};
const batchedClients = {};
export const client = {
  post: (url, options) => {
    const {
      data: {
        objectTypeId,
        portalId
      }
    } = options;
    const useCrmSearch = matchCrmSearch(url) && objectTypeId && portalId;
    if (!match(url) && !useCrmSearch) {
      return hubHttp.post(url, options);
    }
    const objectType = useCrmSearch ? objectTypeId : parse(url).objectType;
    let batchedClient = batchedClients[objectType];
    if (!batchedClient) {
      batchedClient = makeBatchedClient(objectType, {
        useCrmSearch
      });
      batchedClients[objectType] = batchedClient;
    }
    return batchedClient.post(url, options);
  }
};
export const __TESTABLE__ = {
  parse,
  match,
  makeBatchedClient
};