export const FIELDS = {
  TO: 'to',
  FROM: 'from',
  STAGE: 'stage',
  COUNT: 'count',
  STEP_TOTAL: 'toStepTotal',
  STAGE_TOTAL: 'toStageTotal',
  NEXT_STEP_CONVERSION: 'nextStepConversion',
  STAGE_CONVERSION: 'toStageConversion',
  STEP_CUMULATIVE_CONVERSION: 'toStepCumulativeConversion',
  STAGE_CUMULATIVE_CONVERSION: 'toStageCumulativeConversion',
  STAGE_TOTAL_CONVERSION: 'toStageTotalConversion',
  AVERAGE_TIME_TO_STEP: 'averageTimeFromStep',
  CONTAINS_ANONYMOUS_VISITORS: 'possiblyContainsAnonymousVisitors',
  SKIP_STAGE_COUNT: 'skipStageCount'
};
export const SUMMARY_FIELDS = {
  AVERAGE_TIME_BETWEEN_STEPS: 'averageTimeBetweenSteps',
  AVERAGE_TIME_TO_CONVERSION: 'averageTimeToConversion',
  CUMULATIVE_CONVERSION: 'cumulativeConversion',
  TOTAL_CONVERSION: 'totalConversion'
};
export const STAGE_NAME_PROPERTY = 'stageName';
export const HS_OBJECT_ID = 'hs_object_id';
export const FILTERED_ENTITIES = 'filtered_entities';
export const HS_DEAL_OBJECT_ID = '0-3__hs_object_id';
export const HS_DEAL_AMOUNT = '0-3__amount_in_home_currency';