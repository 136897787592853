import { CALL_TYPE_ID, CAMPAIGN_TYPE_ID, COMPANY_TYPE_ID, CONTACT_TYPE_ID, DEAL_TYPE_ID, ENGAGEMENT_TYPE_ID, LINE_ITEM_TYPE_ID, TASK_TYPE_ID, TICKET_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import * as ReferenceTypes from 'customer-data-objects/externalOptions/ExternalOptionsReferenceTypes';
import { Metrics } from '../metrics/Metrics';
import { getGatesSync } from '../auth/getGatesSync';
import { gate } from 'hub-http/gates';
import PortalIdParser from 'PortalIdParser';

// HACK: I tried using `memoize` from `react-utils`, but it just didn't work :shrug:
// This will be short-lived code based on the pace Beau's been moving so I'm okay
// with a little module-scope jank for now.
const __logged = new Set();
const logOverride = ({
  propertyName,
  objectTypeId,
  type
}) => {
  const memoKey = `${propertyName}-${objectTypeId}-${type}`;
  if (!__logged.has(memoKey)) {
    __logged.add(memoKey);
    const isTypeSpecificDealDealstageOverride = type === 'type-specific' && objectTypeId === '0-3' && propertyName === 'dealstage';
    Metrics.counter('override-reference-type', Object.assign({
      propertyName,
      objectTypeId,
      type
    }, isTypeSpecificDealDealstageOverride && {
      portalId: `${PortalIdParser.get()}`
    })).increment();
  }
};
/**
 * Commented out properties are those that we know have
 * externalOptions but the corresponding lite resolver
 * has not yet been built out
 */
const PropertyNameToReferenceType = {
  [CALL_TYPE_ID]: {
    hs_call_disposition: ReferenceTypes.CALL_DISPOSITION
  },
  [CAMPAIGN_TYPE_ID]: {
    hs_owner: ReferenceTypes.OWNER
  },
  [COMPANY_TYPE_ID]: {
    lifecyclestage: ReferenceTypes.PIPELINE_STAGE,
    hs_analytics_first_touch_converting_campaign: ReferenceTypes.CAMPAIGN,
    hs_analytics_last_touch_converting_campaign: ReferenceTypes.CAMPAIGN
  },
  [CONTACT_TYPE_ID]: {
    hs_persona: ReferenceTypes.PERSONA,
    lifecyclestage: ReferenceTypes.PIPELINE_STAGE,
    hs_analytics_first_touch_converting_campaign: ReferenceTypes.CAMPAIGN,
    hs_analytics_last_touch_converting_campaign: ReferenceTypes.CAMPAIGN
  },
  [DEAL_TYPE_ID]: {
    dealstage: ReferenceTypes.PIPELINE_STAGE,
    pipeline: ReferenceTypes.PIPELINE,
    deal_currency_code: ReferenceTypes.MULTI_CURRENCY_CURRENCY_CODE
  },
  [ENGAGEMENT_TYPE_ID]: {
    hs_attendee_owner_ids: ReferenceTypes.OWNER,
    hs_at_mentioned_owner_ids: ReferenceTypes.OWNER,
    hs_created_by: ReferenceTypes.USER,
    hs_call_disposition: ReferenceTypes.CALL_DISPOSITION
  },
  [LINE_ITEM_TYPE_ID]: {
    hs_line_item_currency_code: ReferenceTypes.MULTI_CURRENCY_CURRENCY_CODE
  },
  [TASK_TYPE_ID]: {
    hs_queue_membership_ids: ReferenceTypes.TASK_QUEUE
  },
  [TICKET_TYPE_ID]: {}
};
const CommonPropertiesToReferenceType = {
  hs_persona: ReferenceTypes.PERSONA,
  hubspot_owner_id: ReferenceTypes.OWNER,
  hubspot_team_id: ReferenceTypes.TEAM,
  hs_pipeline: ReferenceTypes.PIPELINE,
  hs_pipeline_stage: ReferenceTypes.PIPELINE_STAGE,
  hs_created_by: ReferenceTypes.USER,
  hs_created_by_user_id: ReferenceTypes.USER,
  hs_updated_by_user_id: ReferenceTypes.USER
};

/**
 * Preference order (highest to lowest):
 *
 * 1. BE property definition (given by the `externalOptionsReferenceType` field)
 * 2. ObjectType-specific property name override
 * 3. Common property name override (for properties shared by all types)
 *
 * @param propertyOrPropertyDefinition  CRM property definition object or property definition object from backend
 * @param objectTypeId                  Object Type ID in the form `metaTypeId-typeId` (i.e. 0-1)
 * @returns                             `ReferenceType` constant for creating a
 * resolver from this library, or `null` if the given property
 * does not map to any reference type
 */
export const getReferenceTypeFromProperty = (propertyOrPropertyDefinition, objectTypeId) => {
  const property = propertyOrPropertyDefinition && 'property' in propertyOrPropertyDefinition ? propertyOrPropertyDefinition.property : propertyOrPropertyDefinition;
  if (property && property.externalOptionsReferenceType) {
    return property.externalOptionsReferenceType;
  }

  // We intentionally do not return early here to preserve our canary logging below.
  const removeOverrides = getGatesSync().has(gate('RRL:RemovePropertyOverrides'));
  const propertyName = property ? property.name : null;
  const propertyIsHubspotDefined = property ? property.hubspotDefined : false;

  // HACK: hs_persona is an explicit omission from the cleanup project, as the property
  // has options that are synced with the reference type. It'll require additional cleanup work.
  // Because we can't fix this, I'm adding an early return to prevent the metric from being fired.
  // See https://git.hubteam.com/HubSpotProtected/InboundDbProperties/issues/3504#issuecomment-9281184
  if (propertyIsHubspotDefined && objectTypeId === CONTACT_TYPE_ID && propertyName === 'hs_persona') {
    return ReferenceTypes.PERSONA;
  }
  const overrideReferenceTypeFields = PropertyNameToReferenceType[objectTypeId] || {};
  const overrideReferenceType = propertyName ? overrideReferenceTypeFields[propertyName] : null;
  if (overrideReferenceType && propertyIsHubspotDefined) {
    // propertyName will always be defined here, but it makes TS happy
    if (propertyName) {
      logOverride({
        propertyName,
        objectTypeId,
        type: 'type-specific'
      });
    }
    if (!removeOverrides) {
      return overrideReferenceType;
    }
  }
  const commonOverrideReferenceType = propertyName ? CommonPropertiesToReferenceType[propertyName] : null;
  if (commonOverrideReferenceType && propertyIsHubspotDefined) {
    // propertyName will always be defined here, but it makes TS happy
    if (propertyName) {
      logOverride({
        propertyName,
        objectTypeId: objectTypeId.startsWith('2-') ? 'custom-object' : objectTypeId,
        type: 'common'
      });
    }
    if (!removeOverrides) {
      return commonOverrideReferenceType;
    }
  }
  return null;
};