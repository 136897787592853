import { RequestState } from 'dashboard-lib/public/request/request-state';
import { getPropertyFromDataSourceByPropertyRecord } from 'platform-dashboard-ui/filter/utils/schema/propertySchema';
import { quickFiltersNamespace, getQuickFilterUniqueKey, QUICK_FILTER_LIMIT } from './constants';
import { createSelector } from '@reduxjs/toolkit';
import { selectDataSourcePropertiesView } from '../platform-data-source-metadata';
import { applyInvalidPropertiesFlagToQuickFilterGroups, checkIfQuickFilterGroupIsApplied, filterOutQuickFilterGroupsInvalidProperties, extractQuickFilterGroupFromAppliedFilters, getQuickFilterGroupDataSources, getQuickFilterGroupWithProperty, getQuickFilterPropertyDataSource, isQuickFilterGroupValid } from '../../lib/dashboardFilters/groupQuickFilterHelpers';
import { selectDashboardFilters } from '../applied-dashboard-filters/selectors';
import { getFilterQueryFormatOperator } from '../../lib/dashboardFilters/filterConversionHelpers';
const isQuickFilterPropertyWithDefinedProperty = definedProperty => {
  return definedProperty.property !== undefined;
};
function withQuickFiltersSliceState(selector) {
  return state => selector(state[quickFiltersNamespace]);
}

// TODO can we deprecate these code paths?
export const selectQuickFilters = withQuickFiltersSliceState(state => (state.quickFilters || []).filter(
// @ts-expect-error there is no reference to this `pinnedProperties` in any of our other code execpt for this selector. We may be able to remove this file fn
quickFilter => !quickFilter.pinnedProperties));
const _selectQuickFilterGroups = withQuickFiltersSliceState(state => state.quickFilterGroups || []);

/** not safe to persist / apply to filters. Should be used as a way to display broken properties in quick filters */
export const selectDisplayQuickFilterGroupsWithMaybeInvalidProperties = createSelector([_selectQuickFilterGroups, selectDataSourcePropertiesView], applyInvalidPropertiesFlagToQuickFilterGroups);

/**  safe to persist/apply to db filters */
export const selectQuickFilterGroups = createSelector([selectDisplayQuickFilterGroupsWithMaybeInvalidProperties], filterOutQuickFilterGroupsInvalidProperties);
export const selectQuickFilterGroupByName = quickFilterGroupName => createSelector([selectQuickFilterGroups], groups => groups.find(g => g.name === quickFilterGroupName));

// TODO refactor to pass a quickFilterGroup.name to select the pinned properties from that group
// and then get the dataSourceProperties from selectDataSourcePropertiesView
export const selectGivenPinnedPropertyToProperty = pinnedProperties => createSelector([selectDataSourcePropertiesView], dataSourceProperties => {
  return pinnedProperties.map(({
    dataSourceId,
    name
  }) => {
    return {
      dataSource: dataSourceId,
      name,
      property: getPropertyFromDataSourceByPropertyRecord(dataSourceProperties, dataSourceId, name)
    };
  }).filter(isQuickFilterPropertyWithDefinedProperty);
});
export const selectQuickFilterToProperty = createSelector([selectQuickFilters, selectDataSourcePropertiesView], (quickFilters, dataSourceProperties) => quickFilters.map(({
  dataSourceId,
  name
}) => {
  return {
    dataSource: dataSourceId,
    name,
    property: getPropertyFromDataSourceByPropertyRecord(dataSourceProperties, dataSourceId, name)
  };
}).filter(isQuickFilterPropertyWithDefinedProperty));
export const selectQuickFilterGroupWithHydratedProperties = createSelector([selectQuickFilterGroups, selectDataSourcePropertiesView], (quickFilterGroups, dataSourceProperties) => quickFilterGroups.map(quickFilterGroup => {
  const {
    properties
  } = quickFilterGroup;
  const definedPropertiesList = properties.map(({
    dataSourceId,
    name
  }) => {
    return {
      dataSource: dataSourceId,
      name,
      property: getPropertyFromDataSourceByPropertyRecord(dataSourceProperties, dataSourceId, name)
    };
  }).filter(isQuickFilterPropertyWithDefinedProperty);
  const quickFilterGroupWithHydratedProperties = Object.assign({}, quickFilterGroup, {
    properties: definedPropertiesList
  });
  return quickFilterGroupWithHydratedProperties;
}));
export const selectQuickFiltersDataSources = createSelector([selectQuickFilters, selectQuickFilterGroups], (quickFilters, quickFilterGroups) => {
  const quickFilterDataSources = quickFilters.map(quickFilter => quickFilter.dataSourceId);
  const quickFilterGroupsDataSources = quickFilterGroups.map(getQuickFilterGroupDataSources).flat();

  // Use a Set to remove duplicates.
  return [...new Set([...quickFilterDataSources, ...quickFilterGroupsDataSources])];
});
export const selectQuickFilterOperators = dashboardId => createSelector([selectQuickFilters, selectDashboardFilters(dashboardId)], (quickFilters, appliedFilters) => {
  return quickFilters.reduce((memo, quickFilter) => {
    const {
      dataSourceId,
      name
    } = quickFilter;
    const [filterValue] = appliedFilters.filter(appliedFilter => appliedFilter.propertyName === name && appliedFilter.dataSource === dataSourceId);
    if (filterValue) {
      memo.set(getQuickFilterUniqueKey({
        dataSource: dataSourceId,
        name
      }), filterValue);
    }
    return memo;
  }, new Map());
});
export const selectPinnedItemCount = createSelector([selectQuickFilters, selectQuickFilterGroups], (quickFilters, quickFilterGroups) => {
  return quickFilters.length + quickFilterGroups.length;
});
export const selectIsPinnedQuickFilterLimitReached = createSelector([selectPinnedItemCount], count => count === QUICK_FILTER_LIMIT);
export const selectHasQuickFilterApplied = createSelector([selectPinnedItemCount], count => count > 0);
export const selectHasQuickFilterGroupBeenApplied = ({
  quickFilterGroup: {
    name: quickFilterGroupName
  },
  dashboardId
}) => createSelector([selectQuickFilterGroupByName(quickFilterGroupName), selectDashboardFilters(dashboardId)], (quickFilterGroup, appliedFilters) => {
  if (!quickFilterGroup) {
    return false;
  }
  const hasQuickFilterGroupBeenApplied = checkIfQuickFilterGroupIsApplied(quickFilterGroup, appliedFilters);
  return hasQuickFilterGroupBeenApplied;
});
export const selectQuickFilterGroupAppliedOperator = ({
  quickFilterGroup: sourceQuickFilterGroup,
  dashboardId
}) => {
  const {
    name: quickFilterGroupName
  } = sourceQuickFilterGroup;
  return createSelector([selectQuickFilterGroupByName(quickFilterGroupName), selectHasQuickFilterGroupBeenApplied({
    quickFilterGroup: sourceQuickFilterGroup,
    dashboardId
  }), selectDashboardFilters(dashboardId), selectDataSourcePropertiesView], (quickFilterGroup, hasQuickFilterGroupBeenApplied, appliedFilters, dataSourceProperties) => {
    if (quickFilterGroup && hasQuickFilterGroupBeenApplied) {
      const {
        properties
      } = quickFilterGroup;
      if (!properties) {
        return null;
      }
      const firstProperty = properties.at(0);
      if (!firstProperty) {
        return null;
      }
      const firstPropertyDataSource = getQuickFilterPropertyDataSource(firstProperty);
      const firstPropertyName = firstProperty.name;
      const quickFilterGroupAppliedFilters = extractQuickFilterGroupFromAppliedFilters(quickFilterGroup, appliedFilters);
      const groupAppliedFilter = quickFilterGroupAppliedFilters.find(appliedFilter => {
        const {
          dataSource,
          propertyName
        } = appliedFilter;
        return dataSource === firstPropertyDataSource && propertyName === firstPropertyName;
      });
      const propertyDefinition = getPropertyFromDataSourceByPropertyRecord(dataSourceProperties, firstPropertyDataSource, firstPropertyName);
      if (groupAppliedFilter && propertyDefinition) {
        return getFilterQueryFormatOperator(groupAppliedFilter, dataSourceProperties[firstPropertyDataSource]);
      }
      return null;
    }
    return null;
  });
};
export const selectIsQuickFilterPropertyPinned = ({
  dataSourceId,
  propertyName
}) => createSelector([selectQuickFilters], quickFilters => {
  return quickFilters.some(quickFilter => quickFilter.dataSourceId === dataSourceId && quickFilter.name === propertyName);
});
export const selectQuickFilterGroupWithProperty = ({
  dataSourceId,
  propertyName
}) => createSelector(selectQuickFilterGroups, quickFilterGroups => getQuickFilterGroupWithProperty(quickFilterGroups, propertyName, dataSourceId));
export const selectIsFetchingQuickFilters = withQuickFiltersSliceState(state => state.fetchQuickFilterStatus === RequestState.PENDING);
export const selectIsQuickFiltersInitialized = withQuickFiltersSliceState(state => state.fetchQuickFilterStatus !== RequestState.UNINITIALIZED);
export const selectCurrentEditingGroup = withQuickFiltersSliceState(state => state.currentEditingGroup);
export const selectIsQuickFilterGroupValid = quickFilterGroup => createSelector([selectQuickFilterGroups, selectDataSourcePropertiesView], (allQuickFilterGroups, dataSourceProperties) => isQuickFilterGroupValid(quickFilterGroup, allQuickFilterGroups, dataSourceProperties));
export const selectIfCurrentEditingGroupIsValid = createSelector([selectCurrentEditingGroup, state => state], (currentEditingGroup, globalState) => currentEditingGroup ? selectIsQuickFilterGroupValid(currentEditingGroup)(globalState) : false);

// TODO this returns the property - name is misleading
export const selectCurrentEditingGroupDataType = createSelector([selectCurrentEditingGroup, selectDataSourcePropertiesView], (currentEditingGroup, dataSourceProperties) => {
  if (!currentEditingGroup) return null;
  const properties = currentEditingGroup.properties;
  if (!properties.length) {
    return null;
  }
  const [{
    dataSourceId,
    name
  }] = properties;
  return getPropertyFromDataSourceByPropertyRecord(dataSourceProperties, dataSourceId, name) || null;
});
export const selectIfPropertyInCurrentEditingGroup = ({
  dataSourceId,
  name
}) => createSelector([selectCurrentEditingGroup], currentEditingGroup => {
  if (!currentEditingGroup) return false;
  return currentEditingGroup.properties.filter(property => property.dataSourceId === dataSourceId && property.name === name).length > 0;
});