import { useCallback, useEffect, useMemo, useState } from 'react';
import { isStarted, buildFailedAsyncData, buildStartedAsyncData, buildSucceededAsyncData, buildUnitializedAsyncData, isFailed, isSucceeded, isUninitialized } from 'reporting-data/asyncData/AsyncData';
import { usePlatformDashboardSelector, usePlatformDashboardDispatch } from 'platform-dashboard-ui/store';
import { selectDataSourcesProperties, selectDataSourcesPropertiesStatus, fetchDataSourcesProperties } from 'platform-dashboard-ui/ducks/dataSourcePropertiesSlice';
import { RequestState } from 'platform-dashboard-ui/filter/requests/request-state';
import { selectDataSourcesMetadataStatus, fetchDataSourcesMetadata, selectDataSourcesMetadataWithUniqueLabels } from 'platform-dashboard-ui/ducks/dataSourceMetadataSlice';
import { createSavedDashboardFilterSet, deleteSavedDashboardFilterSet, fetchSavedFilterById, updateSavedDashboardFilterSet } from './api';
import { useDispatch, useSelector } from 'react-redux';
import { savedDashboardFiltersActions, selectFilterSetById, selectSavedFilterSets } from '../../ducks/savedDashboardFilters';
import { transformDashboardFilterToSavedFilter, transformSavedFilterToDashboardFilter } from './utils';
import { selectQuickFilterGroups } from '../../ducks/quick-filters';
import { QUICK_FILTER_TEMPLATES, isDateRangeGeneratedQuickFilterGroup } from '../dashboardFilters/groupQuickFilterHelpers';
import { DYNAMIC_DATE_RANGE_PROPERTY_NAME } from '../dashboardFilters/types';
// @ts-expect-error migrate
import { fetchGeneratedQuickFilterGroups } from '../../data/QuickFilterDAO';
export const useCreateDashboardFilterSet = (dataSourcesProperties, dataSourcesMetadata) => {
  const [asyncData, setAsyncData] = useState(buildUnitializedAsyncData());
  const dispatch = useDispatch();
  const handleCreate = payload => {
    setAsyncData(buildStartedAsyncData());
    return createSavedDashboardFilterSet(Object.assign({}, payload, {
      filters: transformDashboardFilterToSavedFilter(payload.filters)
    })).then(response => transformAPIFiltersResponse(response, dataSourcesProperties, dataSourcesMetadata)).then(response => {
      dispatch(savedDashboardFiltersActions.addNewSavedDashboardFilterSet(response));
      setAsyncData(buildSucceededAsyncData(response));
      return response;
    }).catch(error => {
      setAsyncData(buildFailedAsyncData(error));
      throw error;
    });
  };
  return {
    onCreate: handleCreate,
    asyncData,
    isLoading: isStarted(asyncData),
    error: isFailed(asyncData) ? asyncData.error : null,
    hasError: isFailed(asyncData)
  };
};
export const useUpdateDashboardFilterSet = (dataSourcesProperties, dataSourcesMetadata) => {
  const [asyncData, setAsyncData] = useState(buildUnitializedAsyncData());
  const dispatch = useDispatch();
  const handleUpdate = payload => {
    setAsyncData(buildStartedAsyncData());
    const filterSetId = payload.id;
    return updateSavedDashboardFilterSet(filterSetId, {
      id: filterSetId,
      name: payload.name,
      dashboardId: payload.dashboardId,
      filters: transformDashboardFilterToSavedFilter(payload.filters),
      defaultFilter: payload.defaultFilter,
      frequency: payload.frequency,
      useFiscalYear: payload.useFiscalYear
    }).then(response => transformAPIFiltersResponse(response, dataSourcesProperties, dataSourcesMetadata)).then(response => {
      dispatch(savedDashboardFiltersActions.updateSavedDashboardFilterSet(response));
      setAsyncData(buildSucceededAsyncData(response));
      return response;
    }).catch(error => {
      setAsyncData(buildFailedAsyncData(error));
      throw error;
    });
  };
  return {
    onUpdate: handleUpdate,
    asyncData,
    isLoading: isStarted(asyncData),
    error: isFailed(asyncData) ? asyncData.error : null,
    hasError: isFailed(asyncData)
  };
};
export const useDeleteSavedDashboardFilterSet = () => {
  const [asyncData, setAsyncData] = useState(buildUnitializedAsyncData());
  const dispatch = useDispatch();
  const handleDelete = (dashboardId, filterSetId) => {
    setAsyncData(buildStartedAsyncData());
    deleteSavedDashboardFilterSet(filterSetId).then(() => {
      dispatch(savedDashboardFiltersActions.removeSavedDashboardFilterSet({
        dashboardId,
        filterSetId
      }));
      setAsyncData(buildSucceededAsyncData(undefined));
    }).catch(error => {
      setAsyncData(buildFailedAsyncData(error));
    });
  };
  return {
    onDelete: handleDelete,
    asyncData,
    isLoading: isStarted(asyncData)
  };
};
export const useDeleteAllSavedDashboardFilterSets = dashboardId => {
  const [asyncData, setAsyncData] = useState(buildUnitializedAsyncData());
  const savedFiltedSets = useSelector(selectSavedFilterSets(dashboardId));
  const dispatch = useDispatch();
  const handleDeleteAll = () => {
    setAsyncData(buildStartedAsyncData());
    Promise.all(savedFiltedSets.map(filterSet => deleteSavedDashboardFilterSet(filterSet.id))).then(() => {
      dispatch(savedDashboardFiltersActions.removeAllSavedDashboardFilterSets({
        dashboardId
      }));
      setAsyncData(buildSucceededAsyncData(undefined));
    }).catch(error => {
      setAsyncData(buildFailedAsyncData(error));
    });
  };
  return {
    onDeleteAll: handleDeleteAll,
    asyncData,
    isLoading: isStarted(asyncData)
  };
};
const getDataSourceIdsFromApiSavedDashboardFilterSet = filterSet => [...new Set(filterSet.filters.flat().map(filter => filter.dataSourceId).filter(id => id != null))];
export const useLazyGetFilterSetById = (dashboardId, filterSetId) => {
  const [asyncState, setAsyncState] = useState(buildUnitializedAsyncData());
  const dispatch = useDispatch();
  const platformDispatch = usePlatformDashboardDispatch();
  const savedFilterSet = useSelector(selectFilterSetById(dashboardId, filterSetId));
  const quickFilterGroups = useSelector(selectQuickFilterGroups);
  const maybeDynamicDateRangeQuickFilterGroup = quickFilterGroups.find(isDateRangeGeneratedQuickFilterGroup);
  const [dynamicDateRangePropertiesAsyncState, setDynamicDateRangePropertiesAsyncState] = useState(maybeDynamicDateRangeQuickFilterGroup ? buildSucceededAsyncData(maybeDynamicDateRangeQuickFilterGroup.properties) : buildUnitializedAsyncData());
  const dataSourceIds = useMemo(() => {
    const filterSetDataSources = isSucceeded(asyncState) ? getDataSourceIdsFromApiSavedDashboardFilterSet(asyncState.data) : [];
    const dateRangeDataSources = isSucceeded(dynamicDateRangePropertiesAsyncState) ? Array.from(new Set(dynamicDateRangePropertiesAsyncState.data.map(p => p.dataSourceId))) : [];
    return filterSetDataSources.concat(dateRangeDataSources);
  }, [asyncState, dynamicDateRangePropertiesAsyncState]);
  const dataSourcesProperties = usePlatformDashboardSelector(selectDataSourcesProperties(dataSourceIds));
  const dataSourcesPropertiesStatus = usePlatformDashboardSelector(selectDataSourcesPropertiesStatus(dataSourceIds));
  const dataSourcesMetadata = usePlatformDashboardSelector(selectDataSourcesMetadataWithUniqueLabels(dataSourceIds));
  const dataSourcesMetadataStatus = usePlatformDashboardSelector(selectDataSourcesMetadataStatus(dataSourceIds));
  const areDataSourcesPropertiesLoaded = dataSourcesPropertiesStatus === RequestState.SUCCEEDED;
  const areDataSourcesMetadataLoaded = dataSourcesMetadataStatus === RequestState.SUCCEEDED;
  const fetchSavedFilterSet = useCallback(() => {
    const fetchDynamicDateRangeProperties = () => {
      setDynamicDateRangePropertiesAsyncState(buildStartedAsyncData());
      return fetchGeneratedQuickFilterGroups(dashboardId, [QUICK_FILTER_TEMPLATES.DATE_RANGE]).then(response => {
        const dateRangeQuickFilterGroup = response.find(isDateRangeGeneratedQuickFilterGroup);
        const nextDateRangeProperties = dateRangeQuickFilterGroup ? dateRangeQuickFilterGroup.properties : [];
        setDynamicDateRangePropertiesAsyncState(buildSucceededAsyncData(nextDateRangeProperties));
        return nextDateRangeProperties;
      }).catch(error => {
        console.error(error);
        setDynamicDateRangePropertiesAsyncState(buildSucceededAsyncData([]));
      });
    };
    if (isUninitialized(asyncState)) {
      setAsyncState(buildStartedAsyncData());
      fetchSavedFilterById(filterSetId).then(response => {
        const hasDynamicDateRangeFilter = response.filters.some(filter => filter.name === DYNAMIC_DATE_RANGE_PROPERTY_NAME);
        const apiFilterSetDataSourceIds = getDataSourceIdsFromApiSavedDashboardFilterSet(response);
        const setFetchSavedFilterSetAsyncState = (apiResponse, dataSourceIdsToFetch) => {
          setAsyncState(buildSucceededAsyncData(apiResponse));
          platformDispatch(fetchDataSourcesMetadata(dataSourceIdsToFetch));
          platformDispatch(fetchDataSourcesProperties(dataSourceIdsToFetch));
        };
        if (hasDynamicDateRangeFilter && !isSucceeded(dynamicDateRangePropertiesAsyncState)) {
          fetchDynamicDateRangeProperties().then(dateRangeProperties => {
            const dateRangeDataSourceIds = Array.from(new Set(dateRangeProperties.map(property => property.dataSourceId)));
            const allDataSourceIds = Array.from(new Set([...apiFilterSetDataSourceIds, ...dateRangeDataSourceIds]));
            setFetchSavedFilterSetAsyncState(response, allDataSourceIds);
          }).catch(__error => {
            const filterSetFiltersLessDateRangeFilter = response.filters.filter(filter => filter.name !== DYNAMIC_DATE_RANGE_PROPERTY_NAME);
            setFetchSavedFilterSetAsyncState(Object.assign({}, response, {
              filters: filterSetFiltersLessDateRangeFilter
            }), apiFilterSetDataSourceIds);
          });
        } else {
          setFetchSavedFilterSetAsyncState(response, apiFilterSetDataSourceIds);
        }
      }).catch(error => {
        setAsyncState(buildFailedAsyncData(error));
      });
    }
  }, [asyncState, dashboardId, dynamicDateRangePropertiesAsyncState, filterSetId, platformDispatch]);
  useEffect(() => {
    if (isSucceeded(asyncState) && areDataSourcesPropertiesLoaded && areDataSourcesMetadataLoaded && dataSourceIds.length > 0 && !savedFilterSet) {
      dispatch(savedDashboardFiltersActions.addNewSavedDashboardFilterSet(transformAPIFiltersResponse(asyncState.data, dataSourcesProperties, dataSourcesMetadata, isSucceeded(dynamicDateRangePropertiesAsyncState) ? dynamicDateRangePropertiesAsyncState.data : [])));
    }
  }, [asyncState, dataSourceIds, areDataSourcesMetadataLoaded, areDataSourcesPropertiesLoaded, dispatch, savedFilterSet, dataSourcesProperties, dataSourcesMetadata, dynamicDateRangePropertiesAsyncState]);
  return {
    savedFilterSet,
    fetchSavedFilterSet,
    isLoading: isStarted(asyncState),
    hasError: isFailed(asyncState),
    error: isFailed(asyncState) ? asyncState.error : undefined
  };
};
function transformAPIFiltersResponse(response, dataSourcesProperties, dataSourcesMetadata, dynamicDateRangeProperties = []) {
  return Object.assign({}, response, {
    filters: transformSavedFilterToDashboardFilter(response.filters, dataSourcesProperties, dataSourcesMetadata, dynamicDateRangeProperties)
  });
}