'use es6';

import { List, Map as ImmutableMap, OrderedMap } from 'immutable';
import invariant from 'reporting-data/lib/invariant';
import { colorAssignmentSets } from '../../../constants/colorAssignmentSets';
import { THEME, themes } from '../../../constants/themes';
import extractMetricSeriesInfo from '../../../lib/extractMetricSeriesInfo';
const PICKER_STRATEGY = {
  GRADIENT: 'gradient',
  ORDERED: 'ordered'
};
const defaultPickerStrategy = PICKER_STRATEGY.GRADIENT;
const themeIdToPickerStrategy = ImmutableMap({
  [THEME.MULTI]: PICKER_STRATEGY.ORDERED,
  [THEME.FUNNEL]: PICKER_STRATEGY.ORDERED,
  [THEME.BRAND_KIT]: PICKER_STRATEGY.ORDERED
});
const defaultTheme = THEME.MULTI;
function pickOrderedThemeColors(colors, keys) {
  return ImmutableMap(keys.map((key, index) => {
    return [key, colors.get(index % colors.size)];
  }).toJS());
}
function getNEveryOtherLeftOfMiddle(n, middle) {
  const ret = [];
  for (let i = 0; i < n; i++) {
    ret.unshift(-2 * (i + 1) + middle);
  }
  return List(ret);
}
function getNEveryOtherRightOfMiddle(n, middle) {
  const ret = [];
  for (let i = 0; i < n; i++) {
    ret.push(2 * (i + 1) + middle);
  }
  return List(ret);
}
export function pickGradientThemeColors(colors, keys) {
  invariant(colors.size > 0, 'there must be at least one color to pick from');
  if (keys.size > (colors.size + 1) / 2) {
    // use all the colors and circle back if you run out
    return ImmutableMap(keys.map((key, index) => {
      return [key, colors.get(index % colors.size)];
    }).toJS());
  } else {
    // use colors from the middle,
    // leave an un-used color between any two used colors,
    // bias towards the front
    // eg: 2keys/4colors: [0, x, 2, x]
    // eg: 3keys/8colors: [x, 1, x, 3, x, 5, x, x]
    const middleIndex = Math.ceil(colors.size / 2 - 1);
    let colorIndices = List();
    if (keys.size % 2 === 0) {
      const nOnEachSide = (keys.size - 2) / 2;
      colorIndices = getNEveryOtherLeftOfMiddle(nOnEachSide, middleIndex - 1).concat([middleIndex - 1, middleIndex + 1]).concat(getNEveryOtherRightOfMiddle(nOnEachSide, middleIndex + 1));
    } else {
      const nOnEachSide = (keys.size - 1) / 2;
      colorIndices = getNEveryOtherLeftOfMiddle(nOnEachSide, middleIndex).concat(middleIndex).concat(getNEveryOtherRightOfMiddle(nOnEachSide, middleIndex));
    }
    return ImmutableMap(keys.map((key, seriesIndex) => {
      return [key, colors.get(colorIndices.get(seriesIndex))];
    }));
  }
}
function pickThemeColors(themeId, keys, customTheme, reverseGradient = false) {
  const colors = customTheme || themes.get(themeId, themes.get(defaultTheme));
  switch (themeIdToPickerStrategy.get(themeId, defaultPickerStrategy)) {
    case PICKER_STRATEGY.ORDERED:
      return pickOrderedThemeColors(colors, keys);
    case PICKER_STRATEGY.GRADIENT:
    default:
      return pickGradientThemeColors(reverseGradient ? colors.reverse() : colors, keys);
  }
}
export function pickCustomColors(colorAssignmentConfig, keys, theme) {
  const preAssignedColors = typeof colorAssignmentConfig === 'string' ? colorAssignmentSets.get(colorAssignmentConfig, ImmutableMap()) : colorAssignmentConfig;
  let maxFrequency = 0;
  let maxFrequencyCount = 0;

  // only count frequencies for colors that are in the theme
  // we will never assign a color that is not in the theme
  const colorFrequencies = theme.reduce((reduction, color) => {
    reduction[color] = 0;
    return reduction;
  }, {});

  // count the normalized "theme" colors entries, to make sure we do not count the duplicates if there is any
  const colorCount = Object.keys(colorFrequencies).length;

  // count the frequencies of theme colors within pre-assigned colors
  preAssignedColors.forEach(color => {
    if (Object.prototype.hasOwnProperty.call(colorFrequencies, color)) {
      const newFrequency = ++colorFrequencies[color];
      if (newFrequency > maxFrequency) {
        maxFrequency = newFrequency;
        maxFrequencyCount = 1;
      } else if (newFrequency === maxFrequency) {
        maxFrequencyCount++;
      }
    }
  });

  // pick colors for all series
  let i = 0;
  return OrderedMap(keys.map(key => {
    const metricInfo = extractMetricSeriesInfo(key);
    if (preAssignedColors.has(key)) {
      return [key, preAssignedColors.get(key)];
    } else if (metricInfo && preAssignedColors.has(metricInfo.property)) {
      return [key, preAssignedColors.get(metricInfo.property)];
    } else {
      while (maxFrequencyCount > 0 && maxFrequencyCount < colorCount && colorFrequencies[theme.get(i % theme.size)] === maxFrequency) i++;
      const color = theme.get(i % theme.size);
      const newFrequency = ++colorFrequencies[color];
      if (newFrequency > maxFrequency) {
        maxFrequency = newFrequency;
        maxFrequencyCount = 1;
      } else if (newFrequency === maxFrequency) {
        maxFrequencyCount++;
      }
      return [key, color];
    }
  }).toJS());
}
export function pickColors(colorConfig, keys, customTheme, reverseGradient = false) {
  if (colorConfig.get('custom')) {
    const themeId = colorConfig.get('theme') || defaultTheme;
    const theme = customTheme || themes.get(themeId);
    return pickCustomColors(colorConfig.get('custom'), keys, theme);
  } else {
    return pickThemeColors(colorConfig.get('theme') || defaultTheme, keys, customTheme, reverseGradient);
  }
}
export function pickPatterns(patterns, keys) {
  return ImmutableMap(keys.map((key, index) => {
    return [key, patterns.get(index % patterns.size)];
  }).toJS());
}