// @ts-expect-error ts-migrate(7016)
import { getCachedPipelines } from '../data/pipelinesDao';
export const getValidPipelinesForMetrics = dealPipelines => getCachedPipelines().toJS().filter(({
  pipelineId
}) => dealPipelines.isEmpty() || dealPipelines.includes(pipelineId)).reduce((collect, {
  stages
}) => [/* eslint-disable-next-line hubspot-dev/no-reduce-accumulator-copy */
...collect, ...stages.filter(({
  metadata: {
    probability
  }
}) => probability > 0 && probability < 1)], []);