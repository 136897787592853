/* hs-eslint ignored failing-rules */
/* eslint-disable no-prototype-builtins */

'use es6';

import { Map as ImmutableMap } from 'immutable';
import both from 'transmute/both';
import curry from 'transmute/curry';
import entrySeq from 'transmute/entrySeq';
import get from 'transmute/get';
import identity from 'transmute/identity';
import keySeq from 'transmute/keySeq';
import reduce from 'transmute/reduce';
import compose from 'transmute/compose';
import some from 'transmute/some';
import curryN from 'transmute/curryN';

// Functional

export const thunkify = fn => {
  return (...args) => {
    return () => {
      return fn(...args);
    };
  };
};

// Values

export const lang = curry((baseLangKey, restOfTheKey) => `${baseLangKey}.${restOfTheKey}`);

// Util

export const defer = fn => setTimeout(fn, 1);

// Conditionals

export const complement = fn => (...args) => !fn(...args);
export const isTruthy = k => !!k;
export const strictEquals = curry((left, right) => left === right);
export const isNil = k => k == null;
export const isNotNil = k => k != null;
const isTypeof = curry((jsTypeName, k) => typeof k === jsTypeName);
export const isFunction = isTypeof('function');
export const isBoolean = isTypeof('boolean');
export const isString = isTypeof('string');
export const isObject = both(isNotNil, isTypeof('object'));

// https://product.hubteam.com/docs/frontend/docs/polyfills.html#Number.isInteger
export const isInteger = Number.isInteger || function (value) {
  return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
};
export const instanceOf = curry((Class, subject) => subject instanceof Class);

// source: https://www.ecma-international.org/ecma-262/5.1/#sec-8.6.2
export const isRegExp = object => Object.prototype.toString.call(object) === '[object RegExp]';
export const regexTest = curry((regex, subject) => isRegExp(regex) && isString(subject) && regex.test(subject));
export const cond = curry((pairs, value) => {
  for (const [pred, fn] of pairs) {
    if (pred(value)) {
      return fn(value);
    }
  }
  return undefined;
});
export const not = subject => !subject;
const pushIf = curry((condition, item, subject) => {
  if (condition(item)) {
    subject.push(item);
  }
  return subject;
});
export const pushIfNotNil = pushIf(isNotNil);

/** Default is returned if the given value is nil (see `isNil`)*/
export const defaultTo = curry((defaultValue, given) => isNil(given) ? defaultValue : given);
export const someIsNil = some(isNil);
export const overSome = (...fns) => value => {
  for (const fn of fns) {
    if (fn(value)) {
      return true;
    }
  }
  return false;
};
export const overEvery = (...fns) => value => {
  for (const fn of fns) {
    if (!fn(value)) {
      return false;
    }
  }
  return true;
};

// String

export const append = curry((toAppend, subject) => `${subject}${toAppend}`);

/**
 * if not nil: casts to String
 * otherwise: return nil
 * @param subject
 * @returns {string | null}
 */
export const stringIfPresent = subject => isNotNil(subject) ? String(subject) : null;
export const isStringUrlFree = text => {
  const regex = /(\w+\.\w+)/g;
  return !regex.test(text);
};

// Iterables
export const find = curry((predicate, subject) => {
  if (isNil(subject)) {
    return null;
  }
  const entries = entrySeq(subject);
  if (isNil(entries)) {
    return null;
  }
  for (const e of entries) {
    const [k, v] = e;
    if (predicate(v, k)) {
      return k;
    }
  }
  return null;
});

// Array

/* `R.invoker` from https://github.com/ramda/ramda/blob/v0.26.1/source/invoker.js */
export const invoker = (arity, functionName) => curryN(arity + 1)((...args) => {
  const subject = args[arity];
  if (isNil(subject) || !isFunction(subject[functionName])) {
    throw new TypeError(`${subject} does not have a method named "${functionName}".`);
  }
  return subject[functionName].apply(subject, Array.prototype.slice.call(args, 0, arity));
});
export const join = invoker(1, 'join');
export const includes = invoker(1, 'includes');

/**
 * @param {Function} - filterFn (<A> -> Boolean)
 * @param {Array<A>} - subject
 * @returns an array of two arrays, partitioned by the `filterFn`.
 */
export const partition = curry((filterFn, subject) => reduce([[], []], ([aList, bList], current) => {
  return filterFn(current) ? [[...aList, current], [...bList]] : [[...aList], [...bList, current]];
}, subject));

// Immutable

export const concat = (...valuesOrIterables) => base => base.concat(...valuesOrIterables);

// Map

// Like forEach, but the given function can control when to exit the loop.
export const forSome = (func, iterable) => {
  if (isObject(iterable)) {
    // https://jsperf.com/object-keys-vs-hasownproperty/55
    for (const k in iterable) {
      if (iterable.hasOwnProperty(k)) {
        if (func(k, iterable[k])) {
          return;
        }
      }
    }
  } else {
    for (const v of iterable) {
      if (func(v)) {
        return;
      }
    }
  }
};

/**
 * @param subject (immutable maps or vanilla maps)
 */
export const toPairs = subject => {
  const keys = ImmutableMap.isMap(subject) ? keySeq(subject) : Object.keys(subject);
  return keys.map(key => [key, get(key, subject)]);
};

/**
 * Creates an object given an array of pairs
 * @param subject (vanilla array)
 * @returns {object} js object
 */
export const fromPairs = reduce({}, (acc, [key, value]) => Object.assign({}, acc, {
  [key]: value
}));

/**
 * Note: If the subject contains a nil element, then this function will skip that element.
 * @example
 *
 *      indexAndMap(
 *        ({ key }) => key === 1 ? null : key,  // indexer
 *        i => i,                               // mapper
 *        [{key: 1}, {key: 2}, {key: 3}]        // subject
 *      )                                       //=> {2: {key: 2}, 3: {key: 3}}
 */
export const indexAndMap = curry((indexer, mapper, subject) => reduce({}, (indexed, item) => {
  const key = indexer(item);
  const shouldSkipItem = isNil(key);
  if (shouldSkipItem) {
    return indexed;
  }
  indexed[key] = mapper(item);
  return indexed;
}, subject));
export const indexBy = curry((indexer, subject) => indexAndMap(indexer, identity, subject));
export const invert = object => {
  if (!isObject(object)) {
    return object;
  }
  const swapPairs = reduce([], (acc, [k, v]) => [...acc, [v, k]]);
  return compose(fromPairs, swapPairs, toPairs)(object);
};