import * as DataSources from './constants/dataSources';
// import * as DataTypes from './constants/dataTypes';
import * as checked from './lib/checked';
const DataSource = checked.symbol(DataSources, 'DataSource');
const DataType = checked.string(); // symbol(DataTypes, 'DataType');

export const Module = checked.record({
  dataSource: DataSource,
  dataType: DataType.optional(),
  use: checked.any(),
  references: checked.any().fromJS(),
  handleError: checked.any(),
  // unified spec
  getUnifiedSpecForConfig: checked.func().optional(),
  // Specific to inbounddb as of now
  getInboundSpec: checked.func().optional(),
  referenceProperties: checked.any().optional(),
  search: checked.any().optional(),
  hydrate: checked.any().optional()
}, 'Module');
export const InboundDbModule = object => Module(Object.assign({
  dataSource: DataSources.INBOUNDDB
}, object));
export const UnifiedModule = object => Module(Object.assign({
  dataSource: DataSources.UNIFIED
}, object, {
  getUnifiedSpecForConfig: object.getUnifiedSpecForConfig ? async (...args) => await object.getUnifiedSpecForConfig(...args) : undefined
}));
export const CustomModule = object => Module(Object.assign({
  dataSource: DataSources.CUSTOM
}, object));