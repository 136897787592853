import { useReducer, useCallback } from 'react';
import { MSG_TYPE_CONFIRM_DELETE_COMMENT, MSG_TYPE_DELETE_COMMENT, MSG_TYPE_DELETE_COMMENT_FAILURE, MSG_TYPE_DELETE_COMMENT_SUCCESS } from 'collaboration-sidebar/constants/IFrameMessageTypes';
import { useCollaborationSidebarState } from '..';
import { DELETE_COMMENT_REQUEST } from '../../constants/CollaborationSidebarStateProperties';
const HANDLE_CONFIRM = 'HANDLE_CONFIRM';
const HANDLE_CLOSE = 'HANDLE_CLOSE';
function reducer(state, action) {
  switch (action.type) {
    case MSG_TYPE_CONFIRM_DELETE_COMMENT:
      return Object.assign({}, state, {
        id: action.id,
        parentId: action.parentId,
        commentType: action.commentType,
        isParentCommentResolved: action.isParentCommentResolved,
        failed: false,
        formattedBody: action.formattedBody,
        loading: false,
        show: true
      });
    case MSG_TYPE_DELETE_COMMENT_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        show: false
      });
    case MSG_TYPE_DELETE_COMMENT_FAILURE:
      return Object.assign({}, state, {
        failed: true,
        loading: false
      });
    case HANDLE_CONFIRM:
      return Object.assign({}, state, {
        failed: false,
        loading: true
      });
    case HANDLE_CLOSE:
      return Object.assign({}, state, {
        show: false
      });
    default:
      return state;
  }
}
export default function useConfirmDeleteCommentModal({
  sendMessageRef,
  isSendMessageReady
}) {
  const {
    onUpdateCollaborationSidebarState
  } = useCollaborationSidebarState();
  const [{
    id,
    parentId,
    commentType,
    failed,
    formattedBody,
    loading,
    show,
    isParentCommentResolved
  }, dispatch] = useReducer(reducer, {
    failed: false,
    loading: false,
    show: false
  });
  const handleMessage = useCallback(({
    payload
  }) => {
    if (payload.type === MSG_TYPE_DELETE_COMMENT_SUCCESS) {
      onUpdateCollaborationSidebarState({
        [DELETE_COMMENT_REQUEST]: {
          loading: false,
          error: false
        }
      });
    }
    if (payload.type === MSG_TYPE_DELETE_COMMENT_FAILURE) {
      onUpdateCollaborationSidebarState({
        [DELETE_COMMENT_REQUEST]: {
          loading: false,
          error: true
        }
      });
    }
    dispatch(payload);
  }, [onUpdateCollaborationSidebarState]);
  const onConfirm = useCallback(() => {
    if (isSendMessageReady && sendMessageRef.current) {
      sendMessageRef.current(MSG_TYPE_DELETE_COMMENT, {
        id,
        commentType,
        parentId,
        isParentCommentResolved
      });
    }
    dispatch({
      type: HANDLE_CONFIRM
    });
  }, [id, parentId, commentType, sendMessageRef, isParentCommentResolved, isSendMessageReady]);
  const onClose = useCallback(() => dispatch({
    type: HANDLE_CLOSE
  }), []);
  return {
    failed,
    formattedBody,
    handleMessage,
    loading,
    onConfirm,
    onClose,
    show
  };
}