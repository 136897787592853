import { EXTERNAL_PROVIDER, TRUSTED_PROVIDERS } from './contentProviders';

// The following is adapted from hubspot-tinymce/tinymce-plugins/hsmedia/util

// regex patterns for determining embed provider name from the iframe body html
const definedContentProviders = [{
  name: EXTERNAL_PROVIDER.AIRTABLE,
  patterns: [/https:\/\/(?:www\.)?airtable\.com\/embed/]
}, {
  name: EXTERNAL_PROVIDER.AMPLITUDE,
  patterns: [/https:\/\/(?:app\.)?amplitude\.com\/analytics\/share\/embed/]
}, {
  name: EXTERNAL_PROVIDER.DATABOX,
  patterns: [/https:\/\/(?:www\.)?app\.databox\.com/]
}, {
  name: EXTERNAL_PROVIDER.DOMO,
  patterns: [/https:\/\/(?:www\.)?public\.domo\.com/, /https:\/\/.+\.domo.com\/embed/]
}, {
  name: EXTERNAL_PROVIDER.GOOGLE_CAL,
  patterns: [/https:\/\/(?:www\.)?calendar\.google\.com\/calendar\/embed/]
}, {
  name: EXTERNAL_PROVIDER.GOOGLE_DATA_STUDIO,
  //includes google analytics
  patterns: [/https:\/\/(?:www\.)?datastudio\.google\.com\/embed\/reporting/]
}, {
  name: EXTERNAL_PROVIDER.GOOGLE_DOCS,
  patterns: [/https:\/\/(?:www\.)?docs\.google\.com\/document/]
}, {
  name: EXTERNAL_PROVIDER.GOOGLE_DRIVE,
  patterns: [/https:\/\/(?:www\.)?drive\.google\.com\/embeddedfolder/]
}, {
  name: EXTERNAL_PROVIDER.GOOGLE_FORMS,
  patterns: [/https:\/\/(?:www\.)?docs\.google\.com\/forms/]
}, {
  name: EXTERNAL_PROVIDER.GOOGLE_SHEETS,
  patterns: [/https:\/\/(?:www\.)?docs\.google\.com\/spreadsheets/]
}, {
  name: EXTERNAL_PROVIDER.GOOGLE_SLIDES,
  patterns: [/https:\/\/(?:www\.)?docs\.google\.com\/presentation/]
}, {
  name: EXTERNAL_PROVIDER.HUBSPOT_APP,
  patterns: [/https:\/\/(?:www\.)?(?:app(-(\w+))?)\.hubspot(?:qa)?\.com/]
}, {
  name: EXTERNAL_PROVIDER.IMPULSE_CREATIVE,
  patterns: [/https:\/\/(?:www\.)?app\.impulsecreative\.com/]
}, {
  name: EXTERNAL_PROVIDER.JOTFORM,
  patterns: [/https:\/\/(?:www\.)?form\.jotform\.com/]
}, {
  name: EXTERNAL_PROVIDER.KEYNOTE,
  patterns: [/https:\/\/(?:www\.)?icloud\.com\/keynote/]
}, {
  name: EXTERNAL_PROVIDER.LOOKER,
  patterns: [/https:\/\/(?:www\.)?looker\..+\.net\/embed/]
}, {
  name: EXTERNAL_PROVIDER.LOOM,
  patterns: [/https:\/\/(?:www\.)?loom\.com\/embed/]
}, {
  name: EXTERNAL_PROVIDER.MAPSLY,
  patterns: [/https:\/\/(?:www\.)?.+\.mapsly\.com/]
}, {
  name: EXTERNAL_PROVIDER.ONEDRIVE,
  patterns: [/https:\/\/(?:www\.)?onedrive\.live\.com\/embed/],
  subcategories: [{
    name: EXTERNAL_PROVIDER.EXCEL,
    patterns: [/myExcelDiv/]
  }, {
    name: EXTERNAL_PROVIDER.POWERPOINT,
    patterns: [/presentation/]
  }, {
    name: EXTERNAL_PROVIDER.MS_WORD,
    patterns: [/document/]
  }]
}, {
  name: EXTERNAL_PROVIDER.POWER_BI,
  patterns: [/https:\/\/(?:www\.)?app\.powerbi\.com/, /https:\/\/(?:www\.)?app\.powerbigov\.us/, /https:\/\/(?:www\.)?437641-sb1\.app\.netsuite\.com/]
}, {
  name: EXTERNAL_PROVIDER.SENDINBLUE,
  patterns: [/https:\/\/(?:www\.)?my\.sendinblue\.com/]
}, {
  name: EXTERNAL_PROVIDER.SHAREPOINT,
  patterns: [/https:\/\/(?:www\.)?.+\.sharepoint\.com/]
}, {
  name: EXTERNAL_PROVIDER.SLIDESHARE,
  patterns: [/https:\/\/(?:www\.)?slideshare\.net\/slideshow\/embed_code/]
}, {
  name: EXTERNAL_PROVIDER.TABLEAU,
  patterns: [/https:\/\/.+\.online\.tableau\.com/, /https:\/\/tableau\..+\.com/]
}, {
  name: EXTERNAL_PROVIDER.VIDYARD,
  patterns: [/https:\/\/(?:www\.)?share\.vidyard\.com\/watch/]
}, {
  name: EXTERNAL_PROVIDER.WHATAGRAPH,
  patterns: [/https:\/\/(?:www\.)?reports\.live\/shared/]
}, {
  name: EXTERNAL_PROVIDER.YOUTUBE,
  patterns: [/https:\/\/(?:www\.)?youtube\.com\/embed/]
}, {
  name: EXTERNAL_PROVIDER.ZOHO,
  patterns: [/https:\/\/(?:www\.)?analytics\.zoho\.com/]
}, {
  name: EXTERNAL_PROVIDER.PLANIT,
  patterns: [/https:\/\/(?:www\.)?plataforma\.planitondemand\.com/]
}, {
  name: EXTERNAL_PROVIDER.WHALY,
  patterns: [/https:\/\/(?:www\.)?app\.whaly\.io/]
}, {
  name: EXTERNAL_PROVIDER.POSTIT,
  patterns: [/https:\/\/(?:www\.)?postit\.beep\.digital/]
}, {
  name: EXTERNAL_PROVIDER.CALLBOXINC,
  patterns: [/https:\/\/(?:www\.)?apps-01\.callboxinc\.com/, /https:\/\/(?:www\.)?api-01\.callboxinc\.com/]
}, {
  name: EXTERNAL_PROVIDER.KLUE,
  patterns: [/https:\/\/(?:www\.)?app\.klue\.com/, /https:\/\/(?:www\.)?app-staging\.klue\.com/, /https:\/\/(?:v2\.)?app\.klue\.com/]
}, {
  name: EXTERNAL_PROVIDER.AIRCALL,
  patterns: [/https:\/\/(?:www\.)?dashboard\.aircall\.io/]
}];
const iframeSrcPattern = /<(?:iframe|script)\s+.*src=["'](.*?)["'?].*>/; //omits query string
const iframeSrcPatternWithQuery = /<(?:iframe|script)\s+.*src=["'](.*?)["'].*>/; //includes query string

const domainPattern = /(?:http:|https:)?\/\/(?:www\.)?([a-zA-Z,0-9/-]+(?:.[a-zA-Z,0-9/-]+)?)\..*/;
const stripComments = (html = '') => html.replace(/(<!--.*?-->)|(<!--[\S\s]+?-->)|(<!--[\S\s]*?$)/g, '');
const findProviderMatch = (html, providerList) => providerList.find(providerInstance => providerInstance.patterns.find(pattern => pattern.test(html)));
export const getSrcFromIframeBody = (iframeBodyHtml,
// TODO: find type
includeQuery = false) => {
  const cleanBodyHtml = stripComments(iframeBodyHtml);
  const srcPatternResult = includeQuery ? iframeSrcPatternWithQuery.exec(cleanBodyHtml) : iframeSrcPattern.exec(cleanBodyHtml);
  if (!srcPatternResult) {
    return undefined;
  }
  return srcPatternResult[1];
};
export const getIframeContentProvider = iframeBodyHtml => {
  const iframeSrc = getSrcFromIframeBody(iframeBodyHtml);
  if (!iframeSrc) {
    return undefined;
  }

  // find defined provider match
  const definedProviderMatch = findProviderMatch(iframeSrc, definedContentProviders);
  if (definedProviderMatch) {
    if (definedProviderMatch.subcategories) {
      const subMatch = findProviderMatch(iframeBodyHtml, definedProviderMatch.subcategories);
      return subMatch ? subMatch.name : definedProviderMatch.name;
    }
    return definedProviderMatch.name;
  }

  // otherwise, return domain
  const domainPatternResult = domainPattern.exec(iframeSrc);
  return domainPatternResult ? domainPatternResult[1] : undefined;
};
export const isHubSpotContent = content => getIframeContentProvider(content) === EXTERNAL_PROVIDER.HUBSPOT_APP;
export const checkIsTrustedProvider = html => {
  const iframeContent = getIframeContentProvider(html);
  if (!iframeContent) {
    return false;
  }
  return TRUSTED_PROVIDERS.includes(iframeContent);
};