// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const NOOP = (...args) => {};
export const DEFAULT_DEBUG = {
  set: NOOP,
  event: NOOP
};
// TODO: This is not a complete list of all the debug events yet
export const DEBUG_EVENTS = {
  VISUALIZATION: 'Visualization',
  ERROR: 'Error',
  NO_DATA_VISUALIZATION: 'NoDataVisualization',
  HIGHCHARTS: 'Highcharts',
  TABLE: 'TableVisualization'
};