export const compareDataSourceIds = (a, b) => {
  if (!a) {
    return -1;
  }
  if (!b) {
    return 1;
  }
  const [aMetaType, aObjectType] = a.split('-');
  const [bMetaType, bObjectType] = b.split('-');
  if (isNaN(Number(aMetaType)) || isNaN(Number(aObjectType))) {
    return 1;
  }
  if (isNaN(Number(bMetaType)) || isNaN(Number(bObjectType))) {
    return -1;
  }
  return +aMetaType === +bMetaType ? +aObjectType - +bObjectType : +aMetaType - +bMetaType;
};