export const WIDGET_CONFIG_TYPES = {
  NOTE: 'NOTE',
  IFRAME: 'IFRAME',
  SECTION_HEADER: 'SECTION_HEADER'
};
export default WIDGET_CONFIG_TYPES;

// TODO: Move IFRAME and NOTE widgets to this pattern, and remove its dependency on the report config };

export const isNonReportWidgetReport = widgetReport => {
  return 'nonReportWidget' in widgetReport;
};
export const isSectionHeaderWidgetReport = widgetReport => {
  return isNonReportWidgetReport(widgetReport) && widgetReport.nonReportWidget.widgetType === WIDGET_CONFIG_TYPES.SECTION_HEADER;
};

// TODO: Lift dashboard widget specific config out of report config field and into the nonReportWidget field of the report object instead
/** from https://git.hubteam.com/HubSpot/Dashboard/blob/master/DashboardBase/src/main/java/com/hubspot/dashboard/v2/reports/configs/NoteConfigIF.java */