import { visualTypeIsXY } from 'reporting-snowflake/relational/utils/visual-utils';
import { getDateTruncTransformValue, getDimensions, hasDateTrunc } from 'reporting-snowflake/relational/utils/column-utils';
import { getReportVisualType } from 'reporting-snowflake/relational/utils/report-utils';
export const canOverrideReportDefinitionsFrequency = (reportDefinition, dashboardFrequency, dashboardFrequencyUseFiscalYear = false) => {
  if (!dashboardFrequency) {
    return false;
  }
  const visualType = getReportVisualType(reportDefinition);
  if (!visualType) {
    return false;
  }
  const dimensionsWithFrequency = getDimensions(reportDefinition.columns).toList().filter(hasDateTrunc);

  /** dashboard frequency is already set */
  const frequencyDimensionsContainDashboardFrequency = dimensionsWithFrequency.some(dimension => getDateTruncTransformValue(dimension) === dashboardFrequency && dimension.get('useFiscalYear') === dashboardFrequencyUseFiscalYear);
  if (frequencyDimensionsContainDashboardFrequency) {
    return false;
  }
  if (dimensionsWithFrequency.size === 1) {
    return true;
  }
  if (visualTypeIsXY(visualType) && dimensionsWithFrequency.size === 2) {
    return true;
  }
  return false;
};