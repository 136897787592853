import { ENUMERATION } from 'customer-data-objects/property/PropertyTypes';
import { INBOUND_DB_LIST } from 'customer-data-objects/property/ExternalOptionTypes';
import { RADIO } from 'customer-data-objects/property/PropertyFieldTypes';
import PropertyRecord from 'customer-data-objects/property/PropertyRecord';

/*
 * Virtual list membership property for CRM search. Works with all object types.
 */
export default PropertyRecord({
  displayOrder: -1,
  externalOptions: true,
  favoritedOrder: -1,
  fieldType: RADIO,
  groupName: 'listmembership',
  hubspotDefined: true,
  label: 'List membership',
  name: 'hs_crm_search.ilsListIds',
  readOnlyDefinition: true,
  readOnlyValue: true,
  referencedObjectType: INBOUND_DB_LIST,
  type: ENUMERATION
});