'use es6';

import { combineConstraints, ConstraintTypes, createConstraint, InvalidSpecificationTypes } from '../validate';
import { validateFilterOperatorValue } from '../../modify/filters/filter-validation-utils';
import toJS from 'reporting-data/lib/toJS';
const validateFilterGroups = (filterGroups, validator) => {
  return filterGroups.every(group => group.filters.every(filter => validator(filter)));
};
const filterGroupsAreValid = createConstraint('filter-groups-valid', ConstraintTypes.HARD, InvalidSpecificationTypes.INCOMPLETE, (filterGroups, userGates) => validateFilterGroups(filterGroups, filter => validateFilterOperatorValue(filter, userGates)), [report => report.filtering.groups, (_, userInfo) => {
  if (!userInfo) return [];
  const {
    gates
  } = toJS(userInfo);
  return gates;
}]);
export const filters = combineConstraints('filters', [filterGroupsAreValid]);