import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import http from 'hub-http/clients/apiClient';
import { RequestState } from '../filter/requests/request-state';
import { refreshReportSuccess } from './reportData/slice';
import { refreshAllReportsOnDashboardAsyncThunk } from './reportData/thunks';
const DASHBOARD_TEMPLATES_URL = 'reporting-discovery/v1/dashboard-template';
const DEFAULT_DASHBOARD_HYDRATE_PARAMS = ['TAG', 'PERMISSION'];
const DASHBOARDS_ENDPOINT = `dashboard/v2`;
const DASHBOARD_URL = `${DASHBOARDS_ENDPOINT}/dashboard`;
// this will convert report template to match the format with report object,
// that we do not need to deal with inconsistency later throughout the components
/*
  report widget would look like,
  {
    layout:{...}
    report:{...}
    reportId: {...}
  }
*/
const reportTemplateConverter = widgetTemplate => {
  const {
    reportTemplate,
    layout,
    dashboardTemplateId
  } = widgetTemplate;
  return {
    layout,
    report: Object.assign({}, reportTemplate),
    dashboardTemplateId,
    reportId: reportTemplate.id
  };
};
const fetchSingleDashboardTemplate = templateId => http.get(`${DASHBOARD_TEMPLATES_URL}/${templateId}`, {
  query: {
    hydrate: DEFAULT_DASHBOARD_HYDRATE_PARAMS
  }
}).then(template => {
  return Object.assign({}, template, {
    widgets: template.widgetTemplates.map(reportTemplateConverter)
  });
});
const fetchDashboardData = dashboardId => http.get(`${DASHBOARD_URL}/${dashboardId}`, {
  query: {
    record: true
  }
});
export const fetchResource = {
  dashboardTemplate: fetchSingleDashboardTemplate,
  dashboard: fetchDashboardData
};
export const fetchDashboardAsyncThunk = createAsyncThunk('dashboard/fetch', async ({
  resourceId,
  resourceType
}) => {
  try {
    const fetchResourceFunc = fetchResource[resourceType];
    if (!fetchResourceFunc) {
      throw new Error('invalid resource type');
    }
    const dashboard = await fetchResourceFunc(resourceId);
    return dashboard;
  } catch (error) {
    return error;
  }
});
const dashboardDataSlice = createSlice({
  name: 'dashboardData',
  initialState: {},
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchDashboardAsyncThunk.pending, (state, action) => {
      const {
        arg
      } = action.meta;
      state[arg.resourceId] = {
        status: RequestState.PENDING
      };
    });
    builder.addCase(fetchDashboardAsyncThunk.fulfilled, (state, action) => {
      const {
        arg
      } = action.meta;
      const dashboard = action.payload;
      state[arg.resourceId] = {
        status: RequestState.SUCCEEDED,
        dashboard
      };
    });
    builder.addCase(fetchDashboardAsyncThunk.rejected, (state, action) => {
      const {
        arg
      } = action.meta;
      state[arg.resourceId] = {
        status: RequestState.FAILED
      };
    });
    builder.addCase(refreshReportSuccess, (state, action) => {
      const {
        resourceId,
        updatedReport,
        reportId
      } = action.payload;
      const {
        dashboard
      } = state[resourceId];
      if (dashboard && updatedReport) {
        const {
          widgets
        } = dashboard;
        const targetWidgetIndexToUpdate = widgets.findIndex(widget => `${widget.reportId}` === reportId);
        widgets[targetWidgetIndexToUpdate].report = updatedReport;
      }
    });
    builder.addCase(refreshAllReportsOnDashboardAsyncThunk.fulfilled, (state, action) => {
      const {
        arg: {
          resourceId
        }
      } = action.meta;
      const dashboard = action.payload;
      state[resourceId] = {
        dashboard,
        status: RequestState.SUCCEEDED
      };
    });
  }
});
export const fetchDashboard = (resourceId, resourceType) => async dispatch => {
  await dispatch(fetchDashboardAsyncThunk({
    resourceId,
    resourceType
  }));
};
export const selectIsDashboardFetched = resourceId => state => {
  const {
    dashboardData: fetchedDashboards
  } = state;
  return fetchedDashboards[resourceId] && fetchedDashboards[resourceId].status === RequestState.SUCCEEDED;
};
export const selectFetchedDashboard = resourceId => state => {
  const {
    dashboardData: fetchedDashboards
  } = state;
  return fetchedDashboards[resourceId].dashboard;
};
export const selectDashboardWidgets = resourceId => state => {
  const dashboard = selectFetchedDashboard(resourceId)(state);
  return (dashboard === null || dashboard === void 0 ? void 0 : dashboard.widgets) || [];
};
export default dashboardDataSlice.reducer;