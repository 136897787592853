import * as checked from '../../lib/checked';
import { Map as ImmutableMap, List } from 'immutable';
import { DatasetInsightMeta, DatasetInsightOption } from '../../tsTypes/datasetInsightTypes';
export const Dataset = checked.record({
  key: checked.string().optional(),
  data: checked.list().fromJS().defaultValue([]),
  properties: checked.map(checked.map()).fromJS().defaultValue({}),
  // The # of documents in a search table
  paginationSize: checked.number().optional(),
  searchDimension: checked.string().optional(),
  dataAge: checked.number().optional(),
  insightOption: DatasetInsightOption.optional(),
  insightMeta: DatasetInsightMeta.optional(),
  // Snowflake queryId used to fetch this data
  // Temporary addition, used for RAAS BE report resolve validation.
  queryId: checked.string().optional()
}, 'Dataset');

// some apps use a Map instead of a dataset record

const getInDataset = (dataset, findPath, fallback) => {
  if (ImmutableMap.isMap(dataset)) {
    return dataset.getIn(findPath, fallback);
  } else {
    // Record.getIn does not support a fallback param. We should only return
    // the fallback if the path does not exist. Sometimes the path contains
    // falsey values like empty string, and we want to use those if present.
    const datasetRecord = dataset;
    return datasetRecord.hasIn(findPath) ? datasetRecord.getIn(findPath) : fallback;
  }
};
export const getProperty = (dataset, property) => getInDataset(dataset, ['properties', property]);
export const getPropertyLabel = (dataset, property) => getInDataset(dataset, ['properties', property, 'label']) || property;
export const getPropertyType = (dataset, property) => getInDataset(dataset, ['properties', property, 'type']);
export const getReference = (dataset, property, key) => getInDataset(dataset, ['properties', property, 'references', String(key)]);
const LABEL_DELIMITER = ', ';
export const getReferenceLabel = (dataset, property, key, fallback = key) => {
  if (List.isList(key)) {
    const compositeLabels = key.map(k => getReferenceLabel(dataset, property, k, fallback));
    return compositeLabels === null || compositeLabels === void 0 ? void 0 : compositeLabels.join(LABEL_DELIMITER);
  }
  return getInDataset(dataset, ['properties', property, 'references', String(key), 'label'], String(fallback));
};
export const getNumberDisplayHint = (dataset, property) => getInDataset(dataset, ['properties', property, 'numberDisplayHint']);
export const hasReferences = (dataset, property, key) => !!getInDataset(dataset, ['properties', property, 'references', String(key)]);