import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["dashboardId", "resourceType"];
import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchDashboardWithFiltersApplied } from '../../data/dashboard-dao';
export const getOverriddenReportsFromAPIThunk = createAsyncThunk('getOverriddenReportsFromAPI/fetch', action => {
  try {
    const {
        dashboardId,
        resourceType
      } = action,
      params = _objectWithoutPropertiesLoose(action, _excluded);
    if (resourceType === 'dashboardTemplate') {
      throw new Error('Filter application for dashboard templates not implemented yet.');
    }
    return fetchDashboardWithFiltersApplied(dashboardId, params);
  } catch (error) {
    return Promise.reject(error);
  }
});