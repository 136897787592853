'use es6';

// Ads
export const ADS_PRO_ACCESS = 'ads-roi-reporting';

// Contacts
export const CONTACTS_READ = 'contacts-read';

// Advanced Builder
export const ADVANCED_REPORT_BUILDER_ACCESS = 'advanced-report-builder-access';

// Goals
export const ADVANCED_SERVICE_GOAL_MANAGER = 'advanced-service-goal-manager';
export const ADVANCED_SALES_GOAL_MANAGER = 'advanced-sales-goal-manager';

// Marketing
export const REVENUE_ATTRIBUTION_ACCESS = 'revenue-attribution-read';
export const REVENUE_ATTRIBUTION_LITE = 'revenue-attribution-lite';
export const CONTACT_CREATE_ATTRIBUTION_ACCESS = 'contact-create-date-attribution-read';
export const DEAL_CREATE_ATTRIBUTION_ACCESS = 'deal-create-attribution-read';
export const MARKETING_FREE_PRODUCT = 'marketing-free-product';
export const MARKETING_STARTER_PRODUCT = 'marketing-starter-product';
export const HUB_MARKETING_PRODUCT = 'hub-marketing-product';
export const MARKETING_PRODUCT_ACCESS = 'marketing-product-access';

// CRM
export const CRM_ACCESS = 'crm-access';
export const CRM_VIEWS_ALL = 'crm-views-all';

// Email
export const EMAIL_EDITOR_ACCESS = 'email-editor-access';
export const EMAIL_ACCESS = 'email-access';
export const EMAIL_CORE_API_ACCESS = 'email-core-api-access';

// Feedback
export const FEEDBACK_REPORTS_ACCESS = 'feedback-reports-access';
export const SERVICE_FEEDBACK_ACCESS = 'service-feedback-access';

// Ecommerce
export const ECOMMERCE_REPORTS_ACCESS = 'ecomm-reports';

// SitePage
export const SITEPAGES_ACCESS = 'sitepages-access';

// Web Analytics API
export const WEB_ANALYTICS_API_ACCESS = 'web-analytics-api-access';
export const TRAFFIC_ANALYTICS_API_ACCESS = 'traffic-analytics-api-access';

// Service
export const SERVICE_ACCESS = 'service-access';

// Conversations
export const CONVERSATIONS_ACCESS = 'conversations-access';

// Source Analytics
export const SOURCES_ANALYTICS_ACCESS = 'sources-analytics';

// Content
export const CONTENT_REPORTS_ACCESS = 'content-reports-access';

// Documents
export const DOCUMENTS_REPORTS_ACCESS = 'documents-reports-access';
export const SALES_DOCUMENT_ANALYTICS = 'sales-documents-analytics';

// Knowledge
export const KNOWLEDGE_REPORTS_ACCESS = 'knowledge-reports-access';
export const SERVICE_KNOWLEDGE_ACCESS = 'service-knowledge-access';

// PLS (Predictive Lead Scoring)
export const PSL_ACCESS = 'pls-infer-with-hubspot-model';

// Sequences
export const SEQUENCES_REPORTS_ACCESS = 'sequences-reports-access';
export const SEQUENCE_SENDER_SCORE_REPORT = 'sequence-sender-score-report';
export const SEQUENCES_ANALYTICS = 'sequences-analytics';

// Templates
export const TEMPLATES_REPORTS_ACCESS = 'templates-reports-access';
export const SALES_TEMPLATE_ANALYTICS = 'sales-templates-analytics';

// Tickets
export const TICKETS_REPORTS_ACCESS = 'tickets-reports-access';

// Pages
export const PAGES_ANALYTICS = 'pages-analytics';

// Campaigns
export const CAMPAIGNS_ACCESS = 'campaigns-access';
export const CAMPAIGNS_ANALYTICS = 'campaigns-analytics';

// Topics
export const TOPIC_CLUSTERS_REPORTS_ACCESS = 'topic-clusters-reports-access';

// CMS
export const CMS_REPORTS_ACCESS = 'cms-reports-access';

//Social
export const SOCIAL_ACCESS = 'social-access';

// ABM
export const ABM_REPORTS_ACCESS = 'abm-reports-access';

// UTM
export const UTM_ANALYTICS = 'utm-analytics';

// FORMS
export const FORMS_ANALYTICS = 'forms-conversion-charts';

// EVENTS
export const EVENTS_ANALYTICS = 'events-analytics';

// SEO
export const GOOGLE_SEARCH_CONSOLE_ACCESS = 'google-search-console-access';

// Reporting: App
export const REPORTS_PAID_ACCESS = 'reports-paid-access';
export const CUSTOM_REPORTING_ACCESS = 'custom-reporting-access';
export const CUSTOM_OBJECT_ACCESS = 'custom-object-access';
export const REPORTS_SALES_PRO_ACCESS = 'reports-sales-pro-access';
export const REPORTS_SERVICE_ACCESS = 'service-team-analytics';

// Reporting: Dashboard
export const DASHBOARD_DEMO_WRITE_ACCESS = 'dashboard-demo-write';
export const IFRAME_WIDGET_ACCESS = 'iframe-widget-access';

// Reporting: User
export const REPORTING_ADMIN = 'reporting-admin';
export const SUPER_USER = 'super-user';
export const SUPER_ADMIN = 'super-admin';
export const JITA_USER = 'jita-user';

// Reporting Permission
export const REPORTING_ACCESS = 'reporting-access';
export const REPORTING_CREATE_AND_OWN = 'reporting-create-and-own';
export const REPORTING_EDIT = 'reporting-edit';

// Teams
export const TEAMS_ADVANCED_MODELING_ACCESS = 'teams-advanced-modeling-access';

// Business Units
export const BUSINESS_UNITS_ACCESS = 'business-units-access';

// datasets
export const REPORTING_DATASETS_ACCESS = 'reporting-datasets-access';

// Payments
export const PAYMENTS_REPORTING_ACCESS = 'commerce-payments-read';