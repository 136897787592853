export const COLLABORATION_SIDEBAR_QUERY_PARAMETERS = {
  commenting: 'commenting',
  csOpen: 'csOpen',
  csTab: 'csTab',
  csSelectedObjectType: 'csSelectedObjectType',
  csSelectedObjectId: 'csSelectedObjectId',
  commentThread: 'commentThread',
  commentId: 'commentId',
  notificationSettingsOpen: 'notificationSettingsOpen',
  autoFocus: 'autoFocus',
  moduleId: 'moduleId'
};
export const COLLABORATION_SIDEBAR_QUERY_PARAMETERS_AS_KEYS = Object.keys(COLLABORATION_SIDEBAR_QUERY_PARAMETERS);