import I18n from 'I18n';
import isValidI18nKey from 'I18n/utils/isValidI18nKey';
import { List, OrderedSet, Map as ImmutableMap, Set as ImmutableSet } from 'immutable';
import { CRM_OBJECT_META_TYPE } from 'reporting-data/constants/crmObjectMetaTypes';
import { HS_OBJECT_ID } from '../metadata/links/links-mapping';
// @ts-expect-error migrate upstream files
import { ID_PROPERTIES } from '../metadata/references/object/object-mapping';
import { FieldRef } from '../schema/field-ref';
import { DATASET_TABLE, JoinDefinition, JoinTypes, TableDescription } from '../schema/table-records';
import { DataSourceTypes, MetaTypes, SnowflakeJoinTypes, TableGroupSections, isEmptySnowflakeTable } from '../schema/source-records';
import { ASSOCIATION_DIVIDER, ASSOCIATION_ID_DIVIDER, getCorrespondingAssociationDisplayLabelFromId, getSelfAssociationDisplayLabelFromId, getSelfAssociationTableLabel, getSelfJoin, getSelfJoinMetadataForObjectId } from './self-association-utils';
import { hasObjectTypeId, maybeGetObjectTypeId } from './source-utils';
import { assertUnreachable, isNotNull } from '../../shared/lib/utility-types';
export const getDataSourceId = table => {
  const type = table.type;
  if (type === DataSourceTypes.HUBSPOT_OBJECT || type === DataSourceTypes.HUBSPOT_EVENT) {
    if (!table.objectTypeId) {
      throw new Error('Expected objectTypeId to be defined for this data source!');
    }
    return table.objectTypeId;
  }
  if (DataSourceTypes.EVENTS_DIGEST === type) {
    return `D-${table.eventsDigestType}`;
  }
  if (type === DataSourceTypes.HUBSPOT_DATASET || type === DataSourceTypes.HUBSPOT_GLOBAL_DATASET) {
    if (!table.datasetId) {
      throw new Error(`Expected datasetId to be defined for ${type.toLowerCase()} sources.`);
    }
    return table.datasetId;
  }
  if (DataSourceTypes.EXTERNAL_DATASOURCE === type) {
    if ('dataSourceId' in table) {
      return `ed-${table.dataSourceId}`;
    } else {
      if (!table.externalDatasourceId) {
        throw new Error('Expected externalDatasourceId to be defined for external_datasource sources.');
      }
      return `ed-${table.externalDatasourceId}`;
    }
  }
  if (DataSourceTypes.VIRTUAL_DATASOURCE === type) {
    if ('dataSourceId' in table) {
      return `${table.dataSourceId}`;
    } else {
      if (table.datasourceId) {
        return `${table.datasourceId}`;
      }
      throw new Error('No dataSourceId or prejoinedDatasourceId found on prejoined datasource');
    }
  }
  assertUnreachable(type, `Unhandled table.type ${type}`);
};
export const getTableList = table => OrderedSet([table, ...(table.join || OrderedSet()).map(join => join.target).filter(tableDescription => !!tableDescription)
// @ts-expect-error join.target lacks correct TableDescription type, currently is any
.flatMap(t => getTableList(t)).filterNot(t => t.name === DATASET_TABLE).toList()]).toList();
export const getTableMapByName = table => {
  return getTableList(table).toMap().mapKeys((_, thisTable) => thisTable ? thisTable.name : '').filter(tableName => !!tableName);
};
export const getSecondaryTableList = table => {
  const primaryId = getDataSourceId(table);
  return getTableList(table).filter(tableDescription => !(getDataSourceId(tableDescription) === primaryId && tableDescription.name === table.name));
};
export const getSingularTableLabel = (table, snowflakeTable) => {
  if (!snowflakeTable || isEmptySnowflakeTable(snowflakeTable)) {
    return table;
  }
  const {
    name
  } = snowflakeTable;
  if ('metaTypeId' in snowflakeTable) {
    const {
      metaTypeId,
      metaDefinition = {}
    } = snowflakeTable;
    const {
      pluralForm: maybePluralForm,
      singularForm
    } = metaDefinition;
    // only use plural form for integrator and custom object labels for now
    if (metaTypeId === CRM_OBJECT_META_TYPE.INTEGRATION || metaTypeId === CRM_OBJECT_META_TYPE.PORTAL_SPECIFIC || metaTypeId === CRM_OBJECT_META_TYPE.INTEGRATION_EVENT || metaTypeId === CRM_OBJECT_META_TYPE.PORTAL_SPECIFIC_EVENT) {
      return maybePluralForm || singularForm;
    }
  }
  const languageKey = `reporting-snowflake.source-labels.${name}.one`;
  return isValidI18nKey(languageKey) ? I18n.text(languageKey) : name;
};
export const getTableLabel = (table, snowflakeTable) => {
  if (!snowflakeTable || isEmptySnowflakeTable(snowflakeTable)) {
    return table;
  }
  const {
    name
  } = snowflakeTable;
  if (snowflakeTable.type === 'EXTERNAL_DATASOURCE') {
    return table;
  }
  if ('metaTypeId' in snowflakeTable) {
    const {
      metaTypeId,
      metaDefinition = {}
    } = snowflakeTable;
    const {
      pluralForm: maybePluralForm,
      singularForm
    } = metaDefinition;

    // only use plural form for integrator and custom object labels for now
    if (metaTypeId === CRM_OBJECT_META_TYPE.INTEGRATION || metaTypeId === CRM_OBJECT_META_TYPE.PORTAL_SPECIFIC || metaTypeId === CRM_OBJECT_META_TYPE.INTEGRATION_EVENT || metaTypeId === CRM_OBJECT_META_TYPE.PORTAL_SPECIFIC_EVENT) {
      return maybePluralForm || singularForm;
    }
  }
  const languageKey = `reporting-snowflake.source-labels.${name}.other`;
  if (table.includes(ASSOCIATION_DIVIDER)) {
    const tableAssociationLabel = table.split(ASSOCIATION_DIVIDER).slice(1)[0].split(ASSOCIATION_ID_DIVIDER)[0];
    return isValidI18nKey(languageKey) ? `${I18n.text(languageKey)} (${tableAssociationLabel})` : table;
  }
  return isValidI18nKey(languageKey) ? I18n.text(languageKey) : name;
};
export const pickRoot = (tableGroup, snowflakeTables, override = ImmutableMap()) => {
  return override.get(tableGroup.name) || tableGroup.root.sort((a, b) => {
    const labelA = getTableLabel(a, snowflakeTables.get(a));
    const labelB = getTableLabel(b, snowflakeTables.get(b));
    return labelA.localeCompare(labelB);
  }).first();
};
const getTableGroupLabelHelper = (tableGroupName, tableGroup, snowflakeTables) => {
  if (!tableGroup) {
    console.error(`getTableGroupLabel: Could not find tableGroup with tableGroupName ${tableGroupName}`);
    return I18n.text(`reporting-snowflake.table-group-labels.${tableGroupName}`);
  }
  if (tableGroup.section === TableGroupSections.CUSTOM_OBJECTS || tableGroup.section === TableGroupSections.APP_OBJECTS) {
    const root = snowflakeTables.get(pickRoot(tableGroup, snowflakeTables));
    if ('metaDefinition' in root) {
      const {
        metaDefinition
      } = root;
      return metaDefinition.pluralForm || metaDefinition.singularForm || tableGroupName;
    } else {
      console.error('metadefinition should be present in custom object and app object tables');
      throw new Error('metadefinition should be present in custom object and app object tables');
    }
  }
};
export const getTableGroupLabel = (tableGroupName, snowflakeTables, snowflakeTableGroups) => {
  const tableGroup = snowflakeTableGroups.get(tableGroupName);
  const getTableGroupLabelWithOrWithoutSelfJoins = getTableGroupLabelHelper(tableGroupName, tableGroup, snowflakeTables);
  if (getTableGroupLabelWithOrWithoutSelfJoins) {
    return getTableGroupLabelWithOrWithoutSelfJoins;
  }
  return isValidI18nKey(`reporting-snowflake.table-group-labels.${tableGroupName}`) ? I18n.text(`reporting-snowflake.table-group-labels.${tableGroupName}`) : tableGroupName;
};
export const getTableGroupLabelWithSelfJoins = (tableGroupName, snowflakeTables, snowflakeTableGroups, tableDescription, allJoinMetadata) => {
  const tableGroup = snowflakeTableGroups.get(tableGroupName);
  const getTableGroupLabelWithOrWithoutSelfJoins = getTableGroupLabelHelper(tableGroupName, tableGroup, snowflakeTables);
  if (getTableGroupLabelWithOrWithoutSelfJoins) {
    return getTableGroupLabelWithOrWithoutSelfJoins;
  }
  const selfJoin = tableDescription && getSelfJoin(tableDescription);
  const root = snowflakeTables.get(pickRoot(tableGroup, snowflakeTables));
  if (selfJoin && allJoinMetadata && root && hasObjectTypeId(root) && selfJoin.target.objectTypeId === root.objectTypeId) {
    const joinMetadata = getSelfJoinMetadataForObjectId(allJoinMetadata, selfJoin.target.objectTypeId);
    const combinedAssociationTypeId = selfJoin.definitions.first().get('combinedAssociationTypeId', '');
    if (tableGroupName.includes(ASSOCIATION_DIVIDER)) {
      const tableGroupNameArray = tableGroupName.split(ASSOCIATION_DIVIDER);
      const tableAssociationLabel = getSelfAssociationDisplayLabelFromId(joinMetadata, combinedAssociationTypeId);
      return isValidI18nKey(`reporting-snowflake.table-group-labels.${tableGroupNameArray[0]}`) ? `${I18n.text(`reporting-snowflake.table-group-labels.${tableGroupNameArray[0]}`)} (${tableAssociationLabel})` : tableGroupName;
    }
    const correspondingLabel = getCorrespondingAssociationDisplayLabelFromId(joinMetadata, combinedAssociationTypeId);
    if (correspondingLabel) {
      return isValidI18nKey(`reporting-snowflake.table-group-labels.${tableGroupName}`) ? `${I18n.text(`reporting-snowflake.table-group-labels.${tableGroupName}`)} (${correspondingLabel})` : tableGroupName;
    }
  }
  return isValidI18nKey(`reporting-snowflake.table-group-labels.${tableGroupName}`) ? I18n.text(`reporting-snowflake.table-group-labels.${tableGroupName}`) : tableGroupName;
};
export const createTableGroupComparator = (snowflakeTables, snowflakeTableGroups) => {
  return (a, b) => {
    const aLabel = getTableGroupLabel(a.name, snowflakeTables, snowflakeTableGroups);
    const bLabel = getTableGroupLabel(b.name, snowflakeTables, snowflakeTableGroups);
    return aLabel.localeCompare(bLabel);
  };
};
export const toTableDescription = snowflakeTable => {
  const {
    name,
    type
  } = snowflakeTable;
  switch (type) {
    case DataSourceTypes.HUBSPOT_OBJECT:
    case DataSourceTypes.HUBSPOT_EVENT:
      {
        return TableDescription({
          name,
          type,
          objectTypeId: snowflakeTable.objectTypeId
        });
      }
    case DataSourceTypes.EVENTS_DIGEST:
      {
        const {
          eventsDigestType
        } = snowflakeTable;
        return TableDescription({
          name,
          type,
          eventsDigestType
        });
      }
    case DataSourceTypes.EXTERNAL_DATASOURCE:
      {
        return TableDescription({
          name: snowflakeTable.name,
          type: 'EXTERNAL_DATASOURCE',
          externalDatasourceId: snowflakeTable.dataSourceId
        });
      }
    case DataSourceTypes.VIRTUAL_DATASOURCE:
      {
        return TableDescription({
          name: snowflakeTable.name,
          type: 'VIRTUAL_DATASOURCE',
          datasourceId: snowflakeTable.dataSourceId
        });
      }
    default:
      assertUnreachable(type, `Unsupported SnowflakeTable.type : ${type}`);
  }
};
// apply an update function to each table in the table description depth-first
export const updateTables = (table, updater) => updater(table.update('join', joinList => joinList ? joinList.map(join => join.update('target', target => updateTables(target, updater))).toList() : List()));
export const findAndReplaceTable = (table, from, to) => table.equals(from) ? to : table.update('join', joinList => joinList ? joinList.map(join => join.update('target', target => findAndReplaceTable(target, from, to))).toList() : List());
const getPrimaryDisplayLabelPropertyNameForTable = snowflakeTable => {
  switch (snowflakeTable.type) {
    case DataSourceTypes.EVENTS_DIGEST:
    case DataSourceTypes.VIRTUAL_DATASOURCE:
      {
        const dataSourceId = getDataSourceId(snowflakeTable);
        return ID_PROPERTIES.get(dataSourceId);
      }
    case DataSourceTypes.HUBSPOT_OBJECT:
    case DataSourceTypes.HUBSPOT_EVENT:
      {
        const dataSourceId = snowflakeTable.objectTypeId;
        const {
          metaDefinition
        } = snowflakeTable;
        const {
          primaryDisplayLabelPropertyName,
          metaType
        } = metaDefinition;
        const isCustomObject = metaType === MetaTypes.PORTAL_SPECIFIC;
        const maybeDefaultObjectId = isCustomObject ? HS_OBJECT_ID : undefined;
        return ID_PROPERTIES.get(dataSourceId) || maybeDefaultObjectId || primaryDisplayLabelPropertyName;
      }
    default:
      console.error('Unhandled `DataSourceTypes`', snowflakeTable);
      return undefined;
  }
};

/**
 * Returns an OrderedSet<FieldRef> for the given tables, by looking up the
 * "primary display label" from `tables`, or hardcoded display properties.
 *
 * @param snowflakeTables {Map<SnowflakeTable>}
 * @param tableDescription {TableDescription}
 */
export const getPrimaryDisplayLabelPropertyName = (snowflakeTables, tableDescription) => {
  return getTableList(tableDescription).map(table => snowflakeTables.get(table.name)).filter(snowflakeTable => !!snowflakeTable).map(snowflakeTable => {
    const tableName = snowflakeTable.name;
    const propertyName = getPrimaryDisplayLabelPropertyNameForTable(snowflakeTable);
    return propertyName ? FieldRef({
      property: propertyName,
      table: tableName
    }) : undefined;
  }).filter(fieldRef => !!fieldRef).toOrderedSet();
};
export const getTablesInTableGroup = tableGroup => {
  if (!tableGroup) {
    return List();
  }
  const {
    root,
    related
  } = tableGroup;
  return root.concat(related).toList();
};
export const getSetOfImplicitlySelectedTableGroupNames = (tableDescription, primaryTableGroupName, secondaryTableGroupNames, nameToTableGroup) => getTableList(tableDescription).map(table => table.name).map(tableName => nameToTableGroup.findKey(tableGroup => tableGroup.root.includes(tableName))).toSet().delete(primaryTableGroupName).subtract(secondaryTableGroupNames);
export const getPrimaryTableName = tableDescription => tableDescription.name;
export const getSecondaryTableNames = tableDescription => getSecondaryTableList(tableDescription).map(table => table.name).toSet();
export const getTableNames = tableDescription => getSecondaryTableNames(tableDescription).add(getPrimaryTableName(tableDescription));
export const getMapOfTableNameToTableGroups = snowflakeTableGroups => snowflakeTableGroups.reduce((mapOfTableNameToTableGroups, tableGroup) => getTablesInTableGroup(tableGroup).reduce((result, tableName) => result.update(tableName, tableGroups => (tableGroups || List()).push(tableGroup)), mapOfTableNameToTableGroups), ImmutableMap());

/**
 * @returns {SnowflakeTableGroup?} - optional
 */
export const findTableGroupWithRoot = (tableName, snowflakeTableGroups) => {
  const mapOfTableNameToTableGroups = getMapOfTableNameToTableGroups(snowflakeTableGroups);
  if (!mapOfTableNameToTableGroups.has(tableName)) {
    return undefined;
  }
  return mapOfTableNameToTableGroups.get(tableName).find(tableGroup => tableGroup.root.includes(tableName) || tableGroup.makeRelatedEventRootIfPrimary && tableGroup.related.some(relatedTableName => relatedTableName === tableName));
};
export const getTableGroups = (tableDescription, snowflakeTableGroups) => ImmutableSet(getTableNames(tableDescription).map(tableName => findTableGroupWithRoot(tableName, snowflakeTableGroups)).toArray().filter(isNotNull));
export const getPrimaryTableGroup = (tableDescription, snowflakeTableGroups) => findTableGroupWithRoot(getPrimaryTableName(tableDescription), snowflakeTableGroups);
export const getSecondaryTableGroups = (tableDescription, snowflakeTableGroups) => getSecondaryTableNames(tableDescription).map(tableName => findTableGroupWithRoot(tableName, snowflakeTableGroups)).filter(tableGroup => !!tableGroup);
export const findEventTable = tableDescription => getTableList(tableDescription).find(table => table.type === DataSourceTypes.HUBSPOT_EVENT);
export const isTableEventLike = (table, snowflakeTables) => {
  if (table.type === DataSourceTypes.VIRTUAL_DATASOURCE && snowflakeTables) {
    const snowflakeTable = snowflakeTables.get(table.name);
    return snowflakeTable ? snowflakeTable.requiresEventDateRangeFilter : false;
  }
  return [DataSourceTypes.HUBSPOT_EVENT, DataSourceTypes.EVENTS_DIGEST].includes(table.type);
};
export const findEventLikeTable = (tableDescription, snowflakeTables) => getTableList(tableDescription).find(table => isTableEventLike(table, snowflakeTables));
export const getEventLikeTables = (tableDescription, snowflakeTables) => getTableList(tableDescription).filter(table => isTableEventLike(table, snowflakeTables));
const SnowflakeJoinTypeToTableJoinType = {
  [SnowflakeJoinTypes.EVENT_AND_OBJECT_COORDINATE]: JoinTypes.COORDINATE,
  [SnowflakeJoinTypes.EVENT_AND_OBJECT_PRIMARY]: JoinTypes.ASSOCIATION,
  [SnowflakeJoinTypes.EVENT_AND_OBJECT_UNIQUE_PROPERTY]: JoinTypes.FIELD,
  [SnowflakeJoinTypes.OBJECT_TO_OBJECT_ASSOCIATION]: JoinTypes.ASSOCIATION,
  [SnowflakeJoinTypes.OBJECT_TO_LIST]: JoinTypes.OBJECT_TO_LIST,
  [SnowflakeJoinTypes.OBJECT_TO_OBJECT_UNIQUE_PROPERTY]: JoinTypes.FIELD,
  [SnowflakeJoinTypes.OBJECT_COORDINATE]: JoinTypes.COORDINATE
};
export const fromJoinMetadata = joinMetadata => {
  // @ts-expect-error type metadata
  const type = SnowflakeJoinTypeToTableJoinType[joinMetadata.joinType];
  switch (type) {
    case JoinTypes.ASSOCIATION:
      {
        const {
          combinedAssociationTypeId
        } = joinMetadata;
        return JoinDefinition({
          type,
          combinedAssociationTypeId
        });
      }
    case JoinTypes.OBJECT_TO_LIST:
    case JoinTypes.FIELD:
      {
        const {
          sourceProperty,
          targetProperty
        } = joinMetadata;
        return JoinDefinition({
          type,
          leftField: sourceProperty,
          rightField: targetProperty
        });
      }
    case JoinTypes.COORDINATE:
      {
        const {
          sourceProperty,
          targetProperty
        } = joinMetadata;
        return JoinDefinition({
          type,
          leftField: sourceProperty,
          rightField: targetProperty
        });
      }
    default:
      throw new Error(`Cannot convert JoinMetadata to JoinDefinition! Unsupported JoinMetadata.joinType ${joinMetadata.joinType} or JoinDefinition.type ${type}!`);
  }
};

/**
 * @param {TableDescription} tableDescription
 * @returns {List<Join>}
 */
export const getJoins = tableDescription => {
  const joins = tableDescription.join;
  if (joins === undefined || joins.isEmpty()) {
    return List();
  }
  const thisJoins = joins.filterNot(join => join.target.name === DATASET_TABLE).map(join => join.deleteIn(['target', 'join'])).toList();
  const rest = joins.filterNot(join => join.target.name === DATASET_TABLE).map(join => join.target).toList().flatMap(getJoins).toList();
  return List().concat(thisJoins).concat(rest).toList();
};
export const getDataSourceIdFromTableDescription = (tableDescription, tableName) => {
  const table = getTableList(tableDescription).find(_table => _table.name === tableName);
  return table ? getDataSourceId(table) : undefined;
};
export const tableGroupRootContainsOnlyEvents = (tableGroup, snowflakeTables) => {
  return tableGroup.root.map(tableName => {
    const maybeSnowflakeTable = snowflakeTables.get(tableName);
    if (!maybeSnowflakeTable) {
      console.warn('tableGroup.root references a name that is not present in SnowflakeTable meta!');
    }
    return maybeSnowflakeTable;
  }).filter(val => !!val).every(snowflakeTable => snowflakeTable.type === DataSourceTypes.HUBSPOT_EVENT);
};
export const getTableLabelWithSelfJoins = (tableName, tableDescription, snowflakeTable, allJoinMetadata) => {
  const selfJoin = getSelfJoin(tableDescription);
  if (snowflakeTable && allJoinMetadata && selfJoin && selfJoin.target.objectTypeId === maybeGetObjectTypeId(snowflakeTable)) {
    return getSelfAssociationTableLabel(tableName, selfJoin, snowflakeTable, tableDescription, allJoinMetadata);
  }
  return getTableLabel(tableName, snowflakeTable);
};