const GROWTH_SIDE_PANEL_REOPENING = 'gsp-automatically-closed-flag';
export const flagGrowthSidePanelForReopening = () => {
  try {
    window.sessionStorage.setItem(GROWTH_SIDE_PANEL_REOPENING, 'true');
  } catch (e) {
    // do nothing
  }
};
export const isGrowthSidePanelFlaggedForReopening = () => {
  try {
    return window.sessionStorage.getItem(GROWTH_SIDE_PANEL_REOPENING) === 'true';
  } catch (e) {
    // do nothing
    return false;
  }
};
export const clearGrowthSidePanelFlagForReopening = () => {
  try {
    window.sessionStorage.removeItem(GROWTH_SIDE_PANEL_REOPENING);
  } catch (e) {
    // do nothing
  }
};