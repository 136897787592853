'use es6';

import * as Operators from 'customer-data-filters/filterQueryFormat/operator/Operators';
import { IS_BETWEEN, IS_NOT_BETWEEN } from 'customer-data-filters/converters/objectSeg/ObjectSegOperatorTypes';
import { fromObjectSegFilterStrict } from 'customer-data-filters/converters/objectSeg/fromObjectSegFilter';
import { SNOWFLAKE_FILTER_FAMILY } from './xo-filter-utils';
import { getIsUngated } from '../../../shared/lib/utils';
export const validateFilterDateRangeValue = filter => {
  if (!filter) {
    return false;
  }
  return [IS_BETWEEN, IS_NOT_BETWEEN].includes(filter.getIn(['filter', 'operation', 'operator'])) ? filter.getIn(['filter', 'operation', 'lowerBoundTimestamp']) <= filter.getIn(['filter', 'operation', 'upperBoundTimestamp']) : true;
};
export const validateFilterBetweenValue = filter => {
  if (!filter) {
    return false;
  }
  if (filter.getIn(['filter', 'operation', 'propertyType']).includes('date')) {
    return true;
  }
  return [IS_BETWEEN, IS_NOT_BETWEEN].includes(filter.getIn(['filter', 'operation', 'operator'])) ? filter.getIn(['filter', 'operation', 'lowerBound']) <= filter.getIn(['filter', 'operation', 'upperBound']) : true;
};
export const validateFilterOperatorValue = (filter, userGates) => {
  if (!filter) {
    return false;
  }
  const operator = fromObjectSegFilterStrict(filter.filter.toJS(), () => filter.field, SNOWFLAKE_FILTER_FAMILY, gate => getIsUngated(gate, userGates), true);
  const Operator = Operators[operator.name];
  const operatorIsValid = Operator.isValid(operator);
  return operatorIsValid;
};