'use es6';

// imports with any logic outside of these existing imports should be added in a separate file with their consumers. See useHasRequiredAttributionReportScope.ts for an example.
import useScopes from './useScopes';
import { CRM_EXPORT_SCOPE, CUSTOM_OBJECT_ACCESS, CUSTOM_REPORTING_ACCESS, DASHBOARD_DEMO_WRITE, DASHBOARD_SPECIFIC_PERMISSION_GRANTS, HUB_MARKETING_PRODUCT, JITA_USER, MARKETING_STARTER_PRODUCT, REPORTING_ACCESS, REPORTING_ADMIN, REPORTING_CREATE_AND_OWN, REPORTING_DATASETS_ACCESS, REPORTING_DATASETS_READ, REPORTING_DATASETS_WRITE, REPORTING_EDIT, REPORTS_PAID_ACCESS, REPORTS_SALES_PRO_ACCESS, COMMENTING_SUPPORTED_HUBS_REQUIRED_SCOPES, REPORTING_EMAIL_EXTERNAL_RECIPIENTS_ACCESS, BUSINESS_UNITS_ACCESS, PAYMENTS_REPORTING_ACCESS, JOURNEY_ANALYTICS_READ, DEAL_JOURNEY_ANALYTICS_READ, SUPER_ADMIN } from '../scopeTypes';
export const useHasScopes = (...scopes) => {
  const scopesList = useScopes();
  return scopes.every(scope => scopesList.includes(scope));
};
const useHasSomeScopes = (...scopes) => {
  const scopesList = useScopes();
  return scopes.some(scope => scopesList.includes(scope));
};
export const useHasReportingAccess = () => useHasScopes(REPORTING_ACCESS);
export const useHasReportingEditAccess = () => useHasScopes(REPORTING_EDIT);
export const useHasReportingCreateAndOwnAccess = () => useHasScopes(REPORTING_CREATE_AND_OWN);
export const useHasReportingAccessAndCreateAndOwnAccess = () => {
  const hasReportingAccess = useHasScopes(REPORTING_ACCESS);
  const hasReportingCreateAndOwnAccess = useHasScopes(REPORTING_CREATE_AND_OWN);
  return hasReportingAccess && hasReportingCreateAndOwnAccess;
};
export const useHasReportingAdminAccess = () => useHasScopes(REPORTING_ADMIN);
export const useIsJitaUser = () => useHasScopes(JITA_USER);
export const useHasCustomObjectAccess = () => useHasScopes(CUSTOM_OBJECT_ACCESS);
export const useHasCustomReportingAccess = () => useHasScopes(CUSTOM_REPORTING_ACCESS);
export const useHasDatasetsAccess = () => useHasScopes(REPORTING_DATASETS_ACCESS);
export const useHasDatasetsRead = () => {
  const hasDatasetsReadScope = useHasScopes(REPORTING_DATASETS_READ);
  const hasDatasetsWriteScope = useHasScopes(REPORTING_DATASETS_WRITE);
  return hasDatasetsReadScope || hasDatasetsWriteScope;
};
export const useHasExportScopeAccess = () => useHasScopes(CRM_EXPORT_SCOPE);
export const useCanAssignSpecificPermissionGrants = () => useHasScopes(DASHBOARD_SPECIFIC_PERMISSION_GRANTS);
export const useHasDemoDashboardWriteAccess = () => useHasScopes(DASHBOARD_DEMO_WRITE);
export const useHasSalesAnalyticsAccess = () => useHasScopes(REPORTS_SALES_PRO_ACCESS);
export const useHasMarketingJourneyAccess = () => useHasScopes(JOURNEY_ANALYTICS_READ);
export const useHasSalesJourneyAccess = () => useHasScopes(DEAL_JOURNEY_ANALYTICS_READ);
export const useIsStarterUpgradeEligible = () => {
  const hasPaidProduct = useHasSomeScopes(MARKETING_STARTER_PRODUCT, HUB_MARKETING_PRODUCT, REPORTS_PAID_ACCESS);
  return !hasPaidProduct;
};
export const useIsProUpgradeEligible = () => {
  const hasMarketingStarter = useHasScopes(MARKETING_STARTER_PRODUCT);
  const isIneligible = useHasSomeScopes(HUB_MARKETING_PRODUCT, REPORTS_PAID_ACCESS);
  return hasMarketingStarter && !isIneligible;
};
export const useHasSomeCommentingAccessScopes = () => useHasSomeScopes(...COMMENTING_SUPPORTED_HUBS_REQUIRED_SCOPES);
export const useHasEmailExternalRecipientsAccess = () => useHasScopes(REPORTING_EMAIL_EXTERNAL_RECIPIENTS_ACCESS);
export const useHasBusinessUnitsAccess = () => useHasScopes(BUSINESS_UNITS_ACCESS);
export const useHasPaymentsReportingAccess = () => useHasScopes(PAYMENTS_REPORTING_ACCESS);
export const useHasSuperAdminScope = () => useHasScopes(SUPER_ADMIN);