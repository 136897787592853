import { DATA_WELL, DONUT, GAUGE, PIE } from 'reporting-data/constants/chartTypes';
import { getDimensions, getMetrics } from 'reporting-data/report/configReportGetters';
import { getReportChartType } from 'reporting-data/report/reportGetters';
const getMeasureSizeRanges = report => {
  const chartType = getReportChartType(report);
  const dimensions = getDimensions(report);
  const metrics = getMetrics(report);
  if ([PIE, DONUT].includes(chartType)) {
    return {
      minDimensions: 0,
      maxDimensions: 1,
      minMetrics: 1,
      maxMetrics: 1
    };
  }
  if (chartType === GAUGE) {
    return {
      minDimensions: 0,
      maxDimensions: 0,
      minMetrics: 1,
      maxMetrics: 1
    };
  }
  if ([DATA_WELL].includes(chartType)) {
    if (dimensions.size === 0 && metrics.size === 0) {
      return {
        minDimensions: 1,
        maxDimensions: 1,
        minMetrics: 1,
        maxMetrics: Infinity
      };
    }
    if (dimensions.size === 0 && metrics.size < 2) {
      return {
        minDimensions: 0,
        maxDimensions: 1,
        minMetrics: 1,
        maxMetrics: Infinity
      };
    }
    if (metrics.size > 1) {
      return {
        minDimensions: 0,
        maxDimensions: 0,
        minMetrics: 1,
        maxMetrics: Infinity
      };
    }
    return {
      minDimensions: 0,
      maxDimensions: 1,
      minMetrics: 1,
      maxMetrics: 1
    };
  }
  if (metrics.size > 1) {
    return {
      minDimensions: 0,
      maxDimensions: 1,
      minMetrics: 1,
      maxMetrics: Infinity
    };
  }
  if (dimensions.size < 2) {
    return {
      minDimensions: metrics.size > 0 ? 0 : 1,
      maxDimensions: 2,
      minMetrics: 1,
      maxMetrics: Infinity
    };
  }
  return {
    minDimensions: metrics.size > 0 ? 0 : 1,
    maxDimensions: 2,
    minMetrics: 1,
    maxMetrics: 1
  };
};
export default getMeasureSizeRanges;