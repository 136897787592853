import { useAsyncFetcher } from 'reporting-data/asyncData/useAsyncFetcher';
import { fetchDatasetInsightTypeOptions } from '../lib/datasetInsightUtils';
import { useCallback } from 'react';
const useFetchDatasetInsightTypeOptions = report => {
  const fetcher = useCallback(() => fetchDatasetInsightTypeOptions(report), [report]);
  return useAsyncFetcher({
    requestArgs: report,
    fetcher,
    deferred: !report
  });
};
export default useFetchDatasetInsightTypeOptions;