'use es6';

import enviro from 'enviro';
import PortalIdParser from 'PortalIdParser';
import { CATEGORY_SUB_APPS as SUBSCRIPTION_SUB_APPS } from './subscription-analytics';
import { CATEGORY_SUB_APPS as SERVICE_SUB_APPS } from './service-analytics';
import { CATEGORY_SUB_APPS as SALES_SUB_APPS } from './salesAnalytics';
import { CATEGORY_SUB_APPS as MARKETING_SUB_APPS } from './marketing-analytics';
import { APP_NAMES } from '../apps';
const isTestPortal = () => enviro.isQa() && [99161426, 867463687].includes(PortalIdParser.get());
const APP_SUB_APPS = {
  [APP_NAMES.SUBSCRIPTION_ANALYTICS]: SUBSCRIPTION_SUB_APPS,
  [APP_NAMES.SERVICE_ANALYTICS]: SERVICE_SUB_APPS,
  [APP_NAMES.SALES_ANALYTICS]: SALES_SUB_APPS,
  [APP_NAMES.MARKETING_ANALYTICS]: MARKETING_SUB_APPS
};
const SHOW_SUB_APP_GATES = {};
const HIDE_SUB_APP_GATES = {};
const getAccessibleSubApps = (subApps, gates) => gates && !isTestPortal() ? subApps.filter(subAppKey => {
  const requiredGate = SHOW_SUB_APP_GATES[subAppKey];
  const hiddenGate = HIDE_SUB_APP_GATES[subAppKey];
  return (!requiredGate || gates.includes(requiredGate)) && (!hiddenGate || !gates.includes(hiddenGate));
}) : subApps;
export const getSubAppNamesByCategoryForApp = (appName, gates) => {
  const subAppsByCategory = APP_SUB_APPS[appName];
  return Object.entries(subAppsByCategory).reduce((accessibleSubAppsByCategory, [category, subApps]) => Object.assign({}, accessibleSubAppsByCategory, {
    [category]: getAccessibleSubApps(subApps, gates)
  }), {});
};
export const getSubAppNamesForApp = (appName, gates) => {
  const subAppsByCategory = getSubAppNamesByCategoryForApp(appName, gates);
  return Object.values(subAppsByCategory).reduce( /* eslint-disable-next-line hubspot-dev/no-reduce-accumulator-copy */
  (subApps, categorySubApps) => [...subApps, ...categorySubApps], []);
};
export const getAppNameFromSubAppKey = subAppKey => Object.keys(APP_SUB_APPS).find(appName => getSubAppNamesForApp(appName).includes(subAppKey));