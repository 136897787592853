import http from 'hub-http/clients/apiClient';
import { fromJS } from 'immutable';
import { stringify } from 'hub-http/helpers/params';
import { registerQuery } from 'data-fetching-client';
export const createDashboard = ({
  dashboardName,
  userId,
  dashboardPermissionConfig,
  widgets = [],
  template,
  filterGroups
}) => http.post('dashboard/v2/dashboard', {
  data: {
    name: dashboardName,
    type: 'CUSTOM',
    ownerId: userId,
    dashboardPermissionConfig,
    widgets,
    template,
    filterGroups
  }
});
export const fetchDashboards = (query = {}) => http.get('dashboard/v2/dashboard', {
  query
});
const isApplyAdhocFiltersParams = params => 'filters' in params;
export const fetchDashboardWithFiltersApplied = (dashboardId, params) => {
  const getDashboardWithOverriddenReportsPromise = () => {
    if (isApplyAdhocFiltersParams(params)) {
      return http.post(`dashboard/v2/dashboard/${dashboardId}/apply-filter`, {
        data: {
          dashboardId,
          name: 'adhoc-filters',
          // Needed for api request to succeed
          filters: params.filters || [],
          frequency: params.frequency,
          useFiscalYear: params.useFiscalYear
        }
      });
    }
    const queryParams = stringify(params);
    return http.get(`dashboard/v2/dashboard/${dashboardId}?${queryParams}`);
  };
  return getDashboardWithOverriddenReportsPromise().then(response => {
    return fromJS(response); // next dashboard object
  }).catch(error => {
    console.error(error);
    throw error;
  });
};
export const FETCH_EMBEDDABLE_DASHBOARDS = registerQuery({
  fieldName: 'embeddableDashboards',
  args: [],
  fetcher() {
    return fetchDashboards({
      sortBy: 'UPDATED_AT'
    });
  }
});