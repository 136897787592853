'use es6';

import { has } from '../../../lib/has';
import * as DataTypes from '../../../constants/dataTypes';
import { preprocess as analyticsContentSearch } from './analytics-content-search';
import { preprocess as analyticsVideosViewership } from './analytics-videos-viewership';
import { preprocess as analyticsForms } from './analytics-forms';
import { preprocess as unifiedAdsCampaigns } from './unified-ads-campaigns';
import { preprocess as unifiedFeedbackTrends } from './unified-feedback-trends';
import { preprocess as analyticsSources } from './analytics-sources';
import { preprocess as analyticsDeviceTypes } from './analytics-device-types';
import { preprocess as sequenceSenderScore } from './sequence-sender-score';
import { preprocess as analyticsAllPages } from './analytics-all-pages';
const specs = {
  [DataTypes.ANALYTICS_CONTENT_SEARCH]: analyticsContentSearch,
  [DataTypes.ANALYTICS_SOURCES]: analyticsSources,
  [DataTypes.ANALYTICS_VIDEOS_VIEWERSHIP]: analyticsVideosViewership,
  [DataTypes.ANALYTICS_FORMS]: analyticsForms,
  [DataTypes.UNIFIED_ADS_CAMPAIGNS]: unifiedAdsCampaigns,
  [DataTypes.UNIFIED_FEEDBACK_TRENDS]: unifiedFeedbackTrends,
  [DataTypes.ANALYTICS_DEVICE_TYPES]: analyticsDeviceTypes,
  [DataTypes.SEQUENCE_SENDER_SCORE]: sequenceSenderScore,
  [DataTypes.ANALYTICS_ALL_PAGES]: analyticsAllPages
};
const passthrough = response => response;
export const get = (spec, config) => {
  const {
    dataType
  } = config;
  if (has(specs, dataType)) {
    return specs[dataType](spec, config);
  }
  return passthrough;
};