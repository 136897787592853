'use es6';

import { Record } from 'immutable';
import get from 'transmute/get';
import has from 'transmute/has';
import indexBy from 'transmute/indexBy';
import { MRUNAL } from './sentry-owners';

//sentry-levels
export const ERROR = 'error';
export const INFO = 'info';
export const WARNING = 'warning';

//sentry events
export const CHART_LOAD_ERROR = 'CHART_LOAD_ERROR';
export const USE_SET_SUBAPP_CONTEXT = 'USE_SET_SUBAPP_CONTEXT';
export const INCOMPLETE_DATE_RANGE = 'INCOMPLETE_DATE_RANGE';
export const DATERANGE_INPUT_ERROR_BOUNDARY = 'DATERANGE_INPUT_ERROR_BOUNDARY';
export const SALES_ANALYTICS_ERROR_BOUNDARY = 'SALES_ANALYTICS_ERROR_BOUNDRY';
export const CONTROL_ERROR_BOUNDRY = 'CONTROL_ERROR_BOUNDRY';
export const ERROR_SALES_REPORT_RESOLVER = 'ERROR_SALES_REPORT_RESOLVER';
export const REPORT_LIBRARY_ERROR_BOUNDARY = 'REPORT_LIBRARY_ERROR_BOUNDARY';
export const WOOTRIC_RUN_ERROR = 'WOOTRIC_RUN_ERROR';
export const SentryEvent = Record({
  id: null,
  name: null,
  description: null,
  level: ERROR,
  owner: MRUNAL
}, 'SentryEvent');
const EVENT_LIST = [SentryEvent({
  id: CHART_LOAD_ERROR,
  name: 'Chart resolve error',
  description: 'caught in platformReportResolver'
}), SentryEvent({
  id: DATERANGE_INPUT_ERROR_BOUNDARY,
  name: 'the daterange is null/invalid frequency',
  description: 'error caught in date range settings by error bounadry'
}), SentryEvent({
  id: SALES_ANALYTICS_ERROR_BOUNDARY,
  // not used
  name: 'error occured in sales analytics container',
  description: 'error occured in sales analytics container'
}), SentryEvent({
  id: CONTROL_ERROR_BOUNDRY,
  name: 'error occured in the one of the sales analytics controls',
  description: 'error occured in the one of the sales analytics controls'
}), SentryEvent({
  id: ERROR_SALES_REPORT_RESOLVER,
  name: 'error occured at salesReportResolver',
  description: 'error caught in ReportCard by error boundary'
}), SentryEvent({
  id: REPORT_LIBRARY_ERROR_BOUNDARY,
  // not used
  name: 'error occured in a report from report-library',
  description: 'error caught in LibraryReport component from report-library components'
}), SentryEvent({
  id: WOOTRIC_RUN_ERROR,
  name: 'error caught attempting to run wootric',
  description: 'error caught attempting to run wootric'
})];
export const SENTRY_EVENT_MAP = indexBy(get('id'), EVENT_LIST);
export const getEventById = id => SENTRY_EVENT_MAP.get(id);
export const hasEventById = id => has(id, SENTRY_EVENT_MAP);