import { List, Map as ImmutableMap } from 'immutable';
import { ACTIVITY_TYPE, BUSINESS_UNIT, CALL_DISPOSITION, COMPANY, CONTACT, CONTACT_LIST, DEAL, DEAL_PIPELINE, DEAL_PIPELINE_STAGE, ENGAGEMENT, EVENT, FEEDBACK, FORM, INBOUND_DB_COMPANY_IMPORT, INBOUND_DB_CONTACT_IMPORT, INBOUND_DB_DEAL_IMPORT, KNOWLEDGE_ARTICLES, LINE_ITEM, MULTI_CURRENCY_CURRENCY_CODE, OWNER, PERSONA, PRODUCT, SALESFORCE_CAMPAIGN, SURVEY, TEAM, TICKET, TICKET_PIPELINE, TICKET_STAGE, USER, INBOUND_DB_DEAL_LIST, INBOUND_DB_TICKET_LIST
// @ts-expect-error Untyped dependency
} from 'reference-resolvers/constants/ReferenceObjectTypes';
// @ts-expect-error Untyped dependency, depends on creating types for checked.record
import { Pipeline } from '../../../config/pipeline';
import * as dataTypes from '../../../constants/dataTypes';
// @ts-expect-error Untyped dependency
import { isSupportedCrmObject } from '../../../crmObjects/utils';
import { extractPropertyNamespace } from '../../../properties/namespaceProperty';
const BATCHED_FORM_RESOLVER = 'BATCHEDFORM';
// crossObjectTypes of each data type should match the list in
// https://git.hubteam.com/HubSpot/CRM/blob/master/crm_schema/static/js/filter/objectSeg/ObjectSegAssociationTypeId.js
export const dataTypeSettings = {
  [dataTypes.CALL]: {
    propertyReferenceType: {
      hubspot_team_id: 'TEAM'
    },
    filterFamily: '0-48',
    externalEnumerationsWithDisplayOrder: []
  },
  [dataTypes.CONTACTS]: {
    apiPath: 'contacts',
    idColumn: 'vid',
    referenceType: CONTACT,
    propertyReferenceType: {
      hubspot_owner_id: OWNER,
      hubspot_team_id: TEAM,
      associatedcompanyid: COMPANY,
      'formSubmissions.formId': BATCHED_FORM_RESOLVER,
      hs_persona: PERSONA,
      'listMemberships.listId': CONTACT_LIST,
      salesforcecampaignids: SALESFORCE_CAMPAIGN,
      '_inbounddbio.importid_': INBOUND_DB_CONTACT_IMPORT,
      hs_all_assigned_business_unit_ids: BUSINESS_UNIT
    },
    defaultProperties: ['createdate', 'lifecyclestage'],
    defaultDateProperty: 'createdate',
    defaultExportProperties: ['firstname', 'lastname'],
    filterFamily: 'CONTACT',
    objectType: 'CONTACT',
    defaultPipeline: Pipeline({
      id: 'default',
      stages: List()
    }),
    defaultDimension: 'lifecyclestage',
    externalEnumerationsWithDisplayOrder: [],
    supportPropertyCounts: true,
    crossObjectTypes: [dataTypes.COMPANIES, dataTypes.DEALS, dataTypes.ENGAGEMENTS, dataTypes.TICKETS, dataTypes.FEEDBACK_SUBMISSIONS],
    scope: 'contacts-read'
  },
  [dataTypes.COMPANIES]: {
    apiPath: 'companies',
    idColumn: 'company-id',
    referenceType: COMPANY,
    propertyReferenceType: {
      hs_parent_company_id: 'COMPANY',
      hubspot_owner_id: 'OWNER',
      hubspot_team_id: 'TEAM',
      '_inbounddbio.importid_': INBOUND_DB_COMPANY_IMPORT,
      hs_all_assigned_business_unit_ids: BUSINESS_UNIT
    },
    defaultProperties: ['industry'],
    defaultDateProperty: 'createdate',
    defaultExportProperties: ['name'],
    filterFamily: 'COMPANY',
    objectType: 'COMPANY',
    defaultDimension: 'hs_analytics_source',
    externalEnumerationsWithDisplayOrder: [],
    supportPropertyCounts: true,
    crossObjectTypes: [dataTypes.CONTACTS, dataTypes.DEALS, dataTypes.ENGAGEMENTS, dataTypes.TICKETS],
    scope: 'crm-access'
  },
  [dataTypes.CONVERSATIONS]: {
    propertyReferenceType: {
      hubspot_owner_id: 'OWNER',
      hubspot_team_id: 'TEAM',
      hs_all_assigned_business_unit_ids: BUSINESS_UNIT
    },
    filterFamily: '0-11',
    externalEnumerationsWithDisplayOrder: []
  },
  [dataTypes.DEALS]: {
    apiPath: 'deals',
    idColumn: 'dealId',
    referenceType: DEAL,
    propertyReferenceType: {
      hubspot_owner_id: OWNER,
      hubspot_team_id: TEAM,
      pipeline: DEAL_PIPELINE,
      'ilsListMemberships.listId': INBOUND_DB_DEAL_LIST,
      'associations.contact': CONTACT,
      'associations.company': COMPANY,
      deal_currency_code: MULTI_CURRENCY_CURRENCY_CODE,
      '_inbounddbio.importid_': INBOUND_DB_DEAL_IMPORT,
      dealstage: DEAL_PIPELINE_STAGE,
      hs_all_assigned_business_unit_ids: BUSINESS_UNIT
    },
    defaultProperties: ['amount_in_home_currency', 'dealstage'],
    alwaysIncludedCrossObjectProperties: ['deal_currency_code'],
    defaultDateProperty: 'createdate',
    defaultExportProperties: ['dealname'],
    filterFamily: 'DEAL',
    objectType: 'DEAL',
    defaultPipeline: Pipeline({
      id: 'default',
      stages: List()
    }),
    defaultDimension: 'dealstage',
    externalEnumerationsWithDisplayOrder: ['dealstage'],
    supportPropertyCounts: true,
    crossObjectTypes: [dataTypes.CONTACTS, dataTypes.COMPANIES, dataTypes.ENGAGEMENTS, dataTypes.LINE_ITEMS, dataTypes.TICKETS],
    scope: 'crm-access'
  },
  [dataTypes.COMMERCE_PAYMENTS]: {
    propertyReferenceType: {
      hs_currency_code: MULTI_CURRENCY_CURRENCY_CODE
    }
  },
  [dataTypes.ENGAGEMENT]: {
    apiPath: 'engagements',
    idColumn: 'engagement.id',
    referenceType: ENGAGEMENT,
    propertyReferenceType: {
      'engagement.ownerId': OWNER,
      hubspot_owner_id: OWNER,
      'engagement.teamId': TEAM,
      hubspot_team_id: TEAM,
      'engagement.createdBy': USER,
      hs_created_by: USER,
      'engagement.modifiedBy': USER,
      hs_modified_by: USER,
      'engagement.activityType': ACTIVITY_TYPE,
      hs_activity_type: ACTIVITY_TYPE,
      'call.disposition': CALL_DISPOSITION
    },
    defaultProperties: ['hs_engagement_type', 'engagement.details', 'hs_createdate'],
    defaultDateProperty: 'hs_createdate',
    defaultExportProperties: [],
    filterFamily: 'ENGAGEMENT_LEGACY',
    objectType: 'ENGAGEMENT',
    defaultDimension: 'hs_engagement_type',
    externalEnumerationsWithDisplayOrder: [],
    supportPropertyCounts: true,
    hideIdColumn: true,
    crossObjectTypes: [dataTypes.CONTACTS, dataTypes.COMPANIES, dataTypes.TICKETS, dataTypes.DEALS],
    scope: 'crm-access'
  },
  [dataTypes.ENGAGEMENTS]: {
    apiPath: 'engagements',
    idColumn: 'hs_unique_id',
    referenceType: ENGAGEMENT,
    propertyReferenceType: {
      hubspot_owner_id: OWNER,
      hubspot_team_id: TEAM,
      hs_created_by: USER,
      hs_modified_by: USER,
      hs_activity_type: ACTIVITY_TYPE,
      hs_call_disposition: CALL_DISPOSITION,
      hs_all_assigned_business_unit_ids: BUSINESS_UNIT
    },
    defaultProperties: ['hs_engagement_type', 'hs_createdate'],
    defaultDateProperty: 'hs_timestamp',
    defaultExportProperties: [],
    filterFamily: 'ENGAGEMENT',
    objectType: 'ENGAGEMENT',
    defaultDimension: 'hs_engagement_type',
    externalEnumerationsWithDisplayOrder: [],
    supportPropertyCounts: true,
    hideIdColumn: true,
    crossObjectTypes: [dataTypes.CONTACTS, dataTypes.COMPANIES, dataTypes.DEALS, dataTypes.TICKETS],
    scope: 'crm-access'
  },
  [dataTypes.TICKETS]: {
    apiPath: 'tickets',
    idColumn: 'hs_ticket_id',
    referenceType: TICKET,
    propertyReferenceType: {
      hs_pipeline_stage: TICKET_STAGE,
      hs_pipeline: TICKET_PIPELINE,
      hs_all_assigned_business_unit_ids: BUSINESS_UNIT,
      'ilsListMemberships.listId': INBOUND_DB_TICKET_LIST
    },
    defaultProperties: ['createdate', 'hs_ticket_category'],
    defaultDateProperty: 'createdate',
    defaultExportProperties: ['subject'],
    filterFamily: 'TICKET',
    objectType: 'TICKET',
    defaultDimension: 'hs_ticket_category',
    externalEnumerationsWithDisplayOrder: [],
    crossObjectTypes: [dataTypes.CONTACTS, dataTypes.COMPANIES, dataTypes.DEALS, dataTypes.ENGAGEMENTS, dataTypes.FEEDBACK_SUBMISSIONS],
    scope: 'tickets-reports-access'
  },
  [dataTypes.LINE_ITEMS]: {
    apiPath: 'lineitems',
    idColumn: 'objectId',
    referenceType: LINE_ITEM,
    propertyReferenceType: {
      hs_product_id: PRODUCT,
      hs_line_item_currency_code: MULTI_CURRENCY_CURRENCY_CODE
    },
    defaultProperties: ['name', 'createdate', 'price', 'amount'],
    alwaysIncludedCrossObjectProperties: ['hs_line_item_currency_code'],
    defaultDateProperty: 'createdate',
    defaultExportProperties: ['name'],
    filterFamily: 'LINE_ITEM',
    objectType: 'LINE_ITEM',
    defaultDimension: 'name',
    externalEnumerationsWithDisplayOrder: [],
    crossObjectTypes: [dataTypes.DEALS],
    hideIdColumn: true,
    scope: 'crm-access'
  },
  [dataTypes.ANALYTICS_FUNNEL]: {
    defaultDimension: 'event',
    defaultPipeline: Pipeline({
      id: 'default',
      stages: List()
    }),
    defaultDateProperty: 'sessionDate',
    defaultProperties: ['completions'],
    externalEnumerationsWithDisplayOrder: [],
    tableDimensions: List(['breakdown']),
    requiresManualRun: true,
    referenceType: EVENT,
    scope: 'events-analytics'
  },
  [dataTypes.FEEDBACK_SUBMISSIONS]: {
    idColumn: 'hs_object_id',
    referenceType: FEEDBACK,
    propertyReferenceType: {
      hs_engagement_id: ENGAGEMENT,
      hs_visitor_id: CONTACT,
      hs_knowledge_article_id: KNOWLEDGE_ARTICLES,
      hs_survey_id: SURVEY,
      hs_form_id: FORM
    },
    defaultDimension: 'hs_survey_id',
    defaultDateProperty: 'hs_submission_timestamp',
    defaultProperties: ['hs_survey_type'],
    defaultExportProperties: [],
    filterFamily: 'FEEDBACK_SUBMISSION',
    objectType: 'FEEDBACK_SUBMISSION',
    externalEnumerationsWithDisplayOrder: [],
    crossObjectTypes: [dataTypes.CONTACTS, dataTypes.TICKETS],
    hideIdColumn: true,
    supportPropertyCounts: false,
    scope: 'feedback-reports-access'
  },
  [dataTypes.ATTRIBUTION_TOUCH_POINTS]: {
    defaultExportProperties: ['hs_touchpoint_timestamp', 'hs_touchpoint_type', 'hs_touchpoint_positions', 'hs_touchpoint_url', 'hs_asset_type', 'hs_asset_title', 'hs_deal_id', 'hs_associated_deals', 'hs_deal_amount', 'hs_associated_contact_ids', 'hs_associated_contacts', 'hs_linear_amount', 'hs_full_path_amount', 'hs_u_amount', 'hs_w_amount', 'hs_first_touch_amount', 'hs_last_touch_amount'],
    objectType: 'ATTRIBUTION'
  },
  [dataTypes.CONTACT_CREATE_ATTRIBUTION]: {
    defaultExportProperties: ['hs_interaction_timestamp', 'hs_interaction_type', 'hs_interaction_positions', 'hs_interaction_url', 'hs_asset_type', 'hs_asset_title', 'hs_associated_contact_ids', 'hs_associated_contacts', 'hs_linear_interaction_percentage', 'hs_u_model_percentage', 'hs_first_interaction_percentage', 'hs_last_interaction_percentage'],
    objectType: 'CONTACT_CREATE_ATTRIBUTION'
  },
  [dataTypes.DEAL_CREATE_ATTRIBUTION]: {
    defaultExportProperties: ['hs_interaction_timestamp', 'hs_interaction_type', 'hs_interaction_positions', 'hs_interaction_url', 'hs_asset_type', 'hs_asset_title', 'hs_associated_contact_ids', 'hs_associated_contacts', 'hs_linear_interaction_percentage', 'hs_u_model_percentage', 'hs_w_percentage', 'hs_first_interaction_percentage', 'hs_last_interaction_percentage'],
    objectType: 'DEAL_CREATE_ATTRIBUTION'
  },
  [dataTypes.GOAL_TARGET]: {
    apiPath: 'goaltarget',
    idColumn: 'hs_object_id',
    defaultProperties: ['hs_goal_name', 'hs_kpi_progress_percent', 'hs_goal_type', 'hs_start_datetime', 'hs_end_datetime'],
    defaultDateProperty: 'hs_end_datetime',
    requiredDateFilterProperty: 'hs_start_datetime',
    defaultExportProperties: ['hs_goal_name'],
    filterFamily: 'GOAL_TARGET',
    objectType: 'GOAL_TARGET',
    defaultDimension: 'hs_goal_type',
    externalEnumerationsWithDisplayOrder: [],
    scope: 'goals-migration-kpi-gate-access'
  },
  [dataTypes.TASK]: {
    propertyReferenceType: {
      hubspot_team_id: TEAM
    },
    filterFamily: '0-27',
    externalEnumerationsWithDisplayOrder: []
  },
  [dataTypes.PARTNER_CLIENT]: {
    filterFamily: 'OBJECT_LIST'
  }
};
export const supportedInboundDataTypes = [dataTypes.CONTACTS, dataTypes.COMPANIES, dataTypes.DEALS, dataTypes.ENGAGEMENT, dataTypes.ENGAGEMENTS, dataTypes.TICKETS, dataTypes.LINE_ITEMS, dataTypes.FEEDBACK_SUBMISSIONS, dataTypes.ATTRIBUTION_TOUCH_POINTS, dataTypes.CONTACT_CREATE_ATTRIBUTION, dataTypes.DEAL_CREATE_ATTRIBUTION];
export const dataTypeToObjectType = ImmutableMap({
  ATTRIBUTION_TOUCH_POINTS: 'ATTRIBUTION',
  COMPANIES: 'COMPANY',
  CONTACTS: 'CONTACT',
  CONTACT_CREATE_ATTRIBUTION: 'CONTACT_CREATE_ATTRIBUTION',
  DEAL_CREATE_ATTRIBUTION: 'DEAL_CREATE_ATTRIBUTION',
  DEALS: 'DEAL',
  ENGAGEMENT: 'ENGAGEMENT',
  ENGAGEMENTS: 'ENGAGEMENT',
  FEEDBACK_SUBMISSIONS: 'FEEDBACK_SUBMISSION',
  LINE_ITEMS: 'LINE_ITEM',
  TICKETS: 'TICKET',
  GOAL_TARGET: 'GOAL_TARGET'
});
export const objectTypeToDataType = ImmutableMap({
  ATTRIBUTION: 'ATTRIBUTION_TOUCH_POINTS',
  COMPANY: 'COMPANIES',
  CONTACT: 'CONTACTS',
  CONTACT_CREATE_ATTRIBUTION: 'CONTACT_CREATE_ATTRIBUTION',
  DEAL_CREATE_ATTRIBUTION: 'DEAL_CREATE_ATTRIBUTION',
  DEAL: 'DEALS',
  ENGAGEMENT: 'ENGAGEMENTS',
  FEEDBACK_SUBMISSION: 'FEEDBACK_SUBMISSIONS',
  LINE_ITEM: 'LINE_ITEMS',
  TICKET: 'TICKETS',
  GOAL_TARGET: 'GOAL_TARGET'
});
export const supportedAnalyticsDataTypes = [dataTypes.ANALYTICS_FUNNEL];
export const supportedDataTypes = [...supportedInboundDataTypes, ...supportedAnalyticsDataTypes, dataTypes.CRM_OBJECT];
export const funnelReportDataTypes = [dataTypes.CONTACTS, dataTypes.DEALS];
export const attributionReportDataTypes = [dataTypes.CONTACTS];
export const getSingleObjectInboundDataTypes = () => supportedInboundDataTypes.filter(dataType => ![dataTypes.ENGAGEMENTS, dataTypes.ATTRIBUTION_TOUCH_POINTS, dataTypes.CONTACT_CREATE_ATTRIBUTION, dataTypes.DEAL_CREATE_ATTRIBUTION].includes(dataType));
export const getCrossObjectInboundDataTypes = () => supportedInboundDataTypes.filter(dataType => ![dataTypes.ENGAGEMENT, dataTypes.ATTRIBUTION_TOUCH_POINTS, dataTypes.CONTACT_CREATE_ATTRIBUTION, dataTypes.DEAL_CREATE_ATTRIBUTION].includes(dataType));
export const isExternalEnumerationWithDisplayOrder = (dataType, property) => {
  var _dataTypeSettings$dat;
  if (dataType === dataTypes.CROSS_OBJECT) {
    const propertyInformation = extractPropertyNamespace(property);
    if (propertyInformation.dataType) {
      return isExternalEnumerationWithDisplayOrder(propertyInformation.dataType, propertyInformation.propertyName);
    }
    return false;
  }
  if (isSupportedCrmObject(dataType)) {
    return false;
  }

  // ensure we always return a boolean
  return !!((_dataTypeSettings$dat = dataTypeSettings[dataType]) !== null && _dataTypeSettings$dat !== void 0 && (_dataTypeSettings$dat = _dataTypeSettings$dat.externalEnumerationsWithDisplayOrder) !== null && _dataTypeSettings$dat !== void 0 && _dataTypeSettings$dat.includes(property));
};