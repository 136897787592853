/**
 * @deprecated: We do not need to define net new actions in this ducks file since we now leverage redux toolkit.
 * Define new actions in ./slice instead.
 */

import { appliedDashboardFiltersActions } from './slice';
import { getFrequencyQueryParam, getFrequencyFiscalYearQueryParam, getAdhocFiltersParam } from '../../lib/dashboardFilters/dashboard-filter-query-params-util';
import { getPersistedFiltersForSingleDashboard } from '../../lib/dashboardFilters/persistedDashboardFilters';
// @ts-expect-error migrate quick filters file
import { setQuickFilterGroups } from '../quick-filters/quickFilters';
export const initializeDashboardFilters = ({
  dashboardId,
  history,
  filterSetId,
  filterSets,
  quickFilters = []
}) => dispatch => {
  const parsedFilterQueryParams = getAdhocFiltersParam({
    history,
    dashboardId
  });
  const persistedFilters = getPersistedFiltersForSingleDashboard(dashboardId);
  const frequency = getFrequencyQueryParam({
    history
  });
  const useFiscalYear = getFrequencyFiscalYearQueryParam({
    history
  });
  const initializedDashboardFilters = (() => {
    if (parsedFilterQueryParams && parsedFilterQueryParams.length > 0) {
      return parsedFilterQueryParams;
    }
    if (persistedFilters && persistedFilters.length > 0) {
      return persistedFilters;
    }
    return [];
  })();
  dispatch(setQuickFilterGroups(quickFilters));
  dispatch(appliedDashboardFiltersActions.initializeDashboardFilters({
    dashboardId,
    filters: initializedDashboardFilters,
    filterSetId,
    filterSets,
    frequency,
    useFiscalYear
  }));
};
export const addDashboardFilter = payload => dispatch => dispatch(appliedDashboardFiltersActions.addDashboardFilter(payload));
export const addGroupDashboardFilter = payload => dispatch => {
  dispatch(appliedDashboardFiltersActions.addGroupDashboardFilter(payload));
};
export const updateDashboardFilter = payload => dispatch => dispatch(appliedDashboardFiltersActions.updateDashboardFilter(payload));
export const removeDashboardFilter = payload => dispatch => dispatch(appliedDashboardFiltersActions.removeDashboardFilter(payload));
export const removeDashboardFilterByName = payload => dispatch => dispatch(appliedDashboardFiltersActions.removeDashboardFilterByName(payload));
export const removeCurrentDashboardFilters = payload => dispatch => dispatch(appliedDashboardFiltersActions.removeCurrentDashboardFilters(payload));
export const removeAllDashboardFilters = () => dispatch => dispatch(appliedDashboardFiltersActions.removeAllDashboardFilters());
export const openDashboardFiltersPanel = () => dispatch => dispatch(appliedDashboardFiltersActions.openDashboardFiltersPanel());
export const toggleDashboardFiltersPanel = () => dispatch => dispatch(appliedDashboardFiltersActions.toggleDashboardFiltersPanel());
export const closeDashboardFiltersPanel = () => dispatch => dispatch(appliedDashboardFiltersActions.closeDashboardFiltersPanel());