import { DURATION, PERCENTAGE } from 'reporting-data/constants/numberDisplayHints';
import { PERCENT, DURATION as DURATION_PROPERTY } from 'reporting-data/constants/property-types';
import { DAYS, HOURS, MILLISECONDS, MINUTES, SECONDS } from 'reporting-data/constants/timeUnits';
export const mapInputValueToUsableValue = (inputValue, propertyType, valueFormattingOptions) => {
  if (inputValue === undefined) {
    return undefined;
  }
  if (propertyType === PERCENTAGE || propertyType === PERCENT) {
    /**
     * If we're displaying a percentage based property
     * we map the gauge values to decimals as if they
     * were entered as full percentages.
     *
     * Ex: Band range: {
     *    from: 5  -> 0.05,
     *    to:   65 -> 0.65
     * }
     */

    return inputValue / 100;
  } else if (propertyType === DURATION || propertyType === DURATION_PROPERTY) {
    var _valueFormattingOptio;
    /**
     * When displaying a duration based property we
     * map the gauge values to milliseconds based off
     * of the specified duration.
     */

    const timeUnit = (valueFormattingOptions === null || valueFormattingOptions === void 0 || (_valueFormattingOptio = valueFormattingOptions.format) === null || _valueFormattingOptio === void 0 ? void 0 : _valueFormattingOptio.duration) || MILLISECONDS;
    switch (timeUnit) {
      case DAYS:
        return inputValue * 1000 * 60 * 60 * 24;
      case HOURS:
        return inputValue * 1000 * 60 * 60;
      case MINUTES:
        return inputValue * 1000 * 60;
      case SECONDS:
        return inputValue * 1000;
      case MILLISECONDS:
      default:
        return inputValue;
    }
  }
  return inputValue;
};