import http from 'hub-http/clients/apiClient';
import { useCallback } from 'react';
import { useAsyncFetcher } from 'reporting-data/asyncData/useAsyncFetcher';
export const fetchMaxDays = data => http.post('journey-analytics/v1/event-time-range/report', {
  data
}).then(({
  rangeInDays
}) => rangeInDays);
export const useFetchMaxDays = data => {
  const fetcher = useCallback(() => fetchMaxDays(data), [data]);
  return useAsyncFetcher({
    fetcher
  });
};