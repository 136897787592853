import { buildUnitializedAsyncData } from 'reporting-data/asyncData/AsyncData';
import get from 'transmute/get';
import { isList } from 'reporting-snowflake/shared/lib/utility-types';
export const getAppliedFiltersByReportFromDashboard = dashboard => {
  const hasAppliedFilters = !!get('appliedFilters', dashboard);
  if (hasAppliedFilters) {
    const appliedFilters = get('appliedFilters', dashboard);
    return isList(appliedFilters) ? appliedFilters.toJS() : appliedFilters;
  }
  return [];
};
export const setAsyncState = (state, dashboardId, nextAsyncState = buildUnitializedAsyncData()) => {
  if (!(dashboardId in state.overriddenReports)) {
    state.overriddenReports[dashboardId] = {
      reports: {},
      appliedFiltersByReport: [],
      asyncState: nextAsyncState
    };
  } else {
    state.overriddenReports[dashboardId].asyncState = nextAsyncState;
  }
};