const LOWER_BOUND_INCLUSIVE = 'lowerBoundInclusive';
const UPPER_BOUND_INCLUSIVE = 'upperBoundInclusive';

/*
 * Removes the `lowerBoundInclusive` and `upperBoundInclusive` properties from the custom filters
 * Filters team has recently started to set the default lower/upper bound inclusive values, but
 * users aren't allowed to change them yet.  Reporting has an override on the backend to set the
 * lowerBoundInclusive value to false.  With the recent filters changes, we'd start to save the
 * lower/upper bound inclusive values - it'll become hard to untangle once users are allowed to
 * change these values.  For now, we'll remove these values from the custom filters.
 *
 * More context: https://hubspot.slack.com/archives/C0736P8TGT0/p1727271700185849
 */
export const applyCustomFiltersOverrides = customFilters => {
  return customFilters.map(filter => {
    if (filter.get(LOWER_BOUND_INCLUSIVE) || filter.get(UPPER_BOUND_INCLUSIVE)) {
      return filter.delete(LOWER_BOUND_INCLUSIVE).delete(UPPER_BOUND_INCLUSIVE);
    }
    return filter;
  });
};
export const applyFilterGroupsOverrides = filterGroups => {
  return filterGroups.map(filterGroup => filterGroup.update('filters', applyCustomFiltersOverrides));
};