import I18n from 'I18n';
import { ENUMERATION } from 'reporting-data/constants/property-types';
import { CUSTOM_WIDGET_TYPES } from 'reporting-data/tsTypes/customWidgetTypes';
import { DEAL_CHANGE_TYPES } from 'reporting-ui-components/components/customWidgets/lib/salesAnalytics/constants';
const DAYS_14 = '1209600000';
const DAYS_30 = '2592000000';
const DAYS_60 = '5184000000';
const DAYS_90 = '7776000000';
const propertyValues = {
  deal_change_type: DEAL_CHANGE_TYPES,
  hs_engagement_type: ['CALL', 'MEETING', 'TASK', 'EMAIL', 'NOTE', 'CONVERSATION_SESSION'],
  'engagement.type': ['CALL', 'MEETING', 'TASK', 'EMAIL', 'NOTE', 'CONVERSATION_SESSION'],
  pushThresholdMillis: [DAYS_14, DAYS_30, DAYS_60, DAYS_90]
};
const propertyWidgets = {
  deal_change_type: [CUSTOM_WIDGET_TYPES.CHANGE_HISTORY_DEAL_LIST],
  hs_engagement_type: [CUSTOM_WIDGET_TYPES.COMPLETED_ACTIVITIES],
  'engagement.type': [CUSTOM_WIDGET_TYPES.ENGAGEMENT_STREAM, CUSTOM_WIDGET_TYPES.COMPLETED_ACTIVITIES],
  pushThresholdMillis: [CUSTOM_WIDGET_TYPES.DEAL_PUSH_ANALYSIS]
};
const getPropertyLang = (propertyName, remainingKey) => {
  const propertyKey = propertyName.replace('.', '_');
  return `settingLabels.quickFilter.customWidget.${propertyKey}.${remainingKey}`;
};
const getPropertyOptions = propertyName => {
  const values = propertyValues[propertyName];
  return values.map(value => {
    const textKey = propertyName === 'deal_change_type' ? `reporting-ui-components.customWidgets.changeHistoryDealList.deal_change_type.${value}` : getPropertyLang(propertyName, `options.${value}`);
    return {
      label: I18n.text(textKey),
      value
    };
  });
};
const getFilterProperty = propertyName => ({
  name: propertyName,
  type: ENUMERATION,
  label: I18n.text(getPropertyLang(propertyName, 'label')),
  options: getPropertyOptions(propertyName)
});
const getPropertyWidgets = propertyName => propertyWidgets[propertyName] || [];
export const getCustomWidgetFilterProperty = (customWidgetType, propertyName) => {
  if (customWidgetType && getPropertyWidgets(propertyName).includes(customWidgetType)) {
    return getFilterProperty(propertyName);
  }
  return null;
};