'use es6';

import Raven from 'raven-js';
const BLANK_URL = '';
const Types = {
  HUBSPOT_FORM: 'HUBSPOT',
  LEAD_FLOW: 'FLOW',
  COLLECTED_FORM: 'CAPTURED',
  FACEBOOK_LEAD_AD: 'FACEBOOK_LEAD_AD',
  BLOG_COMMENT: 'BLOG_COMMENT',
  MEETING_FORM: 'MEETING',
  FEEDBACK_FORM: 'FEEDBACK',
  TICKET_FORM: 'TICKET_FORM',
  DOCUMENT_FORM: 'DOCUMENT',
  VIDEO_FORM: 'VIDEO_FORM'
};
export function generateFormEditorUrl(portalId, guid) {
  return `/forms/${portalId}/editor/${guid}/edit/form`;
}
export const generateAssetPreviewLink = ({
  portalId,
  guid,
  conversionId
}) => `/submissions/${portalId}/form/${guid}/submissions${conversionId ? `/${conversionId}` : ''}`;
export const generateLeadFlowUrl = (portalId, guid) => generateAssetPreviewLink({
  portalId,
  guid
});
export function generateLeadFlowEditorUrl(portalId, guid, tab) {
  return `/lead-flows/${portalId}/edit/${guid}/${tab}`;
}
export function generateCollectedFormUrl(portalId, guid) {
  return `/forms/${portalId}?collectedFormsId=${guid}`;
}
export function generateMeetingFormUrl(portalId) {
  return `/meetings/${portalId}`;
}
export function generateFacebookLeadAdUrl(portalId, guid) {
  return `/settings/${portalId}/marketing/ads/form/${guid}?from=usages`;
}
function warnUser(message) {
  console.warn(message);
  Raven.captureMessage(message, {
    level: 'warning'
  });
}
function warnOfUnknownType(formType) {
  const warningMessage = [`FormUrlGenerator: Tried to generate a URL for an unknown form type '${formType}'.`, `Defaulted to the route for type '${Types.HUBSPOT_FORM}'.`, 'You might need to re-build and deploy your static app to pick up an update in FormUrlGenerator.'].join(' ');
  warnUser(warningMessage);
}
export function generateFormUrl({
  portalId,
  formType,
  guid,
  editor,
  tab
}) {
  if (!guid) {
    throw new Error('FormUrlGenerator: A `guid` field is required to be able to link to forms.');
  }
  if (!portalId) {
    throw new Error('FormUrlGenerator: A `portalId` field is required to be able to link to forms.');
  }
  if (!formType) {
    const warningMessage = [`FormUrlGenerator: No 'formType' has been provided for form '${guid}'.`, `This will be assumed to be a '${Types.HUBSPOT_FORM}'-type form instead.`, "Make sure you're requesting the 'formType' property from the forms service for your forms."].join(' ');
    warnUser(warningMessage);
    formType = Types.HUBSPOT_FORM;
  }
  switch (formType) {
    case Types.LEAD_FLOW:
      {
        if (editor) {
          if (tab === 'followUp' || tab === 'follow-up') {
            return generateLeadFlowEditorUrl(portalId, guid, 'follow-up');
          }
          return generateLeadFlowEditorUrl(portalId, guid, 'type');
        }
        return generateLeadFlowUrl(portalId, guid);
      }
    case Types.COLLECTED_FORM:
      return generateCollectedFormUrl(portalId, guid);
    case Types.HUBSPOT_FORM:
    case Types.BLOG_COMMENT:
    case Types.TICKET_FORM:
      return generateFormEditorUrl(portalId, guid);
    case Types.MEETING_FORM:
      return generateMeetingFormUrl(portalId);
    case Types.FACEBOOK_LEAD_AD:
      return generateFacebookLeadAdUrl(portalId, guid);
    case Types.FEEDBACK_FORM:
    case Types.DOCUMENT_FORM:
    case Types.VIDEO_FORM:
      return BLANK_URL;
    default:
      warnOfUnknownType(formType);
      return BLANK_URL;
  }
}