export const BOOLEAN = 'bool';
export const DATE = 'date';
export const DATE_TIME = 'datetime';
export const ENUMERATION = 'enumeration';
export const INT = 'int';
export const INTEGER = 'integer';
export const LONG = 'long';
export const MULTIENUM = 'multienum';
export const NUMBER = 'number';
export const NUMERIC = 'numeric';
export const STRING = 'string';