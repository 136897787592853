'use es6';

import CustomFiltersControl from '../components/controls/CustomFiltersControl';
import FirstDimensionControl from '../components/controls/FirstDimensionControl';
import { SecondaryDateRangeControl } from '../components/controls/SecondaryDateRangeControl';
import { DateRangeControl } from '../components/controls/DateRangeControl';
import { FrequencyControl } from '../components/controls/FrequencyControl';
import { CompareControl } from '../components/controls/CompareControl';
import StageOrderControl from '../components/controls/StageOrderControl';
import ColorThemeControl from '../components/reportCard/controls/ColorThemeControl';
import GoalViewControl from '../components/controls/GoalViewControl';
import SecondDimensionControl from '../components/controls/SecondDimensionControl';
import MultiMetricControl from '../components/controls/MultiMetricControl';
import AnalyticsViewControl from '../components/controls/AnalyticsViewControl';
import ChartTypeControl from '../components/reportCard/controls/ChartTypeControl';
import JourneyDateRangeControl from '../components/controls/JourneyDateRangeControl';
import ShowDropoffControl from '../components/reportCard/controls/ShowDropoffControl';
import JourneyListFilterControl from '../components/reportCard/controls/JourneyListFilterControl';
import JourneyAnonVisitorControl from '../components/reportCard/controls/JourneyAnonVisitorControl';
import JourneyPropertyFilterControl from '../components/reportCard/controls/JourneyPropertyFilterControl';
import EventSearchSelector from '../components/controls/EventSearchSelector';
import SnowflakeFiltersControl from '../components/controls/SnowflakeFiltersControl';
const getSidebarControlsInOrder = () => [SecondaryDateRangeControl, DateRangeControl, JourneyDateRangeControl, ShowDropoffControl, JourneyAnonVisitorControl, JourneyListFilterControl, JourneyPropertyFilterControl, FrequencyControl, CompareControl, MultiMetricControl, FirstDimensionControl, SecondDimensionControl, GoalViewControl, StageOrderControl, AnalyticsViewControl, CustomFiltersControl, SnowflakeFiltersControl, EventSearchSelector];
const reportCardControls = [ChartTypeControl, ColorThemeControl];
const filterUsableControls = (controls, report, subAppKey, filteredGates) => controls.filter(control => control.isUsableForReport({
  report,
  subAppKey,
  filteredGates
}));
export const getSidebarControls = (subAppKey, report, filteredGates) => filterUsableControls(getSidebarControlsInOrder(), report, subAppKey, filteredGates);
export const getAllUsableControls = (subAppKey, report, filteredGates) => filterUsableControls(getSidebarControlsInOrder().concat(reportCardControls), report, subAppKey, filteredGates);