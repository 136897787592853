'use es6';

import get from 'transmute/get';
import getIn from 'transmute/getIn';
import compose from 'transmute/compose';
import { getId, getName } from 'dashboard-lib/public/report/report-schema';
export const getReport = get('report');
export const getLayout = get('layout');
export const getReportId = compose(getId, getReport);
export const getReportName = compose(getName, getReport);
export const getNonReportWidget = getIn(['report', 'nonReportWidget']);