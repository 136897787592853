export const CONFIG_VALIDATION_ERROR = {
  INVALID_PROPERTY: 'ConfigValidationError.INVALID_PROPERTY',
  DEPRECATED_PROPERTY: 'ConfigValidationError.DEPRECATED_PROPERTY',
  TOO_MANY_METRICS_REQUESTED: 'ConfigValidationError.TOO_MANY_METRICS_REQUESTED',
  MISSING_DIMENSIONS_AND_METRICS: 'ConfigValidationError.MISSING_DIMENSIONS_AND_METRICS',
  TOO_MANY_FILLING_PERMUTATIONS: 'ConfigValidationError.TOO_MANY_FILLING_PERMUTATIONS',
  TOO_MANY_DEAL_STAGES_REQUESTED: 'ConfigValidationError.TOO_MANY_DEAL_STAGES_REQUESTED',
  INVALID_TWO_DIMENSION_METRIC: 'ConfigValidationError.INVALID_TWO_DIMENSION_METRIC',
  FUNNEL_COUNT_PROPERTY_NOT_PRESENT: 'ConfigValidationError.FUNNEL_COUNT_PROPERTY_NOT_PRESENT',
  UNSUPPORTED_DATE_RANGE_FOR_ATTRIBUTION_REPORTS: 'ConfigValidationError.UNSUPPORTED_DATE_RANGE_FOR_ATTRIBUTION_REPORTS'
};
export const REPORT_DATA_ERROR = {
  ATTRIBUTION_BACKFILL_IN_PROGRESS: 'ReportDataError.ATTRIBUTION_BACKFILL_IN_PROGRESS',
  TOO_MANY_FILLING_PERMUTATIONS: 'ReportDataError.TOO_MANY_FILLING_PERMUTATIONS',
  TOO_MUCH_DATA_TO_PROCESS: 'ReportDataError.TOO_MUCH_DATA_TO_PROCESS'
};
export const JOURNEY_ANALYTICS_ERROR = {
  THREE_SEQUENTIAL_OPTIONAL_STAGES_WITH_OVERLAPPING_EVENTS: 'JourneyAnalyticsError.THREE_SEQUENTIAL_OPTIONAL_STAGES_WITH_OVERLAPPING_EVENTS',
  UNFILTERED_PIPELINE_STAGE: 'JourneyAnalyticsError.UNFILTERED_PIPELINE_STAGE'
};
export const FIELD_LEVEL_PERMISSION_ERROR = 'FieldLevelPermissionsError.FIELD_LEVEL_PERMISSIONS_ERROR';