import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  properties: {}
};
export const platformDataSourceMetadataSliceNamespace = 'platformDataSourceMetadataView';

/** exposes a complete view of all successfully resolved properties within the platform-dashboard-ui store */
const platformDataSourceMetadataSlice = createSlice({
  name: platformDataSourceMetadataSliceNamespace,
  initialState,
  reducers: {
    updateProperties: (state, action) => {
      const {
        payload: {
          properties
        }
      } = action;
      state.properties = properties;
    }
  }
});
export const selectDataSourcePropertiesView = state => {
  var _state$platformDataSo;
  return ((_state$platformDataSo = state[platformDataSourceMetadataSliceNamespace]) === null || _state$platformDataSo === void 0 ? void 0 : _state$platformDataSo.properties) || {};
};
export const platformDataSourceMetadataActions = platformDataSourceMetadataSlice.actions;
export const platformDataSourceMetadataReducer = platformDataSourceMetadataSlice.reducer;