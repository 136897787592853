import * as DSAssetFamilies from 'customer-data-filters-ui/filterQueryFormat/DSAssetFamilies/DSAssetFamilies';
import { List, Map as ImmutableMap } from 'immutable';
import { getListSegClassicTimestampType } from 'customer-data-filters-ui/converters/listSegClassic/ListSegUtils';
import DSFieldRecord from '../DSFieldRecord';
import InboundDbListMembershipProperty from '../../../converters/crmSearch/psuedoProperties/InboundDbListMembershipProperty';
import PortalIdParser from 'PortalIdParser';
import reduce from 'transmute/reduce';
import set from 'transmute/set';
const PropertyUrlBlocklist = ['associatedcompanyid', 'associations.company', 'associations.contact', 'formSubmissions.formId', '_inbounddbio.importid_', 'listMemberships.listId', InboundDbListMembershipProperty.name];
const LINKABLE_TYPES = [DSAssetFamilies.CONTACT_PROPERTY, DSAssetFamilies.COMPANY_PROPERTY, DSAssetFamilies.DEAL_PROPERTY, DSAssetFamilies.TICKET_PROPERTY];
const getPropertyUrl = (assetFamily, propertyName) => {
  if (PropertyUrlBlocklist.includes(propertyName) || !LINKABLE_TYPES.includes(assetFamily)) {
    return null;
  }
  return `/property-settings/${PortalIdParser.get()}/properties?type=${assetFamily}&action=edit&property=${propertyName}`;
};

// Hide deprecated options https://issues.hubspotcentral.com/browse/CRM-39584
// They used to be hidden using the hidden field but that was causing other
// options that should not be hidden from being hidden
const deprecatedEngagementOptions = {
  hs_engagement_type: ['PUBLISHING_TASK'],
  hs_call_status: ['CALLING_CRM_USER', 'CONNECTING', 'IN_PROGRESS', 'QUEUED', 'RINGING']
};
const getVisibleOptionsForEngagements = (propertyName, parsedOptions) => {
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const deprecatedOptions = deprecatedEngagementOptions[propertyName];
  return deprecatedOptions && deprecatedOptions.length ? parsedOptions.filter(option => !deprecatedOptions.includes(option.value)) : parsedOptions;
};
const formatPropertyRecords = (records, assetFamily) => reduce(ImmutableMap(), (acc, propertyRecord) => {
  const options = propertyRecord.get('options');
  const isHubspotDefined = propertyRecord.get('hubspotDefined');
  const optionList = Array.isArray(options) ? List(options) : options;
  const parsedOptions = isHubspotDefined ? optionList.map(option => set('hubspotDefined', true, option)) : optionList;
  const visibleOptions = assetFamily === DSAssetFamilies.ENGAGEMENT_PROPERTY ? getVisibleOptionsForEngagements(propertyRecord.get('name'), parsedOptions) : parsedOptions;
  return acc.set(propertyRecord.name, DSFieldRecord({
    accessLevel: propertyRecord.get('accessLevel'),
    dateDisplayHint: propertyRecord.get('dateDisplayHint'),
    description: propertyRecord.get('description'),
    displayType: propertyRecord.get('type'),
    externalOptions: propertyRecord.get('externalOptions'),
    externalOptionsReferenceType: propertyRecord.get('externalOptionsReferenceType'),
    fieldType: propertyRecord.get('fieldType'),
    hidden: propertyRecord.get('hidden'),
    hubspotDefined: propertyRecord.get('hubspotDefined'),
    label: propertyRecord.get('label'),
    name: propertyRecord.get('name'),
    numberDisplayHint: propertyRecord.get('numberDisplayHint'),
    options: visibleOptions,
    type: propertyRecord.get('type'),
    referencedObjectType: propertyRecord.get('referencedObjectType'),
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'Timestamp... Remove this comment to see the full error message
    timestampType: getListSegClassicTimestampType(propertyRecord),
    url: getPropertyUrl(assetFamily, propertyRecord.get('name')),
    groupName: propertyRecord.get('groupName'),
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'unknown' is not assignable to type 'object |... Remove this comment to see the full error message
    wrappedObject: propertyRecord
  }));
}, records);
export default ((assetFamily, data) => formatPropertyRecords(data, assetFamily));