export const ReportConfigActionTypes = {
  'condition added': 'condition-added',
  'condition deleted': 'condition-deleted'
};
export const DateActionTypes = {
  COMPARED_TO: 'compared-to',
  DATE_RANGE: 'date-range',
  DATE_PROPERTY: 'date-property',
  FREQUENCY: 'frequency',
  SECONDARY_DATE_RANGE: 'secondary-date-range',
  CURRENCY: 'currency',
  QUERY: 'query',
  METRIC: 'metric',
  TABLE_METRIC: 'table-metric',
  GROUP_BY: 'group-by',
  BREAKDOWN_BY: 'breakdown-by',
  UNIFIED_ANALYTICS_VIEW: 'unified-analytics-view'
};
export const DRILLDOWN_ACTION = 'drill-into';
export const GOAL_EDITOR_ACTION_TYPES = {
  ADD_GOAL: 'add-goal',
  REMOVE_GOAL: 'remove-goal',
  RESET_GOAL: 'reset-goal'
};