import { List } from 'immutable';
import memoize from 'transmute/memoize';
import I18n from 'I18n';
import * as http from 'reporting-data/request/http';
import { DATASET_INSIGHT_TYPES, DatasetInsightOption } from 'reporting-data/tsTypes/datasetInsightTypes';
import { getDatasetInsightOptions } from 'reporting-data/report/reportGetters';
import { setDatasetInsightOptions } from 'reporting-data/report/reportSetters';
import { DATASET_INSIGHT_PARAMS_PROPERTY } from 'reporting-data/datasetInsights';
import { ADVANCED_REPORTING_INSIGHTS_SCOPE } from '../constants/scopes';
import { ADVANCED_INSIGHT_TYPES, MUTUALLY_INCLUSIVE_INSIGHT_TYPES } from '../constants/datasetInsights';
import { ANOMALY_DETECTION_V2_GATE } from '../constants/gates';
const VALID_INSIGHTS_API_URL = 'reporting/v2/insights/valid-insights';
export const buildDatasetInsightOptions = (insightOptions, hasAdvancedInsightsAccess) => {
  if (!hasAdvancedInsightsAccess) {
    return insightOptions.map(option => {
      if (option.value in ADVANCED_INSIGHT_TYPES) {
        return Object.assign({}, option, {
          icon: 'locked'
        });
      }
      return option;
    });
  }
  return insightOptions;
};
export const reportHasDatasetInsightOptionType = (report, datasetInsightType) => {
  const datasetInsightOptions = getDatasetInsightOptions(report);
  if (!datasetInsightOptions) {
    return false;
  }
  return datasetInsightOptions.some(insightOption => insightOption.get('insightType') === datasetInsightType);
};
const fetchDatasetInsightTypes = memoize(report => {
  return http.post(VALID_INSIGHTS_API_URL, {
    data: report.toJS()
  });
});
export const enableDatasetInsightType = (report, datasetInsightType, gates = []) => {
  const hasAnomalyDetectionV2Gate = gates.includes(ANOMALY_DETECTION_V2_GATE);
  if (hasAnomalyDetectionV2Gate) {
    var _getDatasetInsightOpt;
    const datasetInsightOptions = (_getDatasetInsightOpt = getDatasetInsightOptions(report)) !== null && _getDatasetInsightOpt !== void 0 ? _getDatasetInsightOpt : List([]);

    // if the report already has the insight type, do nothing
    if (reportHasDatasetInsightOptionType(report, datasetInsightType)) {
      return report;
    }

    // if adding a mutually inclusive insight type, add without removing any existing insight types
    if (datasetInsightType in MUTUALLY_INCLUSIVE_INSIGHT_TYPES) {
      return setDatasetInsightOptions(report, datasetInsightOptions.push(DatasetInsightOption({
        insightType: datasetInsightType
      })));
    }

    // remove any existing insight types that are not mutually inclusive and add the new insight type
    const existingMutuallyInclusiveInsightTypes = List(datasetInsightOptions.filter(insightOption => insightOption.get('insightType') in MUTUALLY_INCLUSIVE_INSIGHT_TYPES));
    return setDatasetInsightOptions(report, existingMutuallyInclusiveInsightTypes.push(DatasetInsightOption({
      insightType: datasetInsightType
    })));
  }
  const datasetInsightOptions = getDatasetInsightOptions(report);
  if (!reportHasDatasetInsightOptionType(report, datasetInsightType)) {
    const insightOptionToAdd = DatasetInsightOption({
      insightType: datasetInsightType
    });
    const updatedDatasetInsightOptions = List(datasetInsightOptions && datasetInsightType === ADVANCED_INSIGHT_TYPES.ANOMALY_DETECTION ? datasetInsightOptions.push(insightOptionToAdd) : [insightOptionToAdd]);
    return setDatasetInsightOptions(report, updatedDatasetInsightOptions);
  }
  return report;
};
export const disableDatasetInsights = (report, datasetInsightType) => {
  const datasetInsightOptions = getDatasetInsightOptions(report);
  if (datasetInsightType && reportHasDatasetInsightOptionType(report, datasetInsightType) && datasetInsightOptions) {
    var _getDatasetInsightOpt2;
    const updatedDatasetInsightOptions = List(datasetInsightOptions.filter(insightOption => insightOption.get('insightType') !== datasetInsightType));
    const updatedReport = setDatasetInsightOptions(report, updatedDatasetInsightOptions);
    return ((_getDatasetInsightOpt2 = getDatasetInsightOptions(updatedReport)) === null || _getDatasetInsightOpt2 === void 0 ? void 0 : _getDatasetInsightOpt2.count()) === 0 ? report.delete(DATASET_INSIGHT_PARAMS_PROPERTY) : updatedReport;
  }
  return report.delete(DATASET_INSIGHT_PARAMS_PROPERTY);
};
export const getSelectedDatasetInsightOptionType = report => {
  const datasetInsightOptions = getDatasetInsightOptions(report);
  return datasetInsightOptions && datasetInsightOptions.count() ? datasetInsightOptions.first().get('insightType') : undefined;
};
export const getSelectedAnalyticsTrendLineOptionType = report => {
  var _getDatasetInsightOpt3;
  const analyticsTrendLineOptions = (_getDatasetInsightOpt3 = getDatasetInsightOptions(report)) === null || _getDatasetInsightOpt3 === void 0 ? void 0 : _getDatasetInsightOpt3.filter(insightOption => insightOption.get('insightType') !== DATASET_INSIGHT_TYPES.ANOMALY_DETECTION);
  return analyticsTrendLineOptions && analyticsTrendLineOptions.count() ? analyticsTrendLineOptions.first().get('insightType') : undefined;
};
export const fetchDatasetInsightTypeOptions = async report => {
  const validDatasetInsightTypes = await fetchDatasetInsightTypes(report);
  return validDatasetInsightTypes.map(validDatasetInsightType => {
    return {
      text: I18n.text(`report-customization-components.customization-components.datasetInsightTypes.${validDatasetInsightType}`),
      value: validDatasetInsightType,
      help: I18n.text(`report-customization-components.customization-components.datasetInsightTypesHelpText.${validDatasetInsightType}`)
    };
  }).toArray();
};
export const hasAdvancedInsightsAccess = scopes => scopes && scopes.includes(ADVANCED_REPORTING_INSIGHTS_SCOPE) || false;