import { buildUnitializedAsyncData } from 'reporting-data/asyncData/AsyncData';
import { dashboardReportsNamespace } from './slice';
import { createSelector } from '@reduxjs/toolkit';
function withState(selector) {
  return state => selector(state[dashboardReportsNamespace]);
}
export const selectDashboardReportsAsyncStatus = dashboardId => withState(state => {
  if (dashboardId in state.dashboardReports) {
    return state.dashboardReports[dashboardId].asyncState;
  }
  return buildUnitializedAsyncData();
});
export const selectOverriddenDashboardReport = (dashboardId, reportId) => withState(state => {
  if (dashboardId in state.dashboardReports && reportId in state.dashboardReports[dashboardId].reports) {
    return state.dashboardReports[dashboardId].reports[reportId];
  }
  return undefined;
});
export const selectBackendAppliedFiltersForReport = (dashboardId, reportId) => withState(state => {
  if (dashboardId in state.dashboardReports) {
    return state.dashboardReports[dashboardId].appliedFiltersByReport.filter(overridden => overridden.reportIds.includes(reportId)).map(overridden => overridden.dashboardFilter);
  }
  return [];
});
export const selectReportsOverriddenByFilter = (dashboardId, filter) => withState(state => {
  if (!(dashboardId in state.dashboardReports)) {
    return [];
  }
  const {
    reports: allReports,
    appliedFiltersByReport
  } = state.dashboardReports[dashboardId];
  const maybeAppliedFilterByReportIds = appliedFiltersByReport.find(({
    dashboardFilter
  }) => {
    return dashboardFilter.dataSourceId === filter.dataSource && dashboardFilter.name === filter.propertyName;
  });
  if (!maybeAppliedFilterByReportIds) {
    return [];
  }
  return maybeAppliedFilterByReportIds.reportIds.reduce((next, reportId) => {
    const report = allReports[reportId];
    if (report) {
      return [...next, report];
    }
    return next;
  }, []);
});
const selectDashboardReports = dashboardId => withState(state => {
  return dashboardId in state.dashboardReports ? Object.values(state.dashboardReports[dashboardId].reports) : [];
});
export const selectDashboardReportsCount = dashboardId => createSelector([selectDashboardReports(dashboardId)], reports => {
  return reports.length;
});