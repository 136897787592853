// @ts-expect-error migrate upstream types
import * as Operators from 'customer-data-filters/filterQueryFormat/operator/Operators';
import { BOOLEAN, DATE, DATE_TIME, ENUMERATION, NUMBER, STRING } from 'customer-data-objects/property/PropertyTypes';
// @ts-expect-error migrate dependency
import { get as isUnified } from 'reporting-data/retrieve/unified/supported';
// @ts-expect-error migrate dependency
import { unifiedReferences } from 'reporting-enablement/components/reportEditors/UnifiedFilterEditor/utils';
// @ts-expect-error migrate dependency
import { ANALYTICS_VIEW } from 'reference-resolvers/constants/ReferenceObjectTypes';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'repo... Remove this comment to see the full error message
import { getOperatorsForType as getUnifiedOperatorsForType } from 'reporting-enablement/components/reportEditors/UnifiedFilterEditor/utils';
import { Map as ImmutableMap, OrderedSet } from 'immutable';
import { isGenericDateRangeProperty } from './dateRangeHelpers';
export const getIsUngated = gates => gate => gates.includes(gate);
const analyticsViewOperators = OrderedSet.of(Operators.Equal);
export const dateRangePropertyOperators = OrderedSet.of(Operators.InRollingDateRange, Operators.Equal, Operators.AfterDate, Operators.BeforeDate, Operators.InRange);
const dateOperators = OrderedSet.of(Operators.InRollingDateRange, Operators.Equal, Operators.AfterDate, Operators.BeforeDate, Operators.InRange, Operators.Known, Operators.NotKnown);
const PropertyTypeToOperator = ImmutableMap({
  [BOOLEAN]: OrderedSet.of(Operators.Equal, Operators.NotEqual),
  [DATE]: dateOperators,
  [DATE_TIME]: dateOperators,
  [ENUMERATION]: OrderedSet.of(Operators.In, Operators.NotIn, Operators.Known, Operators.NotKnown),
  [NUMBER]: OrderedSet.of(Operators.Equal, Operators.NotEqual, Operators.Greater, Operators.GreaterOrEqual, Operators.Less, Operators.LessOrEqual, Operators.Known, Operators.NotKnown),
  [STRING]: OrderedSet.of(Operators.ContainAny, Operators.NotContainAny, Operators.Known, Operators.NotKnown)
});
export const enumeratedValueOperators = [Operators.In, Operators.NotIn, Operators.ContainAny, Operators.NotContainAny];
export const getDashboardFilterOperatorsForType = type => {
  return PropertyTypeToOperator.get(type);
};
export const getDashboardFilterOperators = ({
  propertyName,
  propertyType,
  dataSource
}) => {
  if (isGenericDateRangeProperty(propertyName)) {
    return dateRangePropertyOperators;
  }
  if (isUnified(dataSource)) {
    if (unifiedReferences[propertyName] === ANALYTICS_VIEW) {
      return analyticsViewOperators;
    }
    return getUnifiedOperatorsForType(propertyType);
  }
  return getDashboardFilterOperatorsForType(propertyType);
};