import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import get from 'transmute/get';
import set from 'transmute/set';
export const WIDGET_CONFIG_TYPES = {
  NOTE: 'NOTE',
  IFRAME: 'IFRAME'
};
const isDashboardTemplateWidget = widget => 'reportTemplate' in widget;
export const getReport = widget => {
  const getter = isDashboardTemplateWidget(widget) ? get('reportTemplate') : get('report');
  return getter(widget);
};
export const renameKey = (oldkey, newKey) => subject => {
  const value = get(oldkey, subject);
  const _set = set(newKey, value, subject),
    rest = _objectWithoutPropertiesLoose(_set, [oldkey].map(_toPropertyKey));
  return rest;
};