import { isContactCreateAttributionReport, isDealCreateAttributionReport, isRevenueAttributionReport
// @ts-expect-error untyped import
} from '../../../lib/report-logic';
import { CONTACT_CREATE_ATTRIBUTION_ACCESS, DEAL_CREATE_ATTRIBUTION_ACCESS, REVENUE_ATTRIBUTION_ACCESS } from '../scopeTypes';
// @ts-expect-error untyped import
import useScopes from './useScopes';
export const useHasRequiredAttributionReportScope = report => {
  const scopes = useScopes();
  const requiredScope = isContactCreateAttributionReport(report) ? CONTACT_CREATE_ATTRIBUTION_ACCESS : isDealCreateAttributionReport(report) ? DEAL_CREATE_ATTRIBUTION_ACCESS : isRevenueAttributionReport(report) ? REVENUE_ATTRIBUTION_ACCESS : null;
  return !requiredScope || scopes.includes(requiredScope);
};