'use es6';

import { GLOBAL_NULL } from '../constants/defaultNullValues';
import I18n from 'I18n';
import { List, Map as ImmutableMap } from 'immutable';
import { generateAnalyticsSourceData2Label } from './specialReferenceLabelers';
import { generateBusinessUnitLabel } from '../references/businessUnit';
import { generateCampaignLabel } from '../references/campaign/index';
import { generateChatflowLabel } from '../references/chatflows/index';
import { generateCompanyLabel } from '../references/company';
import { generateContactLabel } from '../references/contact/index';
import { generateConversationInboxLabel } from '../references/conversation-inbox/index';
import { generateCurrencyLabel } from '../references/currency/index';
import { generateDealLabel } from '../references/deal/index';
import { generateGenericChannelLabel } from '../references/genericChannel';
import { generateListMembershipIdLabel } from '../references/list';
import { generateMarketableReasonIdLabel } from '../references/marketableReason/index';
import { generateOwnerLabel } from '../references/owner/index';
import { generatePipelineLabel } from '../references/pipeline/index';
import { generatePipelineStageLabel } from '../references/pipelineStage/index';
import { generateProductLabel } from '../references/products';
import { generateSequenceStepOrderLabel } from '../references/sequenceStepOrder';
import { generateTeamLabel } from '../references/team';
import { generateTicketLabel } from '../references/ticket';
import { generateUserLabel } from '../references/user';
import { generateTemplateLabel } from '../references/template';
import generateLabel from '../references/label';

// a map for all the referenced object types with the corresponding function to handle generating labels
export const referenceTypes = ImmutableMap(Object.assign({
  USER: generateUserLabel,
  OWNER: generateOwnerLabel,
  CAMPAIGN: generateCampaignLabel,
  PIPELINE: generatePipelineLabel,
  PIPELINE_STAGE: generatePipelineStageLabel,
  TEAM: generateTeamLabel,
  CURRENCY_CODE: generateCurrencyLabel,
  GENERIC_CHANNEL: generateGenericChannelLabel,
  LIST: generateListMembershipIdLabel,
  MARKETABLE_REASON: generateMarketableReasonIdLabel,
  ANALYTICS_SOURCE_DATA_2: generateAnalyticsSourceData2Label,
  '0-1': generateContactLabel,
  '0-2': generateCompanyLabel,
  '0-3': generateDealLabel,
  '0-5': generateTicketLabel,
  '0-7': generateProductLabel,
  '0-55': generateConversationInboxLabel,
  '0-56': generateChatflowLabel,
  '0-62': generateTemplateLabel,
  SEQUENCE_STEP_ORDER: generateSequenceStepOrderLabel,
  BUSINESS_UNIT: generateBusinessUnitLabel
}, ImmutableMap({
  '0-35': {
    labelKeyOrKeys: List(['label', 'hs_name']),
    fallbackTranslationPath: 'reporting-data.references.campaign.unknown'
  },
  '0-45': {
    labelKeyOrKeys: 'label',
    fallbackTranslationPath: 'reporting-data.references.list.unknown'
  },
  '0-58': {
    labelKeyOrKeys: 'hs_sequence_name',
    fallbackTranslationPath: 'reporting-data.references.sequence.unknown'
  },
  '0-83': {
    labelKeyOrKeys: 'hs_document_title',
    fallbackTranslationPath: 'reporting-data.references.sales-document.unknown'
  },
  '0-119': {
    labelKeyOrKeys: 'hs_tag_name',
    fallbackTranslationPath: 'reporting-data.references.feedback-submission.unknown'
  },
  SEQUENCE: {
    labelKeyOrKeys: List(['label', 'name']),
    fallbackTranslationPath: 'reporting-data.references.sequence.unknown'
  },
  EVENT_TYPE: {
    labelKeyOrKeys: 'label',
    fallbackTranslationPath: 'reporting-data.references.event-type.unknown'
  },
  CTA: {
    labelKeyOrKeys: List(['label', 'name']),
    fallbackTranslationPath: 'reporting-data.references.cta.unknown'
  },
  FORM: {
    labelKeyOrKeys: List(['label', 'name']),
    fallbackTranslationPath: 'reporting-data.references.form.unknown'
  },
  INTERACTION_TYPE: {
    labelKeyOrKeys: List(['label', 'name']),
    fallbackTranslationPath: 'reporting-data.references.interaction-type.unknown'
  },
  SALESFORCE_CAMPAIGN: {
    labelKeyOrKeys: List(['label', 'name']),
    fallbackTranslationPath: 'reporting-data.references.salesforce-campaign.unknown'
  },
  OBJECT_COORDINATES: {
    labelKeyOrKeys: 'label',
    fallbackTranslationPath: 'reporting-data.references.object-coordinates.unknown'
  },
  CRM_OBJECT_TAG: {
    labelKeyOrKeys: 'label',
    fallbackTranslationPath: 'reporting-data.references.crm-object-tags.unknown'
  },
  CALL_DISPOSITION: {
    labelKeyOrKeys: 'label',
    fallbackTranslationPath: 'reporting-data.references.call-disposition.unknown'
  },
  CUSTOM_OBJECT: {
    labelKeyOrKeys: 'label',
    fallbackTranslationPath: 'reporting-data.references.custom-objects.unknown'
  },
  OBJECT_LIST: {
    labelKeyOrKeys: 'label',
    fallbackTranslationPath: 'reporting-data.references.object-list.unknown'
  },
  TASK_QUEUE: {
    labelKeyOrKeys: 'label',
    fallbackTranslationPath: 'reporting-data.references.task-queue.unknown'
  },
  PERSONA: {
    labelKeyOrKeys: 'label',
    fallbackTranslationPath: 'reporting-data.references.persona.unknown'
  }
}).map(params => generateLabel(params)).toJS()));
export const getReferenceLabeler = referenceType => {
  var _referenceTypes$get;
  const referenceLabeler = (_referenceTypes$get = referenceTypes.get(referenceType)) !== null && _referenceTypes$get !== void 0 ? _referenceTypes$get : generateLabel({
    labelKeyOrKeys: 'label'
  });
  return (reference, key, ...rest) => {
    const label = referenceLabeler(reference, key, ...rest);
    if (!label && key === GLOBAL_NULL) {
      return I18n.text('reporting-data.missing.value');
    }
    return label !== null && label !== void 0 ? label : key;
  };
};