import { useCallback, useState } from 'react';
import useCollaborationSidebarState from './useCollaborationSidebarState';
import { Metrics } from '../utils/metrics';
import useCommentingApi from './useCommentingApi';
import useHasCommentingFeature from './useHasCommentingFeature';
export default function useFetchComments() {
  const commentingApi = useCommentingApi();
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const {
    onUpdateCollaborationSidebarState,
    modulesWithReplies
  } = useCollaborationSidebarState();
  const hasCommentingFeature = useHasCommentingFeature();
  const fetchComments = useCallback(({
    objectType,
    objectId,
    refetch
  }) => {
    if (!hasCommentingFeature) {
      return;
    }
    if (!objectId || !objectType) {
      return;
    }
    setLoading(true);
    commentingApi.fetchCommentsCached({
      objectType,
      objectId,
      refetch
    }).then(({
      results
    }) => {
      // MSW will return undefined in some of our client apps
      // until we mock the client apps response we fallback to an empty array.
      // This won't impact the apps behaviour because the real API will always return
      // an array.
      setComments(results || []);
      Metrics.counter('fetch-comments-cached-success', {
        object_type: objectType
      }).increment();
    }).catch(e => {
      Metrics.counter('fetch-comments-cached-error', {
        object_type: objectType
      }).increment();
      setError(e);
    }).finally(() => {
      setLoading(false);
    });
  }, [commentingApi, hasCommentingFeature]);
  const updateCache = useCallback(({
    objectType,
    objectId,
    updatedCommentId
  }) => {
    const updatedComments = comments.map(comment => {
      if (comment.id === updatedCommentId) {
        return Object.assign({}, comment, {
          read: true
        });
      }
      return comment;
    });
    setComments(updatedComments);
    commentingApi.updateCommentsCache({
      objectType,
      objectId,
      updatedCommentId,
      comments: updatedComments,
      modulesWithReplies,
      onUpdateCollaborationSidebarState
    });
  }, [comments, modulesWithReplies, onUpdateCollaborationSidebarState, commentingApi]);
  return {
    fetchComments,
    comments,
    error,
    loading,
    updateCache
  };
}