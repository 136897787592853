'use es6';

import * as FilterTypes from './ObjectSegFilterTypes';
import * as OperationTypes from './OperationTypes';
import * as OperatorTypes from './ObjectSegOperatorTypes';
import * as Operators from '../../filterQueryFormat/operator/Operators';
import { ALL_HISTORY_REFINE_BY, COALESCING_REFINE_BY, COALESCING_REFINE_BY_SET_TYPE, COALESCING_REFINE_BY_TYPE, PRUNING_REFINE_BY } from './constants/constants';
import { Map as ImmutableMap, fromJS } from 'immutable';
import getIn from 'transmute/getIn';
import invariant from 'react-utils/invariant';
const defaultOperatorMapping = ImmutableMap({
  [OperatorTypes.CONSTANT]: Operators.DeprecatedFilter,
  [OperatorTypes.IS_AFTER]: Operators.After,
  [OperatorTypes.IS_BEFORE]: Operators.Before,
  [OperatorTypes.IS_ANY_OF]: Operators.In,
  [OperatorTypes.IS_NONE_OF]: Operators.NotIn,
  [OperatorTypes.HAS_EVER_BEEN_ANY_OF]: Operators.EverIn,
  [OperatorTypes.HAS_NEVER_BEEN_ANY_OF]: Operators.NeverIn,
  [OperatorTypes.IS_EQUAL_TO]: Operators.Equal,
  [OperatorTypes.IS_NOT_EQUAL_TO]: Operators.NotEqual,
  [OperatorTypes.IS_GREATER_THAN]: Operators.Greater,
  [OperatorTypes.IS_LESS_THAN]: Operators.Less,
  [OperatorTypes.IS_GREATER_THAN_OR_EQUAL_TO]: Operators.GreaterOrEqual,
  [OperatorTypes.IS_LESS_THAN_OR_EQUAL_TO]: Operators.LessOrEqual,
  [OperatorTypes.IS_BEFORE_DATE]: Operators.Less,
  [OperatorTypes.IS_AFTER_DATE]: Operators.Greater,
  [OperatorTypes.IS_BETWEEN]: Operators.InRange,
  [OperatorTypes.IS_NOT_BETWEEN]: Operators.NotInRange,
  [OperatorTypes.IS_KNOWN]: Operators.Known,
  [OperatorTypes.IS_UNKNOWN]: Operators.NotKnown,
  [OperatorTypes.IN_LAST_TIME_UNIT]: Operators.InRollingDateRange,
  [OperatorTypes.IN_THIS_TIME_UNIT]: Operators.InRollingDateRange,
  [OperatorTypes.IN_THIS_TIME_UNIT_SO_FAR]: Operators.InRollingDateRange,
  [OperatorTypes.IN_NEXT_TIME_UNIT]: Operators.InRollingDateRange,
  [OperatorTypes.IS_LESS_THAN_X_DAYS_AGO]: Operators.LessRolling,
  [OperatorTypes.IS_LESS_THAN_X_DAYS_FROM_NOW]: Operators.LessRolling,
  [OperatorTypes.IS_MORE_THAN_X_DAYS_AGO]: Operators.GreaterRolling,
  [OperatorTypes.IS_MORE_THAN_X_DAYS_FROM_NOW]: Operators.GreaterRolling,
  [OperatorTypes.CONTAINS]: Operators.Contain,
  [OperatorTypes.DOES_NOT_CONTAIN]: Operators.NotContain,
  [OperatorTypes.STARTS_WITH]: Operators.StartsWith,
  [OperatorTypes.ENDS_WITH]: Operators.EndsWith,
  [OperatorTypes.HAS_EVER_BEEN_EQUAL_TO]: Operators.EverEqual,
  [OperatorTypes.HAS_NEVER_BEEN_EQUAL_TO]: Operators.NeverEqual,
  [OperatorTypes.HAS_EVER_CONTAINED]: Operators.EverContained,
  [OperatorTypes.HAS_NEVER_CONTAINED]: Operators.NeverContained,
  [OperatorTypes.UPDATED_IN_LAST_X_DAYS]: Operators.UpdatedInLastXDays,
  [OperatorTypes.NOT_UPDATED_IN_LAST_X_DAYS]: Operators.NotUpdatedInLastXDays,
  [OperatorTypes.IS_EXACTLY]: Operators.EqualAll,
  [OperatorTypes.IS_NOT_EXACTLY]: Operators.NotEqualAll,
  [OperatorTypes.CONTAINS_ALL]: Operators.ContainAll,
  [OperatorTypes.DOES_NOT_CONTAIN_ALL]: Operators.NotContainAll,
  [OperatorTypes.HAS_EVER_BEEN_EXACTLY]: Operators.EverEqualAll,
  [OperatorTypes.HAS_NEVER_BEEN_EXACTLY]: Operators.NeverEqualAll,
  [OperatorTypes.HAS_EVER_CONTAINED_ALL]: Operators.EverContainedAll,
  [OperatorTypes.HAS_NEVER_CONTAINED_ALL]: Operators.NeverContainedAll
});
const propertyTypeOperatorMapping = fromJS({
  [OperationTypes.PROPERTY_UPDATED_COMPARATIVE]: {
    [OperatorTypes.IS_AFTER]: Operators.UpdatedAfter,
    [OperatorTypes.IS_BEFORE]: Operators.UpdatedBefore
  },
  [OperationTypes.STRING]: {
    [OperatorTypes.IS_EQUAL_TO]: Operators.EqualAny,
    [OperatorTypes.IS_NOT_EQUAL_TO]: Operators.NotEqualAny,
    [OperatorTypes.CONTAINS]: Operators.ContainAny,
    [OperatorTypes.DOES_NOT_CONTAIN]: Operators.NotContainAny,
    [OperatorTypes.STARTS_WITH]: Operators.StartsWithAny,
    [OperatorTypes.ENDS_WITH]: Operators.EndsWithAny,
    [OperatorTypes.HAS_EVER_BEEN_EQUAL_TO]: Operators.EverEqualAny,
    [OperatorTypes.HAS_NEVER_BEEN_EQUAL_TO]: Operators.NeverEqualAny
  },
  [OperationTypes.MULTI_STRING]: {
    [OperatorTypes.IS_EQUAL_TO]: Operators.EqualAny,
    [OperatorTypes.IS_NOT_EQUAL_TO]: Operators.NotEqualAny,
    [OperatorTypes.CONTAINS]: Operators.ContainAny,
    [OperatorTypes.DOES_NOT_CONTAIN]: Operators.NotContainAny,
    [OperatorTypes.STARTS_WITH]: Operators.StartsWithAny,
    [OperatorTypes.ENDS_WITH]: Operators.EndsWithAny,
    [OperatorTypes.HAS_EVER_BEEN_EQUAL_TO]: Operators.EverEqualAny,
    [OperatorTypes.HAS_NEVER_BEEN_EQUAL_TO]: Operators.NeverEqualAny
  },
  [OperationTypes.DATE_TIME]: {
    [OperatorTypes.IS_AFTER]: Operators.AfterDate,
    [OperatorTypes.IS_BEFORE]: Operators.BeforeDate,
    [OperatorTypes.IS_AFTER_DATE]: Operators.AfterDate,
    [OperatorTypes.IS_BEFORE_DATE]: Operators.BeforeDate
  },
  [OperationTypes.RANGED_TIME]: {
    [OperatorTypes.IS_BETWEEN]: Operators.Any,
    [OperatorTypes.IS_NOT_BETWEEN]: Operators.Any
  },
  [OperationTypes.TIME_POINT]: {
    [OperatorTypes.IS_AFTER]: Operators.Any,
    [OperatorTypes.IS_BEFORE]: Operators.Any
  }
});
const filterTypeOperatorMapping = fromJS({
  [FilterTypes.ASSOCIATION]: {
    [OperatorTypes.IS_IN_LIST]: Operators.MissingAssociationBranchFilter
  },
  [FilterTypes.ADS_TIME]: {
    [OperatorTypes.LESS_THAN_X_AGO_OR_FROM_NOW]: Operators.LessThanRolling,
    [OperatorTypes.MORE_THAN_X_AGO_OR_FROM_NOW]: Operators.GreaterThanRolling
  },
  [FilterTypes.CTA]: {
    [OperatorTypes.HAS_CLICKED_CTA]: Operators.CtaHasClicked,
    [OperatorTypes.HAS_NOT_CLICKED_CTA]: Operators.CtaHasNotClicked,
    [OperatorTypes.HAS_OPENED_CTA]: Operators.CtaHasSeen,
    [OperatorTypes.HAS_NOT_OPENED_CTA]: Operators.CtaHasNotSeen,
    [OperatorTypes.HAS_CLICKED_CTA_PLACEMENT]: Operators.CtaHasClicked,
    [OperatorTypes.HAS_NOT_CLICKED_CTA_PLACEMENT]: Operators.CtaHasNotClicked,
    [OperatorTypes.HAS_OPENED_CTA_PLACEMENT]: Operators.CtaHasSeen,
    [OperatorTypes.HAS_NOT_OPENED_CTA_PLACEMENT]: Operators.CtaHasNotSeen
  },
  [FilterTypes.EVENT]: {
    [OperatorTypes.HAS_EVENT]: Operators.EventCompleted,
    [OperatorTypes.NOT_HAS_EVENT]: Operators.EventNotCompleted
  },
  [FilterTypes.PRIVACY_CONSENT]: {
    [OperatorTypes.PRIVACY_CONSENT_NOT_GRANTED]: Operators.PrivacyConsentNotCompleted,
    [OperatorTypes.PRIVACY_CONSENT_GRANTED]: Operators.PrivacyConsentCompleted
  },
  [FilterTypes.EMAIL_EVENT]: {
    [OperatorTypes.EMAIL_BOUNCED]: Operators.EmailBounced,
    [OperatorTypes.EMAIL_LINK_CLICKED]: Operators.EmailLinkClicked,
    [OperatorTypes.EMAIL_MARKED]: Operators.EmailMarked,
    [OperatorTypes.EMAIL_OPENED_BUT_LINK_NOT_CLICKED]: Operators.EmailOpenedButLinkNotClicked,
    [OperatorTypes.EMAIL_OPENED_BUT_NOT_REPLIED]: Operators.EmailOpenedButNotReplied,
    [OperatorTypes.EMAIL_OPENED]: Operators.EmailOpened,
    [OperatorTypes.EMAIL_RECEIVED_BUT_NOT_OPENED]: Operators.EmailReceivedButNotOpened,
    [OperatorTypes.EMAIL_RECEIVED]: Operators.EmailReceived,
    [OperatorTypes.EMAIL_REPLIED]: Operators.EmailReplied,
    [OperatorTypes.EMAIL_SENT_BUT_LINK_NOT_CLICKED]: Operators.EmailSentButLinkNotClicked,
    [OperatorTypes.EMAIL_SENT_BUT_NOT_RECEIVED]: Operators.EmailSentButNotReceived,
    [OperatorTypes.EMAIL_SENT]: Operators.EmailSent,
    [OperatorTypes.EMAIL_UNSUBSCRIBED]: Operators.EmailUnsubscribed
  },
  [FilterTypes.EMAIL_SUBSCRIPTION]: {
    [OperatorTypes.HAS_OPTED_IN]: Operators.EmailSubscriptionOptIn,
    [OperatorTypes.HAS_OPTED_OUT]: Operators.EmailSubscriptionOptOut,
    [OperatorTypes.HAS_NOT_OPTED]: Operators.EmailSubscriptionNotOpted
  },
  [FilterTypes.FORM_SUBMISSION]: {
    [OperatorTypes.FORM_SUBMISSION]: Operators.FormFilledOut
  },
  [FilterTypes.FORM_SUBMISSION_ON_PAGE]: {
    [OperatorTypes.FORM_SUBMISSION_ON_PAGE]: Operators.FormFilledOut
  },
  [FilterTypes.IN_IMPORT]: {
    [OperatorTypes.IS_IN_IMPORT]: Operators.InImport,
    [OperatorTypes.IS_NOT_IN_IMPORT]: Operators.NotInImport
  },
  [FilterTypes.IN_LIST]: {
    [OperatorTypes.IS_IN_LIST]: Operators.ListIsMember,
    [OperatorTypes.IS_NOT_IN_LIST]: Operators.ListIsNotMember
  },
  [FilterTypes.PAGE_VIEW]: {
    [OperatorTypes.HAS_PAGEVIEW_EQ]: Operators.PageViewEqual,
    [OperatorTypes.HAS_PAGEVIEW_CONTAINS]: Operators.PageViewContain,
    [OperatorTypes.HAS_PAGEVIEW_MATCHES_REGEX]: Operators.PageViewMatchRegex,
    [OperatorTypes.NOT_HAS_PAGEVIEW_EQ]: Operators.PageViewNotEqual,
    [OperatorTypes.NOT_HAS_PAGEVIEW_CONTAINS]: Operators.PageViewNotContain,
    [OperatorTypes.NOT_HAS_PAGEVIEW_MATCHES_REGEX]: Operators.PageViewNotMatchRegex
  },
  [FilterTypes.SURVEY_MONKEY]: {
    [OperatorTypes.HAS_NOT_RESPONDED_TO_SURVEY]: Operators.SurveyMonkeyNotHasResponded,
    [OperatorTypes.HAS_RESPONDED_TO_SURVEY]: Operators.SurveyMonkeyHasResponded
  },
  [FilterTypes.WEBINAR]: {
    [OperatorTypes.HAS_WEBINAR_ATTENDANCE]: Operators.WebinarHasAttended,
    [OperatorTypes.HAS_WEBINAR_REGISTRATION]: Operators.WebinarHasRegistered,
    [OperatorTypes.NOT_HAS_WEBINAR_ATTENDANCE]: Operators.WebinarNotHasAttended,
    [OperatorTypes.NOT_HAS_WEBINAR_REGISTRATION]: Operators.WebinarNotHasRegistered
  },
  [FilterTypes.WORKFLOW]: {
    [OperatorTypes.WORKFLOW_ACTIVE]: Operators.WorkflowActive,
    [OperatorTypes.WORKFLOW_COMPLETED]: Operators.WorkflowCompleted,
    [OperatorTypes.WORKFLOW_ENROLLED]: Operators.WorkflowEnrolled,
    [OperatorTypes.WORKFLOW_MET_GOAL]: Operators.WorkflowMetGoal,
    [OperatorTypes.WORKFLOW_NOT_ACTIVE]: Operators.WorkflowNotActive,
    [OperatorTypes.WORKFLOW_NOT_COMPLETED]: Operators.WorkflowNotCompleted,
    [OperatorTypes.WORKFLOW_NOT_ENROLLED]: Operators.WorkflowNotEnrolled,
    [OperatorTypes.WORKFLOW_NOT_MET_GOAL]: Operators.WorkflowNotMetGoal
  }
});
const historicalMappings = fromJS({
  [OperationTypes.MULTI_STRING]: {
    [OperatorTypes.IS_EQUAL_TO]: Operators.EverEqualAny,
    [OperatorTypes.IS_NOT_EQUAL_TO]: Operators.NeverEqualAny
  }
});
const refinementNegatedOperators = fromJS({
  DEFAULT: {
    [FilterTypes.FORM_SUBMISSION]: Operators.FormNotFilledOut,
    [FilterTypes.FORM_SUBMISSION_ON_PAGE]: Operators.FormNotFilledOut
  }
});
const byRefinement = (operatorType, propertyType, filter = {}) => {
  const operation = filter.operation || filter;
  const refinementProperties = [PRUNING_REFINE_BY, COALESCING_REFINE_BY];
  const refinements = refinementProperties.reduce((acc, refinement) => {
    if (operation[refinement]) {
      acc[refinement] = operation[refinement];
    }
    return acc;
  }, {});
  if (Object.prototype.hasOwnProperty.call(refinements, PRUNING_REFINE_BY) && refinements[PRUNING_REFINE_BY].type === ALL_HISTORY_REFINE_BY) {
    return historicalMappings.getIn([propertyType, operatorType]);
  }
  if (Object.prototype.hasOwnProperty.call(refinements, COALESCING_REFINE_BY) && typeof refinements[COALESCING_REFINE_BY] === 'object') {
    const {
      type,
      setType,
      maxOccurrences
    } = refinements[COALESCING_REFINE_BY];

    // refinement where occurrences is NONE
    const isUsingSetOccurrence = type === COALESCING_REFINE_BY_TYPE.SET_OCCURRENCES;
    const isSetToNone = setType === COALESCING_REFINE_BY_SET_TYPE.NONE || setType === COALESCING_REFINE_BY_SET_TYPE.NONE_EXCLUDE_EMPTY;
    const isNegateUsingSetOccurrence = isUsingSetOccurrence && isSetToNone;

    // refinement where max number is 0
    const isUsingNumOccurrence = type === COALESCING_REFINE_BY_TYPE.NUM_OCCURRENCES;
    const isSetToZero = maxOccurrences === 0;
    const isNegateUsingNumOccurrence = isUsingNumOccurrence && isSetToZero;
    if (isNegateUsingSetOccurrence || isNegateUsingNumOccurrence) {
      return refinementNegatedOperators.getIn([propertyType || 'DEFAULT', operatorType]);
    }
  }
  return undefined;
};
export function operatorTypeToOperator(operatorType, propertyType, filter = {}) {
  const {
    filterType
  } = filter;
  const operator = byRefinement(operatorType, propertyType, filter) || getIn([filterType, operatorType], filterTypeOperatorMapping) || getIn([propertyType, operatorType], propertyTypeOperatorMapping) || getIn([operatorType], defaultOperatorMapping);
  if (!operator) {
    invariant(false, `operator type "${operatorType}" is not supported`);
  }
  return operator;
}