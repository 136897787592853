import get from 'transmute/get';
import { WIDGET_CONFIG_TYPES } from './widgetSchema';
import getIn from 'transmute/getIn';
import { isRelationalReport, isSingleObjectReport } from 'reporting-data/tsTypes/reportTypes';
import { getTemplateKey } from 'reporting-data/report/reportGetters';
import { fromJS } from 'immutable';
export const getId = get('id');
export const getConfigType = getIn(['config', 'configType']);
export const getCustomWidgetType = getIn(['displayParams', 'customWidget', 'type']);
const isNoteWidget = report => getConfigType(report) === WIDGET_CONFIG_TYPES.NOTE;
const isIframeWidget = report => getConfigType(report) === WIDGET_CONFIG_TYPES.IFRAME;
export const getName = get('name');
export const isStaticWidget = report => isNoteWidget(report) || isIframeWidget(report);
export const getReportChartType = report => {
  if (!report) {
    return undefined;
  }
  if (isRelationalReport(report)) {
    return report.getIn(['reportDefinition', 'visual', 'type']);
  }
  if (isSingleObjectReport(report)) {
    return report.get('chartType');
  }
};
export const isReportTemplate = report => !!getTemplateKey(fromJS(report));