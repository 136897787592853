'use es6';

import http from 'hub-http/clients/apiClient';
import { Map as ImmutableMap } from 'immutable';
import fromJS from 'transmute/fromJS';
import toJS from 'transmute/toJS';
import { registerMutation, registerQuery } from 'data-fetching-client';
const DASHBOARDS_URL = 'dashboard/v2/dashboard';
export const ALL_DASHBOARDS_FIELD_NAME = 'dashboards';
export const DASHBOARD_FIELD_NAME = 'dashboard';
export const DASHBOARD_COUNT_FIELD_NAME = 'dashboardCount';
export const SAVE_WIDGETS_FIELD_NAME = 'saveWidgets';
const DASHBOARD_HYDRATION_PARAMS = {
  FAVORITE: 'FAVORITE',
  USER_PERMISSION_LEVEL: 'USER_PERMISSION_LEVEL'
};
const getAddWidgetsToDashboardUrl = dashboardId => `${DASHBOARDS_URL}/${dashboardId}/widgets`;

//NOTE: When reportIds is empty, the action applies to all reports (filtered by the query string)
export const addReportsToDashboard = ({
  reportIds,
  dashboardId,
  query
}) => http.post(`${DASHBOARDS_URL}/${dashboardId}/widget/from-reports`, {
  data: reportIds,
  query
});
export const createDashboard = ({
  dashboardName,
  userId,
  dashboardPermissionConfig,
  widgets = []
}) => http.post(DASHBOARDS_URL, {
  data: {
    name: dashboardName,
    type: 'CUSTOM',
    ownerId: userId,
    dashboardPermissionConfig,
    widgets
  }
});
const fetchAllDashboards = () => http.get(DASHBOARDS_URL, {
  query: {
    hydrate: [DASHBOARD_HYDRATION_PARAMS.FAVORITE, DASHBOARD_HYDRATION_PARAMS.USER_PERMISSION_LEVEL]
  }
});
export const fetchSingleDashboard = (dashboardId, query) => http.get(`${DASHBOARDS_URL}/${dashboardId}`, {
  query
});
const fetchAllDashboardWidgetCounts = () => http.get(`${DASHBOARDS_URL}/widget-counts`);
const saveWidgetsToDashboard = (widgetReports, dashboardId) => http.post(getAddWidgetsToDashboardUrl(dashboardId), {
  data: widgetReports
}).then(fromJS);
export const FETCH_DASHBOARD_COUNT = registerQuery({
  fieldName: DASHBOARD_COUNT_FIELD_NAME,
  fetcher() {
    return http.get(`${DASHBOARDS_URL}/count`);
  }
});

// @returns {Map(
//    allCustomDashboard: Number,
//    allDashboards: Number,
//    dashboards: Map() A Map of dashboards, keyed by the dashboardId,
//    limit: Number,
//    offset: Number,
//    total: Number,
//    totalDashboardsInPages: Number
// )}
export const FETCH_ALL_DASHBOARDS = registerQuery({
  fieldName: ALL_DASHBOARDS_FIELD_NAME,
  fetcher() {
    return Promise.all([fetchAllDashboards(), fetchAllDashboardWidgetCounts()]).then(([dashboardsResponse, widgetCountsResponse]) => {
      const dashboards = dashboardsResponse.dashboards;
      const dashboardsById = dashboards.reduce((acc, dashboard) => {
        const widgetCount = widgetCountsResponse.find(widgetObj => widgetObj.id === dashboard.id);
        return acc.set(dashboard.id, fromJS(Object.assign({}, dashboard, {
          widgetCount: widgetCount ? widgetCount.widgetCount : 0
        })));
      }, ImmutableMap());
      return fromJS(Object.assign({}, dashboardsResponse, {
        dashboards: dashboardsById
      }));
    });
  }
});

// @dashboardName {String} Name of the new dashboard
// @userId {Number} Id of the user who creates the dashboard
// @permissionConfig { {accessClassification: String, permissionGrants: Array, permissionLevel: String}}
// @returns {Map} The new dashboard object & the permissionConfig used to create it
export const CREATE_DASHBOARD = registerMutation({
  fieldName: DASHBOARD_FIELD_NAME,
  args: ['dashboardName', 'userId', 'permissionConfig'],
  fetcher({
    dashboardName,
    userId,
    permissionConfig
  }) {
    const newDashboard = {
      dashboardName,
      type: 'CUSTOM',
      widgets: [],
      ownerId: userId,
      dashboardPermissionConfig: toJS(permissionConfig)
    };
    return createDashboard(newDashboard).then(dashboard => fromJS({
      dashboard,
      permissionConfig
    }));
  }
});
export const SAVE_WIDGETS_TO_DASHBOARD = registerMutation({
  fieldName: SAVE_WIDGETS_FIELD_NAME,
  args: ['widgetReports'],
  fetcher({
    widgetReports,
    dashboardId
  }) {
    return saveWidgetsToDashboard(widgetReports, dashboardId);
  }
});