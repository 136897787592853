import { PORTAL_TZ_ALIGNED } from '../timestamps/TimestampTypes';
import { List, Record } from 'immutable';
import { STRING } from 'customer-data-objects/property/PropertyTypes';
import { TEXT } from 'customer-data-objects/property/PropertyFieldTypes';
import PropertyOptionRecord from 'customer-data-objects/property/PropertyOptionRecord';
const FORMATTED = 'formatted';
const DSFieldRecord = Record({
  accessLevel: undefined,
  dateDisplayHint: undefined,
  description: '',
  disabled: false,
  displayName: '',
  displayOrder: 0,
  displayType: null,
  externalOptions: false,
  externalOptionsReferenceType: null,
  fieldType: TEXT,
  groupName: null,
  hidden: false,
  hubspotDefined: false,
  isHiddenFromSelect: false,
  label: '',
  metadata: null,
  name: '',
  numberDisplayHint: FORMATTED,
  numberRefinement: false,
  options: null,
  placeholder: '',
  referencedObjectType: null,
  refinement: false,
  showCurrencySymbol: false,
  timestampType: PORTAL_TZ_ALIGNED,
  tooltip: '',
  type: STRING,
  url: null,
  wrappedObject: null
}, 'DSFieldRecord');
DSFieldRecord.fromJS = json => {
  if ('options' in json && Array.isArray(json.options)) {
    const options = json.options.map(option => PropertyOptionRecord(option));
    json.options = List(options);
  }
  return DSFieldRecord(json);
};
DSFieldRecord.isDSFieldRecord = field => field instanceof DSFieldRecord;
export default DSFieldRecord;