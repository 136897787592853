import I18n from 'I18n';
// @ts-expect-error Untyped dependency
import formatShortMonthYear from 'I18n/utils/formatShortMonthYear';

// @ts-expect-error Untyped dependency
import * as SimpleDate from 'UIComponents/core/SimpleDate';
import { TODAY, YESTERDAY, THIS_WEEK, LAST_WEEK, THIS_MONTH, LAST_MONTH, LAST_THIRTY_DAYS, LAST_THREE_MONTHS, THIS_QUARTER, LAST_QUARTER, THIS_YEAR, LAST_YEAR } from 'UIComponents/dates/dateRangePresets';
import { DATE_FORMAT, DATE_RANGE_TYPES, PROPERTY_FILTER_OPERATION_CONSTANTS, RELIABLE_DATE_MINIMUM, IN_LAST_TIME_UNIT_OP, IN_THIS_TIME_UNIT_OP } from '../../constants/journeyDateRangeConstants';
import { RANGE_TYPES, ROLLING_DATE_TYPES } from 'reporting-data/constants/dateRangeTypes';
import { DAY, MONTH, QUARTER, WEEK, YEAR } from 'reporting-data/constants/frequency';

// @ts-expect-error Untyped dependency
import makeDateRangeByType from 'reporting-data/lib/makeDateRangeByType';
export function intervalFromDateInput(dateInfo) {
  if (dateInfo.type === DATE_RANGE_TYPES.STATIC) {
    const {
      startDate,
      endDate
    } = dateInfo.dateInput;
    return {
      dateFrom: startDate ? SimpleDate.toFormattedString(startDate, DATE_FORMAT) : undefined,
      dateTo: endDate ? SimpleDate.toFormattedString(endDate, DATE_FORMAT) : undefined
    };
  }
  return {
    propertyFilterOperation: Object.assign({}, PROPERTY_FILTER_OPERATION_CONSTANTS, {
      timeUnitCount: dateInfo.dateInput
    })
  };
}
export function dateRangeFromInterval({
  dateFrom,
  dateTo
}) {
  return {
    startDate: dateFrom ? SimpleDate.fromMoment(I18n.moment(dateFrom, DATE_FORMAT)) : null,
    endDate: dateTo ? SimpleDate.fromMoment(I18n.moment(dateTo, DATE_FORMAT)) : null
  };
}
export const getDaysFromDate = (date, days) => SimpleDate.fromMoment(SimpleDate.toMoment(date).add(days, 'day'));
export const dateIsOutsideBounds = (maxRange, {
  startDate,
  endDate
}) => {
  if (!startDate || !endDate) {
    return false;
  }
  return SimpleDate.compare(startDate, getDaysFromDate(endDate, -maxRange)) < 0;
};
export const getTodaysDate = () => SimpleDate.now();
export const getDateRangeBounds = (maxRange, {
  startDate,
  endDate
}) => {
  if (!startDate && !endDate) {
    // No dates set yet, only enforce minimum reliable date
    return {
      min: RELIABLE_DATE_MINIMUM
    };
  }
  return startDate ? {
    // start date exists, enforce reliable minimum and max as valid date bound
    min: RELIABLE_DATE_MINIMUM,
    max: getDaysFromDate(startDate, maxRange)
  } : {
    // only end date exists, enforce minimum as reliable minimum or valid
    // date range bound (whichever comes later)
    min: SimpleDate.maxDate([RELIABLE_DATE_MINIMUM, getDaysFromDate(endDate, maxRange * -1)])
  };
};
const alwaysTrueCondition = () => true;
export const STATIC_DATE_PRESETS = [{
  preset: TODAY,
  condition: alwaysTrueCondition
}, {
  preset: YESTERDAY,
  condition: alwaysTrueCondition
}, {
  preset: THIS_WEEK,
  condition: alwaysTrueCondition
}, {
  preset: LAST_WEEK,
  condition: alwaysTrueCondition
}, {
  preset: THIS_MONTH,
  condition: alwaysTrueCondition
}, {
  preset: LAST_MONTH,
  condition: alwaysTrueCondition
}, {
  preset: LAST_THIRTY_DAYS,
  condition: alwaysTrueCondition
}, {
  preset: LAST_THREE_MONTHS,
  condition: maxDays => maxDays >= 93
}, {
  preset: THIS_QUARTER,
  condition: maxDays => !dateIsOutsideBounds(maxDays, {
    startDate: SimpleDate.fromMoment(I18n.moment().startOf('quarter')),
    endDate: getTodaysDate()
  })
}, {
  preset: LAST_QUARTER,
  condition: maxDays => maxDays >= 91
}, {
  preset: THIS_YEAR,
  condition: maxDays => !dateIsOutsideBounds(maxDays, {
    startDate: SimpleDate.fromMoment(I18n.moment().startOf('year')),
    endDate: getTodaysDate()
  })
}, {
  preset: LAST_YEAR,
  condition: maxDays => maxDays >= 365
}];
export const getApplicablePresets = maxDays => Object.values(STATIC_DATE_PRESETS).filter(preset => preset.condition(maxDays)).map(presetObject => presetObject.preset);
export const dateIntervalIsComplete = maybeDateInterval => {
  return !!(maybeDateInterval.dateFrom && maybeDateInterval.dateTo) || !!(maybeDateInterval.propertyFilterOperation && maybeDateInterval.propertyFilterOperation.timeUnitCount);
};
export const getRollingDateRangeOptions = maxDays => Object.values(ROLLING_DATE_TYPES).map(days => ({
  text: I18n.text(`settingLabels.dateRange.journey.rollingOptions.${days.toString()}`),
  value: days,
  disabled: days > maxDays
}));
export const getEventCountDateRange = dateInterval => {
  const dateRange = dateRangeFromInterval(dateInterval);
  const today = getTodaysDate();
  const isStaticDate = !!(dateInterval.dateFrom && dateInterval.dateTo);
  const isRollingDate = !!dateInterval.propertyFilterOperation;
  return {
    startDate: formatShortMonthYear(isStaticDate ? dateRange.startDate : isRollingDate ? getDaysFromDate(today, -dateInterval.propertyFilterOperation.timeUnitCount) : undefined),
    endDate: formatShortMonthYear(isStaticDate ? dateRange.endDate : today)
  };
};
export const closestRollingDateRange = maxDays => {
  return Object.values(ROLLING_DATE_TYPES).reduce((prev, curr) => curr > maxDays ? prev : curr);
};

// Utils that are required for new journey date range picker

export const getRollingDaysOptions = maxDays => Object.values(ROLLING_DATE_TYPES).map(days => ({
  help: I18n.text(`DateRangePicker.ROLLING_DATE_TYPES.help.${days}`),
  text: I18n.text(`DateRangePicker.ROLLING_DATE_TYPES.text.${days}`),
  value: days,
  disabled: days > maxDays
}));
const RangeTypePresets = {
  THIS_DAY: {
    condition: alwaysTrueCondition,
    timeUnit: DAY,
    operator: IN_THIS_TIME_UNIT_OP
  },
  LAST_DAY: {
    condition: alwaysTrueCondition,
    timeUnit: DAY,
    operator: IN_LAST_TIME_UNIT_OP
  },
  THIS_WEEK: {
    condition: alwaysTrueCondition,
    timeUnit: WEEK,
    operator: IN_THIS_TIME_UNIT_OP
  },
  LAST_WEEK: {
    condition: alwaysTrueCondition,
    timeUnit: WEEK,
    operator: IN_LAST_TIME_UNIT_OP
  },
  THIS_MONTH: {
    condition: alwaysTrueCondition,
    timeUnit: MONTH,
    operator: IN_THIS_TIME_UNIT_OP
  },
  LAST_MONTH: {
    condition: alwaysTrueCondition,
    timeUnit: MONTH,
    operator: IN_LAST_TIME_UNIT_OP
  },
  THIS_QUARTER: {
    condition: maxDays => !dateIsOutsideBounds(maxDays, {
      startDate: SimpleDate.fromMoment(I18n.moment().startOf('quarter')),
      endDate: getTodaysDate()
    }),
    timeUnit: QUARTER,
    operator: IN_THIS_TIME_UNIT_OP
  },
  LAST_QUARTER: {
    condition: maxDays => maxDays >= 91,
    timeUnit: QUARTER,
    operator: IN_LAST_TIME_UNIT_OP
  },
  THIS_YEAR: {
    condition: maxDays => !dateIsOutsideBounds(maxDays, {
      startDate: SimpleDate.fromMoment(I18n.moment().startOf('year')),
      endDate: getTodaysDate()
    }),
    timeUnit: YEAR,
    operator: IN_THIS_TIME_UNIT_OP
  },
  LAST_YEAR: {
    condition: maxDays => maxDays >= 365,
    timeUnit: YEAR,
    operator: IN_LAST_TIME_UNIT_OP
  }
};
const RangeTypePresetKeys = Object.keys(RangeTypePresets);
export const getJourneyRangeTypeOverrides = maxDays => {
  const allowedRangeTypes = RangeTypePresetKeys.filter(preset => RangeTypePresets[preset].condition(maxDays));
  return [...allowedRangeTypes, RANGE_TYPES.ROLLING, RANGE_TYPES.CUSTOM].map(rangeTypeKey => ({
    rangeTypeKey,
    text: `DateRangePicker.RANGE_TYPES.text.${rangeTypeKey}`,
    help: `DateRangePicker.RANGE_TYPES.help.${rangeTypeKey}`
  }));
};
export const getJourneyPropertyFilter = rangeType => {
  const {
    timeUnit,
    operator
  } = RangeTypePresets[rangeType];
  return {
    propertyFilterOperation: Object.assign({}, PROPERTY_FILTER_OPERATION_CONSTANTS, {
      operator,
      timeUnit,
      operatorName: operator
    })
  };
};
export const getEventDateItervalFromDateRange = dateRange => {
  const {
    rangeType,
    startDate,
    endDate,
    rollingDays
  } = dateRange;
  if (RangeTypePresetKeys.includes(rangeType)) {
    return getJourneyPropertyFilter(rangeType);
  } else if (rangeType === RANGE_TYPES.ROLLING) {
    return {
      propertyFilterOperation: Object.assign({}, PROPERTY_FILTER_OPERATION_CONSTANTS, {
        timeUnitCount: rollingDays
      })
    };
  } else if (rangeType === RANGE_TYPES.CUSTOM) {
    return {
      dateFrom: startDate,
      dateTo: endDate
    };
  }
};
export const getDateRangeFromEventDateInterval = eventDateInterval => {
  if (eventDateInterval.propertyFilterOperation) {
    const {
      operator,
      timeUnit,
      timeUnitCount
    } = eventDateInterval.propertyFilterOperation;
    const rangeType = RangeTypePresetKeys.find(preset => {
      const {
        operator: presetOperator,
        timeUnit: presetTimeUnit
      } = RangeTypePresets[preset];
      return operator === presetOperator && timeUnit === presetTimeUnit && timeUnitCount === 1;
    });
    const dateRange = rangeType ? {
      rangeType
    } : {
      rangeType: RANGE_TYPES.ROLLING,
      rollingDays: timeUnitCount
    };
    return makeDateRangeByType(dateRange);
  }
  return makeDateRangeByType({
    rangeType: 'CUSTOM',
    startDate: eventDateInterval.dateFrom,
    endDate: eventDateInterval.dateTo
  });
};
export const getSimpleDatesfromDateRange = daterange => {
  const {
    startDate: from,
    endDate: to
  } = daterange;
  return {
    startDate: SimpleDate.fromMoment(I18n.moment(from, DATE_FORMAT)),
    endDate: SimpleDate.fromMoment(I18n.moment(to, DATE_FORMAT))
  };
};