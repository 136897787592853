/* hs-eslint ignored failing-rules */

import http from 'hub-http/clients/apiClient';
import { fromJS } from 'immutable';
import emptyFunction from 'react-utils/emptyFunction';
import { POLL_RATE } from 'reporting-data/constants/snowflakeQuery';
import { Dataset } from 'reporting-data/v2/dataset/datasetRecords';
import { AsyncQueryCancelled, AsyncQueryError, AsyncQueryFailed, AsyncQueryResponse, AsyncQueryStatus, AsyncQueryTimeout, DeprecatedDataSourcesError, MaximumColumnsCountExceededError } from './resolve-records';
import { setRequestCache, isValidRequestInCache, getResponseFromRequestCache, invalidateRequestInCache } from './request-cache';

// @ts-expect-error migrate upstream file
import { reportHasDeprecatedDataSources } from '../utils/report-deprecation-utils';
import { doesColumnCountExceedExpandedMaximum } from '../utils/report-utils';
const toDataset = res => Dataset({
  paginationSize: res.getIn(['queryResponse', 'total']),
  data: res.getIn(['queryResponse', 'data']),
  dataAge: res.get('dataAge'),
  queryId: res.get('queryId')
});
export const toAsyncQueryResponse = res => {
  const id = res.get('queryId');
  const status = res.get('queryStatus');
  const response = res.get('queryResponse');
  const dataset = response ? toDataset(res) : undefined;
  return AsyncQueryResponse({
    id,
    status,
    dataset
  });
};

/**
 * Kick off a new async query. Might return a successful response if the query
 * finishes quickly.
 */
export const request = (reportWithOptions, reportId) => {
  const data = reportWithOptions.get('reportId') ? Object.assign({}, reportWithOptions.toJS()) : Object.assign({}, reportWithOptions.toJS(), {
    reportId
  });
  return http.post('sql-reporting-query/v1/report-builder/asyncQuery', {
    data
  }).then(fromJS).then(toAsyncQueryResponse);
};

/**
 * Get the result of an existing async query, such as a running query. Useful
 * for polling for a result.
 */
export const getResult = (reportWithOptions, queryId) => http.post(`sql-reporting-query/v1/report-builder/result/${queryId}`, {
  data: reportWithOptions.toJS()
}).then(fromJS).then(toAsyncQueryResponse);

/**
 * Retrieve a report dataset by report id. If the option "fetchFromCache" is
 * false, this will request fresh data, otherwise we will try to use the cache
 * if possible.
 */
export const retrieve = (reportWithOptions, reportId) => http.get(`sql-reporting-query/v1/report-builder/asyncQuery/${reportId}`, {
  query: reportWithOptions.reportOptions.toJS()
}).then(fromJS).then(toAsyncQueryResponse);

/**
 * Cancel an existing async query by query id. Cached async query response will
 * result in the status "CANCELLED".
 * @param {string} queryId
 * @returns {Promise}
 */
export const cancel = queryId => http.delete(`sql-reporting-query/v1/report-builder/query/${queryId}`);

/**
 * Perform a synchronous query for a report dataset. Only recommended for
 * debugging purposes.
 * @param {ReportWithOptions} reportWithOptions
 * @returns {Promise<Dataset>}
 */
export const query = reportWithOptions => http.post('sql-reporting/v1/report-builder/query', {
  data: reportWithOptions.toJS()
}).then(fromJS).then(toDataset);

/**
 * Start an async query, then recursively poll async query results until complete.
 * @param {ReportWithOptions} reportWithOptions
 * @param {{
 *     onTick: function,
 *     reportId: string,
 *     lastQuery: AsyncQueryResponse
 * }} params an object containing an onTick callback to be called on every tick,
 * the reportId to query for (if applicable), and the previous query, which
 * can be used to start the query outside this function (also used in recursion)
 * @returns {Promise<Dataset>}
 */
const poll = (reportWithOptions, params = {}) => new Promise((resolvePromise, rejectPromise) => {
  const {
    onTick = emptyFunction,
    reportId: maybeReportId,
    lastQuery: {
      status,
      dataset,
      id
    } = AsyncQueryResponse({})
  } = params;
  const tick = nextQuery => {
    const cancelled = onTick(nextQuery, reportWithOptions);
    if (!cancelled) {
      poll(reportWithOptions, Object.assign({}, params, {
        lastQuery: nextQuery
      })).then(resolvePromise, rejectPromise).catch(rejectPromise);
    }
  };
  switch (status) {
    // no status means we need to request a new query
    case undefined:
      {
        // Allow for an opt out of fetching from the cache for a persisted report but default to use cache
        const fetchFromCache = reportWithOptions.getIn(['reportOptions', 'fetchFromCache']) !== undefined ? reportWithOptions.getIn(['reportOptions', 'fetchFromCache']) : true;
        const requester = maybeReportId && fetchFromCache ? () => retrieve(reportWithOptions, maybeReportId) : () => request(reportWithOptions, maybeReportId);
        requester().then(tick, rejectPromise).catch(rejectPromise);
        break;
      }
    case AsyncQueryStatus.RUNNING:
      if (id) {
        setTimeout(() => {
          getResult(reportWithOptions, id).then(tick, rejectPromise).catch(rejectPromise);
        }, POLL_RATE);
      }
      break;
    case AsyncQueryStatus.SUCCESS:
      if (dataset) {
        resolvePromise(dataset);
      }
      break;
    case AsyncQueryStatus.FAILED:
      invalidateRequestInCache(reportWithOptions);
      rejectPromise(new AsyncQueryFailed(`Async query ${id} failed`));
      break;
    case AsyncQueryStatus.CANCELLED:
      invalidateRequestInCache(reportWithOptions);
      rejectPromise(new AsyncQueryCancelled(`Async query ${id} was cancelled`));
      break;
    case AsyncQueryStatus.TIMEOUT:
      invalidateRequestInCache(reportWithOptions);
      rejectPromise(new AsyncQueryTimeout(`Async query ${id} timed out`));
      break;
    case AsyncQueryStatus.UNKNOWN:
    default:
      invalidateRequestInCache(reportWithOptions);
      rejectPromise(new AsyncQueryError(`Async query ${id} errored with status ${status}`));
  }
});
const checkBeforeResolve = reportWithOptions => {
  /*
   * This method is copied to useQueryRaaS/checkBeforeResolve to ensure this validation
   * for RaaSBE resolve.  Both of these methods will exists during migration. Please add
   * any changes here to queryQueryRaaS method.
   */
  if (doesColumnCountExceedExpandedMaximum(reportWithOptions.reportDefinition)) {
    return new MaximumColumnsCountExceededError('Report has too many columns');
  }
  if (reportHasDeprecatedDataSources(reportWithOptions.reportDefinition)) {
    return new DeprecatedDataSourcesError('Report has deprecated data sources');
  }
  return undefined;
};

/**
 * Abstract away async query logic into a promise which returns a dataset.
 */
export const asyncQuery = (reportWithOptions, params) => {
  const maybeError = checkBeforeResolve(reportWithOptions);
  if (maybeError) {
    return Promise.reject(maybeError);
  }
  // if request does not exist in the cache write to it
  if (!isValidRequestInCache(reportWithOptions)) {
    setRequestCache(reportWithOptions, poll(reportWithOptions, params));
  }
  return getResponseFromRequestCache(reportWithOptions);
};