import { fetchPropertyMetadataEntries } from './frameworkBuilderReadApi';
import { isEarlyCacheReturnEnabled, makePropertyMetadataEntriesFetchKey } from 'framework-data-schema-quick-fetch';
import { deepFreeze } from '../cache/deepFreeze';
import { createPersistedAsyncCache } from '../cache/createPersistedAsyncCache';
import { Metrics } from '../metrics';
import { makeLoadFailed } from '../utils/makeLoadFailed';
import { normalizeCustomIdentifier } from '../utils/normalizeCustomIdentifier';
import { wrapPromise } from 'persist-promise/wrapPromise';
const segmentKey = key => {
  const match = key.match(makePropertyMetadataEntriesFetchKey({
    portalId: '.*',
    frameworkTypeIdentifier: '(.*)',
    appSettingName: '(.*)'
  }));
  if (!match || !match[1] || !match[2]) {
    return null;
  }
  const [__, identifier, appSettingName] = match;
  return `${normalizeCustomIdentifier(identifier)}-${appSettingName}`;
};
const propertyMetadataPersistedPromise = wrapPromise({
  namespace: 'FDSR',
  entityName: 'propertyMetadata',
  deepFreeze: true,
  toCacheKey: makePropertyMetadataEntriesFetchKey,
  fetch: fetchPropertyMetadataEntries,
  metricsConfig: {
    convertKeyToMetricsDimension: segmentKey,
    enablePatchDiffing: true
  }
});
const defaultAsyncPropertyMetadataRequestCache = createPersistedAsyncCache({
  cacheName: 'propertyMetadata',
  segmentKey
});
export const makePropertyMetadataClient = ({
  httpClient,
  usePersistPromise,
  persistedPromise = propertyMetadataPersistedPromise,
  requestCache = defaultAsyncPropertyMetadataRequestCache,
  toRequestCacheKey = makePropertyMetadataEntriesFetchKey
}) => {
  const client = {
    /**
     * Prints debug info to the console.
     */
    debug: () => {
      if (usePersistPromise) {
        persistedPromise.debug();
      } else {
        requestCache.printDebug();
      }
    },
    /**
     * Clears internal cache state.
     *
     * @returns A promise which resolves when state is clear.
     */
    clearCache: async () => {
      await Promise.all([requestCache.clear(), persistedPromise.clearCache()]);
    },
    /**
     * Gets all property metadata entries for the specified app setting under the specified framework type.
     *
     * @param options.appSettingName The app setting to fetch entries for.
     * @param options.frameworkTypeIdentifier The framework type to fetch entries for under the app setting.
     * @param options.__isComposed For internal metrics tracking purposes only. Set to true when called within another client method.
     * @returns A promise which resolves to the property metadata entries, or null if the data could not be found.
     */
    get: ({
      frameworkTypeIdentifier,
      appSettingName,
      __isComposed = false
    }) => {
      if (!__isComposed) {
        Metrics.counter('propertyMetadata.get', {
          usePersistPromise: `${usePersistPromise}`
        }).increment();
      }
      if (usePersistPromise) {
        return persistedPromise.makeFetchWithOpts({
          allowEagerCacheReturn: isEarlyCacheReturnEnabled()
        })({
          frameworkTypeIdentifier,
          appSettingName,
          httpClient
        });
      }
      const cachedValue = requestCache.readThrough({
        cacheKey: toRequestCacheKey({
          frameworkTypeIdentifier,
          appSettingName
        }),
        loadValue: () => fetchPropertyMetadataEntries({
          frameworkTypeIdentifier,
          appSettingName,
          httpClient
        }).then(deepFreeze)
      });
      return cachedValue === null ? makeLoadFailed() : cachedValue;
    }
  };
  return Promise.resolve(client);
};