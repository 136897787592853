import isEmpty from 'transmute/isEmpty';
import { List, fromJS } from 'immutable';
import { DSFieldRecord } from 'customer-data-filters-ui/public/core';
// @ts-expect-error ts-migrate(2307)
import * as Operators from 'customer-data-filters/filterQueryFormat/operator/Operators';
import RollingDateConfig from 'customer-data-filters/filterQueryFormat/rollingDates/RollingDateConfig';
import { getFullyQualifiedName } from './schema/dataSourceMetadataSchema';
import { getPropertyFromDataSourceByPropertyRecord } from './schema/propertySchema';
export const convertPropertyToDSFieldRecordWithScriptedField = property => {
  // Formats property in expected DSFieldRecord query format, and
  // explicitly set "scripted" field within the "wrappedObject" field of the DSFieldRecord.
  return DSFieldRecord(fromJS(Object.assign({}, property.toJS(), {
    wrappedObject: {
      scripted: Boolean(property.get('scripted'))
    }
  })));
};
// converts an operator in Filter Query Format to the format used in our redux store and local storage
export const convertToDashboardFilter = ({
  dataSource,
  dataSourceName,
  operator
}) => {
  const operatorName = operator.get('name');
  const value = operator.get('value');
  const property = operator.get('field');
  return Object.assign({
    dataSource,
    dataSourceName,
    propertyName: property.get('name'),
    propertyType: property.get('type'),
    operator: operatorName,
    value
  }, operatorName === Operators.InRange.toString() && {
    highValue: operator.get('highValue')
  });
};

// converts stored dashboard filter data to the format (Filter Query Format) used by customer-data-filters components & conversion functions
export const getFilterQueryFormatOperator = (dashboardFilter, dataSourceProperties) => {
  const {
    operator,
    propertyName,
    value
  } = dashboardFilter;
  const Operator = Operators[operator];
  const propertyData = dataSourceProperties[propertyName];
  if (!propertyData) {
    return null;
  }
  const field = DSFieldRecord(propertyData);
  const modifiedValue = Operator === Operators.InRollingDateRange ? RollingDateConfig(value) : Array.isArray(value) && !isEmpty(value) ? List(value) : value;
  const operatorRecord = Operator === Operators.InRange ? Operator.of(field, modifiedValue, dashboardFilter.highValue) : Operator.of(field, modifiedValue);
  return operatorRecord;
};

/** Expands one operator into multiple filter operators for a quick filter group */
export const convertSingleOperatorToGroupOperators = ({
  singlePropertyOperator,
  quickFilterGroupProperties,
  dataSourcesMetadata,
  dataSourcesProperties
}) => {
  const operatorName = singlePropertyOperator.get('name');
  const value = singlePropertyOperator.get('value');
  const Operator = Operators[operatorName];
  const highValue = Operator === Operators.InRange ? singlePropertyOperator.get('highValue') : null;
  const quickFilterGroupOperators = quickFilterGroupProperties.map(({
    name,
    dataSourceId
  }) => {
    const property = getPropertyFromDataSourceByPropertyRecord(dataSourcesProperties, dataSourceId, name);
    return {
      name,
      dataSourceId,
      property
    };
  }).filter(p => p.property != null).map(({
    dataSourceId,
    property
  }) => {
    const dataSourceMetadata = dataSourcesMetadata[dataSourceId];
    const dataSourceName = getFullyQualifiedName(dataSourceMetadata);
    const modifiedValue = Operator === Operators.InRollingDateRange ? RollingDateConfig(value) : Array.isArray(value) && !isEmpty(value) ? List(value) : value;
    const operatorRecord = Operator === Operators.InRange ? Operator.of(property, modifiedValue, highValue) : Operator.of(property, modifiedValue);
    return {
      dataSource: dataSourceId,
      dataSourceName,
      operator: operatorRecord
    };
  });
  return quickFilterGroupOperators;
};
export const convertGroupOperatorsToDashboardFilters = ({
  quickFilterGroupOperators,
  systemGenerated,
  quickFilterGroupName
}) => {
  const quickFilterGroupDashboardFilters = quickFilterGroupOperators.map(({
    dataSource,
    dataSourceName,
    operator
  }) => {
    const dashboardFilter = convertToDashboardFilter({
      dataSource,
      dataSourceName,
      operator,
      systemGenerated,
      quickFilterGroupName
    });
    return dashboardFilter;
  });
  return quickFilterGroupDashboardFilters;
};