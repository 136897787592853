/* hs-eslint ignored failing-rules */
/* eslint-disable no-prototype-builtins */

'use es6';

import { getCustomWidgetType } from 'reporting-data/report/reportGetters';
import { isSalesQuotaPipelineFilter } from 'reporting-enablement/components/reportEditors/QuickFilterEditor/quickFilterEditorUtils';
import { QuotasMutator, DealCountTableMutator, WaterfallChartTypeMutator, LeadResponseMetricsMutator, FunnelPipelineDealStageMutator, timeInDealStageMetricsMutator, TicketTimeInPipelineMutator, StaticGoalMutator, ChartMetricMutator, ChartMetricsMutator, TooltipMutator, DrilldownMutator, FrequencyMutator, CompareMutator, ColorThemeMutator, FilteredBreakdownsMutator, ChartTypeMutator, PipelineSnapshotsDimensionsMutator, EventSearchSelectorMutator, SubAppKeyMutator, HideTotalsMutator } from '../../config/mutators/configMutators';
import { SUB_APPS } from 'reporting-data/tsTypes/subAppTypes';
import { SecondDateRangeMutator, getFilterMutator, CustomFiltersMutator, SalesQuotaPipelineMutator, DateRangeMutator, AnalyticsViewFilterMutator, JourneyDateRangeMutator, ShowDropoffMutator, JourneyFilterMutator, JourneyAnonVisitorMutator, RelationalCustomFiltersMutator } from '../../config/mutators/filterMutators';
import { JourneyStagesMutator } from '../../config/mutators/journeyMutators';
import { getAllUsableControls } from '../../utils/controls';
import { getContextFieldForEditor, getQuickFilterSchemasForReport } from '../../utils/editorSchemaHelpers';
import { isJourneyReport } from '../../utils/salesAnalyticsHelpers';
import CustomFiltersControl from '../../components/controls/CustomFiltersControl';
import GoalViewControl from '../../components/controls/GoalViewControl';
import { SecondaryDateRangeControl } from '../../components/controls/SecondaryDateRangeControl';
import StageOrderControl from '../../components/controls/StageOrderControl';
import { DateRangeControl } from '../../components/controls/DateRangeControl';
import { FrequencyControl } from '../../components/controls/FrequencyControl';
import { CompareControl } from '../../components/controls/CompareControl';
import MultiMetricControl from '../../components/controls/MultiMetricControl';
import ColorThemeControl from '../../components/reportCard/controls/ColorThemeControl';
import AnalyticsViewControl from '../../components/controls/AnalyticsViewControl';
import ChartTypeControl from '../../components/reportCard/controls/ChartTypeControl';
import JourneyDateRangeControl from '../../components/controls/JourneyDateRangeControl';
import ShowDropoffControl from '../../components/reportCard/controls/ShowDropoffControl';
import JourneyListFilterControl from '../../components/reportCard/controls/JourneyListFilterControl';
import JourneyAnonVisitorControl from '../../components/reportCard/controls/JourneyAnonVisitorControl';
import JourneyPropertyFilterControl from '../../components/reportCard/controls/JourneyPropertyFilterControl';
import SnowflakeFiltersControl from '../../components/controls/SnowflakeFiltersControl';
import EventSearchSelector from '../../components/controls/EventSearchSelector';
const subAppMutators = {
  [SUB_APPS.salesBySource]: [DealCountTableMutator],
  [SUB_APPS.weightedPipelineForecast]: [DealCountTableMutator],
  [SUB_APPS.averageSalesPrice]: [DealCountTableMutator],
  [SUB_APPS.timeInDealStage]: [timeInDealStageMetricsMutator, DealCountTableMutator],
  [SUB_APPS.dealVelocity]: [DealCountTableMutator],
  [SUB_APPS.leadResponseTime]: [LeadResponseMetricsMutator],
  [SUB_APPS.waterfall]: [WaterfallChartTypeMutator, SubAppKeyMutator],
  [SUB_APPS.changeHistoryDealList]: [SubAppKeyMutator, HideTotalsMutator],
  [SUB_APPS.prospectEngagement]: [StaticGoalMutator, TooltipMutator],
  [SUB_APPS.leadFunnel]: [StaticGoalMutator, DrilldownMutator, TooltipMutator],
  [SUB_APPS.salesVelocity]: [StaticGoalMutator, TooltipMutator],
  [SUB_APPS.ticketTimeInPipeline]: [TicketTimeInPipelineMutator],
  [SUB_APPS.ads]: [ChartMetricMutator, FilteredBreakdownsMutator],
  [SUB_APPS.leadCapture]: [ChartMetricMutator, FilteredBreakdownsMutator],
  [SUB_APPS.marketingEmails]: [ChartMetricMutator],
  [SUB_APPS.sources]: [ChartMetricMutator, FilteredBreakdownsMutator],
  [SUB_APPS.pages]: [ChartMetricMutator, FilteredBreakdownsMutator],
  [SUB_APPS.utmParameters]: [ChartMetricMutator, FilteredBreakdownsMutator],
  [SUB_APPS.deviceTypes]: [ChartMetricMutator, FilteredBreakdownsMutator],
  [SUB_APPS.geolocation]: [ChartMetricMutator, FilteredBreakdownsMutator],
  [SUB_APPS.browsers]: [ChartMetricMutator, FilteredBreakdownsMutator],
  [SUB_APPS.pipelineSnapshots]: [PipelineSnapshotsDimensionsMutator],
  [SUB_APPS.sms]: [EventSearchSelectorMutator]
};
const controlMutators = {
  [ChartTypeControl]: () => ChartTypeMutator,
  [ColorThemeControl]: () => ColorThemeMutator,
  [CustomFiltersControl]: () => CustomFiltersMutator,
  [DateRangeControl]: () => DateRangeMutator,
  [FrequencyControl]: () => FrequencyMutator,
  [CompareControl]: () => CompareMutator,
  [GoalViewControl]: () => QuotasMutator,
  [JourneyDateRangeControl]: () => JourneyDateRangeMutator,
  [MultiMetricControl]: () => ChartMetricsMutator,
  [SecondaryDateRangeControl]: () => SecondDateRangeMutator,
  [ShowDropoffControl]: () => ShowDropoffMutator,
  [JourneyAnonVisitorControl]: () => JourneyAnonVisitorMutator,
  [JourneyListFilterControl]: () => JourneyFilterMutator,
  [JourneyPropertyFilterControl]: () => JourneyFilterMutator,
  [StageOrderControl]: report => isJourneyReport(report) ? JourneyStagesMutator : FunnelPipelineDealStageMutator,
  [AnalyticsViewControl]: () => AnalyticsViewFilterMutator,
  [EventSearchSelector]: () => EventSearchSelectorMutator,
  [SnowflakeFiltersControl]: () => RelationalCustomFiltersMutator
};
const getControlMutators = (controls, report, subAppKey, context, filteredGates, options) => controls.reduce((mutators, Control) => {
  if (controlMutators[Control]) {
    return mutators.concat(controlMutators[Control](report));
  }
  const {
    getContextField,
    getFilterSchema
  } = Control;
  if (!getFilterSchema || !getContextField) {
    return mutators;
  }
  /* eslint-disable-next-line hubspot-dev/no-reduce-accumulator-copy */
  return mutators.concat(getFilterMutator({
    filteredGates,
    getContextField,
    filterSchema: getFilterSchema({
      filteredGates,
      grouping: context.grouping,
      report,
      subAppKey
    }),
    options
  }));
}, []);
const getQuickFilterMutators = (report, subAppKey, grouping, filteredGates, options) => getQuickFilterSchemasForReport(report, subAppKey, grouping, filteredGates).map(filterSchema => {
  if (isSalesQuotaPipelineFilter(filterSchema.property, getCustomWidgetType(report))) {
    return SalesQuotaPipelineMutator;
  }
  return getFilterMutator({
    filteredGates,
    getContextField: () => getContextFieldForEditor(filterSchema),
    filterSchema,
    options
  });
});
export function getMutators(subAppKey, report, filteredGates, context, options) {
  const controls = getAllUsableControls(subAppKey, report, filteredGates);
  const mutatorsForControls = getControlMutators(controls, report, subAppKey, context, filteredGates, options);
  const quickFilterMutators = getQuickFilterMutators(report, subAppKey, context.grouping, filteredGates, options);
  const otherMutators = subAppMutators.hasOwnProperty(subAppKey) ? subAppMutators[subAppKey] : [];
  return Array.from(new Set([...mutatorsForControls, ...quickFilterMutators, ...otherMutators]));
}