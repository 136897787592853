import { createSelector } from 'reselect';
import { appliedDashboardFiltersNamespace } from './slice';
import { groupFiltersByDataSource } from './utils';
import { selectQuickFilterGroups } from '../quick-filters/selectors';
import { applyInvalidPropertyFlagToDashboardFilters, applySystemGeneratedAndQuickFilterGroupFlags, filterOutDashboardFiltersWithInvalidProperties } from '../../lib/dashboardFilters/groupQuickFilterHelpers';
import { selectDataSourcePropertiesView } from '../platform-data-source-metadata';
import { getFilterQueryFormatOperator } from '../../lib/dashboardFilters/filterConversionHelpers';
function withState(selector) {
  return state => selector(state[appliedDashboardFiltersNamespace]);
}
const _selectDashboardFilters = dashboardId => withState(state => {
  return state.filters[dashboardId] || [];
});

/** Contains dashboard filters that could contain invalid properties. Not safe to persist / apply */
export const selectDashboardFiltersWithMaybeInvalidProperties = dashboardId => createSelector([_selectDashboardFilters(dashboardId), selectQuickFilterGroups, selectDataSourcePropertiesView], (filters, quickFilterGroups, dataSourceProperties) => applyInvalidPropertyFlagToDashboardFilters(applySystemGeneratedAndQuickFilterGroupFlags(filters, quickFilterGroups), dataSourceProperties));

/** Safe to persist and apply to reports */
export const selectDashboardFilters = dashboardId => createSelector([selectDashboardFiltersWithMaybeInvalidProperties(dashboardId)], filterOutDashboardFiltersWithInvalidProperties);
export const selectIsExistingProperty = (property, dashboardId) => createSelector(selectDashboardFilters(dashboardId), filters => {
  return filters.some(filter => filter.propertyName === property.name && filter.dataSource === property.dataSource);
});
export const selectIsDashboardFilterPanelOpen = withState(state => state.isPanelOpen);
export const selectDashboardFiltersGroupedByDataSource = dashboardId => createSelector(selectDashboardFilters(dashboardId), appliedDashboardFilters => {
  return groupFiltersByDataSource(appliedDashboardFilters);
});
export const selectDashboardFrequency = dashboardId => withState(state => dashboardId in state.frequency ? state.frequency[dashboardId].frequency : undefined);
export const selectDashboardFrequencyUseFiscalYear = dashboardId => withState(state => dashboardId in state.frequency ? state.frequency[dashboardId].useFiscalYear || false : false);
export const selectDashboardFilterOperator = dashboardFilter => createSelector([selectDataSourcePropertiesView], dataSourceProperties => {
  return getFilterQueryFormatOperator(dashboardFilter, dataSourceProperties[dashboardFilter.dataSource]);
});
export const selectHasDashboardFiltersInitialized = dashboardId => withState(state => {
  return dashboardId in state.filters && !!state.filters[dashboardId];
});