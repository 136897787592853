import { fetchFrameworkTypes } from './frameworkTypesApi';
import { deepFreeze } from '../cache/deepFreeze';
import { isEarlyCacheReturnEnabled, makeFrameworkTypesQuickFetchKey } from 'framework-data-schema-quick-fetch';
import { createPersistedAsyncCache } from '../cache/createPersistedAsyncCache';
import { Metrics } from '../metrics';
import { makeLoadFailed } from '../utils/makeLoadFailed';
import { wrapPromise } from 'persist-promise/wrapPromise';
const normalizeForPatchDiffing = (data, env) => env.keyBy(data, ({
  fullyQualifiedName
}) => fullyQualifiedName, type => {
  var _type$scopeMappings;
  if (!((_type$scopeMappings = type.scopeMappings) !== null && _type$scopeMappings !== void 0 && _type$scopeMappings.length)) {
    return type;
  }
  return Object.assign({}, type, {
    scopeMappings: env.keyBy(type.scopeMappings, ({
      requestAction,
      accessLevel,
      scopeName
    }) => `${requestAction}-${accessLevel}-${scopeName}`)
  });
});
const segmentKey = key => {
  const match = key.match(makeFrameworkTypesQuickFetchKey({
    portalId: '.*',
    family: '(.*)'
  }));
  return match && match[1] || null;
};
const persistedFetchFrameworkTypes = wrapPromise({
  namespace: 'FDSR',
  entityName: 'frameworkTypes',
  deepFreeze: true,
  toCacheKey: makeFrameworkTypesQuickFetchKey,
  fetch: fetchFrameworkTypes,
  metricsConfig: {
    enablePatchDiffing: true,
    convertKeyToMetricsDimension: segmentKey,
    normalizeForPatchDiffing
  }
});
const defaultAsyncFrameworkTypesRequestCache = createPersistedAsyncCache({
  cacheName: 'frameworkTypes',
  segmentKey,
  workerConfig: {
    normalizeForPatchDiffing
  }
});
export const makeFrameworkTypesClient = ({
  httpClient,
  usePersistPromise,
  requestCache = defaultAsyncFrameworkTypesRequestCache,
  persistedPromise = persistedFetchFrameworkTypes,
  toCacheKey = makeFrameworkTypesQuickFetchKey
}) => {
  const getForFamily = ({
    family
  }) => {
    if (usePersistPromise) {
      return persistedPromise.makeFetchWithOpts({
        allowEagerCacheReturn: isEarlyCacheReturnEnabled()
      })({
        family,
        httpClient
      });
    }
    const cachedValue = requestCache.readThrough({
      cacheKey: toCacheKey({
        family
      }),
      loadValue: () => fetchFrameworkTypes({
        family,
        httpClient
      }).then(deepFreeze)
    });
    return cachedValue === null ? makeLoadFailed() : cachedValue;
  };
  return Promise.resolve({
    /**
     * Prints debug info to the console.
     */
    debug: () => {
      if (usePersistPromise) {
        persistedPromise.debug();
      } else {
        requestCache.printDebug();
      }
    },
    /**
     * Clears internal cache state
     *
     * @returns A promise which resolves when state is clear.
     */
    clearCache: async () => {
      await Promise.all([requestCache.clear(), persistedPromise.clearCache()]);
    },
    /**
     * Gets all event type definitions in this portal.
     *
     * @param options.__isComposed For internal metrics tracking purposes only. Set to true when called within another client method.
     * @returns A promise which resolves to all event types, or null if the data could not be found.
     */
    getEvents: ({
      __isComposed = false
    } = {}) => {
      if (!__isComposed) {
        Metrics.counter('frameworkTypes.getEvents', {
          usePersistPromise: `${usePersistPromise}`
        }).increment();
      }
      return getForFamily({
        family: 'events'
      });
    },
    /**
     * Gets all object type definitions in this portal.
     *
     * @param options.__isComposed For internal metrics tracking purposes only. Set to true when called within another client method.
     * @returns A promise which resolves to all object types, or null if the data could not be found.
     */
    getObjects: ({
      __isComposed = false
    } = {}) => {
      if (!__isComposed) {
        Metrics.counter('frameworkTypes.getObjects', {
          usePersistPromise: `${usePersistPromise}`
        }).increment();
      }
      return getForFamily({
        family: 'objects'
      });
    }
  });
};