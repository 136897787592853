import { NONE } from 'reporting-data/constants/chartTypes';
import { Map as ImmutableMap } from 'immutable';
import { getDataType, getDimensions, getMetrics, getSort, isSearchReport, isTimeSeriesReport } from 'reporting-data/report/configReportGetters';
import { isConfigReport, isFunnelReport } from 'reporting-data/tsTypes/reportTypes';
import { isExternalEnumerationWithDisplayOrder } from 'reporting-data/retrieve/inboundDb/common/dataTypes';
import getMetricLabel from 'reporting-data/public/get-metric-label';
const UNSORTABLE_DIMENSIONS = ['hubspot_owner_id', 'engagement.ownerId', 'dealstage.probability'];
export const getIsReportValidForSortSelectorDropdown = ({
  report
}) => {
  return isConfigReport(report) && !isTimeSeriesReport(report) && !isFunnelReport(report) && !isSearchReport(report);
};
export const getSortPropertyValueFromConfigReport = report => {
  var _getSort;
  const sort = (_getSort = getSort(report)) === null || _getSort === void 0 ? void 0 : _getSort.first();
  const dimensions = getDimensions(report);
  const sortProperty = sort && (sort.get('property').includes('.displayOrder') ? sort.get('property').split('.')[0] : sort.get('property'));
  return sort && (dimensions.includes(sortProperty) ? sortProperty : `${sort.get('metricType')}|${sortProperty}`) || NONE;
};
export const getIsDisplayOrderSort = report => {
  var _getSort2;
  const sort = (_getSort2 = getSort(report)) === null || _getSort2 === void 0 ? void 0 : _getSort2.first();
  return sort.get('property').includes('.displayOrder');
};
export const getSortOrder = report => {
  var _getSort3;
  const sort = (_getSort3 = getSort(report)) === null || _getSort3 === void 0 ? void 0 : _getSort3.first();
  return sort.get('order');
};
export const getDefaultSortForDimensionProp = (property, dataType) => {
  if (isExternalEnumerationWithDisplayOrder(dataType, property)) {
    return {
      property: `${property}.displayOrder`,
      type: 'METRIC',
      metricType: 'MAX'
    };
  } else {
    return {
      property,
      type: 'ALPHA'
    };
  }
};
export const isSortableDimension = (property, dataType) => {
  if (UNSORTABLE_DIMENSIONS.includes(property.name)) {
    return false;
  }
  if (property.type === 'string') {
    return true;
  }
  return property.type === 'enumeration' && (isExternalEnumerationWithDisplayOrder(dataType, property.name) || !property.externalOptions);
};
export const getMetricSortOptions = (property, metric) => {
  const metricTypes = metric.get('metricTypes');
  if (!metricTypes || metricTypes.size === 0) {
    return [{
      value: property === null || property === void 0 ? void 0 : property.name,
      text: property === null || property === void 0 ? void 0 : property.label
    }];
  }
  return metricTypes.map(metricType => ({
    value: `${metricType}|${property === null || property === void 0 ? void 0 : property.name}`,
    text: getMetricLabel(property === null || property === void 0 ? void 0 : property.label, metricType)
  }));
};
export const getSortablePropertiesForUISelect = (report, properties) => {
  const dataType = getDataType(report);
  const metrics = getMetrics(report).flatMap(metric => getMetricSortOptions(properties.get(metric.get('property')), metric)).toJS();
  const dimensions = getDimensions(report).map(dimension => properties.get(dimension, ImmutableMap())).filter(property => isSortableDimension(property, dataType)).reduce((options, property) => [/* eslint-disable-next-line hubspot-dev/no-reduce-accumulator-copy */
  ...options, {
    value: property.name,
    text: property.label
  }], []);
  const options = [...metrics, ...dimensions];
  return options;
};