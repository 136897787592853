import enviro from 'enviro';
import Raven from 'raven-js';
import { getFullUrl } from 'hubspot-url-utils';
import { parse } from 'hub-http/helpers/params';
import { FMMessageType } from '../constants';
const ENV_KEY = 'file_manager';
export const isDebug = () => enviro.debug(ENV_KEY);
export const isDeployed = () => enviro.deployed(ENV_KEY);
export const isLocal = () => enviro.getShort(ENV_KEY) === 'local';
export const logDebug = (...args) => {
  if (isDebug()) {
    console.log('[file-manager]', ...args);
  }
};
let queryParams;
export const parseQueryObject = (location = window.location) => {
  if (queryParams) {
    return Object.assign({}, queryParams);
  }
  queryParams = parse(location.search.substring(1));
  return queryParams;
};
export const isFileManagerMessage = e => {
  if (isDeployed()) {
    if (e.origin !== window.location.origin && /* eslint-disable-next-line hubspot-dev/prefer-hubspot-url-utils */
    e.origin !== 'https://local.hubspotqa.com') {
      return false;
    }
  }
  return typeof e.data === 'object' && e.data.messageType === FMMessageType;
};
export function isIframed() {
  return window.parent !== window;
}
let _iframeOriginOverride;

// prevents unit tests from rendering iframe that could really hit deployed `file-picker-ui` app
export const mockIframeOriginForTesting = () => {
  _iframeOriginOverride = 'about:blank';
};
export const resetIframeOriginForTesting = () => {
  _iframeOriginOverride = '';
};
export const getIframeAppOrigin = () => {
  if (_iframeOriginOverride) {
    return _iframeOriginOverride;
  }
  if (isLocal()) {
    return getFullUrl('local');
  }
  return getFullUrl('app');
};
export function getApiHostForEnvironment() {
  let apiUrl = getFullUrl('app-api');
  if (enviro.getShort('file_manager_api') === 'local') {
    apiUrl = getFullUrl('local', {
      envOverride: 'qa'
    });
  }
  return apiUrl.replace('https://', '');
}
export const getCurrentApp = () => {
  // @ts-expect-error hubspot.bender lacks types
  if (!(window.hubspot && window.hubspot.bender)) {
    return null;
  }
  return window.hubspot.bender.currentProject;
};
export function getCurrentAppVersion() {
  return window.hubspot &&
  // @ts-expect-error No hubspot type in global window yet
  window.hubspot.bender &&
  // @ts-expect-error No hubspot type in global window yet
  window.hubspot.bender.currentProjectVersion;
}
export function appendStaticAppInfo(url) {
  const staticAppName = getCurrentApp();
  const staticAppVersion = getCurrentAppVersion();
  if (staticAppName) {
    url = `${url}&hs_static_app=${staticAppName}`;
    if (staticAppVersion && staticAppVersion !== 'static') {
      url = `${url}&hs_static_app_version=${staticAppVersion}`;
    }
  }
  return url;
}

// firing a global FileManagerLib:Drawer:open event allows apps work around a Chrome bug
// that prevents drag and drop events to the file manager drawer if it is on top of an iframe.
// More info in https://git.hubteam.com/HubSpot/FileManagerUI/pull/1272
export function fireEvent(type) {
  document.dispatchEvent(new CustomEvent(type));
}
export function createWrapperEl() {
  const wrapperEl = document.createElement('div');
  wrapperEl.id = 'file_manager_wrapper';
  wrapperEl.className = 'mce-ignore';
  setStyles(wrapperEl, {
    display: 'none',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1112,
    'pointer-events': 'none'
  });
  return wrapperEl;
}
export function setStyles(el, styleObj = {}) {
  if (el instanceof HTMLElement) {
    Object.assign(el.style, styleObj);
  }
}
export function truthyObject(obj) {
  obj = Object.assign({}, obj);
  Object.keys(obj).forEach(key => {
    if (obj[key] === undefined || obj[key] === null) {
      delete obj[key];
    }
  });
  return obj;
}
export function trackBreadcrumb(message, data) {
  Raven.captureBreadcrumb({
    message: `[file-manager] ${message}`,
    data
  });
}
let id = 1;
export function uniqueId(prefix = '') {
  return `${prefix}${id++}`;
}
export function base64ToFile(base64String, filename) {
  var _arr$;
  const arr = base64String.split(',');
  const mime = (_arr$ = arr[0]) === null || _arr$ === void 0 || (_arr$ = _arr$.match(/:(.*?);/)) === null || _arr$ === void 0 ? void 0 : _arr$[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {
    type: mime
  });
}