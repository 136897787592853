import I18n from 'I18n';
import { DAYS, HOURS, MILLISECONDS, MINUTES, SECONDS, YEARS } from '../constants/timeUnits';
const THRESHOLDS = {
  [YEARS]: 12,
  [DAYS]: 2.4,
  [HOURS]: 2.4,
  [MINUTES]: 2.4,
  [SECONDS]: 0,
  [MILLISECONDS]: 0
};
const ONE_TIME_UNIT_IN_MS = {
  [YEARS]: 31556952000,
  [DAYS]: 86400000,
  [HOURS]: 3600000,
  [MINUTES]: 60000,
  [SECONDS]: 1000,
  [MILLISECONDS]: 1
};
const DURATION_UNITS_FROM_GREATEST_TO_SMALLEST = [YEARS, DAYS, HOURS, MINUTES, SECONDS, MILLISECONDS];
const getRoundedUnitWithPrecision = (ms, unit, precisionModifier = 1) => Math.round(ms / unit * precisionModifier) / precisionModifier;
const convertMsToRoundedDuration = (ms, unit, precision) => {
  const precisionModifier = precision ? Math.pow(10, precision) : 1;
  const oneUnitInMs = ONE_TIME_UNIT_IN_MS[unit];
  if (unit === MILLISECONDS) {
    return Math.round(ms);
  } else if (unit === SECONDS) {
    return getRoundedUnitWithPrecision(ms, oneUnitInMs);
  }
  return getRoundedUnitWithPrecision(ms, oneUnitInMs, precisionModifier);
};
const getFormattedDurationString = (roundedTimeValue, unit, smallScale) => unit !== MILLISECONDS ? I18n.text(`reporting-data.durations.${unit}`, {
  count: roundedTimeValue
}) : I18n.text(`reporting-data.durations.seconds`, {
  count: smallScale || roundedTimeValue === 0 ? roundedTimeValue / 1000 : I18n.SafeString(`< ${roundedTimeValue > 0 ? '1' : '-1'}`)
});
export const formatDuration = (ms, smallScale, precision, durationUnit, minimumDisplayUnit = MILLISECONDS) => {
  let durationInMs;
  if (I18n.moment.isDuration(ms)) {
    durationInMs = ms.asMilliseconds();
  } else if (typeof ms === 'string') {
    durationInMs = Number(ms);
  } else {
    durationInMs = ms;
  }
  const minimumUnitLocation = DURATION_UNITS_FROM_GREATEST_TO_SMALLEST.findIndex(unit => unit === minimumDisplayUnit);
  const filteredDurationUnits = minimumUnitLocation !== -1 && minimumDisplayUnit !== MILLISECONDS ? DURATION_UNITS_FROM_GREATEST_TO_SMALLEST.slice(0, minimumUnitLocation + 1) : DURATION_UNITS_FROM_GREATEST_TO_SMALLEST;
  const formattedDuration = filteredDurationUnits.reduce((label, currentUnit) => {
    if (label) {
      return label;
    }
    const roundedValue = convertMsToRoundedDuration(durationInMs, currentUnit, precision);
    const shouldFormatTimeValue = Math.abs(roundedValue) > THRESHOLDS[currentUnit] || roundedValue === 0 && durationUnit === currentUnit || currentUnit === minimumDisplayUnit && minimumDisplayUnit !== MILLISECONDS;
    return shouldFormatTimeValue ? getFormattedDurationString(roundedValue, currentUnit, smallScale) : undefined;
  }, undefined);
  return formattedDuration || getFormattedDurationString(0, SECONDS, smallScale);
};