import * as DSAssetFamilies from '../../DSAssetFamilies/DSAssetFamilies';
import * as ExternalOptionTypes from 'customer-data-objects/property/ExternalOptionTypes';
import * as OperationTypes from 'customer-data-filters-ui/converters/listSegClassic/OperationTypes';
import { EnumerationDisplayType, NumberDisplayType } from 'customer-data-filters-ui/filterQueryFormat/DisplayTypes';
import { LIST_TYPES } from 'customer-data-objects/constants/SegmentsConstants';
import { List, Map as ImmutableMap } from 'immutable';
import { ObjectTypeIdRegex } from 'customer-data-filters-ui/filterQueryFormat/ObjectTypeId';
import { UTC_TZ_ALIGNED } from '../../timestamps/TimestampTypes';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'Form... Remove this comment to see the full error message
import { generateFormUrl } from 'FormUrlGenerator';
import { makeDynamicFilterFamily, testDynamicFilterFamily } from '../../DynamicFilterFamilies';
import DSFieldRecord from '../DSFieldRecord';
import PortalIdParser from 'PortalIdParser';
import PropertyRecord from 'customer-data-objects/property/PropertyRecord';
import PropertyRecordToDSFieldRecord from './PropertyRecordToDSFieldRecord';
import get from 'transmute/get';
import getIn from 'transmute/getIn';
import indexBy from 'transmute/indexBy';
import map from 'transmute/map';
import partial from 'transmute/partial';
import pipe from 'transmute/pipe';
import reduce from 'transmute/reduce';
import set from 'transmute/set';
import translate from 'transmute/translate';
import update from 'transmute/update';
import I18n from 'I18n';
const getObjectUrl = (family, obj) => {
  const portalId = PortalIdParser.get();
  const id = get('id', obj);
  if (testDynamicFilterFamily(DSAssetFamilies.IN_LIST, family)) {
    return `/contacts/${portalId}/objectLists/${id}`;
  }
  switch (family) {
    case DSAssetFamilies.CTA:
      return `/cta/${portalId}/${id}`;
    case DSAssetFamilies.EMAIL_CAMPAIGN:
      return `/email/${portalId}/details/${getIn(['referencedObject', 'contentId'], obj)}/`;
    case DSAssetFamilies.EVENT:
      return `/analytics/${portalId}/events/${id}/edit`;
    case DSAssetFamilies.FORM:
      return generateFormUrl({
        guid: id,
        formType: getIn(['referencedObject', 'formType'], obj),
        portalId
      });
    case DSAssetFamilies.CONTACT_LIST:
      return `/contacts/${portalId}/lists/${id}`;
    case DSAssetFamilies.GOTO_WEBINAR_WEBINAR:
      return `https://global.gotowebinar.com/manageWebinar.tmpl?webinar=${id}`;
    case DSAssetFamilies.WORKFLOW:
      return `/workflows/${portalId}/flow/${id}/edit`;
    default:
      return null;
  }
};
const convertEmailCampaign = (objects, assetFamily) => reduce(ImmutableMap(), (acc, object) => acc.set(object.id, DSFieldRecord({
  name: `${object.id}`,
  label: object.label,
  type: assetFamily,
  url: getObjectUrl(assetFamily, object),
  // This is a field that shouldn't be under the users control so it
  // is set manually in the field to make sure it's correct.
  metadata: {
    appId: object.referencedObject.get('appId'),
    level: object.referencedObject.get('idLevel')
  }
})), objects);
const convertForm = pipe(map(pipe(translate({
  name: 'id',
  label: 'label',
  url: partial(getObjectUrl, DSAssetFamilies.FORM)
}), set('type', DSAssetFamilies.FORM), set('displayType', EnumerationDisplayType), DSFieldRecord)), indexBy(get('name')));
const convertSurveyMonkeySurvey = pipe(map(pipe(translate({
  name: 'id',
  label: 'label',
  url: getIn(['referencedObject', 'analysisUrl'])
}), set('type', DSAssetFamilies.SURVEY_MONKEY_SURVEY), DSFieldRecord)), indexBy(get('name')));
const convertSurveyMonkeyQuestion = pipe(map(pipe(translate({
  label: 'label',
  name: 'id',
  url: getIn(['referencedObject', 'surveyAnalysisUrl']),
  type: record => {
    const answerType = getIn(['referencedObject', 'answerType'], record);
    return answerType === OperationTypes.MULTIENUM ? OperationTypes.ENUMERATION : answerType;
  },
  metadata: pipe(getIn(['referencedObject']), ImmutableMap),
  options: pipe(getIn(['referencedObject', 'answerChoices']), map(str => ImmutableMap({
    label: str,
    value: str
  })))
}), DSFieldRecord)), indexBy(get('name')));
const convertWorkflow = pipe(map(pipe(translate({
  name: 'id',
  label: 'label',
  url: partial(getObjectUrl, DSAssetFamilies.WORKFLOW)
}), set('type', DSAssetFamilies.WORKFLOW), DSFieldRecord)), indexBy(get('name')));
const convertListMembership = (objects, assetFamily) => reduce(ImmutableMap(), (acc, object) => acc.set(object.id, DSFieldRecord({
  name: `${object.id}`,
  label: object.label,
  type: assetFamily,
  url: getObjectUrl(assetFamily, object)
})), objects);
const convertContactListMembership = (objects, assetFamily) => reduce(ImmutableMap(), (acc, object) => acc.set(object.id, DSFieldRecord({
  name: `${object.id}`,
  label: object.label,
  type: assetFamily,
  url: getObjectUrl(assetFamily, object),
  // This is a field that shouldn't be under the users control so it
  // is set manually in the field to make sure it's correct.
  metadata: {
    dynamicList: !!(object.referencedObject.get('listType') === LIST_TYPES.DYNAMIC || object.referencedObject.get('dynamic'))
  }
})), objects);
const convertIntegration = pipe(map(pipe(translate({
  name: 'id',
  label: obj => `${obj.referencedObject.get('appName')}: ${obj.label}`,
  type: obj => makeDynamicFilterFamily(DSAssetFamilies.INTEGRATION, obj.id),
  wrappedObject: 'referencedObject'
}), DSFieldRecord)), indexBy(get('name')));
const getIntegrationType = obj => {
  const type = obj.get('propertyType').toLowerCase();
  return type === OperationTypes.NUMERIC || type === OperationTypes.INTEGER || type === OperationTypes.INT ? NumberDisplayType : type;
};
const convertIntegrationProperty = pipe(indexBy(get('id')), map(pipe(getIn(['referencedObject', 'properties']), map(pipe(translate({
  displayType: getIntegrationType,
  label: 'label',
  options: 'options',
  name: 'name',
  type: getIntegrationType
}), update('options', options => options.map(option => ({
  label: option.get('label'),
  value: option.get('value')
}))), DSFieldRecord)), indexBy(get('name')))));
const convertCtaToPlacement = objects => reduce(ImmutableMap(), (acc, object) => acc.set(object.id, DSFieldRecord({
  name: `${object.id}`,
  label: DSAssetFamilies.CTA_TO_PLACEMENT,
  metadata: ImmutableMap({
    placementGuid: object.referencedObject.get('placement_guid')
  }),
  type: OperationTypes.STRING
})), objects);
const convertGoToWebinar = (objects, assetFamily) => reduce(ImmutableMap(), (acc, object) => acc.set(object.id, DSFieldRecord({
  description: object.description,
  name: `${object.id}`,
  label: object.description ? `${object.label} (${object.description})` : object.label,
  type: assetFamily,
  url: getObjectUrl(assetFamily, object)
})), objects);
const getCtaVersions = ctaList => {
  if (!ctaList || !ctaList.size) {
    return [];
  }
  return ctaList.map(cta => {
    const text = cta.get('label') ? cta.get('label') : I18n.text('customerDataFiltersUI.customerDataFilters.FilterEditor.specialOptionValues.variationCta.input', {
      variantLabel: cta.get('variantLabel')
    });
    return {
      value: cta.get('guid'),
      text: cta.get('isDefault') ? I18n.text('customerDataFiltersUI.customerDataFilters.FilterEditor.specialOptionValues.defaultCta.input') : text
    };
  }).toArray();
};
const convertCTA = (objects, assetFamily) => reduce(ImmutableMap(), (acc, object) => acc.set(object.id, DSFieldRecord({
  description: object.description,
  name: `${object.id}`,
  label: object.label,
  type: assetFamily,
  url: getObjectUrl(assetFamily, object),
  options: getCtaVersions(object.getIn(['referencedObject', 'ctaInfo']))
})), objects);
const defaultConverter = (objects, assetFamily) => reduce(ImmutableMap(), (acc, object) => acc.set(object.id, DSFieldRecord({
  description: object.description,
  name: `${object.id}`,
  label: object.label,
  type: assetFamily,
  url: getObjectUrl(assetFamily, object)
})), objects);
const convertProperty = (referenceRecords, assetFamily) => {
  const propertyRecords = map(record => PropertyRecord.fromJS(record.get('referencedObject').toJS()), referenceRecords);
  return PropertyRecordToDSFieldRecord(assetFamily, propertyRecords);
};
const convertSetting = pipe(map(pipe(translate({
  name: 'id',
  label: 'label',
  metadata: obj => ({
    value: obj.getIn(['referencedObject', 'value'])
  })
}), DSFieldRecord)), indexBy(get('name')));
const convertPropertyGroups = pipe(
// @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
indexBy(obj => get('id', obj)), map(pipe(getIn(['referencedObject', 'results']), map(pipe(translate({
  displayOrder: 'displayOrder',
  hubspotDefined: 'hubspotDefined',
  label: 'displayName',
  name: 'name'
}), DSFieldRecord)), indexBy(get('name')))));
const _getAdPropertyType = referenceRecord => {
  const type = getIn(['referencedObject', 'propertyType'], referenceRecord).toLowerCase();
  if ([OperationTypes.NUMERIC, OperationTypes.INTEGER, OperationTypes.INT].includes(type)) {
    return NumberDisplayType;
  }
  return type;
};
const _getAdEntityReferencedObjectType = referenceRecord => {
  const whitelist = {
    adwords_ad_id: ExternalOptionTypes.ADS_GOOGLE_AD,
    adwords_adgroup_id: ExternalOptionTypes.ADS_GOOGLE_ADGROUP,
    adwords_campaign_id: ExternalOptionTypes.ADS_GOOGLE_CAMPAIGN,
    adwords_keyword_id: ExternalOptionTypes.ADS_GOOGLE_KEYWORD,
    facebook_ad_id: ExternalOptionTypes.ADS_FACEBOOK_AD,
    facebook_adgroup_id: ExternalOptionTypes.ADS_FACEBOOK_ADGROUP,
    facebook_campaign_id: ExternalOptionTypes.ADS_FACEBOOK_CAMPAIGN,
    linkedin_ad_id: ExternalOptionTypes.ADS_LINKEDIN_AD,
    linkedin_adgroup_id: ExternalOptionTypes.ADS_LINKEDIN_ADGROUP,
    linkedin_campaign_id: ExternalOptionTypes.ADS_LINKEDIN_CAMPAIGN
  };
  const name = getIn(['referencedObject', 'name'], referenceRecord);

  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
  return Object.prototype.hasOwnProperty.call(whitelist, name) ?
  // @ts-expect-error ts-migrate(2538) FIXME: Type 'unknown' cannot be used as an index type.
  whitelist[name] : undefined;
};
const convertAdsProperty = pipe(map(pipe(translate({
  groupName: getIn(['referencedObject', 'groupName']),
  isHiddenFromSelect: getIn(['referencedObject', 'isHiddenFromSelect']),
  label: 'label',
  name: 'id',
  options: pipe(getIn(['referencedObject', 'options']), optionMap => optionMap.toJS(), List),
  referencedObjectType: _getAdEntityReferencedObjectType,
  type: _getAdPropertyType,
  wrappedObject: 'referencedObject'
}), DSFieldRecord, set('timestampType', UTC_TZ_ALIGNED))), indexBy(get('name')));
export default ((assetFamily, data) => {
  let formatter;
  if (ObjectTypeIdRegex.test(assetFamily)) {
    return convertProperty(data, assetFamily);
  }
  if (testDynamicFilterFamily(DSAssetFamilies.IN_LIST, assetFamily)) {
    return convertListMembership(data, assetFamily);
  }
  switch (assetFamily) {
    case DSAssetFamilies.ADS_PROPERTY:
      formatter = convertAdsProperty;
      break;
    case DSAssetFamilies.EMAIL_CAMPAIGN:
      formatter = convertEmailCampaign;
      break;
    case DSAssetFamilies.CTA_TO_PLACEMENT:
      formatter = convertCtaToPlacement;
      break;
    case DSAssetFamilies.CTA:
      formatter = convertCTA;
      break;
    case DSAssetFamilies.FORM:
      formatter = convertForm;
      break;
    case DSAssetFamilies.INTEGRATION:
      formatter = convertIntegration;
      break;
    case DSAssetFamilies.INTEGRATION_PROPERTY:
      formatter = convertIntegrationProperty;
      break;
    case DSAssetFamilies.CONTACT_LIST:
      formatter = convertContactListMembership;
      break;
    case DSAssetFamilies.SURVEY_MONKEY_SURVEY:
      formatter = convertSurveyMonkeySurvey;
      break;
    case DSAssetFamilies.SURVEY_MONKEY_QUESTION:
      formatter = convertSurveyMonkeyQuestion;
      break;
    case DSAssetFamilies.WORKFLOW:
      formatter = convertWorkflow;
      break;
    case DSAssetFamilies.GOTO_WEBINAR_WEBINAR:
      formatter = convertGoToWebinar;
      break;
    case DSAssetFamilies.GOTO_WEBINAR_SETTING:
    case DSAssetFamilies.SURVEY_MONKEY_SETTING:
      formatter = convertSetting;
      break;
    case DSAssetFamilies.PROPERTY_GROUP:
      formatter = convertPropertyGroups;
      break;
    default:
      formatter = defaultConverter;
      break;
  }
  return formatter(data, assetFamily);
});