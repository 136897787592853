import getIn from 'transmute/getIn';
import { List as ImmutableList, Iterable as ImmutableIterable } from 'immutable';
import { DATE, DATE_TIME } from 'reporting-data/constants/property-types';
import { areFilterOperatorsAndValuesSame } from '../../ducks/dashboardFilters/types';
import { getExternalOptionsReferenceType, getOptions, getPropertyFromDataSourceByPropertyRecord, getType, isRecordIdProperty } from './schema/propertySchema';
// Names of quick filter groups that are generated.
const DATE_RANGE_QUICK_FILTER_TEMPLATE_NAME = 'Date range';
const OWNER_QUICK_FILTER_TEMPLATE_NAME = 'Owners';
const TEAM_QUICK_FILTER_TEMPLATE_NAME = 'Teams';
export const MAX_QUICK_FILTER_TEMPLATES = 3;
export const QUICK_FILTER_TEMPLATES = {
  DATE_RANGE: 'DATE_RANGE',
  OWNER: 'OWNER',
  TEAM: 'TEAM'
};
export const extractQuickFilterGroupFromAppliedFilters = (quickFilterGroup, appliedFilters) => {
  const {
    properties: quickFilterGroupProperties
  } = quickFilterGroup;
  // Filter out properties from applied filters that match a property from the quick filter group.
  const appliedQuickFilterGroupProperties = appliedFilters.filter(appliedFilter => {
    const {
      dataSource: appliedFilterDataSource,
      propertyName: appliedFilterPropertyName
    } = appliedFilter;
    return quickFilterGroupProperties.some(quickFilterGroupProperty => {
      const {
        name: quickFilterGroupPropertyName
      } = quickFilterGroupProperty;
      const quickFilterGroupPropertyDataSource = quickFilterGroupProperty.dataSourceId;
      return quickFilterGroupPropertyDataSource === appliedFilterDataSource && quickFilterGroupPropertyName === appliedFilterPropertyName;
    });
  });
  return appliedQuickFilterGroupProperties;
};
export const checkIfQuickFilterGroupIsApplied = (quickFilterGroup, appliedFilters) => {
  const {
    properties: quickFilterGroupProperties
  } = quickFilterGroup;
  const maybeAppliedQuickFilterGroupFilters = extractQuickFilterGroupFromAppliedFilters(quickFilterGroup, appliedFilters);

  // If the applied filters list does contain every property from the quick filter group, then check for operator/value equality.
  if (maybeAppliedQuickFilterGroupFilters.length === quickFilterGroupProperties.length) {
    const firstFilter = maybeAppliedQuickFilterGroupFilters[0];
    const areAllFiltersEqual = maybeAppliedQuickFilterGroupFilters.every(appliedFilter => {
      return areFilterOperatorsAndValuesSame(appliedFilter, firstFilter);
    });
    return areAllFiltersEqual;
  }
  return false;
};
export const isEditingQuickFilterGroupNameUnique = (name, editingQuickFilterGroup, quickFilterGroups) => {
  const {
    originalGroupName
  } = editingQuickFilterGroup;
  if (name !== originalGroupName) {
    if (quickFilterGroups.some(group => group.name === name)) {
      return false;
    }
  }
  return true;
};
export const getPropertyRecordsInQuickFilterGroup = (quickFilterGroup, dataSourcesProperties) => {
  return quickFilterGroup.properties.map(property => getPropertyFromDataSourceByPropertyRecord(dataSourcesProperties, property.dataSourceId, property.name)).filter(maybeProperty => !!maybeProperty);
};
export const isDateOrDatetimeType = type => type === DATE || type === DATE_TIME;
export const isPropertyDateOrDatetimeType = property => {
  const propertyType = getType(property);
  return isDateOrDatetimeType(propertyType);
};
export const isQuickFilterGroupPropertyTypesSame = (editingQuickFilterGroup, dataSourcesProperties) => {
  const propertiesInGroup = getPropertyRecordsInQuickFilterGroup(editingQuickFilterGroup, dataSourcesProperties);
  const types = [...new Set(propertiesInGroup.map(property => getType(property)))];
  if (types.length > 1) {
    // Allow for date and datetime properties in a group
    if (types.every(type => isDateOrDatetimeType(type))) {
      return true;
    }
    return false;
  }
  return true;
};
export const isUsingRecordIdPropertyInGroup = (quickFilterGroup, dataSourcesProperties) => {
  const propertiesInGroup = getPropertyRecordsInQuickFilterGroup(quickFilterGroup, dataSourcesProperties);
  return propertiesInGroup.some(isRecordIdProperty);
};
export const shouldPropertyBeEnabledForHubSpotTeamGroup = (property, quickFilterGroup) => {
  if (quickFilterGroup.properties.length === 0) {
    return true;
  }
  const {
    properties
  } = quickFilterGroup;
  const isHubSpotTeamGroup = properties.every(_property => _property.name === 'hubspot_team_id');
  if (isHubSpotTeamGroup) {
    const propertyName = property.get('name');
    return propertyName === 'hubspot_team_id';
  }
  return false;
};
export const doesPropertyShareExternallyReferencedObjectWithGroup = (property, quickFilterGroup, dataSourcesProperties) => {
  if (quickFilterGroup.properties.length === 0) {
    return true;
  }
  const groupProperty = quickFilterGroup.properties[0];
  const groupPropertyRecord = getIn([groupProperty.dataSourceId, groupProperty.name], dataSourcesProperties);
  const groupPropertyExternalReferenceObject = getExternalOptionsReferenceType(groupPropertyRecord) || null;
  const propertiesExternalReferencedObject = getExternalOptionsReferenceType(property) || null;
  return propertiesExternalReferencedObject === groupPropertyExternalReferenceObject;
};
export const doPropertiesInGroupShareExternallyReferencedObject = (quickFilterGroup, dataSourcesProperties) => {
  const propertiesInGroup = getPropertyRecordsInQuickFilterGroup(quickFilterGroup, dataSourcesProperties);
  return propertiesInGroup.every(property => doesPropertyShareExternallyReferencedObjectWithGroup(property, quickFilterGroup, dataSourcesProperties) === true);
};
export const doesPropertyShareDropdownOptionsWithGroup = (property, quickFilterGroup, dataSourcesProperties) => {
  if (quickFilterGroup.properties.length === 0) {
    return true;
  }
  const groupProperty = quickFilterGroup.properties[0];
  const groupPropertyRecord = getPropertyFromDataSourceByPropertyRecord(dataSourcesProperties, groupProperty.dataSourceId, groupProperty.name);
  const getPropertyOptions = propertyRecord => {
    if (!propertyRecord) {
      return ImmutableList();
    }
    const options = getOptions(propertyRecord);
    if (options && ImmutableIterable.isIterable(options)) {
      return options;
    }
    return ImmutableList();
  };
  const groupPropertyOptions = getPropertyOptions(groupPropertyRecord);
  const propertiesOptions = getPropertyOptions(property);
  if (propertiesOptions.size !== groupPropertyOptions.size) {
    return false;
  }
  return propertiesOptions.equals(groupPropertyOptions);
};
export const doPropertiesInGroupShareDropdownOptions = (quickFilterGroup, dataSourcesProperties) => {
  const propertiesInGroup = getPropertyRecordsInQuickFilterGroup(quickFilterGroup, dataSourcesProperties);
  return propertiesInGroup.every(property => doesPropertyShareDropdownOptionsWithGroup(property, quickFilterGroup, dataSourcesProperties) === true);
};
export const getQuickFilterGroupWithProperty = (quickFilterGroups, propertyName, dataSourceId) => {
  const maybeGroup = quickFilterGroups.find(group => group.properties.some(property => property.name === propertyName && property.dataSourceId === dataSourceId));
  return maybeGroup;
};
export const getExistingGeneratedQuickFilterGroupTemplates = quickFilterGroups => {
  const generatedQuickFilterGroups = quickFilterGroups.filter(quickFilterGroup => quickFilterGroup.systemGenerated);
  const existingGeneratedQuickFilterGroupTemplates = generatedQuickFilterGroups.reduce((acc, generatedQuickFilterGroup) => {
    const {
      name
    } = generatedQuickFilterGroup;
    switch (name) {
      case DATE_RANGE_QUICK_FILTER_TEMPLATE_NAME:
        return [...acc, QUICK_FILTER_TEMPLATES.DATE_RANGE];
      case OWNER_QUICK_FILTER_TEMPLATE_NAME:
        return [...acc, QUICK_FILTER_TEMPLATES.OWNER];
      case TEAM_QUICK_FILTER_TEMPLATE_NAME:
        return [...acc, QUICK_FILTER_TEMPLATES.TEAM];
      default:
        return acc;
    }
  }, []);
  return existingGeneratedQuickFilterGroupTemplates;
};
export const isDateRangeGeneratedQuickFilterGroup = quickFilterGroup => {
  const {
    name,
    systemGenerated
  } = quickFilterGroup;
  return systemGenerated && name === DATE_RANGE_QUICK_FILTER_TEMPLATE_NAME;
};
export const isOwnersGeneratedQuickFilterGroup = quickFilterGroup => {
  const {
    name,
    systemGenerated
  } = quickFilterGroup;
  return systemGenerated && name === OWNER_QUICK_FILTER_TEMPLATE_NAME;
};
export const isTeamsGeneratedQuickFilterGroup = quickFilterGroup => {
  const {
    name,
    systemGenerated
  } = quickFilterGroup;
  return systemGenerated && name === TEAM_QUICK_FILTER_TEMPLATE_NAME;
};

/** Single property quick filter groups use the property name of its only property as the groups name
 * Like the legacy pinned property quick filters
 */
export const isSinglePropertyQuickFilterGroup = quickFilterGroup => {
  return quickFilterGroup.properties.length === 1 && !quickFilterGroup.name;
};
export const isAppliedFilterInQuickFilterGroup = (quickFilterGroup, appliedFilter) => {
  const {
    properties
  } = quickFilterGroup;
  return properties.some(property => {
    const propertyDataSource = property.dataSourceId;
    return appliedFilter.dataSource === propertyDataSource && appliedFilter.propertyName === property.name;
  });
};
export const applySystemGeneratedAndQuickFilterGroupFlags = (dashboardFilters, quickFilterGroups) => {
  const appliedQuickFilterGroups = quickFilterGroups.filter(group => {
    const isApplied = checkIfQuickFilterGroupIsApplied(group, dashboardFilters);
    return isApplied;
  });
  return dashboardFilters.map(filter => {
    const belongingQuickFilterGroup = appliedQuickFilterGroups.find(group => {
      return isAppliedFilterInQuickFilterGroup(group, filter);
    });
    if (belongingQuickFilterGroup) {
      const groupFlagsAppliedFilter = Object.assign({}, filter, {
        systemGenerated: belongingQuickFilterGroup.systemGenerated,
        quickFilterGroupName: belongingQuickFilterGroup.name
      });
      return groupFlagsAppliedFilter;
    }
    return filter;
  });
};
export const applyInvalidPropertiesFlagToQuickFilterGroups = (quickFilterGroups, dataSourceProperties) => quickFilterGroups.map(group => {
  const invalidProperties = group.properties.filter(({
    dataSourceId,
    name
  }) => {
    const maybeProperty = getPropertyFromDataSourceByPropertyRecord(dataSourceProperties, dataSourceId, name);
    return maybeProperty == null;
  });
  return Object.assign({}, group, {
    invalidProperties
  });
});
export const filterOutQuickFilterGroupsInvalidProperties = filterGroups => filterGroups.map(group => {
  const {
    properties,
    invalidProperties = []
  } = group;
  const isInvalidProperty = prop => {
    const {
      name
    } = prop;
    const dataSourceId = prop.dataSourceId;
    return invalidProperties.some(p => p.dataSourceId === dataSourceId && p.name === name);
  };
  return Object.assign({}, group, {
    properties: properties.filter(prop => !isInvalidProperty(prop))
  });
});