import { useMemo } from 'react';
import { fromJS } from 'immutable';
import { ANALYTICS_FORMS } from 'reporting-data/constants/dataTypes/unified';
import { BETWEEN, GT, LT } from 'reporting-data/constants/operators';
import { getDataType, getDateRangeFilterProperty, getDateRangeFilterValue, getReportConfig, isTimeSeriesReport } from 'reporting-data/report/configReportGetters';
import { getCustomWidgetType } from 'reporting-data/report/reportGetters';
import { CUSTOM_WIDGET_TYPES } from 'reporting-data/tsTypes/customWidgetTypes';
import { isCustomWidgetReport, isUnifiedAnalyticsReport } from 'reporting-data/tsTypes/reportTypes';
import { SUB_APPS } from 'reporting-data/tsTypes/subAppTypes';
import * as frequencyOptions from 'reporting-data/constants/frequency';
import { customWidgetTypeIs } from 'reporting-ui-components/components/customWidgets/utils';
import { DEFAULT_RANGE_TYPES, RANGE_TYPES
// @ts-expect-error Untyped dependency
} from 'reporting-ui-components/components/shared/constants/DateRangeConstants';
// @ts-expect-error Untyped dependency
import { isReportAccumulative } from 'reporting-ui-components/components/customWidgets/lib/salesAnalytics/helpers';
// @ts-expect-error Untyped dependency
import getDefaultFrequency from 'reporting-ui-components/lib/getDefaultFrequency';
const FUTURE_RANGE_TYPES = [RANGE_TYPES.NEXT_DAY, RANGE_TYPES.NEXT_WEEK, RANGE_TYPES.NEXT_MONTH, RANGE_TYPES.NEXT_QUARTER, RANGE_TYPES.NEXT_YEAR];
const MONTH_RANGE_TYPES = [RANGE_TYPES.THIS_MONTH, RANGE_TYPES.LAST_MONTH, RANGE_TYPES.NEXT_MONTH, RANGE_TYPES.CUSTOM_MONTH];
const QUARTER_RANGE_TYPES = [RANGE_TYPES.THIS_QUARTER, RANGE_TYPES.LAST_QUARTER, RANGE_TYPES.NEXT_QUARTER, RANGE_TYPES.CUSTOM_QUARTER];
const YEAR_RANGE_TYPES = [RANGE_TYPES.THIS_YEAR, RANGE_TYPES.LAST_YEAR, RANGE_TYPES.NEXT_YEAR, RANGE_TYPES.CUSTOM_YEAR];
const UA_DATA_TYPES_COMPATABILE_WITH_RELATIVE_RANGES = [ANALYTICS_FORMS];
export const hasRelativeRangeTypeSupport = report => !isCustomWidgetReport(report) && !isUnifiedAnalyticsReport(report) || UA_DATA_TYPES_COMPATABILE_WITH_RELATIVE_RANGES.includes(getDataType(report));
const hasSecondaryDateRangeSubApp = displayParams => {
  const subAppKey = displayParams === null || displayParams === void 0 ? void 0 : displayParams.getIn(['salesAnalytics', 'subAppKey'], '');
  return [SUB_APPS.changeHistoryDealList, SUB_APPS.waterfall].includes(subAppKey);
};
const hasSecondaryDateRangeCustomWidget = customWidgetType => customWidgetType && [CUSTOM_WIDGET_TYPES.WATERFALL, CUSTOM_WIDGET_TYPES.CHANGE_HISTORY_DEAL_LIST].includes(customWidgetType);
export const hasSecondaryDateRangeEditor = (customWidgetType, displayParams) => hasSecondaryDateRangeCustomWidget(customWidgetType) || hasSecondaryDateRangeSubApp(displayParams);
export const hasFutureRangeTypeSupport = report => customWidgetTypeIs(report, CUSTOM_WIDGET_TYPES.PIPELINE_SNAPSHOTS);
const getLimitedRangeTypesForReport = (customWidgetType, isTimeSeries, isAccumulative) => {
  if (customWidgetType === CUSTOM_WIDGET_TYPES.PIPELINE_SNAPSHOTS) {
    return Object.values(DEFAULT_RANGE_TYPES).filter(rangeType => !FUTURE_RANGE_TYPES.includes(rangeType));
  }
  if (customWidgetType !== CUSTOM_WIDGET_TYPES.SALES_QUOTA) {
    return undefined;
  }
  if (isTimeSeries) {
    return isAccumulative ? MONTH_RANGE_TYPES : QUARTER_RANGE_TYPES.concat(YEAR_RANGE_TYPES);
  }
  return [...MONTH_RANGE_TYPES, ...QUARTER_RANGE_TYPES, ...YEAR_RANGE_TYPES];
};
export const getValidFrequenciesForReport = report => {
  if (getCustomWidgetType(report) !== CUSTOM_WIDGET_TYPES.SALES_QUOTA) {
    return Object.keys(frequencyOptions);
  }
  if (isReportAccumulative(report)) {
    return [frequencyOptions.DAY, frequencyOptions.WEEK];
  }
  return [frequencyOptions.MONTH];
};
export const useLimitedDateRangeOptions = report => {
  const customWidgetType = getCustomWidgetType(report);
  const isAccumulative = isReportAccumulative(report);
  const isTimeSeries = isTimeSeriesReport(report);
  return useMemo(() => getLimitedRangeTypesForReport(customWidgetType, isTimeSeries, isAccumulative), [customWidgetType, isAccumulative, isTimeSeries]);
};
export const getIsSecondaryDateRangeCustomFilter = (filter, config, customWidgetType, displayParams) => {
  const dateProperty = getDateRangeFilterProperty(config);
  return hasSecondaryDateRangeEditor(customWidgetType, displayParams) && filter.get('property') === dateProperty && [GT, LT, BETWEEN].includes(filter.get('operator'));
};
const DEFAULT_CUSTOM_WIDGET_RANGES = [RANGE_TYPES.LAST_MONTH, RANGE_TYPES.LAST_QUARTER, RANGE_TYPES.LAST_YEAR];
export const getReportWithValidRangeAndFrequency = report => {
  const isAccumulative = isReportAccumulative(report);
  const isTimeSeries = isTimeSeriesReport(report);
  const customWidgetType = getCustomWidgetType(report);
  const limitedRangeTypes = getLimitedRangeTypesForReport(customWidgetType, isTimeSeries, isAccumulative);
  const dateRangeValue = getDateRangeFilterValue(getReportConfig(report));
  const correctedDateRangeValue = limitedRangeTypes && !limitedRangeTypes.includes(dateRangeValue.get('rangeType')) ? fromJS({
    rangeType: limitedRangeTypes.find(range => DEFAULT_CUSTOM_WIDGET_RANGES.includes(range))
  }) : dateRangeValue;
  const validFrequencies = getValidFrequenciesForReport(report);
  return report.setIn(['config', 'filters', 'dateRange', 'value'], correctedDateRangeValue).updateIn(['config', 'frequency'], currentFrequency => {
    if (!isTimeSeries) {
      return null;
    }
    if (currentFrequency && validFrequencies.includes(currentFrequency)) {
      return currentFrequency;
    }
    const defaultFrequency = getDefaultFrequency(correctedDateRangeValue.toJS());
    if (validFrequencies.includes(defaultFrequency)) {
      return defaultFrequency;
    }
    return validFrequencies[validFrequencies.length - 1];
  });
};