import userInfo from 'hub-http/userInfo';
import { List, Map as ImmutableMap } from 'immutable';
import { CustomObjectsGroup, TableGroupNames, SnowflakeTableGroup, DataSourceTypes } from '../schema/source-records';
import { fetchDataSources } from './tables';
import * as ObjectTypes from './objectTypes';

// Map<Id, SnowflakeTableGroup>
let tableGroupCache;
const isReportableObject = ({
  dataSourceType
}) => dataSourceType === DataSourceTypes.HUBSPOT_OBJECT;
const isReportableEvent = ({
  dataSourceType
}) => dataSourceType === DataSourceTypes.HUBSPOT_EVENT;
const isEventsDigest = ({
  dataSourceType
}) => dataSourceType === DataSourceTypes.EVENTS_DIGEST;
const isTableGroupUngatedForUser = (tableGroup, user) => {
  const {
    gates = []
  } = user || {};
  switch (tableGroup.name) {
    case TableGroupNames.ADS:
      return false;
    case TableGroupNames.FORM_SUBMISSIONS:
      return gates.includes('ReportBuilder:FormSubmissions');
    case TableGroupNames.FORMS:
      return gates.includes('ReportBuilder:FormSubmissions') || gates.includes('Submissions:CustomReportBuilder');
    default:
      return true;
  }
};
const isTableGroupRootEmpty = tableGroup => {
  return tableGroup.root.isEmpty();
};
const getTableGroupDefinition = (name, dataSourcesInGroup, allDataSources) => {
  const objectTypes = dataSourcesInGroup.filter(isReportableObject).map(dataSource => dataSource.dataSourceId).toList();
  const eventTypes = dataSourcesInGroup.filter(isReportableEvent).map(dataSource => dataSource.dataSourceId).toList();
  const digestTypes = dataSourcesInGroup.filter(isEventsDigest).map(dataSource => dataSource.dataSourceId).toList();
  const all = objectTypes.concat(eventTypes).concat(digestTypes).toList();
  let root;
  let makeRelatedEventRootIfPrimary = false;
  let rolloutStage;
  switch (name) {
    case TableGroupNames.ADS:
      root = eventTypes;
      break;
    case TableGroupNames.AD_INTERACTIONS_BY_CONTACT:
      root = eventTypes;
      break;
    case TableGroupNames.AD_PERFORMANCE_METRICS:
      root = eventTypes;
      break;
    case TableGroupNames.MARKETING_EMAILS:
      root = eventTypes.isEmpty() ? List.of(ObjectTypes.MARKETING_EMAIL) : eventTypes;
      break;
    case TableGroupNames.LANDING_PAGES:
      root = List.of(ObjectTypes.LANDING_PAGE);
      break;
    case TableGroupNames.BLOG:
      root = List.of(ObjectTypes.BLOG_POST);
      break;
    case TableGroupNames.WEBSITE_PAGES:
      root = List.of(ObjectTypes.SITE_PAGE);
      break;
    case TableGroupNames.FORMS:
      root = List.of(ObjectTypes.FORMS);
      break;
    case TableGroupNames.FORM_SUBMISSIONS:
      root = List.of(ObjectTypes.FORM_SUBMISSION);
      break;
    case TableGroupNames.V_FORM_SUBMISSIONS:
      root = dataSourcesInGroup.map(dataSource => dataSource.dataSourceId).toList();
      rolloutStage = 'beta';
      break;
    case TableGroupNames.FORM_ANALYTICS:
      root = dataSourcesInGroup.map(dataSource => dataSource.dataSourceId).toList();
      rolloutStage = 'beta';
      break;
    case TableGroupNames.MEETINGS:
      root = List.of(ObjectTypes.MEETING_EVENT);
      makeRelatedEventRootIfPrimary = true;
      break;
    case TableGroupNames.CALL:
      root = List.of(ObjectTypes.CALL);
      break;
    case TableGroupNames.COMMERCE_PAYMENTS:
      root = all;
      break;
    case TableGroupNames.VIDEO:
      root = eventTypes;
      break;
    case TableGroupNames.SALES_EMAIL:
      root = eventTypes;
      break;
    case TableGroupNames.SEQUENCE:
      root = eventTypes;
      break;
    case TableGroupNames.SUBSCRIPTION:
      root = List.of(ObjectTypes.SUBSCRIPTION);
      break;
    case TableGroupNames.BEHAVIORAL_EVENTS:
      root = all;
      break;
    case TableGroupNames.WORKFLOWS:
      root = eventTypes;
      break;
    case TableGroupNames.MEDIA:
      root = eventTypes;
      break;
    case TableGroupNames.QUOTES:
      root = all;
      break;
    case TableGroupNames.EMAIL_EVENTS_DIGEST:
      root = all;
      break;
    case TableGroupNames.CTA_VIEW:
      root = eventTypes;
      rolloutStage = 'beta';
      break;
    case TableGroupNames.INVOICES:
      root = all;
      break;
    case TableGroupNames.LEADS:
      root = all;
      break;
    case TableGroupNames.CAMPAIGN:
      root = eventTypes;
      break;
    case TableGroupNames.CAMPAIGNS:
      root = dataSourcesInGroup.map(dataSource => dataSource.dataSourceId).toList();
      break;
    case TableGroupNames.MARKETING_CAMPAIGN_INFLUENCES:
      root = all;
      break;
    case TableGroupNames.MARKETING_CAMPAIGNS:
      root = dataSourcesInGroup.map(dataSource => dataSource.dataSourceId).toList();
      rolloutStage = 'beta';
      break;
    case TableGroupNames.LISTS:
      root = objectTypes;
      rolloutStage = 'beta';
      break;
    case TableGroupNames.MARKETING_EVENTS:
      root = all;
      rolloutStage = 'beta';
      break;
    case TableGroupNames.MARKETING_EVENT_ATTENDANCE:
      root = all;
      rolloutStage = 'beta';
      break;
    case TableGroupNames.MARKETING_EVENT_UE_DIGEST:
      root = all;
      rolloutStage = 'beta';
      break;
    case TableGroupNames.USERS:
      root = List.of(ObjectTypes.USER);
      break;
    case TableGroupNames.CTA_ACTIVITIES:
      root = objectTypes;
      rolloutStage = 'beta';
      break;
    default:
      {
        root = all;
        break;
      }
  }
  const related = all.filterNot(object => root.includes(object)).toList();

  // quick-fix: filter out unscoped/deleted reportable objects
  root = root.filter(dataSourceId => !!allDataSources.find(dataSource => dataSourceId === dataSource.dataSourceId)).toList();
  return {
    root,
    related,
    makeRelatedEventRootIfPrimary,
    rolloutStage
  };
};
const toSnowflakeTableGroup = (name, dataSourcesInGroup, dataSources) => {
  // make sure every single object within group has same "section"
  const section = dataSourcesInGroup.first().reportingSection;
  if (!dataSourcesInGroup.every(dataSource => dataSource.reportingSection === section)) {
    console.warn(`Encountered reportable objects within the same section with different reporting sections`);
  }
  return SnowflakeTableGroup(Object.assign({
    name,
    section
  }, getTableGroupDefinition(name, dataSourcesInGroup, dataSources)));
};
const parseSnowflakeTableGroups = dataSources => {
  const groupNameToDataSourceList = dataSources.reduce((groupMap, dataSource) => {
    const {
      reportingFirstLevelGroups
    } = dataSource;
    return reportingFirstLevelGroups.reduce((merged, groupName) => {
      // todo: lift this logic to BE
      if (groupName === CustomObjectsGroup) {
        const {
          inboundDbObjectType
        } = dataSource;
        return merged.update(`custom_object:${inboundDbObjectType.fullyQualifiedName}`, List(), firstLevelGroup => firstLevelGroup.push(dataSource));
      }
      return merged.update(groupName, List(), firstLevelGroup => firstLevelGroup.push(dataSource));
    }, groupMap);
  }, ImmutableMap());
  return groupNameToDataSourceList.map((dataSourcesInGroup, tableGroupName) => toSnowflakeTableGroup(tableGroupName, dataSourcesInGroup, dataSources)).filterNot(tableGroup => isTableGroupRootEmpty(tableGroup));
};
export const getSnowflakeTableGroups = () => {
  if (tableGroupCache) {
    return Promise.resolve(tableGroupCache);
  }
  return Promise.all([fetchDataSources(), userInfo()]).then(([dataSources, user]) => {
    const nameToTableGroup = parseSnowflakeTableGroups(dataSources).filter(tableGroup => isTableGroupUngatedForUser(tableGroup, user)).toMap();
    tableGroupCache = nameToTableGroup;
    return nameToTableGroup;
  });
};
export const __TESTABLE__ = {
  parseSnowflakeTableGroups
};