import { BOOLEAN, CURRENCY, DATE, DATE_TIME, DURATION, ENUMERATION, NUMBER, PERCENT, STRING } from 'reporting-data/constants/property-types';
import { getPropertyType } from 'reporting-data/properties/propertyGetters';
import { isDateOrDateTime, isDurationDateProperty } from 'reporting-data/properties/propertyUtils';
import { getDimensions, getDisplayParamsDataColumns, getMetricProperties } from 'reporting-data/report/configReportGetters';
import { isUnifiedAnalyticsReport } from 'reporting-data/tsTypes/reportTypes';
export const getIsValidDimensionProperty = property => {
  if (isDateOrDateTime(property)) {
    return !isDurationDateProperty(property);
  }
  const propertyType = getPropertyType(property);
  return [STRING, ENUMERATION, BOOLEAN, CURRENCY].includes(propertyType);
};
export const getIsValidConfigMetricProperty = (property, report) => {
  const propertyType = getPropertyType(property);
  const isUnifiedAnalytics = isUnifiedAnalyticsReport(report);
  if (isUnifiedAnalytics) {
    return [ENUMERATION, STRING, DATE, DATE_TIME].includes(propertyType);
  }
  if (isDateOrDateTime(property)) {
    return isDurationDateProperty(property);
  }
  return [NUMBER, PERCENT, CURRENCY, DURATION].includes(propertyType);
};
export const getPropertiesFromConfigReport = report => {
  var _getDisplayParamsData, _getDisplayParamsData2;
  const dimensions = getDimensions(report).toJS() || [];
  const metricProperties = getMetricProperties(report).toJS() || [];
  const dataColumns = ((_getDisplayParamsData = getDisplayParamsDataColumns(report)) === null || _getDisplayParamsData === void 0 || (_getDisplayParamsData2 = _getDisplayParamsData.toJS) === null || _getDisplayParamsData2 === void 0 ? void 0 : _getDisplayParamsData2.call(_getDisplayParamsData)) || [];
  return dimensions.concat(metricProperties).concat(dataColumns);
};
export const sortPropertyOptionsByText = (a, b) => {
  if (a.text < b.text) {
    return -1;
  }
  if (a.text > b.text) {
    return 1;
  }
  return 0;
};