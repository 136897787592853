// for onBeforeEditModeChange
export const WILL_CANCEL_CHANGES = 'WILL_CANCEL_CHANGES';
export const WILL_DISCARD_CHANGES = 'WILL_DISCARD_CHANGES';
export const WILL_CONFIRM_CHANGES = 'WILL_CONFIRM_CHANGES';
export const WILL_EDIT_FILTERS = 'WILL_EDIT_FILTERS';
// for onEditModeChange
export const CANCELED_CHANGES = 'CANCELED_CHANGES';
export const DISCARDED_CHANGES = 'DISCARDED_CHANGES';
export const CONFIRMED_CHANGES = 'CONFIRMED_CHANGES';
export const EDITED_FILTERS = 'EDITED_FILTERS';
export const BEFORE_EDIT_MODE_ACTIONS = {
  [WILL_CANCEL_CHANGES]: WILL_CANCEL_CHANGES,
  [WILL_DISCARD_CHANGES]: WILL_DISCARD_CHANGES,
  [WILL_CONFIRM_CHANGES]: WILL_CONFIRM_CHANGES,
  [WILL_EDIT_FILTERS]: WILL_EDIT_FILTERS
};
export const EDIT_MODE_ACTIONS = {
  [CANCELED_CHANGES]: CANCELED_CHANGES,
  [DISCARDED_CHANGES]: DISCARDED_CHANGES,
  [CONFIRMED_CHANGES]: CONFIRMED_CHANGES,
  [EDITED_FILTERS]: EDITED_FILTERS
};