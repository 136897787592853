'use es6';

import { isBlocklistedForCrossObject } from './blocklisted-for-cross-object';
import { hasFieldLevelPermissionRestrictions } from '../properties/propertyGetters';
export const isExplicitlyBlocklistedForFiltering = property => property.get('blocklistedForFiltering');
export function isBlocklistedForFiltering(dataType, isCrossObject, property) {
  const isBuckets = property.get('type') === 'buckets';
  const isCount = property.get('name') === 'count';
  const fieldLevelPermissionRestricted = hasFieldLevelPermissionRestrictions(property);
  return fieldLevelPermissionRestricted || isBuckets || isCount || isExplicitlyBlocklistedForFiltering(property) || isCrossObject && isBlocklistedForCrossObject(dataType, property);
}