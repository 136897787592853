import { getGlobal, setGlobal } from './global';

// Disables the indexeddb write behavior to make testing less painful.
let __disableIdb = false;

/**
 * Re-enables indexeddb for testing this file
 */
export const __TEST_ONLY__REENABLE_IDB = () => {
  __disableIdb = false;
};

/**
 * Disables the IndexedDB integration. Only for use in tests!
 */
export const __TEST_ONLY__DISABLE_IDB = () => {
  __disableIdb = true;
};
const CACHE_OBJECT_STORE = 'cache';
const METRICS_OBJECT_STORE = 'metrics';
const open = ({
  resolvePortalAndUserId
}) => {
  return resolvePortalAndUserId().then(({
    portalId,
    userId
  }) => new Promise((resolve, reject) => {
    try {
      const openRequest = window.indexedDB.open(`persist-promise/cache/${portalId}/${userId}`, 1);
      if (openRequest) {
        openRequest.onerror = reject;

        // @ts-expect-error This is typed as a generic event, but it will have a `result` field
        openRequest.onupgradeneeded = ({
          target: {
            result
          }
        }) => {
          if (!result.objectStoreNames.contains(CACHE_OBJECT_STORE)) {
            result.createObjectStore(CACHE_OBJECT_STORE);
          }
          if (!result.objectStoreNames.contains(METRICS_OBJECT_STORE)) {
            result.createObjectStore(METRICS_OBJECT_STORE);
          }
        };

        // @ts-expect-error This is typed as a generic event, but it will have a `result` field
        openRequest.onsuccess = ({
          target: {
            result
          }
        }) => {
          // Set a global error handler on the db
          result.onerror = console.error;
          resolve(result);
        };
      }
    } catch (e) {
      reject(e);
    }
  }));
};
const idbConnectionKey = '__persistPromiseIDBConnection';
const openIndexedDBConnection = ({
  resolvePortalAndUserId
}) => {
  const existingConn = getGlobal(idbConnectionKey);
  if (existingConn) {
    return existingConn;
  }
  const conn = open({
    resolvePortalAndUserId
  });
  setGlobal(idbConnectionKey, conn);
  return conn;
};
/**
 *
 * @param key The key to query in indexeddb
 * @returns A promise that resolves to a value, or rejects if the value is null/undefined or some other error ocurred
 */
export const getFromIDB = ({
  key,
  store = CACHE_OBJECT_STORE,
  resolvePortalAndUserId
}) => __disableIdb ? Promise.reject(Error('IndexedDB is disabled')) : openIndexedDBConnection({
  resolvePortalAndUserId
}).then(db => new Promise((resolve, reject) => {
  try {
    const request = db.transaction(store, 'readonly').objectStore(store).get(key);

    // @ts-expect-error This is typed as a generic event, but it will have a `result` field
    request.onsuccess = ({
      target: {
        result
      }
    }) =>
    // NOTE: Load-bearing != (not !==)
    result != null ? resolve(result) : reject(Error('Result was empty'));
    request.onerror = reject;
  } catch (e) {
    reject(e);
  }
}));
export const setInIDB = ({
  key,
  store = CACHE_OBJECT_STORE,
  value,
  resolvePortalAndUserId
}) => __disableIdb ? Promise.reject(Error('IndexedDB is disabled')) : openIndexedDBConnection({
  resolvePortalAndUserId
}).then(db => new Promise((resolve, reject) => {
  try {
    const request = db.transaction(store, 'readwrite').objectStore(store).put(value, key);
    request.onsuccess = resolve;
    request.onerror = reject;
  } catch (e) {
    reject(e);
  }
}));
export const removeAllKeysWithPrefix = ({
  prefix,
  store = CACHE_OBJECT_STORE,
  resolvePortalAndUserId
}) => {
  return openIndexedDBConnection({
    resolvePortalAndUserId
  }).then(db => new Promise((resolve, reject) => {
    try {
      const request = db.transaction(store, 'readwrite').objectStore(store).openCursor();
      request.onsuccess = () => {
        const cursor = request.result;
        if (cursor) {
          if (cursor.key.toString().startsWith(prefix)) {
            cursor.delete();
          }
          cursor.continue();
        } else {
          resolve();
        }
      };
      request.onerror = reject;
    } catch (e) {
      reject(e);
    }
  }));
};
export const removeFromIDB = ({
  key,
  store = CACHE_OBJECT_STORE,
  resolvePortalAndUserId
}) => __disableIdb ? Promise.reject(Error('IndexedDB is disabled')) : openIndexedDBConnection({
  resolvePortalAndUserId
}).then(db => new Promise((resolve, reject) => {
  try {
    const request = db.transaction(store, 'readwrite').objectStore(store).delete(key);
    request.onsuccess = resolve;
    request.onerror = reject;
  } catch (e) {
    reject(e);
  }
}));
export const clearIDB = ({
  store = CACHE_OBJECT_STORE,
  resolvePortalAndUserId
}) => openIndexedDBConnection({
  resolvePortalAndUserId
}).then(db => new Promise((resolve, reject) => {
  const request = db.transaction(store, 'readwrite').objectStore(store).clear();
  request.onsuccess = resolve;
  request.onerror = reject;
}));