import { Map as ImmutableMap } from 'immutable';
import { PROPERTY_RECORD, REFERENCE_RECORD } from './formatter/FormatTypes';
import PropertyRecordToDSFieldRecord from './formatter/PropertyRecordToDSFieldRecord';
import ReferenceRecordToDSFieldRecord from './formatter/ReferenceRecordToDSFieldRecord';
import invariant from 'react-utils/invariant';
const convertDataToDSFieldRecord = (format, assetFamily, data) => {
  if (format === REFERENCE_RECORD) {
    return ReferenceRecordToDSFieldRecord(assetFamily, data);
  } else if (format === PROPERTY_RECORD) {
    return PropertyRecordToDSFieldRecord(assetFamily, data);
  }
  invariant(true, `Format ${format} not supported by toDSFieldRecord formatter`);
  return ImmutableMap();
};
export const toDSFieldRecord = (data, assetFamily, format = REFERENCE_RECORD) => convertDataToDSFieldRecord(format, assetFamily, data);