'use es6';

import { Set as ImmutableSet, Record, Map as ImmutableMap, List } from 'immutable';
const value = Record({
  raw: null,
  label: null
});
function extractRecurse(property, dataset, values) {
  let nextValues = values;
  if (dataset.has('metrics')) {
    nextValues = dataset.get('metrics').reduce((memo, metricValue, metricProperty) => metricProperty === property ? /* eslint-disable-next-line hubspot-dev/no-reduce-accumulator-copy */
    memo.concat(metricValue.valueSeq().flatMap(val => List.isList(val) ? val : List([val])).map(raw => ImmutableMap.isMap(raw) ? raw : value({
      raw
    }))) : memo, nextValues);
  }
  if (dataset.getIn(['dimension', 'property']) === property) {
    nextValues = nextValues.concat(dataset.getIn(['dimension', 'buckets']).map(bucket => value({
      raw: bucket.get('key'),
      label: bucket.get('keyLabel')
    })));
  }
  if (dataset.has('dimension') && dataset.hasIn(['dimension', 'buckets'])) {
    nextValues = dataset.getIn(['dimension', 'buckets']).reduce((memo, bucket) => extractRecurse(property, bucket, memo), nextValues);
  }
  return nextValues;
}
export default ((property, dataset) => {
  return extractRecurse(property, dataset, ImmutableSet());
});