'use es6';

import I18n from 'I18n';
import formatShortMonthYear from 'I18n/utils/formatShortMonthYear';
import formatShortQuarterYear from 'I18n/utils/formatShortQuarterYear';
import formatMediumMonthYear from 'I18n/utils/formatMediumMonthYear';
import { List } from 'immutable';
import { SEARCH } from '../constants/configTypes';
import * as InboundDataTypes from '../constants/dataTypes/inboundDb';
import { DEFAULT_NULL_VALUES, GLOBAL_NULL } from '../constants/defaultNullValues';
import { COUNT, DISTINCT_APPROX } from '../constants/metricTypes';
import * as NumberDisplayHints from '../constants/numberDisplayHints';
import * as PropertyTypes from '../constants/property-types';
import { OWNER } from '../constants/referencedObjectTypes';
import { HUBSPOT_OWNER_ID } from '../constants/referenceTypes';
import { debug } from '../lib/debug';
import { getGuidLabel, isKnownGuid } from '../lib/guids';
import { has } from '../lib/has';
import prefix from '../lib/prefix';
import { extractPropertyNamespace } from '../properties/namespaceProperty';
import { formatForScientificNotation } from '../v2/dataset/utils';
import { currency, duration, number, percent } from './numberFormatter';
import { getTextContentFromHtml } from 'sanitize-text/sanitizers/TextSanitizer';
import { DATE_FORMATS } from '../constants/dateFormats';
const NULL = '–';
const formatHTML = value => getTextContentFromHtml(value);
export const formatDate = (value, format, method = 'portalTz') => {
  if (!value || value === GLOBAL_NULL) {
    return NULL;
  }
  const typeSafeValue = isNaN(Number(value)) ? value : Number(value);
  if (format === 'short-m-y') {
    return formatShortMonthYear(typeSafeValue, method);
  } else if (format === 'short-q-y') {
    return formatShortQuarterYear(typeSafeValue, method);
  } else if (format === 'medium-m-y') {
    return formatMediumMonthYear(typeSafeValue, method);
  }
  return I18n.moment[method](typeSafeValue).format(format);
};
const OWNER_PROPERTIES = [HUBSPOT_OWNER_ID, 'engagement.createdBy', 'engagement.modifiedBy', 'engagement.createdBy', 'engagement.ownerId'];
const missing = prefix('reporting-data.missing');
const included = prefix('reporting-data.properties.common.buckets');
const boolean = value => {
  if (value === undefined || value === null) {
    return value;
  }
  let normalized;
  if (value === 'true') {
    normalized = true;
  } else if (value === 'false') {
    normalized = false;
  } else if (typeof value === 'string') {
    // RA-1704: Handles '0' and '1', also worth noting that other strings are considered true
    normalized = Boolean(Number(value));
  } else {
    normalized = value;
  }
  return included(normalized ? 'included' : 'excluded');
};

// ID properties shouldn't be formatted as such if they're actually used for counting
const isActualCountMetric = ({
  metricType,
  configType,
  dataType
}) => [COUNT, DISTINCT_APPROX].includes(metricType) && configType !== SEARCH && has(InboundDataTypes, dataType);
const getOption = (propertyInfo, value) => propertyInfo.get('options', List()).find(option => option.get('value') === value);
export const getFallback = (propertyInfo, value) => {
  const property = propertyInfo.get('name');
  if (value === DEFAULT_NULL_VALUES.STRING) {
    // RA-1704: Handle bool mistyped as enumeration
    const isEnumeratedBoolean = propertyInfo.get('type') === PropertyTypes.ENUMERATION && propertyInfo.get('fieldType') === 'booleancheckbox';
    if (isEnumeratedBoolean) {
      return missing('value');
    }
    return OWNER_PROPERTIES.includes(property) ? missing('unassigned') : missing('value');
  }
  if (OWNER_PROPERTIES.includes(property) || propertyInfo.get('type') === PropertyTypes.ENUMERATION && (propertyInfo.get('fieldType') === OWNER || propertyInfo.get('referencedObjectType') === OWNER)) {
    return !value || value === '0' ? missing('unassigned') : missing('unknown.owner', {
      id: value
    });
  }
  return null;
};
export const formatEnumeration = value => value === '' ? missing('value') : value;
export const getPropertyTypeFromDisplayHint = displayHint => {
  switch (displayHint) {
    case NumberDisplayHints.CURRENCY:
      return PropertyTypes.CURRENCY;
    case NumberDisplayHints.DURATION:
      return PropertyTypes.DURATION;
    case NumberDisplayHints.PERCENTAGE:
      return PropertyTypes.PERCENT;
    case NumberDisplayHints.FORMATTED:
    case NumberDisplayHints.UNFORMATTED:
    default:
      return PropertyTypes.NUMBER;
  }
};
export default ((value, propertyInfo, {
  currencyCode,
  frequency,
  metricType,
  configType,
  dataType,
  smallScale
} = {}) => {
  const type = propertyInfo.get('type');
  const durationUnit = propertyInfo.get('durationUnit');
  const minimumDisplayUnit = propertyInfo.getIn(['format', 'minimumDisplayUnit']);
  const propertyMeta = propertyInfo.get('propertyMeta');
  const customPrecision = propertyMeta && propertyMeta.get('customPrecision');
  if (isKnownGuid(value)) {
    return getGuidLabel(value);
  }

  // RA-2483: try semicolon delimited list
  if (typeof value === 'string' && value.indexOf(';') >= 0 && propertyInfo.get('fieldType') === 'checkbox') {
    value = List(value.split(';')).map(val => val.trim());
  }
  if (List.isList(value)) {
    return value.isEmpty() ? List.of(NULL) : value.map(val => {
      const option = getOption(propertyInfo, String(val));
      return option && option.has('label') ? option.get('label') : getFallback(propertyInfo, val) || formatEnumeration(val);
    });
  }
  if (type === 'number' || type === 'id' || type === 'enumeration' && propertyInfo.get('externalOptions') === true && propertyInfo.get('referencedObjectType') !== null) {
    value = formatForScientificNotation(value);
  }
  if (!isActualCountMetric({
    metricType,
    configType,
    dataType
  })) {
    const option = getOption(propertyInfo, String(value));
    if (option != null && option.has('label')) {
      // NOTE: https://issues.hubspotcentral.com/browse/RA-1444
      const {
        propertyName
      } = extractPropertyNamespace(propertyInfo.get('name', ''));
      return propertyName === 'hs_persona' ? option.get('description') : option.get('label');
    }
  }
  const fallback = getFallback(propertyInfo, value);
  if (fallback !== null) {
    return fallback;
  }
  const numberDisplayHint = propertyInfo.get('numberDisplayHint');
  switch (type === PropertyTypes.NUMBER ? getPropertyTypeFromDisplayHint(numberDisplayHint) : type) {
    case PropertyTypes.ENUMERATION:
      return formatEnumeration(value);
    case PropertyTypes.STRING:
      return propertyInfo.getIn(['format', 'isHtml'], false) ? formatHTML(value) : value;
    case PropertyTypes.BUCKETS:
      return value;
    case PropertyTypes.DATE:
      return formatDate(value, DATE_FORMATS[frequency] || 'l', 'utc');
    case PropertyTypes.DATE_TIME:
      return formatDate(value, DATE_FORMATS[frequency] || 'l', 'userTz');
    case PropertyTypes.TIMESTAMP:
      return formatDate(value, 'lll', 'userTz');
    case PropertyTypes.NUMBER:
      return numberDisplayHint === NumberDisplayHints.UNFORMATTED ? value : number(value, {
        customPrecision
      });
    case PropertyTypes.PERCENT:
      return percent(value * 100);
    case PropertyTypes.CURRENCY:
      return currency(value, {
        currencyCode,
        customPrecision
      });
    case PropertyTypes.DURATION:
      return duration(value, {
        durationUnit,
        smallScale,
        minimumDisplayUnit
      });
    case PropertyTypes.BOOLEAN:
      return boolean(value);
    case PropertyTypes.UNKNOWN:
    default:
      debug.once('hydrate/propertyFormatter', `unknown type for ${value}`);
      return value;
  }
});