import { CANDY_APPLE, CHART_AQUA, CHART_ORANGE, MARIGOLD, OLAF, OZ } from 'HubStyleTokens/colors';
const OrangeToWhite = {
  id: 'OrangeToWhite',
  colors: {
    minColor: CHART_ORANGE,
    maxColor: OLAF
  },
  label: 'reporting-visualizations.conditional-formatting-palette.orange-to-white'
};
const WhiteToOrange = {
  id: 'WhiteToOrange',
  colors: {
    minColor: OLAF,
    maxColor: CHART_ORANGE
  },
  label: 'reporting-visualizations.conditional-formatting-palette.white-to-orange'
};
const AquaToWhite = {
  id: 'AquaToWhite',
  colors: {
    minColor: CHART_AQUA,
    maxColor: OLAF
  },
  label: 'reporting-visualizations.conditional-formatting-palette.aqua-to-white'
};
const WhiteToAqua = {
  id: 'WhiteToAqua',
  colors: {
    minColor: OLAF,
    maxColor: CHART_AQUA
  },
  label: 'reporting-visualizations.conditional-formatting-palette.white-to-aqua'
};
const GreenYellowRed = {
  id: 'GreenYellowRed',
  colors: {
    minColor: OZ,
    midColor: MARIGOLD,
    maxColor: CANDY_APPLE
  },
  label: 'reporting-visualizations.conditional-formatting-palette.green-yellow-red'
};
const RedYellowGreen = {
  id: 'RedYellowGreen',
  colors: {
    minColor: CANDY_APPLE,
    midColor: MARIGOLD,
    maxColor: OZ
  },
  label: 'reporting-visualizations.conditional-formatting-palette.red-yellow-green'
};
const GreenToWhite = {
  id: 'GreenToWhite',
  colors: {
    minColor: OZ,
    maxColor: OLAF
  },
  label: 'reporting-visualizations.conditional-formatting-palette.green-to-white'
};
const WhiteToGreen = {
  id: 'WhiteToGreen',
  colors: {
    minColor: OLAF,
    maxColor: OZ
  },
  label: 'reporting-visualizations.conditional-formatting-palette.white-to-green'
};
const RedToWhite = {
  id: 'RedToWhite',
  colors: {
    minColor: CANDY_APPLE,
    maxColor: OLAF
  },
  label: 'reporting-visualizations.conditional-formatting-palette.red-to-white'
};
const WhiteToRed = {
  id: 'WhiteToRed',
  colors: {
    minColor: OLAF,
    maxColor: CANDY_APPLE
  },
  label: 'reporting-visualizations.conditional-formatting-palette.white-to-red'
};
export const CONDITIONAL_PALETTES = {
  OrangeToWhite,
  WhiteToOrange,
  AquaToWhite,
  WhiteToAqua,
  GreenYellowRed,
  RedYellowGreen,
  GreenToWhite,
  WhiteToGreen,
  RedToWhite,
  WhiteToRed
};