import { useCallback, useMemo, useRef } from 'react';
import { isSucceeded } from 'reporting-data/asyncData/AsyncData';
import useFetchPropertyGroupsForEditors from '../../../hooks/useFetchPropertyGroupsForEditors';
import { getIsValidDimensionProperty, getPropertiesFromConfigReport, sortPropertyOptionsByText } from '../../../lib/reportPropertyUtils';
const getFilterInvalidDimensionPropertiesNotInReportUtil = propertiesFromReport => prop => {
  return getIsValidDimensionProperty(prop) && propertiesFromReport.includes(prop.get('name'));
};
const getPropertiesForUISelectFormatter = propertiesFromReport => group => {
  const filterInvalidDimensionPropertiesNotInReport = getFilterInvalidDimensionPropertiesNotInReportUtil(propertiesFromReport);
  const propertyOptions = group.get('properties').filter(filterInvalidDimensionPropertiesNotInReport).map(prop => {
    return {
      text: prop.get('label'),
      value: prop.get('name')
    };
  }).sort(sortPropertyOptionsByText);
  return {
    text: group.get('displayName'),
    options: propertyOptions.toList().toJS()
  };
};
const useFetchPropertyOptionsForDimensionEditorDropdown = report => {
  const initialPropertiesFromReport = useRef(getPropertiesFromConfigReport(report) || []);
  const propertiesFromReport = useMemo(() => {
    const properties = getPropertiesFromConfigReport(report);
    const initialPropArray = initialPropertiesFromReport.current || [];
    initialPropArray.forEach(initialProp => {
      if (!properties.includes(initialProp)) {
        properties.push(initialProp);
      }
    });
    return properties;
  }, [report]);
  const resultFormatter = useCallback(response => {
    return response.map(getPropertiesForUISelectFormatter(propertiesFromReport)).filter(group => {
      return group.options.length;
    }).toList().toJS();
  }, [propertiesFromReport]);
  const fetch = useFetchPropertyGroupsForEditors(report, resultFormatter);
  if (isSucceeded(fetch)) {
    return fetch.data;
  }
  return [];
};
export default useFetchPropertyOptionsForDimensionEditorDropdown;