import { OrderedMap } from 'immutable';
import I18n from 'I18n';
import isValidI18nKey from 'I18n/utils/isValidI18nKey';
import userInfo from 'hub-http/userInfo';
// @ts-expect-error ts-migrate(2307)
import { load } from 'reporting-data/dataTypeDefinitions';
// @ts-expect-error ts-migrate(2307)
import { getFields } from 'reporting-enablement/components/reportEditors/UnifiedFilterEditor/utils';
export const getUnifiedDataTypeMetadata = dataSource => {
  const reportingDataLangKey = `reporting-data.dataTypes.${dataSource}`;
  const fallbackKey = `reporting-ui-components.filterEditor.unified.objects.${dataSource}`;
  let langKey;
  if (isValidI18nKey(reportingDataLangKey)) {
    langKey = reportingDataLangKey;
  } else if (isValidI18nKey(fallbackKey)) {
    langKey = fallbackKey;
  }
  return {
    label: langKey ? I18n.text(langKey) : dataSource
  };
};
const loadAndGetUnifiedSpec = (dataSource, config) => load(dataSource).then(dataTypeModule => dataTypeModule.getUnifiedSpecForConfig(config));
export const getUnifiedDataTypeProperties = dataSource => {
  const config = {};
  return Promise.all([userInfo(), loadAndGetUnifiedSpec(dataSource, config)]).then(([{
    user: {
      scopes
    }
  }, spec]) => getFields({
    config,
    dataType: dataSource,
    spec,
    specialPropertyFilters: OrderedMap(),
    scopes,
    shouldIncludeAnalyticsViewField: true
  }).toObject());
};