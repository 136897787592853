'use es6';

import http from 'hub-http/clients/apiClient';
import userInfo from 'hub-http/userInfo';
import { fromJS, List, Map as ImmutableMap, Set as ImmutableSet } from 'immutable';
import { useEffect, useState } from 'react';
import * as checked from 'reporting-data/lib/checked';
import { getTableDescriptionMeta } from '../relational/metadata/table-description-meta';
import { SnowflakeProperty, SnowflakeTable } from '../relational/schema/source-records';
import { getTableList } from '../relational/utils/table-utils';
import { DatasetProperty, RelationalDataset } from './dataset-records';
import { fetchFunctions, FunctionMeta } from './expression-meta';
import { getExpressionFunctions } from './expression-utils';
import { tryDecodeInput } from './text-utils';
let datasetsCache;
const toRelationalDataset = datasetDefinition => RelationalDataset(datasetDefinition);
const _fetchDatasets = () => userInfo().then(({
  user: {
    scopes
  }
}) => {
  if (!scopes.includes('reporting-datasets-access')) {
    return Promise.resolve(List());
  }
  return http.get('sql-reporting/v1/datasets');
}).then(fromJS).then(datasetObjects => datasetObjects.reduce((result, datasetObject) => result.set(datasetObject.get('id'), datasetObject.update('datasetDefinition', toRelationalDataset).update('datasetDefinition', datasetDefinition => datasetDefinition.update('fields', fields => fields.map(tryDecodeInput)))), ImmutableMap())).then(datasets => {
  datasetsCache = datasets;
  return datasets;
});
export const fetchDatasets = () => {
  if (datasetsCache) {
    return Promise.resolve(datasetsCache);
  }
  return _fetchDatasets();
};
export const refreshDatasetsCache = () => {
  datasetsCache = undefined;
};
export const useFetchDatasets = () => {
  const [requestState, setRequestState] = useState({
    loading: true
  });
  const {
    loading,
    data,
    error
  } = requestState;
  useEffect(() => {
    let expired = false;
    if (loading) {
      fetchDatasets().then(datasets => {
        if (!expired) {
          setRequestState({
            loading: false,
            data: datasets
          });
        }
      }).catch(e => {
        if (!expired) {
          setRequestState({
            loading: false,
            error: e
          });
        }
      });
    }
    return () => expired = true;
  }, [loading]);
  return {
    loading,
    data,
    error
  };
};
const _fetchById = (datasetId, isGlobal = false) => {
  return http.get(isGlobal ? `sql-reporting/v1/global-datasets/${datasetId}` : `sql-reporting/v1/datasets/${datasetId}`).then(fromJS).then(datasetObject => datasetObject.update('datasetDefinition', toRelationalDataset).update('datasetDefinition', datasetDefinition => datasetDefinition.update('fields', fields => fields.map(tryDecodeInput))));
};

// { datasetId: number, request: Promise<DatasetDefinition> }
let fetchByIdRequestCache;
export const fetchById = (datasetId, shouldUseCache = true, isGlobal = false) => {
  if (shouldUseCache && datasetsCache && datasetsCache.has(datasetId)) {
    return Promise.resolve(datasetsCache.get(datasetId));
  }
  if (shouldUseCache && fetchByIdRequestCache && fetchByIdRequestCache.datasetId === datasetId) {
    return fetchByIdRequestCache.request;
  }
  const request = _fetchById(datasetId, isGlobal);
  fetchByIdRequestCache = {
    datasetId,
    request
  };
  return request;
};
export const useFetchDatasetById = (datasetId, shouldUseCache = true, isGlobal = false) => {
  const [requestState, setRequestState] = useState({
    loading: true
  });
  const {
    loading,
    data,
    error
  } = requestState;
  useEffect(() => {
    if (datasetId == null) {
      setRequestState({
        loading: false,
        error: new Error('No dataset id')
      });
      return;
    }
    fetchById(datasetId, shouldUseCache, isGlobal).then(response => {
      setRequestState({
        data: response,
        loading: false
      });
    }).catch(e => {
      setRequestState({
        error: e,
        loading: false
      });
    });
  }, [datasetId, shouldUseCache, isGlobal]);
  return {
    loading,
    data,
    error
  };
};
export const DatasetMeta = checked.record({
  // Map<tableName, SnowflakeTable>
  tables: checked.map(SnowflakeTable),
  // Map<tableName, Map<propertyName, SnowflakeProperty>>
  properties: checked.map(checked.map(SnowflakeProperty)),
  // Map<tableName, Map<propertyName, DatasetProperty>>
  datasetProperties: checked.map(checked.map(DatasetProperty)),
  // List<FunctionMeta>
  functions: checked.list(FunctionMeta)
});
export const resolveDatasetMeta = dataset => Promise.all([getTableDescriptionMeta(dataset.table, true), fetchFunctions()]).then(([{
  tables: snowflakeTables,
  properties: snowflakeProperties
}, functionMeta]) => {
  const tables = getTableList(dataset.table).toMap().mapKeys((_, table) => table ? table.name : '').filter((_, maybeTableName) => !!maybeTableName).map(table => snowflakeTables.get(table.name)).toMap();
  const properties = dataset.properties.map(tableProperties => tableProperties.map(property => snowflakeProperties.getIn([property.table, property.name])));
  const functions = dataset.fields.reduce((result, field) => result.union(getExpressionFunctions(field.expression, functionMeta)), ImmutableSet());
  return DatasetMeta({
    tables,
    properties,
    functions
  });
});