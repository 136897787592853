'use es6';

import DisplayValueEnumeration from 'customer-data-filters/components/display/DisplayValueEnumeration';
import FilterOperatorEnumInput from 'customer-data-filters/components/operator/FilterOperatorEnumInput';
import * as OperatorTypes from 'customer-data-filters/converters/contactSearch/FilterContactSearchOperatorTypes';
import DSFieldRecord from 'customer-data-filters/filterQueryFormat/DSFieldRecord/DSFieldRecord';
import And from 'customer-data-filters/filterQueryFormat/logic/And';
import Equal from 'customer-data-filters/filterQueryFormat/operator/Equal';
import In from 'customer-data-filters/filterQueryFormat/operator/In';
import * as Operators from 'customer-data-filters/filterQueryFormat/operator/Operators';
import PropertyOptionRecord from 'customer-data-objects/property/PropertyOptionRecord';
import { BOOLEAN, DATE, DATE_TIME, ENUMERATION, NUMBER, STRING } from 'customer-data-objects/property/PropertyTypes';
import I18n from 'I18n';
import { fromJS, List, Map as ImmutableMap, OrderedMap, OrderedSet } from 'immutable';
import { ANALYTICS_VIEW, BLOGS, BLOG_AUTHOR, BLOG_TAG, SUBDOMAIN, SUBDOMAIN_ID, USER } from 'reference-resolvers/constants/ReferenceObjectTypes';
import { ANALYTICS_EVENT_COMPLETIONS, ANALYTICS_SALES_TEMPLATES, ANALYTICS_SOURCES, ANALYTICS_TOPICS, SOCIAL_CHANNELS, UNIFIED_FEEDBACK_TRENDS } from 'reporting-data/constants/dataTypes';
import * as unifiedDataTypes from 'reporting-data/constants/dataTypes/unified';
import { load } from 'reporting-data/dataTypeDefinitions';
import * as overrides from 'reporting-data/lib/overrides';
import { createPropertiesFromUnifiedObjects, createUnifiedTranslationKey } from 'reporting-data/properties/data-type/unified';
import * as collect from 'reporting-data/retrieve/unified/labels/collect';
import { dataTypeSupportsAnalyticsViews } from '../UnifiedAnalyticsViewFilterEditor/analyticsViewUtils';
const isDProperty = /^d\d$/;
export const unifiedReferences = {
  filterId: ANALYTICS_VIEW,
  CONTENT_GROUP_ID: BLOGS,
  BLOG_AUTHOR_ID: BLOG_AUTHOR,
  TAG_IDS: BLOG_TAG,
  subdomain: SUBDOMAIN,
  DOMAIN_ID: SUBDOMAIN_ID,
  CREATED_BY: USER
};
export const unifiedPropertyResolvers = Object.keys(unifiedDataTypes).reduce((acc, key) => Object.assign({}, acc, {
  [key]: unifiedReferences
}), {});
const dateDefaults = OrderedSet.of(Operators.Less, Operators.Greater, Operators.InRange);
const TypeToOperators = ImmutableMap({
  [BOOLEAN]: OrderedSet.of(Operators.Equal, Operators.NotEqual),
  [DATE]: dateDefaults,
  [DATE_TIME]: dateDefaults,
  [ENUMERATION]: OrderedSet.of(Operators.Equal, Operators.In),
  [NUMBER]: OrderedSet.of(Operators.Equal, Operators.GreaterOrEqual, Operators.LessOrEqual, Operators.InRange),
  [STRING]: OrderedSet.of(Operators.Equal, Operators.In)
});
const DataTypeToTypeToOperatorsOverrides = ImmutableMap({
  [ANALYTICS_SOURCES]: TypeToOperators.set(ENUMERATION, OrderedSet.of(Operators.Equal))
});
const ContactSearchOperators = ImmutableMap({
  [OperatorTypes.EQUAL]: Operators.Equal,
  [OperatorTypes.GREATER_OR_EQUAL]: Operators.GreaterOrEqual,
  [OperatorTypes.GREATER]: Operators.Greater,
  [OperatorTypes.IN_RANGE]: Operators.InRange,
  [OperatorTypes.IN]: Operators.In,
  [OperatorTypes.KNOWN]: Operators.Known,
  [OperatorTypes.LESS_OR_EQUAL]: Operators.LessOrEqual,
  [OperatorTypes.LESS]: Operators.Less,
  [OperatorTypes.NEAR]: Operators.Near,
  [OperatorTypes.NOT_EQUAL]: Operators.NotEqual,
  [OperatorTypes.NOT_IN]: Operators.NotIn,
  [OperatorTypes.NOT_KNOWN]: Operators.NotKnown
});
export const customFieldDefinitions = fromJS({
  filters: {
    inputType: 'enumeration',
    operators: OrderedSet([In]),
    InputComponent: FilterOperatorEnumInput,
    ValueComponent: DisplayValueEnumeration
  },
  PUBLISH_STATUS_ID: {
    inputType: 'enumeration',
    operators: OrderedSet([Equal, In]),
    InputComponent: FilterOperatorEnumInput,
    ValueComponent: DisplayValueEnumeration
  }
});
const translatePropertyForDataType = dataType => key => I18n.text(`reporting-data.properties.${createUnifiedTranslationKey(dataType)}.${key}`);
export const hasUnifiedFilterEditorAccess = ({
  scopes
}) => {
  return scopes.includes('analytics-filer-editor-access');
};
export const getOperatorsForType = type => TypeToOperators.get(type);
export const getOperatorsForTypeFromDataType = dataType => {
  const dataTypeBasedOverrides = DataTypeToTypeToOperatorsOverrides.get(dataType);
  return dataTypeBasedOverrides ? type => dataTypeBasedOverrides.get(type) : getOperatorsForType;
};
export const getFilters = report => report.getIn(['config', 'filters', 'custom'], List());
export const getDrilldownFilters = report => getFilters(report).filter(filter => isDProperty.test(filter.get('property', '')));
export const getValuePromise = ({
  config,
  spec,
  breakdowns,
  metadata,
  values
}) => {
  if (typeof breakdowns === 'object') {
    return Promise.resolve(breakdowns);
  } else if (metadata) {
    return collect.fetchMetadataValues(spec, config)(metadata);
  } else if (typeof breakdowns === 'string') {
    return load(config.dataType).then(dataTypeModule => {
      const referenceFn = dataTypeModule.getIn(['references', breakdowns])(spec, config);
      return referenceFn(values);
    });
  }
  return Promise.resolve({});
};
export const getDrilldownLabels = (report, spec) => {
  const config = report.get('config').toJS();
  return getDrilldownFilters(report).map(filter => {
    const currentD = filter.get('property');
    const dValue = filter.get('value');
    const oldD = `d${currentD[1] - 1}`;
    if (!spec[oldD]) {
      return Promise.resolve({});
    }
    const {
      default: title,
      breakdowns,
      metadata
    } = spec[oldD];
    return getValuePromise({
      config,
      spec,
      breakdowns,
      metadata,
      values: [dValue]
    }).then(fromJS).then(references => ({
      title,
      value: references.get(dValue)
    }));
  });
};
export const getSelectedSeriesLabels = (report, spec) => {
  const config = report.get('config').toJS();
  const fFilter = getFilters(report).find(f => f.get('property') === 'filters');
  if (!fFilter) {
    return null;
  }
  const currentDLevel = getDrilldownFilters(report).count();
  const {
    default: title,
    breakdowns,
    metadata
  } = spec[`d${currentDLevel}`];
  const values = fFilter.get('values', List()).toArray();
  return getValuePromise({
    breakdowns,
    metadata,
    config,
    spec,
    values
  }).then(fromJS).then(references => ({
    title,
    value: values.map(val => references.get(val, null)).filter(x => x).join(', ')
  }));
};
export const getDrilldownFilterPromises = ({
  report,
  spec
}) => {
  const config = report.get('config').toJS();
  return getDrilldownFilters(report).map(filter => {
    const currentD = filter.get('property');
    const dValue = filter.get('value');
    const oldD = `d${currentD[1] - 1}`;
    if (!spec[oldD]) {
      return Promise.resolve({});
    }
    const {
      default: label,
      breakdowns,
      metadata
    } = spec[oldD];
    return getValuePromise({
      config,
      spec,
      breakdowns,
      metadata,
      values: [dValue]
    }).then(fromJS).then(enums => {
      return DSFieldRecord({
        name: currentD,
        label,
        type: 'enumeration',
        isHiddenFromSelect: true,
        options: enums.reduce((opts, optionLabel, value) => opts.push(PropertyOptionRecord({
          label: optionLabel,
          value
        })), List())
      });
    });
  });
};
export const getSelectedSeriesPromise = ({
  report,
  spec
}) => {
  const config = report.get('config').toJS();
  const fFilter = getFilters(report).find(f => f.get('property') === 'filters');
  if (!fFilter) {
    return null;
  }
  const currentDLevel = getDrilldownFilters(report).count();
  const {
    default: label,
    breakdowns,
    metadata
  } = spec[`d${currentDLevel}`];
  const values = fFilter.get('values', List()).toArray();
  return getValuePromise({
    breakdowns,
    metadata,
    config,
    spec,
    values
  }).then(fromJS).then(enums => {
    return DSFieldRecord({
      name: 'filters',
      label,
      type: 'enumeration',
      hidden: true,
      options: enums.reduce((opts, optionLabel, value) => opts.push(PropertyOptionRecord({
        label: optionLabel,
        value
      })), List())
    });
  });
};
const removeAnalyticsViewField = ({
  dataType,
  fields
}) => {
  return dataTypeSupportsAnalyticsViews(dataType) ? fields.delete('filterId') : fields;
};
export const modifyFieldsForDataType = ({
  dataType,
  config,
  fields,
  shouldIncludeAnalyticsViewField = false
}) => {
  const bulkDataTypeModifications = !shouldIncludeAnalyticsViewField ? [removeAnalyticsViewField] : [];
  const bulkModifiedFields = bulkDataTypeModifications.reduce((modifiedFields, modifyFunction) => modifyFunction({
    dataType,
    config,
    fields: modifiedFields
  }), fields);
  switch (dataType) {
    case ANALYTICS_SALES_TEMPLATES:
      if (getDrilldownFilters(ImmutableMap({
        config
      })).isEmpty()) {
        return OrderedMap();
      }
      return bulkModifiedFields;
    case ANALYTICS_TOPICS:
      return bulkModifiedFields.delete('source');
    case ANALYTICS_SOURCES:
      return bulkModifiedFields.delete('subdomain');
    case ANALYTICS_EVENT_COMPLETIONS:
      return bulkModifiedFields.delete('IS_SOFT_DELETED').delete('WAITLISTED');
    case unifiedDataTypes.UNIFIED_ADS_CAMPAIGNS:
      return bulkModifiedFields.delete('NAME').delete('NETWORK');
    case UNIFIED_FEEDBACK_TRENDS:
    case SOCIAL_CHANNELS:
      return OrderedMap();
    default:
      return bulkModifiedFields;
  }
};
const hideMissingEnums = field => {
  if (field.get('type') !== 'enumeration') return true;
  return unifiedReferences[field.get('name')] || field.get('options') && !field.get('options').isEmpty();
};
const hideRestrictedFields = scopes => field => {
  const fieldScope = {
    filterId: 'analytics-filters-read'
  }[field];
  return !fieldScope || scopes.includes(fieldScope);
};
export const isMetricFilteringSupported = (config, spec) => {
  return overrides.unifiedMetricFilters.enabled && spec.url.includes('activities/v2/reports');
};
export const getFields = ({
  dataType,
  spec,
  specialPropertyFilters,
  config,
  scopes = [],
  shouldIncludeAnalyticsViewField
}) => {
  const metricProperties = isMetricFilteringSupported(config, spec) ? spec.metrics : {};
  const fields = createPropertiesFromUnifiedObjects([spec.filters || {}, spec.metadata || {}, metricProperties], key => translatePropertyForDataType(dataType)(key));
  const initialFields = fields.reduce((acc, field) => acc.set(field.name, DSFieldRecord(fromJS(field)).update('options', options => options ? options.map(PropertyOptionRecord) : options).set('displayType', field.type).update('type', type => type === 'percent' || type === 'duration' ? 'number' : type).set('description', field.type === 'duration' ? I18n.text('reporting-ui-components.filterEditor.unified.durationDescription') : '')), specialPropertyFilters);
  return modifyFieldsForDataType({
    fields: initialFields,
    dataType,
    config,
    shouldIncludeAnalyticsViewField
  }).filter(hideMissingEnums).filter(hideRestrictedFields(scopes));
};
export const convertFromContactSearch = ({
  dataType,
  fields,
  filters
}) => {
  const conditions = filters.map(filter => {
    const property = filter.get('property');
    const field = fields.get(property);
    const operator = ContactSearchOperators.get(filter.get('operator')) || Equal;
    if (!field) return null;
    const value = filter.get('value') !== undefined && filter.get('value') !== null ? filter.get('value') : filter.get('values');
    const highValue = filter.get('highValue');
    return operator.ofUnsafe(field, value, highValue);
  });
  return And.of(...conditions.filter(condition => condition).map(condition => condition.update('value', val => val === 'true' ? true : val === 'false' ? false : val))).set('filterFamily', dataType);
};