'use es6';

import { fromJS, List, Map as ImmutableMap } from 'immutable';
import { getFilterByProperty } from '../../config/filters/functions';
import { EQ } from '../../constants/operators';
import extractUniqueValues from '../../dataset/extract-unique-values';
import chunk from '../../lib/async/chunk';
import toJS from '../../lib/toJS';
import * as http from '../../request/http';
import { makeOption, Option } from '../Option';
const REASON_PROPERTY = 'hs_marketable_reason_id';
const REASON_TYPE_PROPERTY = 'hs_marketable_reason_type';
const REASON_ID_NAME = 'reasonSourceObjectId';
const getReasons = (config, data) => {
  const reasonTypeFilter = getFilterByProperty(config, REASON_TYPE_PROPERTY) || ImmutableMap();
  const reasonTypes = reasonTypeFilter.get('operator') === EQ && reasonTypeFilter.get('value') ? List([reasonTypeFilter.get('value')]) : reasonTypeFilter.get('values') || List();
  if (reasonTypes.size !== 1) {
    return null;
  }
  return extractUniqueValues(REASON_PROPERTY, data).map(reasonId => ImmutableMap({
    [REASON_ID_NAME]: reasonId,
    reasonType: reasonTypes.first()
  }));
};
const getReasonIdsByVid = data => {
  return data.getIn(['dimension', 'buckets']).reduce((acc, bucket) => acc.set(bucket.get('key'), bucket.getIn(['metrics', REASON_PROPERTY], ImmutableMap()).first()), ImmutableMap()).filter(val => !!val);
};
export const adapt = get => (config, _, data) => {
  const reasons = getReasons(config, data);
  const reasonsByVid = getReasonIdsByVid(data);
  const ids = reasons ? reasons.toJS() : reasonsByVid.keySeq().map(Number).toJS();
  return get(ids).then(options => {
    return reasons ? options : options.mapKeys(vid => reasonsByVid.get(vid)).map((val, key) => val.set('value', key));
  }).then(value => ImmutableMap({
    key: REASON_PROPERTY,
    value
  }));
};
const batchRequestByVid = ids => chunk(() => http.post('marketable-contacts/v1/marketable-labels/vids', {
  data: ids
}).then(toJS), responses => responses.reduce((acc, response) => Object.assign({}, acc, response), {}), ids);
const batchRequestByReason = ids => chunk(() => http.post('marketable-contacts/v1/marketable-labels/reasons', {
  data: ids
}).then(toJS), responses => responses.reduce((acc, response) => [/* eslint-disable-next-line hubspot-dev/no-reduce-accumulator-copy */
...acc, ...response], []), ids);
export const generateMarketableReasonIdLabel = marketableReasonInfo => marketableReasonInfo.get('label');
export const getMarketableContactReferences = ids => {
  if (ids.length === 0) {
    return Promise.resolve(ImmutableMap());
  }
  const shouldFetchByVid = typeof ids[0] === 'number';
  if (shouldFetchByVid) {
    return batchRequestByVid(ids).then(response => {
      return ids.reduce((options, vid) => {
        const reference = response[Number(vid)];
        return options.set(String(vid), reference ? new Option(Object.assign({}, reference, {
          value: vid
        })) : makeOption(String(vid)));
      }, ImmutableMap());
    });
  }
  return batchRequestByReason(ids).then(fromJS).then(response => {
    return ids.reduce((options, id) => {
      const reference = response.find(result => result.get(REASON_ID_NAME) === id[REASON_ID_NAME], ImmutableMap()).get('reportingLabel', ImmutableMap());
      const key = id[REASON_ID_NAME];
      return options.set(key, new Option(reference.set('value', key).toJS()));
    }, ImmutableMap());
  });
};