export const SALES_SUB_APPS = {
  activities: 'activities',
  callOutcomes: 'callOutcomes',
  taskOutcomes: 'taskOutcomes',
  conversations: 'conversations',
  lossReasons: 'lossReasons',
  salesBySource: 'salesBySource',
  dealJourney: 'dealJourney',
  dealsCreatedByRep: 'dealsCreatedByRep',
  dealsWon: 'dealsWon',
  weightedPipelineForecast: 'weightedPipelineForecast',
  averageSalesPrice: 'averageSalesPrice',
  timeInDealStage: 'timeInDealStage',
  activityStream: 'activityStream',
  dealVelocity: 'dealVelocity',
  leadResponseTime: 'leadResponseTime',
  waterfall: 'waterfall',
  changeHistoryDealList: 'changeHistoryDealList',
  dealPushAnalysis: 'dealPushAnalysis',
  pipelineSnapshots: 'pipelineSnapshots',
  quotaAttainment: 'quotaAttainment',
  manualForecast: 'manualForecast',
  manualForecastTotal: 'manualForecastTotal',
  meetingOutcomes: 'meetingOutcomes',
  prospectEngagement: 'prospectEngagement',
  leadFunnel: 'leadFunnel',
  salesVelocity: 'salesVelocity',
  lineItemRevenue: 'lineItemRevenue'
};
export const SERVICE_SUB_APPS = {
  ticketTimeInPipeline: 'ticketTimeInPipeline',
  supportVolume: 'supportVolume',
  resolutionTime: 'resolutionTime',
  queueTime: 'queueTime',
  postSupportCSAT: 'postSupportCSAT',
  customerEffortScore: 'customerEffortScore',
  customerReplyTime: 'customerReplyTime',
  customerWaitTime: 'customerWaitTime',
  ownerReplyCount: 'ownerReplyCount',
  ownerReplyTime: 'ownerReplyTime',
  ticketReplyCount: 'ticketReplyCount',
  timeOfDay: 'timeOfDay',
  workingHoursAvailability: 'workingHoursAvailability',
  maxAvailableRep: 'maxAvailableRep',
  repAvailability: 'repAvailability'
};
export const SUBSCRIPTION_SUB_APPS = {
  newMonthlyRecurringRevenue: 'newMonthlyRecurringRevenue',
  newAnnualRecurringRevenue: 'newAnnualRecurringRevenue',
  churnMonthlyRecurringRevenue: 'churnMonthlyRecurringRevenue',
  churnAnnualRecurringRevenue: 'churnAnnualRecurringRevenue',
  churnSubscriptions: 'churnSubscriptions',
  newSubscriptions: 'newSubscriptions'
};
export const MARKETING_SUB_APPS = {
  attribution: 'attribution',
  ads: 'ads',
  leadCapture: 'leadCapture',
  marketingEmails: 'marketingEmails',
  sources: 'sources',
  pages: 'pages',
  sms: 'sms',
  geolocation: 'geolocation',
  utmParameters: 'utmParameters',
  browsers: 'browsers',
  deviceTypes: 'deviceTypes',
  topicClusters: 'topicClusters',
  marketingRevenueImpact: 'marketingRevenueImpact',
  adImpact: 'adImpact',
  blogImpact: 'blogImpact',
  formImpact: 'formImpact',
  landingPageImpact: 'landingPageImpact',
  marketingSources: 'marketingSources',
  contactInsights: 'contactInsights',
  customerJourneyAnalytics: 'customerJourneyAnalytics',
  cta: 'cta'
};
export const SUB_APPS = Object.assign({}, SALES_SUB_APPS, SERVICE_SUB_APPS, SUBSCRIPTION_SUB_APPS, MARKETING_SUB_APPS);