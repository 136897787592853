import { Map as ImmutableMap } from 'immutable';
import { useCallback, useMemo } from 'react';
import { useAsyncFetcher } from '../asyncData/useAsyncFetcher';
// @ts-expect-error Untyped import
import getProperties from '../properties';
const useFetchPropertyMetaDataForDataType = (dataType, properties, deferred = false) => {
  const resultFormatter = useCallback(propertyMetaDataList => {
    const propertyMap = dataType ? propertyMetaDataList.get(dataType) : ImmutableMap({});
    if (!properties) {
      return propertyMap;
    }
    return propertyMap.filter(propertyMetaData => {
      return properties.includes(propertyMetaData.get('name'));
    });
  }, [dataType, properties]);
  const fetcher = useCallback(dataTypeParam => getProperties(dataTypeParam), []);
  const requestArgs = useMemo(() => dataType, [dataType]);
  return useAsyncFetcher({
    fetcher,
    requestArgs,
    resultFormatter,
    deferred: deferred || dataType === undefined
  });
};
export default useFetchPropertyMetaDataForDataType;