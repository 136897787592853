import { createSlice } from '@reduxjs/toolkit';
import { getAppliedFiltersByReportFromDashboard, setAsyncState } from './helpers';
import { getOverriddenReportsFromAPIThunk } from './thunks';
import { buildFailedAsyncData, buildStartedAsyncData, buildSucceededAsyncData } from 'reporting-data/asyncData/AsyncData';
/// @ts-expect-error migrate dependency
import { getDashboardReports } from 'dashboard-lib/public/dashboard/dashboard-logic';
import { getReportId } from 'reporting-data/report/reportGetters';
export const OVERRIDDEN_REPORTS_NAMESPACE = 'overriddenReportsNamespace';
const initialState = {
  overriddenReports: {}
};
const overriddenReportsSlice = createSlice({
  name: OVERRIDDEN_REPORTS_NAMESPACE,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getOverriddenReportsFromAPIThunk.pending, (state, action) => {
      const {
        arg: {
          dashboardId
        }
      } = action.meta;
      setAsyncState(state, dashboardId, buildStartedAsyncData());
    }).addCase(getOverriddenReportsFromAPIThunk.rejected, (state, action) => {
      const {
        meta: {
          arg: {
            dashboardId
          }
        },
        payload: error
      } = action;
      setAsyncState(state, dashboardId, buildFailedAsyncData(error));
    }).addCase(getOverriddenReportsFromAPIThunk.fulfilled, (state, action) => {
      const {
        meta: {
          arg: {
            dashboardId
          }
        },
        payload: dashboard
      } = action;
      const dashboardReports = getDashboardReports(dashboard);
      const overriddenReportsConfig = dashboardReports.reduce((nextDashboardReports, reportConfig) => {
        const reportId = getReportId(reportConfig);
        if (reportId) {
          nextDashboardReports[reportId] = reportConfig;
        }
        return nextDashboardReports;
      }, {});
      state.overriddenReports[dashboardId] = {
        asyncState: buildSucceededAsyncData(true),
        appliedFiltersByReport: getAppliedFiltersByReportFromDashboard(dashboard),
        reports: overriddenReportsConfig
      };
    });
  }
});
export const overriddenReportsActions = overriddenReportsSlice.actions;
export const overriddenReportsReducer = overriddenReportsSlice.reducer;