import { useCallback, useRef } from 'react';
import { isSucceeded } from 'reporting-data/asyncData/AsyncData';
import useFetchPropertyGroupsForEditors from '../../../hooks/useFetchPropertyGroupsForEditors';
import { getIsValidConfigMetricProperty, getPropertiesFromConfigReport } from '../../../lib/reportPropertyUtils';
const getPropertiesForUISelectFormatter = (propertiesFromReport, report) => group => {
  const propertyOptions = group.get('properties').filter(prop => {
    return getIsValidConfigMetricProperty(prop, report) && propertiesFromReport.includes(prop.get('name'));
  }).map(prop => {
    return {
      text: prop.get('label'),
      value: prop.get('name')
    };
  });
  return {
    text: group.get('displayName'),
    options: propertyOptions.toList().toJS()
  };
};
const useFetchPropertyOptionsForMetricsEditor = report => {
  const initialPropertiesFromReport = useRef(getPropertiesFromConfigReport(report) || []);
  const resultFormatter = useCallback(response => {
    const propertiesFromReport = getPropertiesFromConfigReport(report);
    const initialPropArray = initialPropertiesFromReport.current || [];
    initialPropArray.forEach(initialProp => {
      if (!propertiesFromReport.includes(initialProp)) {
        propertiesFromReport.push(initialProp);
      }
    });
    return response.map(getPropertiesForUISelectFormatter(propertiesFromReport, report)).filter(group => {
      return group.options.length;
    }).toList().toJS();
  }, [report]);
  const fetch = useFetchPropertyGroupsForEditors(report, resultFormatter);
  if (isSucceeded(fetch)) {
    return fetch.data;
  }
  return [];
};
export default useFetchPropertyOptionsForMetricsEditor;