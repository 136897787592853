import { getTableDescriptionMetaWithCache } from 'reporting-snowflake/relational/metadata/table-description-meta';
import { RelationalReport as SnowflakeRelationalReport } from 'reporting-snowflake/relational/schema/report-records';
import useFetchUserInfo from './useFetchUserInfo';
import { useEffect, useState } from 'react';
import { isRelationalReport } from 'reporting-data/tsTypes/reportTypes';
const isPromise = value => !!(value && typeof value === 'object' && 'then' in value && typeof value.then === 'function');
const useFetchDependenciesForSnowflakeUpdaters = report => {
  const [reportDeps, setReportDeps] = useState();
  const [tableMetaIsLoading, setTableMetaIsLoading] = useState(false);
  const isSnowflakeReport = isRelationalReport(report);
  const userInfo = useFetchUserInfo();
  useEffect(() => {
    if (isSnowflakeReport) {
      const reportDefinition = SnowflakeRelationalReport(report.get('reportDefinition').toJS());
      const tableDescription = reportDefinition.table;
      const tableDescriptionMeta = getTableDescriptionMetaWithCache(tableDescription);
      if (isPromise(tableDescriptionMeta)) {
        setTableMetaIsLoading(true);
      }
      if (!isPromise(tableDescriptionMeta) && userInfo) {
        setTableMetaIsLoading(false);
        setReportDeps({
          tableDescriptionMeta,
          userInfo
        });
      }
    }
  }, [userInfo, isSnowflakeReport, report, tableMetaIsLoading]);
  return reportDeps;
};
export default useFetchDependenciesForSnowflakeUpdaters;