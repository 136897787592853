import { gate } from 'hub-http/gates';
export const CRM_SEARCH_FILTERS_V2 = 'ReportingEnablement:FiltersV2';
export const CRM_SEARCH_FILTERS_V2_UA_REPORTS = 'ReportingEnablement:FiltersV2:UAReports';
export const CRM_SEARCH_FILTERS_V2_UEQL_REPORTS = gate('ReportingEnablement:FiltersV2:UAQLReports');
export const JOURNEY_REPORT_FILTERS_BAR = gate('ReportingEnablement:FiltersV2:JourneyReports');
export const CRM_DEAL_LIST_CREATION = 'CRM:Segments:DealListsCreation';
export const USE_REPORT_CONFIG_TO_CREATE_LIST = 'Reporting:UseReportConfigToCreateList';
export const ANOMALY_DETECTION_GATE = gate('RaaS:AnomalyDetection');
export const hasUpgradeEngagementGate = ({
  gates
}) => gates && gates.includes('RAAS:ReturnEngagementsAsAValidObjectType');
export const ENGAGEMENT_SOURCE_MIGRATION = 'RM:Migration:EngagementSourceConfigMigration';