import { useEffect, useState } from 'react';
import events from '../../events.yaml';
import { emptyTracker, isEmptyTracker } from 'reporting-data/tsTypes/usageTrackerTypes';
import { getUsageTracker } from '../monitoring/usageTracker';

/**
 * Custom hook to clone a usage tracker.
 *
 * @param {Tracker | undefined} appUsageTracker - The application usage tracker.
 * @returns {Tracker | EmptyTracker} - The cloned usage tracker or an empty tracker.
 * @deprecated This hook is deprecated and should not be used. Instead, use `getUsageTracker` for usage tracking in reporting-ui-components.
 * @see `getUsageTracker` - `import { getUsageTracker } from 'reporting-ui-components/monitoring/usageTracker'`;
 */
const useCloneTracker = appUsageTracker => {
  const [usageTracker, setUsageTracker] = useState(emptyTracker);
  const primaryTracker = getUsageTracker();
  useEffect(() => {
    if (!isEmptyTracker(primaryTracker)) {
      setUsageTracker(primaryTracker);
    } else if (appUsageTracker) {
      setUsageTracker(appUsageTracker.clone({
        events,
        preserveTrackerProperties: false
      }) || emptyTracker);
    }
  }, [appUsageTracker, primaryTracker]);
  return usageTracker;
};
export default useCloneTracker;