import { getTypeMetadataQuickFetches, makePropertyMetadataEntriesFetchKey, makePropertyMetadataEntriesFetchUrl, makeTypeMetadataEntriesFetchUrl, takeTypeMetadataQuickFetch } from 'framework-data-schema-quick-fetch';
import { withQuickFetch } from 'framework-data-schema-quick-fetch/withQuickFetch';
import { keyEntriesByAppSettings } from './keyEntriesByAppSettings';

/**
 * This function actually makes our fetch request! It has a small optimization to avoid
 * checking for empty arrays higher up.
 * @param options.appSettingNames A list of app settings to query
 * @param options.httpClient The http client to use for the request
 * @returns
 */
const maybeFetchTypeEntries = ({
  appSettingNames,
  httpClient
}) => {
  if (appSettingNames.length === 0) {
    return Promise.resolve({});
  }
  return httpClient.post(makeTypeMetadataEntriesFetchUrl(), {
    data: appSettingNames
  }).then(keyEntriesByAppSettings);
};

/**
 * This function maps all app settings to a promise that can come from a quick fetch (if available)
 * or a batch fetch. It then waits for all promises to resolve and combines the results into a single object.
 * @param opts.appSettingNames A list of app settings to query
 * @param opts.httpClient The http client to use for the request
 * @returns All app settings results merged into a single object. Failures are ignored
 */
const fetchTypeMetadataEntries = async ({
  appSettingNames,
  httpClient
}) => {
  const quickFetches = getTypeMetadataQuickFetches();
  const batchFetch = maybeFetchTypeEntries({
    httpClient,
    appSettingNames: appSettingNames.filter(appSettingName => !quickFetches.has(appSettingName))
  });
  const results = await Promise.allSettled(appSettingNames.map(appSettingName => {
    const quickFetched = takeTypeMetadataQuickFetch(appSettingName);
    return (!quickFetched ? batchFetch : quickFetched.catch(() => maybeFetchTypeEntries({
      httpClient,
      appSettingNames: [appSettingName]
    }))).then(result => result[appSettingName]);
  }));

  // Combine all the results into a single object
  return Object.fromEntries(appSettingNames.map((name, index) => [name, results[index]]));
};

/**
 * Makes a function that appears to fetch type metadata entries for a single app setting.
 * It actually batches calls together within a configurable window, and handles delegation
 * to quick-fetched requests if there are any. It will also dedupe requests for a given app setting
 * within the batch window.
 * @param opts.debounceInterval The interval in milliseconds to wait before making a request
 */
export const makeFetchTypeMetadataEntries = ({
  debounceInterval = 10
} = {}) => {
  let timeout;
  const queuedFetchPromises = new Map();
  return ({
    appSettingName,
    httpClient
  }) => {
    const performFetch = appSettingNames => {
      fetchTypeMetadataEntries({
        appSettingNames,
        httpClient
      }).then(results => {
        for (const name of appSettingNames) {
          const result = results[name];
          const queuedFetchPromiseResolvers = queuedFetchPromises.get(name);
          queuedFetchPromises.delete(name);
          if (result.status === 'fulfilled') {
            queuedFetchPromiseResolvers === null || queuedFetchPromiseResolvers === void 0 || queuedFetchPromiseResolvers.map(({
              resolve
            }) => resolve(result.value));
          } else if (result.status === 'rejected') {
            queuedFetchPromiseResolvers === null || queuedFetchPromiseResolvers === void 0 || queuedFetchPromiseResolvers.map(({
              reject
            }) => reject(result.reason));
          }
        }
      }).catch(err => {
        throw err;
      });
    };
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    timeout = setTimeout(() => performFetch([...queuedFetchPromises.keys()]), debounceInterval);
    return new Promise((resolve, reject) => {
      const queuedFetchPromiseResolvers = queuedFetchPromises.get(appSettingName) || [];
      queuedFetchPromises.set(appSettingName, queuedFetchPromiseResolvers.concat([{
        resolve,
        reject
      }]));
    });
  };
};
export const fetchPropertyMetadataEntries = ({
  frameworkTypeIdentifier,
  appSettingName,
  httpClient
}) => withQuickFetch({
  requestName: makePropertyMetadataEntriesFetchKey({
    frameworkTypeIdentifier,
    appSettingName
  }),
  baseFetch: () => httpClient.get(makePropertyMetadataEntriesFetchUrl({
    frameworkTypeIdentifier,
    appSettingName
  }))
});