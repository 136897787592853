import I18n from 'I18n';
import unescapedText from 'I18n/utils/unescapedText';
import { PERCENTAGE } from 'reporting-data/constants/numberDisplayHints';
import { List } from 'immutable';
//@ts-expect-error Untyped import
import store from 'reporting-data/redux/store';
import { AggregationTypeDefinitions, AggregationTypes, BasicTypes, Column, DateTruncationTypes, DomainTypes, Field, FieldSources, FieldTypes, Formats, Transform, TransformTypes, UNDEFINED_BUCKET } from '../schema/column-records';
import { DATASET_TABLE } from '../schema/table-records';
import { DataSourceTypes } from '../schema/source-records';
import { FieldRef } from '../schema/field-ref';
import { DEFAULT_COLUMN_SORT } from '../modify/sorts/sort-utils';
import { ASSOCIATION_FIELD_NAME } from './association-utils';
import { getTableLabel, getTableLabelWithSelfJoins } from './table-utils';
import { expressionToList } from '../../dataset/expression-utils';
import { ExpressionTypes } from '../../dataset/expression-records';
import { convertFieldToFieldRef, FieldTypeToIconValue, getBasicType, isFieldNameMagicId, OBJECT_ID, ROW_ID } from './field-utils';
import { COLUMN_ROLES } from 'reporting-data/constants/relationalReports';
import { dateDurations } from 'reporting-data/constants/dateDurations';
const HS_OBJECT_ID = 'hs_object_id';
export const getIconFromField = field => {
  if (field.source === FieldSources.ASSOCIATION) {
    return 'objectAssociations';
  }
  return FieldTypeToIconValue[field.type] || 'blank';
};
export const getIconFromColumn = column => getIconFromField(column.field);

// field utils
export const getFieldKey = field => `${field.table || field.source}.${field.name}`;
export const getFieldLabel = (field, snowflakeProperty, maybeDatasetField) => {
  if (field.table === DATASET_TABLE) {
    return maybeDatasetField ? maybeDatasetField.label : field.name;
  }
  if (snowflakeProperty) {
    return snowflakeProperty.get('label') || field.name;
  }
  return field.name;
};
export const isFieldExpressionBased = field => field && field.source === FieldSources.EXPRESSION;
export const getExpressionFieldRefs = expressionFields => {
  return expressionFields.reduce((fieldRefs, expressionField) => {
    const expressionParts = expressionToList(expressionField.expression);
    return expressionParts.reduce((nextFieldRefList, expressionPart) => {
      if (expressionPart.type === ExpressionTypes.PROPERTY) {
        return nextFieldRefList.push(FieldRef({
          table: expressionPart.table,
          property: expressionPart.name
        }));
      }
      return nextFieldRefList;
    }, fieldRefs);
  }, List());
};
export const convertColumnToFieldRef = column => {
  const field = column.field;
  return convertFieldToFieldRef(field);
};
export const isStringLikeField = field => getBasicType(field) === BasicTypes.STRING;
export const isNumberLikeField = field => getBasicType(field) === BasicTypes.NUMBER;
export const isPercentageProperty = snowflakeProperty => {
  if (snowflakeProperty) {
    return snowflakeProperty.numberDisplayHint === PERCENTAGE;
  }
  return false;
};
export const isDateLikeField = field => getBasicType(field) === BasicTypes.DATETIME || getBasicType(field) === BasicTypes.DATE;
export const isDateDurationField = ({
  dateDisplayHint = ''
} = {}) => {
  return dateDurations.includes(dateDisplayHint);
};
const isDateLikeProperty = property => property.type === BasicTypes.DATE || property.type === BasicTypes.DATETIME;
const getFieldTypeFromProperty = property => {
  if (isDateLikeProperty(property) && isDateDurationField(property.metaDefinition)) {
    return FieldTypes.NUMBER;
  }
  return property.type;
};
export const fromSnowflakeProperty = (property, table) => {
  const {
    name
  } = property;
  const fieldSource = name === ASSOCIATION_FIELD_NAME ? FieldSources.ASSOCIATION : FieldSources.TABLE;
  return Field({
    name,
    type: getFieldTypeFromProperty(property),
    source: fieldSource,
    table
  });
};

// transform utils
export const setColumnTransform = (column, transform) => column.set('transform', transform);

// column utils
export const getColumnRole = column => column.role;
const getColumnDomain = column => column.domain;
export const getColumnField = column => column.field;
export const getColumnTransform = column => column.transform;
export const getColumnAggregation = column => column.aggregation;
export const getColumnAlias = column => column.alias;
const createColumnsGetter = getter => columns => columns.toList().map(getter).filter(maybeItem => !!maybeItem);
export const getColumnRoles = createColumnsGetter(getColumnRole);
export const getColumnDomains = createColumnsGetter(getColumnDomain);
export const getColumnFields = createColumnsGetter(getColumnField);
export const getColumnTransforms = createColumnsGetter(getColumnTransform);
export const getColumnAggregations = createColumnsGetter(getColumnAggregation);
export const getColumnColor = column => getColumnDomain(column) === DomainTypes.DISCRETE ? 'default' : 'oz';
export const getDimensions = columns => columns.toList().filter(column => getColumnRole(column) === COLUMN_ROLES.DIMENSION);
export const getMeasures = columns => columns.toList().filter(column => getColumnRole(column) === COLUMN_ROLES.MEASURE).toList();
export const getColumn = (columns, alias) => columns.get(alias);
export const isColumnFromTable = (column, table) => column.field.table === table.name;
export const getColumnsFromTable = (columns, table) => columns.toList().filter(column => isColumnFromTable(column, table));
export const getStagedColumnsFromTable = (stagedColumns, table) => stagedColumns.filter(stagedColumn => isColumnFromTable(stagedColumn, table));
export const removeColumnById = (columns, columnId) => columns.delete(columnId);
export const setColumn = (columns, alias, column) => columns.set(alias, column);
export const replaceColumnById = (columns, aliasToReplace, replacementColumn) => columns.set(aliasToReplace, replacementColumn);
export const setColumnDomain = (column, domain) => column.set('domain', domain);
export const updateUndefinedBucket = column => {
  const field = column.field;
  if (!field) {
    return column;
  }
  const isDateLike = isDateLikeField(field);
  if (isDateLike || column.role === COLUMN_ROLES.MEASURE) {
    return column.set('includeUndefinedBuckets', false).delete('undefinedBucket');
  }
  return column.set('includeUndefinedBuckets', true).set('undefinedBucket', UNDEFINED_BUCKET);
};
export const setColumnAggregation = (column, aggregation) => {
  if (aggregation === undefined) {
    return updateUndefinedBucket(column.delete('aggregation').set('role', COLUMN_ROLES.DIMENSION).set('domain', DomainTypes.DISCRETE));
  }
  return updateUndefinedBucket(column.set('aggregation', aggregation).set('role', COLUMN_ROLES.MEASURE).set('domain', DomainTypes.CONTINUOUS));
};
export const clearColumnAggregation = column => setColumnAggregation(column, undefined);
export const clearColumnTransform = column => column.delete('transform');
export const getAggregationSupportedFieldTypes = column => {
  const aggregation = getColumnAggregation(column);
  return AggregationTypeDefinitions.getIn([aggregation, 'supportedFieldTypes']);
};
const chars = '0123456789abcdef'.split('');
const uuid = () => {
  const id = [];
  for (let i = 0; i < 32; i++) {
    id.push(chars[Math.floor(Math.random() * chars.length)]);
  }
  return id.join('');
};
export let createColumnAlias = () => uuid();
export const setCreateColumnAliasForTesting = fn => {
  createColumnAlias = fn;
};
export const duplicateColumn = column => column.set('alias', createColumnAlias());
export const createDefaultDimension = field => updateUndefinedBucket(Column({
  role: COLUMN_ROLES.DIMENSION,
  domain: DomainTypes.DISCRETE,
  field,
  sort: DEFAULT_COLUMN_SORT,
  alias: createColumnAlias()
}));
export const getDefaultTransform = field => isDateLikeField(field) ? Transform({
  type: TransformTypes.DATE_TRUNC,
  arguments: [DateTruncationTypes.MONTH]
}) : undefined;
export const isUngatedToEpochDateTrunc = () => {
  const userData = store.getState().userInfo.get('data');
  return userData && userData.gates.includes('SqlReporting:dateTruncHigherPrecisionEnabled');
};
export const getDefaultDrilldownTransform = field => isDateLikeField(field) ? Transform({
  type: TransformTypes.DATE_TRUNC,
  arguments: isUngatedToEpochDateTrunc() ? [DateTruncationTypes.MINUTE] : [DateTruncationTypes.DAY]
}) : undefined;
export const createDefaultDimensionWithTransform = field => {
  return createDefaultDimension(field).set('transform', getDefaultTransform(field));
};
export const createDefaultDrilldownDimensionWithTransform = field => {
  return createDefaultDimension(field).set('transform', getDefaultDrilldownTransform(field));
};
const isFieldRecordId = field => field.name === HS_OBJECT_ID;
const applyDefaultMagicFieldAggregation = column => setColumnAggregation(column, AggregationTypes.DISTINCT_COUNT);
const getDefaultAggregation = (field, snowflakeProperty) => {
  if (isFieldNameMagicId(field.name)) {
    return AggregationTypes.DISTINCT_COUNT;
  }
  if (isFieldRecordId(field)) {
    return AggregationTypes.DISTINCT_COUNT;
  }
  return isPercentageProperty(snowflakeProperty) ? AggregationTypes.AVERAGE : isNumberLikeField(field) ? AggregationTypes.SUM : AggregationTypes.DISTINCT_COUNT;
};
export const applyDefaultAggregation = (column, snowflakeProperty) => {
  const field = column.field;
  if (isFieldNameMagicId(field.name)) {
    return applyDefaultMagicFieldAggregation(column);
  }
  let next = column;
  const defaultAggregation = getDefaultAggregation(field, snowflakeProperty);
  next = setColumnAggregation(next, defaultAggregation);
  next = next.set('sort', DEFAULT_COLUMN_SORT);
  return next;
};
export const createDefaultMeasure = (field, snowflakeProperty) => {
  const measure = Column({
    role: COLUMN_ROLES.MEASURE,
    domain: DomainTypes.CONTINUOUS,
    field,
    sort: DEFAULT_COLUMN_SORT,
    alias: createColumnAlias()
  });
  const defaultAggregation = getDefaultAggregation(field, snowflakeProperty);
  return setColumnAggregation(measure, defaultAggregation);
};
const createCountOfObjectsColumn = table => Column({
  role: COLUMN_ROLES.MEASURE,
  domain: DomainTypes.CONTINUOUS,
  field: Field({
    name: OBJECT_ID,
    table,
    type: FieldTypes.NUMBER
  }),
  sort: DEFAULT_COLUMN_SORT,
  aggregation: AggregationTypes.DISTINCT_COUNT,
  alias: createColumnAlias()
});
const createCountOfEventsColumn = table => Column({
  role: COLUMN_ROLES.MEASURE,
  domain: DomainTypes.CONTINUOUS,
  field: Field({
    name: ROW_ID,
    table,
    type: FieldTypes.NUMBER
  }),
  sort: DEFAULT_COLUMN_SORT,
  aggregation: AggregationTypes.DISTINCT_COUNT,
  alias: createColumnAlias()
});

// TODO we can't create magic measures for dataset tables, maybe we can use
// count of rows? or reach into the primary object of the dataset table
// and create a count of objects expression from that? that would be hard
const DATA_SOURCE_TYPES_COMPATIBLE_WITH_MAGIC_MEASURE = [DataSourceTypes.HUBSPOT_OBJECT, DataSourceTypes.HUBSPOT_EVENT];
export const dataSourceTypeSupportsMagicMeasure = dataSourceType => DATA_SOURCE_TYPES_COMPATIBLE_WITH_MAGIC_MEASURE.includes(dataSourceType);

/**
 * @returns {Column?} - optional column
 */
export const createMagicMeasure = table => {
  const type = table.type;
  const name = table.name;
  if (!dataSourceTypeSupportsMagicMeasure(type)) {
    return undefined;
  }
  if (type === DataSourceTypes.HUBSPOT_OBJECT) {
    return createCountOfObjectsColumn(name);
  }
  if (type === DataSourceTypes.HUBSPOT_EVENT) {
    return createCountOfEventsColumn(name);
  }
  return undefined;
};
export const createFieldFromFieldRef = (fieldRef, snowflakeProperty) => {
  const property = fieldRef.property;
  const table = fieldRef.table;
  return isFieldNameMagicId(property) ? Field({
    name: property,
    table,
    type: FieldTypes.NUMBER
  }) : fromSnowflakeProperty(snowflakeProperty, table);
};
export const createFieldFromFilterRef = (filterRef, snowflakeProperty) => createFieldFromFieldRef(FieldRef({
  property: filterRef.property,
  table: filterRef.table
}), snowflakeProperty);
export const isMagicMeasure = column => isFieldNameMagicId(column.field.name);
export const hasDateTrunc = column => {
  if (column.transform == null) {
    return false;
  }
  return column.transform.type === TransformTypes.DATE_TRUNC;
};
export const hasDatePart = column => {
  if (column.transform == null) {
    return false;
  }
  return column.transform.type === TransformTypes.DATE_PART;
};
export const getDateTruncTransformValue = column => {
  if (!column.transform || !hasDateTrunc(column)) {
    return undefined;
  }
  return column.transform.arguments.first();
};
export const getDatePartTransformValue = column => {
  if (!column.transform || !hasDatePart(column)) {
    return undefined;
  }
  return column.transform.arguments.first();
};
export const getDateResolutionForColumn = column => getDateTruncTransformValue(column);
export const getColumnLabel = (column, snowflakeProperty, snowflakeTable, maybeDatasetField, expressionFields = List(), tableDescription, allJoinMetadata) => {
  const {
    name,
    field
  } = column;
  if (name) {
    return name;
  }
  const {
    table
  } = field;
  const fieldLabel = getFieldLabel(field, snowflakeProperty, maybeDatasetField);
  const sourceLabelWithSelfJoins = table && tableDescription && getTableLabelWithSelfJoins(table, tableDescription, snowflakeTable, allJoinMetadata);
  const sourceLabel = sourceLabelWithSelfJoins || table && getTableLabel(table, snowflakeTable);
  if (isMagicMeasure(column) && sourceLabel) {
    return I18n.text('reporting-snowflake.magic-measure-label', {
      sourceName: sourceLabel.toLowerCase()
    });
  }
  let label = fieldLabel;
  if (isFieldExpressionBased(column.field)) {
    const expressionField = expressionFields.find(_expressionField => _expressionField.alias === column.field.name);
    if (expressionField) {
      label = expressionField.label;
    }
  }
  if (isFieldRecordId(column.field)) {
    label = `${label} - ${sourceLabel}`;
  }
  const dateTruncTransformValue = getDateTruncTransformValue(column);
  if (dateTruncTransformValue) {
    const dateTruncLabel = I18n.text(`reporting-snowflake.date-trunc.${dateTruncTransformValue}`);
    label = unescapedText('reporting-snowflake.transform-label', {
      subject: label,
      transform: dateTruncLabel
    });
  }
  const datePartTransformValue = getDatePartTransformValue(column);
  if (datePartTransformValue) {
    const datePartLabel = I18n.text(`reporting-snowflake.date-part.${datePartTransformValue}`);
    label = unescapedText('reporting-snowflake.transform-label', {
      subject: label,
      transform: datePartLabel
    });
  }
  const aggregation = getColumnAggregation(column);
  if (aggregation && aggregation !== AggregationTypes.DELEGATE) {
    const aggregationLabel = I18n.text(`reporting-snowflake.aggregations.${aggregation}`);
    label = unescapedText('reporting-snowflake.aggregation-label', {
      subject: label,
      aggregation: aggregationLabel
    });
  }
  return label;
};
export const getColumnBasicType = column => {
  const field = column.field;
  const role = column.role;
  const basicType = getBasicType(field);
  if (role === COLUMN_ROLES.MEASURE) {
    const aggregation = column.aggregation;
    switch (aggregation) {
      case AggregationTypes.MIN:
      case AggregationTypes.MAX:
      case AggregationTypes.MEDIAN:
      case AggregationTypes.DELEGATE:
        return basicType;
      default:
        return BasicTypes.NUMBER;
    }
  }
  return basicType;
};
export const getBasicTypeFormat = (basicType, snowflakeProperty) => {
  const maybeMetaDefinition = snowflakeProperty && snowflakeProperty.metaDefinition;
  switch (basicType) {
    case BasicTypes.NUMBER:
      {
        if (!maybeMetaDefinition) {
          return Formats.NUMBER;
        }
        if (maybeMetaDefinition.numberDisplayHint === 'duration') {
          return Formats.DURATION;
        }
        if (maybeMetaDefinition.showCurrencySymbol) {
          return Formats.CURRENCY;
        }
        return Formats.NUMBER;
      }
    case BasicTypes.BOOLEAN:
      {
        return Formats.BOOLEAN;
      }
    case BasicTypes.DATE:
      {
        if (isDateDurationField(maybeMetaDefinition)) {
          return Formats.DURATION;
        }
        return Formats.DATE;
      }
    case BasicTypes.DATETIME:
      {
        if (isDateDurationField(maybeMetaDefinition)) {
          return Formats.DURATION;
        }
        return Formats.DATETIME;
      }
    default:
      {
        if (maybeMetaDefinition && maybeMetaDefinition.fieldType === Formats.HTML) {
          return Formats.HTML;
        }
        return Formats.STRING;
      }
  }
};
export const getColumnFormat = (column, snowflakeProperty) => {
  const columnType = getColumnBasicType(column);
  return getBasicTypeFormat(columnType, snowflakeProperty);
};
export const addColumn = (columns, column) => columns.set(column.alias, column);
export const getCurrencyNameForColumn = alias => `currency_${alias}`;
export const shouldApplyFiscalYearOffset = column => {
  const dateTruncType = getDateTruncTransformValue(column);
  const isTruncCompatible = [DateTruncationTypes.QUARTER, DateTruncationTypes.YEAR].includes(dateTruncType);
  return column.useFiscalYear && isTruncCompatible;
};
export const getFieldInfoForColumn = (column, reportMeta) => {
  const isCreatedFromDataset = !!reportMeta.datasetDefinition;
  const field = column.field;
  const fieldInformation = {
    table: field.table,
    name: field.name
  };

  // Field table and property name should live in column.field if report not made from Dataset
  if (!isCreatedFromDataset) {
    return fieldInformation;
  }
  const datasetDefinition = reportMeta.datasetDefinition;
  const datasetField = datasetDefinition.fields.get(field.name);
  const isDerived = datasetField.derived;

  /*
    Dataset derived field is made up of an expression and does not represent a single
    snowflake property so we don't want to do anything special to get field information
  */
  if (isDerived) {
    return fieldInformation;
  }
  const expression = datasetField.expression;
  if (expression.type === ExpressionTypes.PROPERTY) {
    return {
      table: expression.table,
      name: expression.name
    };
  }
  return fieldInformation;
};
export const getColumnFieldFromDataset = (column, reportMeta) => {
  const columnField = column.field;
  if (columnField.table !== '__DATASET__') {
    return columnField;
  }
  if (!reportMeta) {
    return undefined;
  }
  const datasetDefinition = reportMeta.datasetDefinition;
  if (!datasetDefinition) {
    return undefined;
  }
  const datasetField = datasetDefinition.fields.get(column.field.name);
  if (!datasetField) {
    return columnField;
  }
  const {
    expression
  } = datasetField;
  const isDatasetFieldAProperty = expression.type === ExpressionTypes.PROPERTY;
  if (!isDatasetFieldAProperty) {
    return columnField;
  }
  const {
    table,
    name
  } = expression;
  const datasetDefinitionProperty = reportMeta.datasetDefinition.properties.getIn([table, name]);
  const field = Field({
    name,
    table,
    source: FieldSources.TABLE,
    type: datasetDefinitionProperty.type
  });
  return field;
};