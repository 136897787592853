import { useMemo } from 'react';
import { useReportingActionsContext } from '../../reportingActions/ReportingActionsContext';
// @ts-expect-error migrate upstream
import { getTrackingConfigOptions } from '../trackingUtils';
const setupUsageTracker = tracker => {
  const configOptionsToOverride = getTrackingConfigOptions();
  return tracker.clone(Object.assign({}, configOptionsToOverride));
};
export const useTracker = appUsageTracker => {
  const {
    tracker: defaultTracker
  } = useReportingActionsContext();
  const usageTracker = useMemo(() => {
    if (appUsageTracker) {
      return setupUsageTracker(appUsageTracker);
    }
    return defaultTracker;
  }, [appUsageTracker, defaultTracker]);
  return usageTracker;
};