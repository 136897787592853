import PortalIdParser from 'PortalIdParser';
import { getFullUrl } from 'hubspot-url-utils';
import { getWindowSearch } from './params';
import { TABS, VALUES_OF_TABS } from '../constants/tabs';
import { hasCommentingFeature, hasFeature } from './features';
import { COLLABORATION_SIDEBAR_QUERY_PARAMETERS } from '../constants';
import { COLLABORATION_SIDEBAR_QUERY_PARAMETERS_AS_KEYS } from '../constants/url';
const EMBEDDABLE_APP_PATH = 'collaboration-sidebar-embeddable';
const MINI_COMMENTER_EMBEDDABLE_APP_PATH = 'collaboration-sidebar-mini-commenter';
export const COMMENTING_QUERYSTRING_PARAM = `${COLLABORATION_SIDEBAR_QUERY_PARAMETERS.commenting}=true`;
export function getEmbeddablePanelUrl({
  objectType,
  objectId,
  ctaUrl,
  appName,
  currentLocation = window.location,
  tab = TABS.COMMENTS,
  enabledFeatures
}) {
  const isDevelopmentMode = currentLocation.hostname.includes('local');
  const params = [`ctaUrl=${encodeURIComponent(ctaUrl || '')}`, `appName=${encodeURIComponent(appName || '')}`];
  const hasCommentingEnabled = hasCommentingFeature(enabledFeatures);
  const encodedObjectId = encodeURIComponent(objectId || '');

  // If commenting is disabled, we don't want to pass the objectId and objectType to the embeddable app
  // because it will cause the early requester to try and fetch comments.
  const objectIdToUse = hasCommentingEnabled ? encodedObjectId : '';
  const objectTypeToUse = hasCommentingEnabled ? objectType : '';
  const baseUrl = getFullUrl(isDevelopmentMode ? 'local' : 'app');
  return `${baseUrl}/${EMBEDDABLE_APP_PATH}/${PortalIdParser.get()}/panel/${objectTypeToUse}/${objectIdToUse}/${tab}?${params.join('&')}`;
}
export function getEmbeddableMiniCommenterUrl({
  objectType,
  objectId,
  moduleId,
  ctaUrl,
  appName,
  currentLocation = window.location,
  autoFocus,
  parentCommentId
}) {
  let isDevelopmentMode = false;
  if (currentLocation.hostname.includes('local') || document.referrer.includes('local')) {
    const isCommentingSandbox = currentLocation.pathname.startsWith('/collaboration-sidebar-sandbox-ui');
    try {
      isDevelopmentMode = isCommentingSandbox || !!localStorage.getItem('DEEP_COMMENT_DEV');
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }
  const params = [`objectType=${objectType}`, `objectId=${encodeURIComponent(objectId || '')}`, `moduleId=${moduleId}`, `ctaUrl=${encodeURIComponent(ctaUrl || '')}`, `appName=${encodeURIComponent(appName || '')}`, `parentCommentId=${parentCommentId}`];
  if (autoFocus) {
    params.push('autoFocus=true');
  }
  const baseUrl = getFullUrl(isDevelopmentMode ? 'local' : 'app');
  return `${baseUrl}/${MINI_COMMENTER_EMBEDDABLE_APP_PATH}/${PortalIdParser.get()}?${params.join('&')}`;
}

// Returns an object with only the query parameters that are part of the collaboration sidebar
export function parseQueryParams() {
  const search = getWindowSearch();
  const searchParams = new URLSearchParams(search);
  return COLLABORATION_SIDEBAR_QUERY_PARAMETERS_AS_KEYS.reduce((acc, sideBarQueryParam) => {
    const value = searchParams.get(sideBarQueryParam);
    if (value) {
      acc[sideBarQueryParam] = value;
    }
    return acc;
  }, {});
}
export function getOpenQueryParam() {
  return parseQueryParams()[COLLABORATION_SIDEBAR_QUERY_PARAMETERS.csOpen] === 'true';
}
export function getTabQueryParam() {
  return parseQueryParams()[COLLABORATION_SIDEBAR_QUERY_PARAMETERS.csTab] || TABS.COMMENTS;
}
export function getSecuredTabQueryParam({
  enabledFeatures
}) {
  const requestedTab = getTabQueryParam();
  const existingRequestedTab = VALUES_OF_TABS.find(tab => tab === requestedTab);
  if (existingRequestedTab && hasFeature(enabledFeatures, existingRequestedTab)) {
    return existingRequestedTab;
  }

  // If not return the first enabled tab
  return VALUES_OF_TABS.find(tab => hasFeature(enabledFeatures, tab));
}
export function getCommentingThreadQueryParam() {
  return parseQueryParams().commentThread || null;
}
export function getSelectedObjectIdQueryParam() {
  return decodeURIComponent(parseQueryParams()[COLLABORATION_SIDEBAR_QUERY_PARAMETERS.csSelectedObjectId] || '');
}
export function getSelectedObjectTypeQueryParam() {
  return parseQueryParams()[COLLABORATION_SIDEBAR_QUERY_PARAMETERS.csSelectedObjectType];
}
export function getNotificationSettingsOpenQueryParam() {
  return parseQueryParams()[COLLABORATION_SIDEBAR_QUERY_PARAMETERS.notificationSettingsOpen] === 'true';
}
export function getIsCommentingUrl() {
  return window.location.search.includes(COMMENTING_QUERYSTRING_PARAM);
}
export function getIsWorkflowActionsUrl() {
  return window.location.pathname.includes('/workflows/') && window.location.pathname.includes('/actions/');
}
export function getCommentIdParam() {
  return parseQueryParams()[COLLABORATION_SIDEBAR_QUERY_PARAMETERS.commentId];
}