'use es6';

import { AREA, BAR, COLUMN, CUSTOM, DATA_WELL, LINE } from 'reporting-data/constants/chartTypes';
import { CompareControl } from '../../components/controls/CompareControl';
import { DIMENSIONS, getStaticDimension } from '../salesAnalyticsHelpers';
const canApplyCompare = (chartType, grouping, breakdown) => {
  const isMultiDimensional = grouping && breakdown;
  const isTimeSeries = grouping === DIMENSIONS.DATE;
  return [DATA_WELL, CUSTOM].includes(chartType) || [COLUMN, BAR].includes(chartType) && !isMultiDimensional || [LINE, AREA].includes(chartType) && isTimeSeries && !isMultiDimensional;
};
export function validateCompare(contextChange, context, report, subAppKey, __defaultContext, __scopes, gates) {
  if (!CompareControl.isUsableForReport({
    report,
    subAppKey,
    filteredGates: gates
  })) {
    return contextChange;
  }
  const chartType = contextChange.chartType != null ? contextChange.chartType : context.chartType;
  const grouping = contextChange.grouping != null ? contextChange.grouping : context.grouping;
  const breakdown = contextChange.breakdown != null ? contextChange.breakdown : context.breakdown;
  const staticDimension = getStaticDimension(report, subAppKey, grouping);
  const canCompare = canApplyCompare(chartType, grouping, breakdown || staticDimension);
  return canCompare ? contextChange : Object.assign({}, contextChange, {
    compare: null
  });
}