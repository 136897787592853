import http from 'hub-http/clients/apiClient';
import { Record, fromJS, Map as ImmutableMap } from 'immutable';
import emptyFunction from 'react-utils/emptyFunction';
import { JOURNEY_POLL_RATE } from 'reporting-data/constants/snowflakeQuery';
import { AsyncQueryStatus } from 'reporting-data/constants/snowflakeQuery';
import { buildDataset } from 'reporting-data/v2/reportingApi/buildDataset';
import { defaultDatasetBuildOptions } from 'reporting-data/v2/reportingApi/datasetBuildOptions';
import { AsyncQueryCancelled, AsyncQueryError, AsyncQueryFailed, AsyncQueryTimeout } from '../../relational/resolve/resolve-records';
import handleRequestError from './handleRequestError';
// @ts-expect-error untyped dep
import { HttpRequestState } from 'reporting-data/request/http/store';
const Request = Record({
  url: null,
  method: null,
  data: null
});
const BASE_URL = 'reporting/v1/journey';
const getDatasetBuildOptionsFromJourneysReport = ({
  dataAge
}) => {
  return Object.assign({}, defaultDatasetBuildOptions, {
    dataAge
  });
};
const getHTTPRequestState = (succeeded, url, queryWithOpts, queryResponse) => {
  return new HttpRequestState({
    request: Request({
      url,
      method: 'post',
      data: ImmutableMap(queryWithOpts)
    }),
    response: (queryResponse === null || queryResponse === void 0 ? void 0 : queryResponse.dataset) || null,
    status: succeeded ? 'SUCCEEDED' : 'FAILED'
  });
};

// Convert raw response to Response shape useful for the polling algorithm
export const toAsyncQueryResponse = res => {
  const queryId = res.asyncQueryResponseMeta.queryId;
  const queryStatus = res.asyncQueryResponseMeta.queryStatus;
  const dataAge = res.asyncQueryResponseMeta.dataAge;
  const responseDataset = res.dataSet;
  const summaryDataset = res.summaryDataSet;
  const options = getDatasetBuildOptionsFromJourneysReport({
    dataAge
  });

  // fromJS() and toJS() are used here as a way to convert to JS Objects from immutable
  const dataset = responseDataset ? {
    primary: fromJS(buildDataset(fromJS(responseDataset), options)).toJS(),
    summary: summaryDataset ? fromJS(buildDataset(fromJS(summaryDataset), options)).toJS() : undefined
  } : undefined;
  return {
    queryId,
    queryStatus,
    dataset
  };
};

/**
 * Kick off a new async query. Might return a successful response if the query
 * finishes quickly.
 * @param {JourneyQueryWithReportOptions} queryWithOpts
 * @param {Function | undefined} debug
 * @returns {Promise<AsyncQueryResponseMeta>}
 */
export const request = (queryWithOpts, debug) => {
  return http.post(`${BASE_URL}/`, {
    data: queryWithOpts
  }).then(response => {
    const queryResponse = toAsyncQueryResponse(response);
    debug === null || debug === void 0 || debug('HTTPRequest', getHTTPRequestState(true, BASE_URL, queryWithOpts, queryResponse));
    return queryResponse;
  });
};

/**
 * Get the result of an existing async query, such as a running query. Useful
 * for polling for a result.
 * @param {JourneyQueryWithReportOptions} queryWithOpts
 * @param {string} queryId
 * @returns {Promise<AsyncQueryResponseMeta>}
 */
export const getResult = (queryWithOpts, queryId) => http.post(`${BASE_URL}/query/${queryId}`, {
  data: queryWithOpts
}).then(response => toAsyncQueryResponse(response));

/**
 * Cancel an existing async query by query id. Cached async query response will
 * result in the status "CANCELLED".
 * @param {string} queryId
 * @returns {Promise}
 */
export const cancel = queryId => http.delete(`${BASE_URL}/query/${queryId}`);

/**
 * Perform a synchronous query for a report dataset. Only recommended for
 * debugging purposes.
 * @param {JourneyQueryWithReportOptions} queryWithOpts
 * @returns {Promise<Dataset>}
 */
export const query = queryWithOpts => http.post(BASE_URL, {
  data: queryWithOpts
}).then(response => {
  const options = getDatasetBuildOptionsFromJourneysReport({
    dataAge: response.asyncQueryResponseMeta.dataAge
  });
  return buildDataset(response.dataSet, options);
});

/**
 * Retrieve a report dataset by report id. If the option "fetchFromCache" is
 * false, this will request fresh data, otherwise we will try to use the cache
 * if possible.
 * @param {JourneyQueryWithReportOptions} queryWithOpts
 * @param {number} reportId
 * @param {Function | undefined} debug
 * @returns {Promise<AsyncQueryResponse>}
 */
export const retrieve = (queryWithOpts, reportId, debug) => {
  const url = `${BASE_URL}/report/${reportId}`;
  return http.post(url, {
    data: queryWithOpts
  }).then(response => {
    const queryResponse = toAsyncQueryResponse(response);
    debug === null || debug === void 0 || debug('HTTPRequest', getHTTPRequestState(true, url, queryWithOpts, queryResponse));
    return queryResponse;
  });
};

/**
 * Start an async query, then recursively poll async query results until
 * complete.
 * @param {JourneyQueryWithReportOptions} queryWithOpts An object containing the
 * journeyQuery and report options
 * @param {{
 *     onTick: function,
 *     lastQuery: AsyncQueryResponseMeta
 * }} params an object containing an onTick callback to be called on every tick
 * and the previous query, which can be used to start the query outside this
 * function (also used in recursion)
 * @returns {Promise<JourneyDataMap>} A promise expected to resolve to a Dataset
 */
const poll = (queryWithOpts, params = {}, debug) => new Promise((resolvePromise, rejectPromise) => {
  const {
    onTick = emptyFunction,
    reportId: maybeReportId,
    lastQuery: {
      queryId,
      queryStatus,
      dataset
    } = {}
  } = params;
  const tick = nextQuery => {
    const cancelled = onTick(nextQuery, queryWithOpts);
    if (!cancelled) {
      poll(queryWithOpts, Object.assign({}, params, {
        lastQuery: nextQuery
      }), debug).then(resolvePromise, rejectPromise).catch(rejectPromise);
    }
  };
  switch (queryStatus) {
    // no status means we need to request a new query
    case undefined:
      {
        // Allow for an opt out of fetching from the cache for a persisted report but default to use cache
        const fetchFromCache = queryWithOpts.reportOptions.fetchFromCache !== undefined ? queryWithOpts.reportOptions.fetchFromCache : true;
        const requester = maybeReportId && fetchFromCache ? () => retrieve(queryWithOpts, maybeReportId, debug) : () => request(queryWithOpts, debug);
        requester().then(tick, rejectPromise).catch(reason => {
          rejectPromise(reason);
          debug === null || debug === void 0 || debug('HTTPRequest', getHTTPRequestState(false, `${BASE_URL}/${maybeReportId && fetchFromCache ? `/report/${maybeReportId}` : ''}`, queryWithOpts, null));
        });
        break;
      }
    case AsyncQueryStatus.RUNNING:
      setTimeout(() => {
        getResult(queryWithOpts, queryId).then(tick, rejectPromise).catch(rejectPromise);
      }, JOURNEY_POLL_RATE);
      break;
    case AsyncQueryStatus.SUCCESS:
      resolvePromise(dataset);
      break;
    case AsyncQueryStatus.FAILED:
      rejectPromise(new AsyncQueryFailed(`Async query ${queryId} failed`));
      break;
    case AsyncQueryStatus.CANCELLED:
      rejectPromise(new AsyncQueryCancelled(`Async query ${queryId} was cancelled`));
      break;
    case AsyncQueryStatus.TIMEOUT:
      rejectPromise(new AsyncQueryTimeout(`Async query ${queryId} timed out`));
      break;
    case AsyncQueryStatus.UNKNOWN:
    default:
      rejectPromise(new AsyncQueryError(`Async query ${queryId} errored with status ${queryStatus}`));
  }
});

/**
 * Non-recursive entry point for the recursive polling function.
 * @param queryWithOpts An object containing the journeyQuery and report options
 * @param params an object containing an onTick callback to be called on every tick
 * and the previous query, which can be used to start the query outside this
 * function (also used in recursion)
 * @returns A promise expected to resolve to a Dataset
 */
export const asyncQuery = (queryWithOpts, params, debug) => {
  return poll(queryWithOpts, params, debug).catch(handleRequestError);
};