'use es6';

import I18n from 'I18n';
import { List, Map as ImmutableMap } from 'immutable';
import { GLOBAL_NULL } from '../../constants/defaultNullValues';
import chunk from '../../lib/async/chunk';
import { Promise } from '../../lib/promise';
import * as http from '../../request/http';
import Request from '../../request/Request';
import { makeOption } from '../Option';
const URL = 'campaigns/v1/campaigns/guids';
const MAX_BATCH_SIZE = 300;
export const generateCampaignLabel = (campaign, key) => {
  if (campaign.has('label')) {
    return campaign.get('label');
  }
  const name = campaign.get('display_name', key !== GLOBAL_NULL ? key : null);
  return campaign.get('deleted') === 'true' || campaign.get('deleted') === true ? I18n.text('reporting-data.references.campaign.deleted', {
    name
  }) : name;
};
const batch = ids => chunk(group => http.retrieve(Request.get({
  url: URL,
  query: {
    guid: group,
    includeDeletes: true
  }
})), responses => responses.reduce((memo, response /* eslint-disable-next-line hubspot-dev/no-reduce-accumulator-copy */) => memo.concat(response), List()), ids, MAX_BATCH_SIZE);
const getCampaigns = ids => {
  if (ids.size === 0) {
    return Promise.resolve(ImmutableMap());
  }
  return batch(ids).then(campaigns => campaigns.reduce((memo, campaign) => {
    const guid = campaign.get('guid');
    return memo.set(guid, makeOption(guid, generateCampaignLabel(campaign)));
  }, ImmutableMap()));
};
export default getCampaigns;
export const campaigns = ids => getCampaigns(List(ids)).then(options => options.reduce((breakdowns, option) => Object.assign({}, breakdowns, {
  [option.get('value')]: option.get('label')
}), {}));