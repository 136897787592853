import { deepFreeze } from '../cache/deepFreeze';
import { isEarlyCacheReturnEnabled, makePipelinesFetchKey } from 'framework-data-schema-quick-fetch';
import { createPersistedAsyncCache } from '../cache/createPersistedAsyncCache';
import { Metrics } from '../metrics';
import { makeLoadFailed } from '../utils/makeLoadFailed';
import { createInMemoryCache } from '../cache/createInMemoryCache';
import { fetchPipelines } from './pipelinesApi';
import { keyBy } from '../utils/keyBy';
import { wrapPromise } from 'persist-promise/wrapPromise';
const normalizeForPatchDiffing = (data, env) => env.keyBy(data, ({
  pipelineId
}) => pipelineId, pipeline => {
  if (!pipeline.stages) {
    return pipeline;
  }
  return Object.assign({}, pipeline, {
    stages: env.keyBy(pipeline.stages, ({
      stageId
    }) => stageId)
  });
});
const segmentKey = key => {
  const match = key.match(makePipelinesFetchKey({
    portalId: '.*',
    frameworkTypeIdentifier: '(.*)'
  }));
  return match && match[1] || null;
};
const pipelinesPersistedPromise = wrapPromise({
  namespace: 'FDSR',
  entityName: 'pipelines',
  deepFreeze: true,
  toCacheKey: makePipelinesFetchKey,
  fetch: fetchPipelines,
  metricsConfig: {
    enablePatchDiffing: true,
    convertKeyToMetricsDimension: segmentKey,
    normalizeForPatchDiffing
  }
});
const defaultAsyncPipelinesRequestCache = createPersistedAsyncCache({
  cacheName: 'pipelines',
  segmentKey,
  workerConfig: {
    normalizeForPatchDiffing
  }
});
const defaultPipelinesOperationCache = createInMemoryCache({
  cacheName: 'pipelines-ops'
});
const makeOperationCacheKey = ({
  frameworkTypeIdentifier,
  portalId
}) => `${makePipelinesFetchKey({
  portalId,
  frameworkTypeIdentifier
})}-operation`;
export const makePipelinesClient = ({
  httpClient,
  usePersistPromise,
  persistedPromise = pipelinesPersistedPromise,
  requestCache = defaultAsyncPipelinesRequestCache,
  toRequestCacheKey = makePipelinesFetchKey,
  operationCache = defaultPipelinesOperationCache,
  toOperationCacheKey = makeOperationCacheKey
}) => {
  const client = {
    /**
     * Prints debug info to the console.
     */
    debug: () => {
      if (usePersistPromise) {
        persistedPromise.debug();
      } else {
        requestCache.printDebug();
      }
    },
    /**
     * Clears internal cache state
     *
     * @returns A promise which resolves when state is clear.
     */
    clearCache: async () => {
      await Promise.all([persistedPromise.clearCache(), requestCache.clear(), operationCache.clear()]);
    },
    /**
     * Gets all pipelines for the requested framework type in the portal.
     *
     * @param options.frameworkTypeIdentifier The identifier of the framework type to get pipelines for.
     * @param options.refetch Set to true to force a refetch of the data.
     * @param options.__isComposed For internal metrics tracking purposes only. Set to true when called within another client method.
     * @returns A promise which resolves to all pipelines under this framework type, or null if the data could not be found.
     */
    get: ({
      frameworkTypeIdentifier,
      refetch,
      __isComposed = false
    }) => {
      if (!__isComposed) {
        Metrics.counter('pipelines.get', {
          usePersistPromise: `${usePersistPromise}`
        }).increment();
      }
      if (usePersistPromise) {
        return persistedPromise.makeFetchWithOpts({
          allowEagerCacheReturn: isEarlyCacheReturnEnabled(),
          refresh: refetch
        })({
          httpClient,
          frameworkTypeIdentifier
        });
      }
      const cachedValue = requestCache.readThrough({
        cacheKey: toRequestCacheKey({
          frameworkTypeIdentifier
        }),
        refresh: refetch,
        loadValue: () => fetchPipelines({
          frameworkTypeIdentifier,
          httpClient
        }).then(deepFreeze)
      });
      return cachedValue === null ? makeLoadFailed() : cachedValue;
    },
    /**
     * Gets the pipeline by id for the requested framework type in the portal.
     *
     * @param options.frameworkTypeIdentifier The identifier of the framework type to get pipelines for.
     * @param options.pipelineId The identifier of the pipeline to get.
     * @param options.refetch Set to true to force a refetch of the data.
     * @param options.__isComposed For internal metrics tracking purposes only. Set to true when called within another client method.
     * @returns A promise which resolves to all pipelines under this framework type, or null if the data could not be found.
     */
    getPipeline: ({
      frameworkTypeIdentifier,
      pipelineId,
      refetch,
      __isComposed = false
    }) => {
      if (!__isComposed) {
        Metrics.counter('pipelines.getPipeline', {
          usePersistPromise: `${usePersistPromise}`
        }).increment();
      }
      const operationCacheKey = toOperationCacheKey({
        frameworkTypeIdentifier
      });
      const pipelineMapPromise = operationCache.readThrough({
        cacheKey: operationCacheKey,
        refresh: refetch,
        loadValue: () => {
          console.log({
            refetch
          });
          return client.get({
            frameworkTypeIdentifier,
            refetch
          }).then(pipelines => keyBy(pipelines, ({
            pipelineId: id
          }) => id));
        }
      });
      if (pipelineMapPromise === null) {
        return makeLoadFailed();
      }
      return pipelineMapPromise.then(pipelines => pipelines[pipelineId] ? deepFreeze(pipelines[pipelineId]) : null);
    }
  };
  return Promise.resolve(client);
};