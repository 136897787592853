'use es6';

import I18n from 'I18n';
import { is, List } from 'immutable';
import { CrossObject } from '../../config/cross-object';
import { DATA_WELL, TABLE } from '../../constants/chartTypes';
import { FUNNEL, PIPELINE, SEARCH } from '../../constants/configTypes';
import { CRM_OBJECT, EXTERNAL, UNIFIED_EVENTS, UNIFIED_EVENTS_QL } from '../../constants/dataTypes';
import { ENGAGEMENT, ENGAGEMENTS } from '../../constants/dataTypes/inboundDb';
import { RANGE_TYPES } from '../../constants/dateRangeTypes';
import { SUM } from '../../constants/metricTypes';
import { DATA_TYPE_TO_HUBSPOT_OBJECT_COORDINATES } from '../../constants/objectCoordinates';
import { BETWEEN } from '../../constants/operators';
import { QUOTA_NAMESPACE } from '../../retrieve/custom/quotas/constants';
import { DEFAULT_LIMIT } from '../../retrieve/inboundDb/aggregate/preconditions/responseTooLarge';
import { getPaginationApiSettings } from '../../lib/paginate';
import { isSupportedUEReport } from '../../lib/unifiedEvents/utils';
const fixCrmObject = ({
  report
}) => {
  const dataType = report.getIn(['config', 'dataType']);
  if (dataType !== CRM_OBJECT && dataType !== UNIFIED_EVENTS && dataType !== EXTERNAL && dataType !== UNIFIED_EVENTS_QL) {
    return {
      report: report.setIn(['config', 'objectTypeId'], DATA_TYPE_TO_HUBSPOT_OBJECT_COORDINATES.get(dataType !== ENGAGEMENT ? dataType : ENGAGEMENTS)).setIn(['config', 'dataType'], CRM_OBJECT)
    };
  }
  return {
    report
  };
};
const dateValueRanges = [RANGE_TYPES.CUSTOM, RANGE_TYPES.IS_EQUAL_TO, RANGE_TYPES.IS_BEFORE_DATE, RANGE_TYPES.IS_AFTER_DATE];
const reformatDate = date => I18n.moment.portalTz(date, 'YYYYMMDD', true).isValid() ? I18n.moment.portalTz(date, 'YYYYMMDD').format('YYYY-MM-DD') : date;
const fixDateFilters = ({
  report
}) => {
  if (dateValueRanges.includes(report.getIn(['config', 'filters', 'dateRange', 'value', 'rangeType']))) {
    report = report.getIn(['config', 'filters', 'dateRange', 'value', 'rangeType']) === RANGE_TYPES.CUSTOM ? report.updateIn(['config', 'filters', 'dateRange', 'value', 'startDate'], reformatDate).updateIn(['config', 'filters', 'dateRange', 'value', 'endDate'], reformatDate) : report.updateIn(['config', 'filters', 'dateRange', 'value', 'date'], reformatDate);
  }
  report = report.updateIn(['config', 'filters', 'custom'], List(), filters => filters.map(filter => {
    if (filter.get('dateTimeFormat') !== 'DATE') {
      return filter;
    }
    filter = filter.update('value', reformatDate);
    return filter.get('operator') === BETWEEN ? filter.update('highValue', reformatDate) : filter;
  }));
  return {
    report
  };
};
const NULL_CROSS_OBJECT = CrossObject(null);
const fixInvalidFields = ({
  report
}) => {
  if (is(report.getIn(['config', 'crossObject'], NULL_CROSS_OBJECT), NULL_CROSS_OBJECT)) {
    report = report.deleteIn(['config', 'crossObject']);
  }
  return {
    report
  };
};
const fixMissingMetricTypes = ({
  report
}) => ({
  report: report.getIn(['config', 'dataType']) !== UNIFIED_EVENTS ? report.updateIn(['config', 'metrics'], metrics => metrics.map(metric => metric.update('metricTypes', types => types && types.count() > 0 ? types : List([SUM])))) : report
});
const fixCountMetricType = ({
  report
}) => ({
  report: report.updateIn(['config', 'metrics'], metrics => metrics.map(metric => metric.get('property') === 'count' ? metric.set('metricTypes', List([SUM])) : metric))
});
const fixZeroLimit = ({
  report
}) => ({
  report: report.getIn(['config', 'limit']) === 0 ? report.deleteIn(['config', 'limit']) : report
});
const fixFunnelLimit = ({
  report
}) => ({
  report: report.get('chartType') !== TABLE && [FUNNEL, PIPELINE].includes(report.getIn(['config', 'configType'])) ? report.deleteIn(['config', 'limit']) : report
});
const fixComparisonDatawellLimit = ({
  report
}) => ({
  report: report.get('chartType') === DATA_WELL ? report.updateIn(['config', 'limit'], limit => limit === undefined ? DEFAULT_LIMIT : limit) : report
});
const fixQuotaProperties = ({
  report
}) => ({
  report: report.updateIn(['config', 'metrics'], metrics => metrics.filter(metric => !metric.get('property', '').startsWith(`${QUOTA_NAMESPACE}.`)))
});
const fixPaginationForUE = ({
  report
}) => {
  const limit = report.getIn(['config', 'limit']);
  const isSupportedUnifiedEventsReport = isSupportedUEReport(report) && limit;
  if (isSupportedUnifiedEventsReport) {
    const apiSettings = getPaginationApiSettings(report);
    const fixedReport = report.setIn(['config', 'limit'], apiSettings.get('limit')).setIn(['config', 'offset'], apiSettings.get('offset'));
    return {
      report: fixedReport,
      externalizedData: {
        offset: fixedReport.getIn(['config', 'offset'])
      }
    };
  }
  return {
    report
  };
};
const fixOffsetForSearchReports = ({
  report
}) => {
  const reportConfigType = report.getIn(['config', 'configType']);
  const isSearchReport = reportConfigType === SEARCH;
  if (isSearchReport) {
    return {
      report: report.setIn(['config', 'offset'], 0),
      externalizedData: {
        offset: report.getIn(['config', 'offset'])
      }
    };
  }
  return {
    report
  };
};
const fixSearchDimensions = ({
  report
}) => {
  const dimensions = report.getIn(['config', 'dimensions']);
  if (report.getIn(['config', 'configType']) === SEARCH && (!dimensions || dimensions.size !== 0)) {
    return {
      report: report.setIn(['config', 'dimensions'], List())
    };
  }
  return {
    report
  };
};
const makeFixes = fns => fns.map(fn => ({
  report,
  externalizedData
}, gates) => {
  const result = fn({
    report,
    externalizedData
  }, gates);
  return {
    report: result.report,
    externalizedData: Object.assign({}, result.externalizedData, externalizedData)
  };
});
const reportFixes = makeFixes([fixCrmObject, fixDateFilters, fixInvalidFields, fixMissingMetricTypes, fixCountMetricType, fixZeroLimit, fixFunnelLimit, fixQuotaProperties, fixSearchDimensions]);
const endpointFixes = makeFixes([fixOffsetForSearchReports, fixPaginationForUE]);
const compareFixes = makeFixes([fixComparisonDatawellLimit]);
export const applyReportFixes = report => reportFixes.reduce((data, fix) => fix(data), {
  report,
  externalizedData: {}
});
export const applyEndpointFixes = (report, gates) => endpointFixes.reduce((data, fix) => fix(data, gates), {
  report,
  externalizedData: {},
  gates
});
export const applyCompareFixes = report => compareFixes.reduce((data, fix) => fix(data), {
  report,
  externalizedData: {}
});
export const __TESTABLE__ = {
  fixCrmObject,
  fixDateFilters,
  fixInvalidFields,
  fixMissingMetricTypes,
  fixCountMetricType,
  fixZeroLimit,
  fixFunnelLimit,
  fixOffsetForSearchReports,
  fixPaginationForUE,
  fixQuotaProperties,
  fixComparisonDatawellLimit,
  fixSearchDimensions
};